"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommandFactory = exports.CommnadDescription = exports.AbstractCommand = exports.CommandFlag = exports.CommandStatus = void 0;
const ltpoint_1 = require("../cad/ltpoint");
const ltutils_1 = require("../cad/ltutils");
var CommandStatus;
(function (CommandStatus) {
    CommandStatus[CommandStatus["eOK"] = 0] = "eOK";
    CommandStatus[CommandStatus["eError"] = 1] = "eError";
    CommandStatus[CommandStatus["eDone"] = 2] = "eDone";
    CommandStatus[CommandStatus["eCancel"] = 3] = "eCancel";
    CommandStatus[CommandStatus["eKeyWord"] = 4] = "eKeyWord";
})(CommandStatus || (exports.CommandStatus = CommandStatus = {}));
;
var CommandFlag;
(function (CommandFlag) {
    CommandFlag[CommandFlag["CommmandTransparent"] = 1] = "CommmandTransparent";
    CommandFlag[CommandFlag["CommandSnap"] = 2] = "CommandSnap";
    CommandFlag[CommandFlag["CommandSaveSelect"] = 4] = "CommandSaveSelect";
    //命令行不可触发;
    CommandFlag[CommandFlag["CommmandNoCommandLine"] = 8] = "CommmandNoCommandLine";
})(CommandFlag || (exports.CommandFlag = CommandFlag = {}));
;
class AbstractCommand {
    commandFlags() {
        return CommandFlag.CommandSnap;
    }
    onMouseUp(mouse) {
        return CommandStatus.eOK;
    }
    onMouseMove(mouse) {
        return CommandStatus.eOK;
    }
    onMouseDown(mouse) {
        return CommandStatus.eOK;
    }
    onKeyDown(key_event) {
        return CommandStatus.eOK;
    }
}
exports.AbstractCommand = AbstractCommand;
class GeneratorObject {
    constructor(command_name, generator) {
        this._callback = null;
        this._input_type = ltutils_1.InputType.None;
        this._command_param_list = [];
        this._base_point = null;
        this._command_tip = "";
        this._command_name = "";
        this._button_key_list = [];
        this._command_name = command_name;
        this._generator = generator;
    }
    reset() {
        this._callback = null;
        this._base_point = null;
        this._input_type = ltutils_1.InputType.None;
    }
    set commandTip(val) {
        this._command_tip = val;
        this.initKeys(val);
    }
    get commandTip() {
        return this._command_tip;
    }
    //获取默认值;
    getDefaultValue(str = "") {
        if (str == "") {
            str = this._command_tip || "";
        }
        let regexs = /\<(.+?)\>/g;
        let sub_list = str.match(regexs);
        if (sub_list != null && sub_list.length == 1) {
            return sub_list[0].replace("<", "").replace(">", "");
        }
        return "";
    }
    //判断是否包含关键字列表;
    initKeys(str) {
        if (this._command_tip == null) {
            this._button_key_list = [];
            return;
        }
        let regex = /\[(.+?)\]/g; // 中括号
        if (regex == null) {
            this._button_key_list = [];
            return;
        }
        let match_str = str.match(regex);
        if (match_str == null || match_str.length == 0) {
            this._button_key_list = [];
            return;
        }
        let val = match_str[0];
        this._button_key_list = val.replace("[", "").replace("]", "").split(" ");
    }
    getButtonKeyList() {
        return this._button_key_list;
    }
    get_key_list() {
        let key_list = [];
        for (let value of this._button_key_list) {
            let key_regex = /\((.+?)\)/g; // 小括号
            let match_keystr = value.match(key_regex);
            if (match_keystr == null || match_keystr.length == 0) {
                continue;
            }
            let sub_key = match_keystr[0].replace("(", "").replace(")", "");
            key_list.push(sub_key.toUpperCase());
        }
        return key_list;
    }
    hasKeyWord(str) {
        let key_list = this.get_key_list();
        if (key_list.length == 0) {
            return false;
        }
        if (key_list.indexOf(str.toUpperCase()) >= 0) {
            return true;
        }
        return false;
    }
}
class CommnadDescription {
    constructor(command_name, command, command_tip = "", comand_flag = CommandFlag.CommandSnap) {
        this._command_name = "";
        this._command_tip = "";
        this._command_flag = CommandFlag.CommandSnap;
        this._command_name = command_name;
        this._command = command;
        this._command_tip = command_tip;
        this._command_flag = comand_flag;
    }
    hasFlag(flag) {
        return (this._command_flag & flag) != 0;
    }
    get commandObject() {
        return this._command;
    }
    get commandTip() {
        return this._command_tip;
    }
}
exports.CommnadDescription = CommnadDescription;
class CommandFactory {
    //使用map存储;
    constructor() {
        this.current_command_list = [];
        //历史命令列表;
        this._histroy_command_list = [];
        //上一次的点;
        this._last_position = null;
        //数值的默认值;
        this._number_default_value = null;
        this._custom_key_funtion = null;
        this._command_list = new Map();
        //当前命令;
    }
    commandList() {
        return this._command_list;
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new CommandFactory();
        }
        return this.instance;
    }
    get currentCommand() {
        if (this.current_command_list.length == 0) {
            return null;
        }
        return this.current_command_list[this.current_command_list.length - 1];
    }
    get currentCommandDescription() {
        let current_command = this.currentCommand;
        if (current_command == null) {
            return null;
        }
        if (current_command instanceof AbstractCommand) {
            return null;
        }
        return this._command_list.get(current_command._command_name);
    }
    get currentCommandFlag() {
        let current_command = this.currentCommand;
        if (current_command == null) {
            return CommandFlag.CommandSnap;
        }
        if (current_command instanceof AbstractCommand) {
            return CommandFlag.CommandSnap;
        }
        if (!this._command_list.has(current_command._command_name)) {
            return CommandFlag.CommandSnap;
        }
        let commnad = this._command_list.get(current_command._command_name);
        if (commnad === undefined) {
            return CommandFlag.CommandSnap;
        }
        return commnad._command_flag;
    }
    get lastPosition() {
        return this._last_position;
    }
    setCustomKeyFunction(key_funtion) {
        this._custom_key_funtion = key_funtion;
    }
    getCommand(command_name) {
        command_name = command_name.trim();
        if (window.CustomCommandManager != null) {
            command_name = window.CustomCommandManager.findCommandBySimpleName(command_name);
        }
        let has_command = this._command_list.has(command_name);
        if (has_command) {
            return this._command_list.get(command_name);
        }
        return null;
    }
    execKeyCommand(key_event, cad_map) {
        var key = key_event.code;
        if (this.currentCommand === null) {
            //按下空格或者回车是否按下;
            if (key == "Space" || key == "Enter") {
                //执行历史命令;
                if (this._histroy_command_list.length > 0) {
                    let command_name = this._histroy_command_list.pop();
                    if (command_name != null) {
                        return this.execCommand(command_name, cad_map);
                    }
                }
            }
            let command_name = '';
            if (this._custom_key_funtion == null) {
                command_name = "$" + key.toLowerCase();
            }
            else {
                command_name = this._custom_key_funtion(key_event);
            }
            var is_ok = this._command_list.has(command_name);
            if (is_ok) {
                return this.execCommand(command_name, cad_map);
            }
            return false;
        }
        if (this.currentCommand instanceof AbstractCommand) {
            this.currentCommand.onKeyDown(key_event);
        }
        else {
            //获取默认值;
            let default_value;
            if (key == "Space" || key == "Enter") {
                default_value = this.currentCommand.getDefaultValue();
            }
            else {
                default_value = key_event.key.toLowerCase();
            }
            if (default_value !== "") {
                if (!this.currentCommand.hasKeyWord(default_value)) {
                    return false;
                }
                return this.putString(cad_map, default_value);
            }
        }
        return false;
    }
    execYieldCommand(commandName, cad_map, command_param = null) {
        let real_command_name = commandName.trim();
        if (window.window.CustomCommandManager != null) {
            real_command_name = window.CustomCommandManager.findCommandBySimpleName(real_command_name);
        }
        return this._execCommand(real_command_name, cad_map, command_param, true);
    }
    execCommand(commandName, cad_map, command_param = null) {
        let is_ok = this._execCommand(commandName.trim(), cad_map, command_param);
        if (is_ok) {
            //保留最后的十个命令;
            this._histroy_command_list.push(commandName);
            if (this._histroy_command_list.length > 10) {
                this._histroy_command_list.shift();
            }
        }
    }
    _execCommand(commandName, cad_map, command_param = null, is_yeild = false) {
        let command_name = "";
        let command_param_list = [];
        if (commandName.startsWith("{")) {
            let json_value = JSON.parse(commandName);
            command_name = json_value.commandName;
            if (command_param == null) {
                command_param = json_value.paramMap;
            }
            if (json_value.paramList != null) {
                command_param_list = json_value.paramList;
            }
        }
        else {
            command_name = commandName.toLowerCase();
        }
        let command = this.getCommand(command_name);
        if (command == null) {
            cad_map.printMessage(`命令${command_name}没有找到`);
            return false;
        }
        let current_command = null;
        //目前只能通过参数个数来判断;
        if (command.commandObject.length == 2) {
            current_command = command.commandObject(cad_map, command_param);
        }
        else {
            current_command = command.commandObject();
        }
        if (current_command == null) {
            return false;
        }
        else {
            cad_map.startCommand();
            cad_map.focus();
            if (current_command instanceof AbstractCommand) {
                current_command.form = command_param;
                this.current_command_list.push(current_command);
                if (current_command.commandFlags() == CommandFlag.CommmandTransparent) {
                    current_command.active(cad_map);
                    return true;
                }
                cad_map.cursorType = ltutils_1.MapCursorType.CursorInCommand;
                let status = current_command.active(cad_map);
                if (status == CommandStatus.eDone) {
                    cad_map.finishCommand();
                }
            }
            else {
                cad_map.cursorType = ltutils_1.MapCursorType.CursorInCommand;
                let command_object = new GeneratorObject(command_name, current_command);
                command_object._command_param_list = command_param_list;
                this.current_command_list.push(command_object);
                let result = command_object._generator.next();
                setTimeout(() => {
                    //不加定时器会出错;
                    while (result.done && this.current_command_list.length > 0) {
                        cad_map.printCommandTip("");
                        this._resetChildCommand(cad_map);
                        let temp_current_command = this.currentCommandDescription;
                        this.current_command_list.pop();
                        if (this.currentCommand != null && !(this.currentCommand instanceof AbstractCommand)) {
                            result = this.currentCommand._generator.next(result.value);
                        }
                        else if (this.currentCommand == null) {
                            cad_map.finishCommand(temp_current_command);
                        }
                    }
                }, 0);
            }
        }
        return true;
    }
    execCommandFun(command_name, command, cad_map, param) {
        cad_map.startCommand();
        cad_map.focus();
        cad_map.cursorType = ltutils_1.MapCursorType.CursorInCommand;
        let command_object = new GeneratorObject(command_name, command(cad_map, param));
        this.current_command_list.push(command_object);
        let result = command_object._generator.next();
        setTimeout(() => {
            //不加定时器会出错;
            while (result.done && this.current_command_list.length > 0) {
                cad_map.printCommandTip("");
                this._resetChildCommand(cad_map);
                let temp_current_command = this.currentCommandDescription;
                this.current_command_list.pop();
                if (this.currentCommand != null && !(this.currentCommand instanceof AbstractCommand)) {
                    result = this.currentCommand._generator.next(result.value);
                }
                else if (this.currentCommand == null) {
                    cad_map.finishCommand(temp_current_command);
                }
            }
        }, 0);
    }
    registerCommand(name, command, command_tip = "", comand_flag = CommandFlag.CommandSnap) {
        if (this._command_list.has(name.toLowerCase())) {
            return;
        }
        this._command_list.set(name.toLowerCase(), new CommnadDescription(name, command, command_tip, comand_flag));
    }
    _reset(map) {
        //结束当前命令;
        this._resetChildCommand(map);
        map.cursorType = ltutils_1.MapCursorType.CursorDefault;
        for (let i = this.current_command_list.length - 1; i >= 0; --i) {
            let command = this.current_command_list[i];
            if (command instanceof AbstractCommand) {
                command.destroy();
            }
            else {
                let result = command._generator.next({ value: null, done: CommandStatus.eCancel });
                if (result.done == false) {
                    alert(`命令${command._command_name}没有正常取消`);
                }
            }
        }
        this.current_command_list = [];
    }
    _resetChildCommand(map) {
        this._last_position = null;
        map.focus();
        map.snap.clear(map);
        //隐藏编辑框;
        map.distanceEditHiden();
    }
    onMouseDown(map, mouse) {
        if (this.currentCommand == null) {
            return CommandStatus.eDone;
        }
        if (this.currentCommand instanceof AbstractCommand) {
            return this.currentCommand.onMouseDown(mouse);
        }
        if (mouse.button == ltutils_1.ButtonStatus.RightButton) {
            let result;
            if (this.currentCommand.getDefaultValue().length > 0) {
                //显示右键菜单;
                map.showContent();
                return CommandStatus.eDone;
            }
            if (this.currentCommand._input_type == ltutils_1.InputType.InputPoint) {
                this.currentCommand.reset();
                result = this.currentCommand._generator.next({ value: mouse.mapPos, done: CommandStatus.eDone });
            }
            else {
                //获取提示的默认值;
                let default_value = this.currentCommand.getDefaultValue();
                return this.putString(map, default_value);
            }
            while (result.done && this.current_command_list.length > 0) {
                window.cadmap.printCommandTip("");
                //隐藏编辑框;
                this._resetChildCommand(map);
                let temp_current_command = this.currentCommandDescription;
                this.current_command_list.pop();
                if (this.currentCommand) {
                    result = this.currentCommand._generator.next(result.value);
                }
                else {
                    map.finishCommand(temp_current_command);
                }
            }
        }
        else {
            //字符串的时候左键 屏蔽
            if (this.currentCommand._input_type == ltutils_1.InputType.InputString) {
                return;
            }
            return this.putPoint(map, mouse.mapPos);
        }
    }
    onMouseMove(mouse) {
        if (this.currentCommand == null) {
            return CommandStatus.eDone;
        }
        if (this.currentCommand instanceof AbstractCommand) {
            return this.currentCommand.onMouseMove(mouse);
        }
        if (this.callback != null) {
            this.callback(mouse);
        }
        return CommandStatus.eOK;
    }
    putPoint(map, map_pos) {
        if (this.currentCommand == null || this.currentCommand instanceof AbstractCommand) {
            return { value: null, done: CommandStatus.eDone };
        }
        let input_type = this.currentCommand._input_type;
        let result;
        if (input_type == ltutils_1.InputType.InputAngle && this.currentCommand._base_point != null) {
            let angle = this.currentCommand._base_point.getAngleTo(map_pos);
            this.currentCommand.reset();
            map.printMessage(this.currentCommand.commandTip + `${angle.toFixed(3)}`);
            result = this.currentCommand._generator.next({ value: angle, done: CommandStatus.eOK });
        }
        else if (input_type == ltutils_1.InputType.InputNumber) {
            return { value: null, done: CommandStatus.eDone };
        }
        else if (input_type == ltutils_1.InputType.InputDistance && this.currentCommand._base_point != null) {
            let distance = this.currentCommand._base_point.getDistanceTo(map_pos);
            this.currentCommand.reset();
            this._number_default_value = distance;
            map.printMessage(this.currentCommand.commandTip + distance.toFixed(3));
            result = this.currentCommand._generator.next({ value: distance, done: CommandStatus.eOK });
        }
        else {
            this.currentCommand.reset();
            this._last_position = map_pos;
            map.printMessage(this.currentCommand.commandTip + `${map_pos.x.toFixed(3)},${map_pos.y.toFixed(3)}`);
            result = this.currentCommand._generator.next({ value: map_pos, done: CommandStatus.eOK });
        }
        while (result.done && this.current_command_list.length > 0) {
            map.printCommandTip("");
            //隐藏编辑框;
            this._resetChildCommand(map);
            let temp_current_command = this.currentCommandDescription;
            this.current_command_list.pop();
            if (this.currentCommand) {
                result = this.currentCommand._generator.next(result.value);
            }
            else {
                map.finishCommand(temp_current_command);
                break;
            }
        }
        return { value: null, done: CommandStatus.eOK };
    }
    putString(map, str) {
        window.onGbMouse();
        if (this.currentCommand == null || this.currentCommand instanceof AbstractCommand) {
            return { value: null, done: CommandStatus.eDone };
        }
        let result;
        let input_type = this.currentCommand._input_type;
        //判断是否输入关键字;
        if (this.currentCommand.hasKeyWord(str)) {
            result = this.currentCommand._generator.next({ value: str, done: CommandStatus.eKeyWord });
        }
        else {
            if (input_type == ltutils_1.InputType.InputAngle) {
                let angle = parseFloat(str);
                if (isNaN(angle)) {
                    this.currentCommand.reset();
                    return { value: null, done: CommandStatus.eDone };
                }
                //转为弧度
                angle = (angle * 1 * Math.PI) / 180;
                this._number_default_value = angle;
                map.printMessage(this.currentCommand.commandTip + `${angle.toFixed(3)}`);
                result = this.currentCommand._generator.next({ value: angle, done: CommandStatus.eOK });
            }
            else if (input_type == ltutils_1.InputType.InputNumber || input_type == ltutils_1.InputType.InputDistance) {
                let num = parseFloat(str);
                if (isNaN(num)) {
                    this.currentCommand.reset();
                    return { value: null, done: CommandStatus.eDone };
                }
                this._number_default_value = num;
                map.printMessage(this.currentCommand.commandTip + `${num}`);
                result = this.currentCommand._generator.next({ value: num, done: CommandStatus.eOK });
            }
            else if (input_type == ltutils_1.InputType.InputPoint) {
                let point_string = str.split(",");
                if (point_string.length == 1) {
                    if (this.currentCommand._base_point == null || window.cadmap.movingPos == null) {
                        return { value: null, done: CommandStatus.eDone };
                    }
                    let distance = parseFloat(point_string[0]);
                    let angle = 0;
                    if (isNaN(distance)) {
                        if (str.startsWith("@")) {
                            let sub_str = str.substring(1);
                            let sub_values = sub_str.split("<");
                            if (sub_values.length == 2) {
                                distance = parseFloat(sub_values[0]);
                                angle = parseFloat(sub_values[0]);
                            }
                            else {
                                return { value: null, done: CommandStatus.eDone };
                            }
                        }
                        else {
                            return { value: null, done: CommandStatus.eDone };
                        }
                    }
                    else {
                        angle = this.currentCommand._base_point.getAngleTo(window.cadmap.movingPos);
                    }
                    //通过角度和距离计算点;
                    let point = this.currentCommand._base_point.calculatePoint(angle, distance);
                    //计算鼠标移动点和基点的角度;
                    this.currentCommand.reset();
                    result = this.currentCommand._generator.next({ value: point, done: CommandStatus.eOK });
                }
                else if (point_string.length == 2) {
                    let x = parseFloat(point_string[0]);
                    let y = parseFloat(point_string[1]);
                    if (isNaN(x) || isNaN(y)) {
                        return { value: null, done: CommandStatus.eDone };
                    }
                    this.currentCommand.reset();
                    this._last_position = new ltpoint_1.LTPoint(x, y);
                    map.printMessage(this.currentCommand.commandTip + `${x.toFixed(3)},${y.toFixed(3)}`);
                    result = this.currentCommand._generator.next({ value: new ltpoint_1.LTPoint(x, y), done: CommandStatus.eOK });
                }
                else {
                    return { value: null, done: CommandStatus.eDone };
                }
            }
            else {
                this.currentCommand.reset();
                map.printMessage(this.currentCommand.commandTip + `${str}`);
                result = this.currentCommand._generator.next({ value: str, done: CommandStatus.eOK });
            }
        }
        while (result.done && this.current_command_list.length > 0) {
            window.cadmap.printCommandTip("");
            //隐藏编辑框;
            this._resetChildCommand(map);
            let temp_current_command = this.currentCommandDescription;
            this.current_command_list.pop();
            if (this.currentCommand) {
                result = this.currentCommand._generator.next(result.value);
            }
            else {
                map.finishCommand(temp_current_command);
                break;
            }
        }
        return { value: null, done: CommandStatus.eOK };
    }
    _getPoint(map, tip, input_type, callback = null, base_point = null) {
        if (this.currentCommand == null || this.currentCommand instanceof AbstractCommand) {
            return;
        }
        window.onGbMouse();
        this.currentCommand._input_type = input_type;
        this.currentCommand._callback = callback;
        this.currentCommand.commandTip = tip;
        this.currentCommand._base_point = base_point;
        map.printCommandTip(tip);
        if (this.currentCommand._command_param_list.length > 0) {
            let param = this.currentCommand._command_param_list.pop();
            setTimeout(() => {
                if (param != null) {
                    this.putString(map, param);
                }
            }, 10);
        }
    }
    getPointAndString(map, tip, callback = null, base_point = null) {
        this._getPoint(map, tip, ltutils_1.InputType.InputPointAndString, callback, base_point);
    }
    getPoint(map, tip, callback = null, base_point = null) {
        this._getPoint(map, tip, ltutils_1.InputType.InputPoint, callback, base_point);
    }
    getNumber(map, tip, callback = null) {
        return this._getNumberValue(map, ltutils_1.InputType.InputNumber, tip, callback, null);
    }
    getDistance(map, tip, callback = null, base_point = null) {
        return this._getNumberValue(map, ltutils_1.InputType.InputDistance, tip, callback, base_point);
    }
    _getNumberValue(map, input_type, tip, callback = null, base_point = null) {
        window.onGbMouse();
        if (this.currentCommand == null || this.currentCommand instanceof AbstractCommand) {
            return { value: null, done: CommandStatus.eCancel };
        }
        //判断是否已经有默认值,没有默认值增加默认值;
        if (this._number_default_value !== null) {
            let default_value = this.currentCommand.getDefaultValue(tip);
            if (default_value == "") {
                if (tip.endsWith(":") || tip.endsWith("：")) {
                    tip = tip.slice(0, -1);
                }
                tip += `<${this._number_default_value.toFixed(3)}>:`;
            }
        }
        this.currentCommand._input_type = input_type;
        this.currentCommand._callback = callback;
        this.currentCommand._base_point = base_point;
        this.currentCommand.commandTip = tip;
        map.printCommandTip(tip);
        if (this.currentCommand._command_param_list.length > 0) {
            let param = this.currentCommand._command_param_list.pop();
            setTimeout(() => {
                if (param != null) {
                    this.putString(map, param);
                }
            }, 10);
        }
    }
    getAngle(map, tip, callback = null, base_point = null) {
        return this._getNumberValue(map, ltutils_1.InputType.InputAngle, tip, callback, base_point);
    }
    getString(map, tip, callback = null) {
        window.onGbMouse();
        if (this.currentCommand == null || this.currentCommand instanceof AbstractCommand) {
            return { value: null, done: CommandStatus.eCancel };
        }
        if (this.currentCommand) {
            this.currentCommand._input_type = ltutils_1.InputType.InputString;
            this.currentCommand._callback = callback;
            this.currentCommand.commandTip = tip;
            map.printCommandTip(tip);
        }
        if (this.currentCommand._command_param_list.length > 0) {
            let param = this.currentCommand._command_param_list.pop();
            setTimeout(() => {
                if (param != null) {
                    this.putString(map, param);
                }
            }, 10);
        }
    }
    get callback() {
        if (this.currentCommand == null) {
            return null;
        }
        if (this.currentCommand instanceof AbstractCommand) {
            return null;
        }
        return this.currentCommand._callback;
    }
}
exports.CommandFactory = CommandFactory;
