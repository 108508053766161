"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PipeNodeType = exports.PipeNode = exports.CoordSystem = void 0;
var CoordSystem;
(function (CoordSystem) {
    CoordSystem[CoordSystem["World"] = 0] = "World";
    CoordSystem[CoordSystem["Local"] = 1] = "Local";
})(CoordSystem || (exports.CoordSystem = CoordSystem = {}));
class PipeNode {
    constructor(point, type, coordSystem = CoordSystem.World) {
        this._count = 0;
        this._children = new Array();
        this._depth = -1; //管点在树中的深度
        this._id = -1;
        this._distanceToRoot = 0; //管点顺着管道计算，距离根节点的累计距离
        this._point = point;
        this._type = type;
        this._coordSystem = coordSystem;
        /*if (point.equals2D(new Coordinate(-6189.038258561306, -2473.013624079388), 1)) {
          debugger
        }*/
    }
    addChildNode(child) {
        /*if (child._point.equals2D(new Coordinate(4878596.098, -1128504.332), 1) || (TestUtil.coordinateUtil && TestUtil.coordinateUtil.transformPointToWorld(child._point).equals2D(new Coordinate(4878596.098, -1128504.332), 1))) {
          console.log(child._point)
          debugger
        }*/
        /*if (this._point.equals2D(child._point)) {
          debugger
        }*/
        this._children.push(child);
        child._parent = this;
    }
    removeChildNode(child) {
        this._children.splice(this._children.indexOf(child), 1);
        child._parent = undefined;
    }
    //#region 房间内计算管径专用
    computeChildrenNodes() {
        this._count = 0;
        this.traverse(this);
        return this._count;
    }
    traverse(node) {
        for (let child of node._children) {
            if (child._type == PipeNodeType.Child) { //只有类型为Child的才是喷头管点
                this._count++;
            }
        }
        for (let childNode of node._children) {
            this.traverse(childNode);
        }
    }
    traverseToSetDepth() {
        for (let child of this._children) {
            child._depth = this._depth + 1;
            child.traverseToSetDepth();
        }
    }
    traverseToSetDistanceToRoot() {
        for (let child of this._children) {
            child._distanceToRoot = this._distanceToRoot + this._point.distance(child._point);
            child.traverseToSetDistanceToRoot();
        }
    }
    getRootNode() {
        if (!this._parent)
            return this;
        let parent = this._parent;
        let times = 0;
        while (true) {
            times++;
            if (times > 10000) { //防止死循环，加个控制
                debugger;
                throw Error("遍历次数过多，可能陷入了死循环");
            }
            if (parent._parent)
                parent = parent._parent;
            else
                return parent;
        }
    }
    //货架喷淋专用方法。计算立管所带的喷头数量
    computeRiserCount() {
        this.traverseToComputeRiserCount(this);
        return this._count;
    }
    //货架喷淋专用方法。计算立管所带的喷头数量（立管节点也有父子关系，父立管节点需要加上子立管节点的喷头数量）
    traverseToComputeRiserCount(node) {
        for (let child of node._children) {
            if (child._type == PipeNodeType.Child) { //Child类型为立管
                this._count += child._count;
            }
        }
        for (let child of node._children) {
            this.traverseToComputeRiserCount(child);
        }
    }
    static traverseToGetNodes(node, nodes) {
        for (let child of node._children) {
            nodes.push(child);
            this.traverseToGetNodes(child, nodes);
        }
    }
    static findDeeperRelationshipNode(node_child, node_parent) {
        while (node_child) {
            if (node_child._parent == node_parent) {
                return node_child;
            }
            node_child = node_child._parent;
        }
        return undefined;
    }
}
exports.PipeNode = PipeNode;
var PipeNodeType;
(function (PipeNodeType) {
    PipeNodeType[PipeNodeType["Main"] = 1] = "Main";
    PipeNodeType[PipeNodeType["Child"] = 2] = "Child";
    PipeNodeType[PipeNodeType["Valve"] = 3] = "Valve";
    PipeNodeType[PipeNodeType["ChildLink"] = 4] = "ChildLink";
})(PipeNodeType || (exports.PipeNodeType = PipeNodeType = {}));
