"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrayRect = void 0;
const ltentity_1 = require("../cad/ltentity");
const ltpoint_1 = require("../cad/ltpoint");
const ltfeature_1 = require("../data/ltfeature");
const commandfactory_1 = require("../map/commandfactory");
class arrayRectImpl {
    constructor(map, blockEntity, selected_features) {
        this._map = {};
        this._blockEntity = {};
        this._selected_features = [];
        this._map = map;
        this._blockEntity = blockEntity;
        this._selected_features = selected_features;
    }
    clear() {
        this._map.removeGraphics(this._blockEntity, this._map.trackGraphics);
    }
    renderTrack() {
        this._map.removeGraphics(this._blockEntity, this._map.trackGraphics);
        this._blockEntity.worldDraw(this._map.canvas, true, null, this._map.trackGraphics);
    }
    save() {
        this.clear();
        var fea = new ltfeature_1.LTFeature({
            geometry: this._blockEntity,
            layer: this._map.activeLayer()
        });
        this._map.undoStack().beginMacro("矩形阵列");
        this._map.addFeature(fea);
        this._map.removeFeatures(this._selected_features);
        this._map.undoStack().endMacro();
        this._map.printMessage("完成矩形阵列");
    }
}
function* arrayRect(map, command_param) {
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, null);
    let select_feature_command = yield;
    if (select_feature_command.done === commandfactory_1.CommandStatus.eCancel || select_feature_command.done === commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    if (map.selectedFeatures().length == 0) {
        map.printMessage("未选择对象，请选择要添加的图形对象");
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let server = map.dwgservice;
    if (server == null) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    //取中心
    let selectFeatures = map.selectedFeatures();
    let rbox = ltpoint_1.RBox.nullBox();
    selectFeatures.forEach(feature => {
        let bbox = feature.getGeometry().getBoundingBox();
        rbox.growToInclude(bbox);
    });
    let center = rbox.getCenter();
    //直接创建
    let blockRef = new ltentity_1.LTBlockReference();
    selectFeatures.forEach(feature => {
        let geo = feature.getGeometry().clone();
        geo.move(new ltpoint_1.LTPoint(-center.x, -center.y));
        blockRef.addEntity(geo);
        map.removeFeatures(feature);
    });
    //获取块名
    let index = 1;
    while (server.hasBlock(String("AR" + index))) {
        index++;
    }
    let blockName = "AR" + index;
    server.setBlock(blockName, blockRef);
    //增加块
    let blockEntity = new ltentity_1.LTEntityPoint();
    blockEntity.blockName = blockName;
    blockEntity.setPoint(center);
    blockEntity.setLayerName(map.activeLayer().name);
    blockEntity.subData().columnCount = 3;
    blockEntity.subData().rowCount = 4;
    blockEntity.subData().rowSpacing = rbox.getHeight() * 1.5;
    blockEntity.subData().columnSpacing = rbox.getWidth() * 1.5;
    let impl = new arrayRectImpl(map, blockEntity, selectFeatures);
    //临时效果
    impl.renderTrack();
    //关联(AS)/基点(B)/计数(COU)/间距(S)/列数(COL)/行数(R)/层数(L)/退出(X
    while (true) {
        //"喷淋绘制[自喷立管(1) 水流指示器(2) 自喷管(3) 主管道(4) 喷淋布置(5)]<5>:
        commandfactory_1.CommandFactory.getInstance().getString(map, "矩形阵列[列数(C) 列距离(S) 行数(R) 行距离(L) 退出(X)]<X>");
        let input_replace = yield;
        if (input_replace.done === commandfactory_1.CommandStatus.eCancel || input_replace.done === commandfactory_1.CommandStatus.eDone) {
            impl.save();
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
        if (input_replace.value === "S") {
            commandfactory_1.CommandFactory.getInstance().getDistance(map, "列距离<" + blockEntity.subData().columnSpacing + ">:");
            let columnSpacing = yield;
            if (columnSpacing.done === commandfactory_1.CommandStatus.eCancel || columnSpacing.done === commandfactory_1.CommandStatus.eDone) {
                impl.save();
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            blockEntity.subData().columnSpacing = columnSpacing.value;
            impl.renderTrack();
            continue;
        }
        if (input_replace.value === "C") {
            commandfactory_1.CommandFactory.getInstance().getString(map, "列数<" + blockEntity.subData().columnCount + ">:");
            let columnCount = yield;
            if (columnCount.done === commandfactory_1.CommandStatus.eCancel || columnCount.done === commandfactory_1.CommandStatus.eDone) {
                impl.save();
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            blockEntity.subData().columnCount = columnCount.value;
            impl.renderTrack();
            continue;
        }
        if (input_replace.value === "R") {
            commandfactory_1.CommandFactory.getInstance().getString(map, "行数<" + blockEntity.subData().rowCount + ">:");
            let rowCount = yield;
            if (rowCount.done === commandfactory_1.CommandStatus.eCancel || rowCount.done === commandfactory_1.CommandStatus.eDone) {
                impl.save();
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            blockEntity.subData().rowCount = rowCount.value;
            impl.renderTrack();
            continue;
        }
        if (input_replace.value === "L") {
            commandfactory_1.CommandFactory.getInstance().getDistance(map, "行距离<" + blockEntity.subData().rowSpacing + ">:");
            let rowSpacing = yield;
            if (rowSpacing.done === commandfactory_1.CommandStatus.eCancel || rowSpacing.done === commandfactory_1.CommandStatus.eDone) {
                impl.save();
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            blockEntity.subData().rowSpacing = rowSpacing.value;
            impl.renderTrack();
            continue;
        }
        if (input_replace.value === "X") {
            impl.save();
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
    }
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.arrayRect = arrayRect;
