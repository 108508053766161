"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LTReactor = void 0;
class LTReactor {
    constructor(map) {
        this._map = map;
    }
    featureAppended(feature) {
        return true;
    }
    featureModified(feature) {
        return true;
    }
    featureErased(feature) {
        return true;
    }
}
exports.LTReactor = LTReactor;
