"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DimensionDrawer = void 0;
const ltentity_1 = require("../../cadplugins/cad/ltentity");
const ltpoint_1 = require("../../cadplugins/cad/ltpoint");
const ltfeature_1 = require("../../cadplugins/data/ltfeature");
const ltfields_1 = require("../../cadplugins/data/ltfields");
const dimension_1 = require("../../cadplugins/cad/dimension");
class DimensionDrawer {
    constructor(dimensionLayer, cadMap) {
        this._dimensionLayer = dimensionLayer;
        this._cadMap = cadMap;
    }
    draw(dimension, drawscale) {
        let p1 = new ltpoint_1.LTPoint(dimension._point0.x, dimension._point0.y);
        let p2 = new ltpoint_1.LTPoint(dimension._point1.x, dimension._point1.y);
        let ltDimension = new ltentity_1.LTDimension(p1, p2, "", new ltpoint_1.LTPoint(dimension._dim_point.x, dimension._dim_point.y), new ltpoint_1.LTPoint(dimension._text_pos.x, dimension._text_pos.y), dimension_1.DimensionType.EntityDimAligned);
        //等后台更新个样式
        ltDimension.subData().setDimStyle("sprinkler200", true);
        ltDimension.subData().setDimSacle(drawscale);
        let feature = new ltfeature_1.LTFeature({
            geometry: ltDimension,
            layer: this._dimensionLayer,
            xdata: JSON.stringify({
                source: "autoGenerate",
            }),
        });
        feature.setValue(ltfields_1.FieldNames.EntityLayer, feature.layer.name);
        feature.setValue(ltfields_1.FieldNames.Color, ltentity_1.dwg_bylayer);
        feature.setValue(ltfields_1.FieldNames.Linetype, ltentity_1.dwg_bylayer);
        if (drawscale > 1) {
            this._cadMap.dwgservice.setDimTextXScale("sprinkler200", 0.6);
        }
        this._cadMap.addFeature(feature);
    }
}
exports.DimensionDrawer = DimensionDrawer;
