"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scaleCommand = void 0;
const commandfactory_1 = require("../map/commandfactory");
const ltpoint_1 = require("../cad/ltpoint");
const ltfeature_js_1 = require("../data/ltfeature.js");
const ltentity_1 = require("../cad/ltentity");
const drawutils_1 = require("./drawutils");
class ScaleCommandImpl {
    constructor(cadmap) {
        this._map = {};
        this._canvas = {};
        this.selectFeatures = [];
        this.entitys_clone = [];
        this._dis = 1;
        this._map = cadmap;
        this._canvas = this._map.canvas;
    }
    destroy() {
        this._map.trackGraphics.clear();
    }
    draws(entitys) {
        this._map.trackGraphics.clear();
        entitys.forEach(entity => {
            entity.worldDraw(this._canvas, true, null, this._map.trackGraphics);
        });
        return commandfactory_1.CommandStatus.eOK;
    }
    setBaseDis(dis) {
        this._dis = dis;
    }
    drawsMove(pos, base_point) {
        let factorX = pos.getDistanceTo(base_point);
        let pp = new ltpoint_1.LTPoint(factorX, factorX);
        this.entitys_clone = [];
        this.selectFeatures.forEach(feature => {
            let geo = feature.getGeometry().clone();
            geo.scale(pp, base_point);
            this.entitys_clone.push(geo);
        });
        this.draws(this.entitys_clone);
    }
    save(factorX, base_point) {
        let pp = new ltpoint_1.LTPoint(factorX, factorX);
        this._map.undoStack().beginMacro("scale");
        this.selectFeatures.forEach(feature => {
            var clone_ent = feature.getGeometry().clone();
            feature.getGeometry().scale(pp, base_point);
            this._map.updateFeatureGeometry(feature, clone_ent);
        });
        this._map.undoStack().endMacro();
        this._map.select_command.show();
    }
}
function* scaleCommand(cadmap, param_map) {
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", cadmap, { command_tip: "请选择缩放对象" });
    let select_feature = yield;
    if (select_feature.done === commandfactory_1.CommandStatus.eCancel || select_feature.value.length === 0 || select_feature.done === commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eDone };
    }
    if (select_feature.value.length <= 0) {
        //退出
        cadmap.printMessage("无选择，退出命令！");
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(cadmap, "指定基点:");
    let base_point = yield;
    if (base_point.done === commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let command = new ScaleCommandImpl(cadmap);
    command.selectFeatures = select_feature.value;
    let base_line = new ltentity_1.LTLine(base_point.value, base_point.value);
    let snap_feature = new ltfeature_js_1.LTFeature({ geometry: new ltentity_1.LTEntityPoint(base_point.value) });
    cadmap.snap.setFeature(snap_feature);
    function basemoveCallback(mouse_event) {
        base_line.setEnd(mouse_event.mapPos);
        command.drawsMove(mouse_event.mapPos, base_point.value);
        drawutils_1.DrawUtilImpl.drawDashLine(cadmap, base_line);
    }
    commandfactory_1.CommandFactory.getInstance().getDistance(cadmap, "指定缩放比例:", basemoveCallback, base_point.value);
    let scale_value = yield;
    if (scale_value.done === commandfactory_1.CommandStatus.eCancel || scale_value.done === commandfactory_1.CommandStatus.eDone) {
        drawutils_1.DrawUtilImpl.destroyDashEntity(cadmap, base_line);
        command.destroy();
        return { value: null, done: scale_value.done };
    }
    command.save(scale_value.value, base_point.value);
    drawutils_1.DrawUtilImpl.destroyDashEntity(cadmap, base_line);
    command.destroy();
    return { value: scale_value.value, done: commandfactory_1.CommandStatus.eDone };
}
exports.scaleCommand = scaleCommand;
