"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClosedAreaResult = void 0;
class ClosedAreaResult {
    constructor(polygons, lines) {
        this._polygons = polygons;
        this._lines = lines;
    }
}
exports.ClosedAreaResult = ClosedAreaResult;
