"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoundingBox = void 0;
const ltpoint_1 = require("../cadplugins/cad/ltpoint");
class BoundingBox {
    constructor(points) {
        this.min = new ltpoint_1.LTPoint(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY);
        this.max = new ltpoint_1.LTPoint(Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY);
        if (points) {
            for (let point of points) {
                this.expand(point);
            }
        }
    }
    expand(point) {
        this.min = new ltpoint_1.LTPoint(Math.min(this.min.x, point.x), Math.min(this.min.y, point.y));
        this.max = new ltpoint_1.LTPoint(Math.max(this.max.x, point.x), Math.max(this.max.y, point.y));
    }
    contains(point) {
        return point.x >= this.min.x && point.x <= this.max.x
            && point.y >= this.min.y && point.y <= this.max.y;
    }
    intersect(other) {
        return !(this.min.x > other.max.x || this.min.y > other.max.y || this.max.x < other.min.x || this.max.y < other.min.y);
    }
    intersectTolerance(other, tolerance) {
        return !(this.min.x - other.max.x > tolerance || this.min.y - other.max.y > tolerance || other.min.x - this.max.x > tolerance || other.min.y - this.max.y > tolerance);
    }
    getLengthX() {
        return this.max.x - this.min.x;
    }
    getLengthY() {
        return this.max.y - this.min.y;
    }
    getCenter() {
        if (this._center) {
            return this._center;
        }
        this._center = ltpoint_1.LTPoint.getAverage(this.min, this.max);
        return this._center;
    }
}
exports.BoundingBox = BoundingBox;
