"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawInfo = void 0;
class DrawInfo {
    constructor() {
        this._pipeLineFeatures = [];
        this._pipePointFeatures = [];
        this._valveFeatures = [];
        this._textFeatures = [];
    }
    addFeaturesToCadMap(cadMap) {
        for (let feature of this._pipeLineFeatures) {
            cadMap.addFeature(feature);
        }
        //为了有正确的覆盖关系，管点绘制要在管线后面
        for (let feature of this._pipePointFeatures) {
            cadMap.addFeature(feature);
        }
        for (let feature of this._valveFeatures) {
            cadMap.addFeature(feature);
        }
        for (let feature of this._textFeatures) {
            cadMap.addFeature(feature);
        }
    }
}
exports.DrawInfo = DrawInfo;
