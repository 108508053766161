"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterCommand = void 0;
const commandfactory_1 = require("./commandfactory");
const drawcommands_1 = require("../tools/drawcommands");
const regencommands_1 = require("../tools/regencommands");
const undocommands_1 = require("../tools/undocommands");
const deletecommands_1 = require("../tools/deletecommands");
const movecommands_1 = require("../tools/movecommands");
const rotatecommands_js_1 = require("../tools/rotatecommands.js");
const scalecommands_js_1 = require("../tools/scalecommands.js");
const drawtextcommands_js_1 = require("../tools/drawtextcommands.js");
const drawblockcommands_js_1 = require("../tools/drawblockcommands.js");
const trimcommands_js_1 = require("../tools/trimcommands.js");
const savecommands_1 = require("../tools/savecommands");
const extendcommands_1 = require("../tools/extendcommands");
const drawdimension_1 = require("../tools/drawdimension");
const joincommands_1 = require("../tools/joincommands");
const breakcommands_1 = require("../tools/breakcommands");
const mirrorcommands_1 = require("../tools/mirrorcommands");
const qselectcommands_1 = require("../tools/qselectcommands");
const drawcircle_1 = require("../tools/drawcircle");
const addblockcommands_1 = require("../tools/addblockcommands");
const drawspline_1 = require("../tools/drawspline");
const drawellipse_1 = require("../tools/drawellipse");
const blockbreakcommands_1 = require("../tools/blockbreakcommands");
const selectcommands_1 = require("../tools/selectcommands");
const arrayrectcommands_1 = require("../tools/arrayrectcommands");
const hatchcommands_1 = require("../tools/hatchcommands");
//注册方法;
class RegisterCommand {
    static init() {
        if (this.has_init) {
            return;
        }
        this.has_init = true;
        commandfactory_1.CommandFactory.getInstance().registerCommand("pline", drawcommands_1.drawPolyline, "绘制多段线");
        commandfactory_1.CommandFactory.getInstance().registerCommand("drawText", drawtextcommands_js_1.drawTextCommand, "绘制文字", commandfactory_1.CommandFlag.CommandSnap | commandfactory_1.CommandFlag.CommmandNoCommandLine);
        commandfactory_1.CommandFactory.getInstance().registerCommand("insert", drawblockcommands_js_1.drawBlock, "插入块", commandfactory_1.CommandFlag.CommandSnap | commandfactory_1.CommandFlag.CommmandNoCommandLine);
        commandfactory_1.CommandFactory.getInstance().registerCommand("block", addblockcommands_1.addBlock, "创建块", commandfactory_1.CommandFlag.CommandSnap | commandfactory_1.CommandFlag.CommmandNoCommandLine);
        commandfactory_1.CommandFactory.getInstance().registerCommand("explode", blockbreakcommands_1.bleakBlock, "块打散");
        commandfactory_1.CommandFactory.getInstance().registerCommand("regen", regencommands_1.regenCommand, "重绘");
        commandfactory_1.CommandFactory.getInstance().registerCommand("xdata", regencommands_1.xdataCommand, "获取对象的扩展属性");
        commandfactory_1.CommandFactory.getInstance().registerCommand("redo", undocommands_1.redoCommand, "重做");
        commandfactory_1.CommandFactory.getInstance().registerCommand("$CTRL+Y", undocommands_1.redoCommand1, "重做");
        commandfactory_1.CommandFactory.getInstance().registerCommand("undo", undocommands_1.undoCommand, "撤销");
        commandfactory_1.CommandFactory.getInstance().registerCommand("$CTRL+Z", undocommands_1.undoCommand1, "撤销");
        commandfactory_1.CommandFactory.getInstance().registerCommand("$delete", deletecommands_1.deleteCommand, "删除对象");
        commandfactory_1.CommandFactory.getInstance().registerCommand("move", movecommands_1.moveCommand, "移动对象");
        commandfactory_1.CommandFactory.getInstance().registerCommand("rotate", rotatecommands_js_1.rotateCommand, "旋转对象");
        commandfactory_1.CommandFactory.getInstance().registerCommand("scale", scalecommands_js_1.scaleCommand, "缩放对象");
        commandfactory_1.CommandFactory.getInstance().registerCommand("trim", trimcommands_js_1.trimCommand, "裁剪对象");
        commandfactory_1.CommandFactory.getInstance().registerCommand("save", savecommands_1.saveCommand, "保存");
        commandfactory_1.CommandFactory.getInstance().registerCommand("extend", extendcommands_1.extendCommand, "延伸");
        commandfactory_1.CommandFactory.getInstance().registerCommand("drawDimension", drawdimension_1.drawDimension, "绘制标注");
        commandfactory_1.CommandFactory.getInstance().registerCommand("drawAngular2LDimension", drawdimension_1.drawAngular2LDimension, "绘制角度标注");
        commandfactory_1.CommandFactory.getInstance().registerCommand("join", joincommands_1.joinCommand, "合并");
        commandfactory_1.CommandFactory.getInstance().registerCommand("intersectbreak", breakcommands_1.intersectBreakCommand, "相交打断");
        commandfactory_1.CommandFactory.getInstance().registerCommand("break", breakcommands_1.breakCommand, "打断");
        commandfactory_1.CommandFactory.getInstance().registerCommand("mirror", mirrorcommands_1.mirrorCommand, "镜像");
        commandfactory_1.CommandFactory.getInstance().registerCommand("qselect", qselectcommands_1.qSelectCommand, "快速选择", commandfactory_1.CommandFlag.CommandSaveSelect | commandfactory_1.CommandFlag.CommandSnap | commandfactory_1.CommandFlag.CommmandNoCommandLine);
        commandfactory_1.CommandFactory.getInstance().registerCommand("line", drawcommands_1.drawline, "绘制直线");
        commandfactory_1.CommandFactory.getInstance().registerCommand("select", selectcommands_1.selectFeature, "选择");
        commandfactory_1.CommandFactory.getInstance().registerCommand("circle", drawcircle_1.drawCircle, "绘制圆");
        commandfactory_1.CommandFactory.getInstance().registerCommand("arc", drawcircle_1.drawArc, "绘制弧");
        commandfactory_1.CommandFactory.getInstance().registerCommand("ellipse", drawellipse_1.drawEllipse, "绘制椭圆");
        commandfactory_1.CommandFactory.getInstance().registerCommand("ellipsearc", drawellipse_1.drawEllipseArc, "绘制椭圆弧");
        commandfactory_1.CommandFactory.getInstance().registerCommand("spline", drawspline_1.drawSpline, "绘制样条曲线");
        // CommandFactory.getInstance().registerCommand(
        //   "offset",
        //   offsetCommand
        // );
        commandfactory_1.CommandFactory.getInstance().registerCommand("copy", movecommands_1.copyCommand, "复制");
        commandfactory_1.CommandFactory.getInstance().registerCommand("polygon", drawcommands_1.drawPolygon, "绘制面");
        commandfactory_1.CommandFactory.getInstance().registerCommand("rectangle", drawcommands_1.drawRectangle, "绘制矩形");
        commandfactory_1.CommandFactory.getInstance().registerCommand("round", drawcommands_1.drawRoundPolyline, "绘制圆角多段线", commandfactory_1.CommandFlag.CommandSnap | commandfactory_1.CommandFlag.CommmandNoCommandLine);
        commandfactory_1.CommandFactory.getInstance().registerCommand("wipeoutblock", addblockcommands_1.addBlockWipeOut);
        commandfactory_1.CommandFactory.getInstance().registerCommand("arrayRect", arrayrectcommands_1.arrayRect, "矩形阵列");
        commandfactory_1.CommandFactory.getInstance().registerCommand("addhatch", hatchcommands_1.addhatch, "填充");
        commandfactory_1.CommandFactory.getInstance().registerCommand("list", savecommands_1.entityListCommand, "获取要素信息，打印到控制台", commandfactory_1.CommandFlag.CommandSnap | commandfactory_1.CommandFlag.CommmandNoCommandLine);
    }
}
exports.RegisterCommand = RegisterCommand;
RegisterCommand.has_init = false;
