"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.XGroup = void 0;
class XGroup {
    constructor(xArr) {
        this._min = Number.POSITIVE_INFINITY;
        this._max = Number.NEGATIVE_INFINITY;
        for (let x of xArr) {
            this.expand(x);
        }
    }
    expand(x) {
        this._min = Math.min(x, this._min);
        this._max = Math.max(x, this._max);
    }
    expandByOther(other) {
        this._min = Math.min(this._min, other._min);
        this._max = Math.max(this._max, other._max);
    }
    length() {
        return this._max - this._min;
    }
    isOverlap(other) {
        return !(this._min > other._max && this._max < other._min);
    }
    //计算两段线重叠的问题
    computeOverlapMinFactor(other) {
        let overlapLength = Math.min(this._max, other._max) - Math.max(this._min, other._min);
        return Math.min(overlapLength / this.length(), overlapLength / other.length());
    }
}
exports.XGroup = XGroup;
