"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RiserGroup = void 0;
class RiserGroup {
    constructor() {
        this._rows = [];
        this._riserPoints = [];
    }
    addRow(row) {
        this._rows.push(row);
    }
    addRiserPoints(sprinklerGroups) {
        for (let group of sprinklerGroups) {
            this._riserPoints.push(group._riserPoint);
        }
    }
}
exports.RiserGroup = RiserGroup;
