"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AUTOPIPE_TEXT_LAYER = exports.SPRINKLER_NOZZLE_LAYER = exports.SPRINKLER_LOCATION_LAYER = exports.PIPDIAMETER_ANNO_LAYER = exports.FLOOR_LAYER = exports.MAINPIPE_LAYER = exports.BRANCHPIPE_LAYER = exports.AUTOPIPE_LAYER = exports.WATERFLOW_LAYER = exports.FLOOR_FLAG = exports.WATERFLOW_NAME = exports.STANDPIPE_LAYER = exports.BLOCK_DEADLINE = exports.STANDPIPE_NAME = void 0;
exports.STANDPIPE_NAME = "自喷立管"; //立管块名
exports.BLOCK_DEADLINE = "截止阀_DN_50_"; //截止阀块名
exports.STANDPIPE_LAYER = "D-自喷立管"; //立管图层名
exports.WATERFLOW_NAME = "水流指示器"; //水流指示器
exports.FLOOR_FLAG = "层高标记"; //楼层标注
exports.WATERFLOW_LAYER = "D-自喷设备"; //水流图层名
exports.AUTOPIPE_LAYER = "D-自喷立管"; //自喷管图层名
//喷淋支管
exports.BRANCHPIPE_LAYER = "D-喷淋支管"; //主管道图层名
exports.MAINPIPE_LAYER = "D-喷淋主管"; //主管道图层名
exports.FLOOR_LAYER = "D-层框"; //层框图层名
//管径标注
exports.PIPDIAMETER_ANNO_LAYER = "D-自喷管径标注";
exports.SPRINKLER_LOCATION_LAYER = "D-喷头定位"; //喷头定位标注图层名
exports.SPRINKLER_NOZZLE_LAYER = "D-喷头"; //喷头
exports.AUTOPIPE_TEXT_LAYER = "D-喷淋立管标注";
