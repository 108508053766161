"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShxRegBigFontFile = exports.ShxFile = void 0;
const ltrendermode_1 = require("../ltrendermode");
const ltpoint_1 = require("./ltpoint");
var SHX_TYPE;
(function (SHX_TYPE) {
    SHX_TYPE[SHX_TYPE["REGFONT"] = 0] = "REGFONT";
    SHX_TYPE[SHX_TYPE["UNIFONT"] = 1] = "UNIFONT";
    SHX_TYPE[SHX_TYPE["BIGFONT"] = 2] = "BIGFONT";
    SHX_TYPE[SHX_TYPE["SHAPEFILE"] = 3] = "SHAPEFILE";
    SHX_TYPE[SHX_TYPE["UNKNOWN"] = 4] = "UNKNOWN";
})(SHX_TYPE || (SHX_TYPE = {}));
;
const CICLESPAN = Math.PI / 18;
var ShowMode;
(function (ShowMode) {
    ShowMode[ShowMode["Horizontal"] = 0] = "Horizontal";
    ShowMode[ShowMode["Vertical"] = 1] = "Vertical";
    ShowMode[ShowMode["All"] = 2] = "All";
})(ShowMode || (ShowMode = {}));
function _toString(buffer) {
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(buffer);
}
class GrapicsCallback {
    constructor() {
        this._points = [];
        this._current_points = [];
        this._offset_x = 0;
        this._offset_y = 0;
        this._pen_x = 0;
        this._pen_y = 0;
    }
    offset(x, y) {
        this._offset_x = x;
        this._offset_y = y;
    }
    get offsetPoint() {
        return new ltpoint_1.LTPoint(this._offset_x, this._offset_y);
    }
    get lastPoint() {
        return new ltpoint_1.LTPoint(this._pen_x, this._pen_y);
    }
    moveTo(x, y) {
        this.end();
        this._pen_x = x + this._offset_x;
        this._pen_y = y + this._offset_y;
        this._current_points.push(new ltpoint_1.LTPoint(this._pen_x, this._pen_y));
    }
    lineTo(x, y) {
        this._pen_x = x + this._offset_x;
        this._pen_y = y + this._offset_y;
        this._current_points.push(new ltpoint_1.LTPoint(this._pen_x, this._pen_y));
    }
    end() {
        if (this._current_points.length > 1) {
            this._points.push(this._current_points);
        }
        this._current_points = [];
    }
    toGraphics(graphics, color) {
        this.end();
        graphics.lineStyle({ color: color, native: true, width: 1 });
        for (let polyLine of this._points) {
            for (let i = 0; i < polyLine.length; ++i) {
                let point = polyLine[i];
                if (i == 0) {
                    graphics.moveTo(point.x, point.y);
                }
                else {
                    graphics.lineTo(point.x, point.y);
                }
            }
        }
        graphics.endFill();
    }
    textWidth() {
        let min = 0.0;
        let max = 0.0;
        this.end();
        for (let polyLine of this._points) {
            for (let point of polyLine) {
                min = Math.min(min, point.x);
                max = Math.max(max, point.x);
            }
        }
        return max - min;
    }
    currentPos() {
        return [this._pen_x, this._pen_y];
    }
}
;
class FontFile {
    constructor() {
        this._font_height = 1;
        this._font_below = 1;
        this._charset_map = new Map();
        this._font_desc = '';
    }
    findBuffer(code) {
        return this._charset_map.get(code);
    }
    get shxType() {
        return SHX_TYPE.UNKNOWN;
    }
    get fontHeight() {
        return this._font_height;
    }
}
;
class BigFontFile extends FontFile {
    constructor() {
        super(...arguments);
        this._escape_list = [];
    }
    isEscapeChar(character) {
        for (let esc_rang of this._escape_list) {
            if (character >= esc_rang._start && character <= esc_rang._end) {
                return true;
            }
        }
        return false;
    }
    init(buffer, index) {
        let view = new DataView(buffer);
        let item_length = view.getUint16(index, true);
        index += 2;
        let count = view.getUint16(index, true);
        index += 2;
        let range_count = view.getUint16(index, true);
        index += 2;
        for (let i = 0; i < range_count; ++i) {
            let start = view.getUint16(index + i * 4, true);
            let end = view.getUint16(index + 2 + i * 4, true);
            let item = { _start: start, _end: end };
            this._escape_list.push(item);
        }
        let byte_offset = index + range_count * 4;
        this._charset_map.clear();
        for (let i = 0; i < count; i++) {
            let data = buffer.slice(byte_offset, byte_offset + 8);
            byte_offset += 8;
            let sub_view = new DataView(data);
            let code = sub_view.getUint16(0, true);
            let length = sub_view.getUint16(2, true);
            let offset = sub_view.getInt32(4, true);
            if (code == 0 && length == 0 && offset == 0) {
                continue;
            }
            //过滤掉起前面无效的部分;
            let temp_offset = offset;
            let temp_length = length;
            while (view.getUint8(temp_offset) != 0) {
                temp_offset += 1;
                temp_length -= 1;
            }
            //跳过0;
            temp_offset += 1;
            temp_length -= 1;
            if (temp_length <= 0) {
                continue;
            }
            let item_bytearray = buffer.slice(temp_offset, temp_offset + temp_length);
            this._charset_map.set(code, item_bytearray);
        }
        //读取文字的高度;
        let info = this._charset_map.get(0);
        if (info !== undefined) {
            let info_view = new DataView(info);
            this._font_height = info_view.getInt8(info.byteLength - 4);
            if (this._font_height == 0) {
                this._font_height = 8;
            }
            this._font_below = info_view.getInt8(info.byteLength - 3);
        }
    }
    get shxType() {
        return SHX_TYPE.BIGFONT;
    }
}
class ShapeFontFile extends FontFile {
    init(buffer, index) {
        const view = new DataView(buffer);
        let startCode = view.getUint16(index, true);
        index += 2;
        let endCode = view.getUint16(index, true);
        index += 2;
        let count = view.getUint16(index, true);
        index += 2;
        view.byteOffset;
        let items = [];
        for (let i = 0; i < count; i++) {
            let item = { _code: 0, _length: 0 };
            item._code = view.getUint16(index, true);
            index += 2;
            item._length = view.getUint16(index, true);
            index += 2;
            items.push(item);
        }
        let offset = index;
        for (let item of items) {
            let bytes = buffer.slice(offset, offset + item._length);
            offset += item._length;
            this._charset_map.set(item._code, bytes);
        }
        let infoData = this._charset_map.get(0);
        if (infoData !== undefined) {
            let info_intarray = new Uint8Array(infoData);
            let temp_index = info_intarray.indexOf(0);
            this._font_desc = _toString(infoData.slice(0, temp_index));
            this._font_height = info_intarray[++temp_index];
            let base_down = info_intarray[++temp_index];
            let show_mode = info_intarray[++temp_index];
        }
    }
    get shxType() {
        return SHX_TYPE.SHAPEFILE;
    }
}
class UniFontFile extends FontFile {
    init(buffer, index) {
        let view = new DataView(buffer);
        let count = view.getUint32(index, true);
        index += 4;
        let infoLength = view.getUint16(index, true);
        index += 2;
        let infoData = buffer.slice(index, index + infoLength);
        let info_intarray = new Uint8Array(infoData);
        let temp_index = info_intarray.indexOf(0);
        this._font_desc = _toString(infoData.slice(0, temp_index));
        this._font_height = info_intarray[++temp_index];
        let basedown = info_intarray[++temp_index];
        let showmode = info_intarray[++temp_index];
        let is_unicode = info_intarray[++temp_index] === 0;
        let is_embedded = info_intarray[++temp_index] === 0;
        index += infoLength;
        for (let i = 0; i < count - 1; i++) {
            let code = view.getUint16(index, true);
            index += 2;
            let length = view.getUint16(index, true);
            index += 2;
            let temp_offset = index;
            let temp_length = length;
            while (view.getUint8(temp_offset) != 0) {
                temp_offset += 1;
                temp_length -= 1;
            }
            //跳过0;
            temp_offset += 1;
            temp_length -= 1;
            if (temp_length <= 0) {
                continue;
            }
            let bytes = buffer.slice(temp_offset, temp_offset + temp_length);
            index += length;
            this._charset_map.set(code, bytes);
        }
    }
    get shxType() {
        return SHX_TYPE.UNIFONT;
    }
}
class abstractShxFile {
    constructor(file_name) {
        this._pre_load = false;
        this._file_name = "";
        this._file_name = file_name;
    }
    valid() {
        return this._pre_load;
    }
    getGraphicData(str, font_height, lineHeight, lineSpace = 0, wordSpace = 0) {
    }
}
class ShxFile extends abstractShxFile {
    constructor(file_url) {
        super(file_url);
        this._font_file = undefined;
        this._scale = 1.0;
        this._current_pos = ltpoint_1.LTPoint.nullVector();
        this._stack_points = [];
    }
    get penPos() {
        return this._current_pos;
    }
    load() {
        if (this._pre_load) {
            return;
        }
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/shxfonts/${this._file_name}`, true);
        xhr.responseType = 'arraybuffer';
        xhr.onload = (e) => {
            if (xhr.status == 200) {
                const arrayBuffer = xhr.response;
                this._init(arrayBuffer);
            }
        };
        xhr.send(null);
    }
    get fontFile() {
        return this._font_file;
    }
    get scale() {
        return this._scale;
    }
    set scale(d) {
        this._scale = d;
    }
    _readString(buf) {
        let header = [];
        let flag = false;
        let index = 0;
        let step = 0;
        let stopMatch = new Uint8Array(3);
        //结束符号;
        stopMatch[0] = 0x0d;
        stopMatch[1] = 0x0a;
        stopMatch[2] = 0x1a;
        while (true) {
            var data = buf[step];
            step += 1;
            if (buf.byteLength == step) {
                break;
            }
            if (flag) {
                if (data == stopMatch[index])
                    index++;
                else {
                    flag = false;
                    index = 0;
                }
            }
            else {
                if (data == stopMatch[index]) {
                    flag = true;
                    index++;
                    continue;
                }
                header.push(data);
            }
            if (flag && index == stopMatch.length)
                break;
        }
        let result = new Uint8Array(header);
        return _toString(result.buffer);
    }
    _stringToArrayBuffer(str) {
        var buf = new ArrayBuffer(str.length);
        var bufView = new Uint8Array(buf);
        for (var i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }
    _init(buffer) {
        const view = new DataView(buffer);
        const int_view = buffer.slice(0, 100);
        let header = this._readString(new Uint8Array(int_view));
        let headers = header.split(' ');
        let shx_type = headers[1];
        if (shx_type === "unifont") {
            this._font_file = new UniFontFile();
        }
        else if (shx_type == "bigfont") {
            this._font_file = new BigFontFile();
        }
        else if (shx_type == "shapes") {
            this._font_file = new ShapeFontFile();
        }
        else {
            return;
        }
        if (this._font_file == undefined) {
            console.log(`${this._file_name}找不到`);
            return;
        }
        this._pre_load = true;
        let index = header.length + 3;
        this._font_file.init(buffer, index);
    }
    get isValid() {
        return this._pre_load;
    }
    graphicData(c, lineHeight, graphics) {
        let code = this.getCode(c);
        if (code == 0) {
            return;
        }
        if (this._font_file == undefined) {
            return;
        }
        this._current_pos = ltpoint_1.LTPoint.nullVector();
        this._scale = lineHeight / this._font_file.fontHeight;
        this._stack_points = [];
        this.graphicDataByCode(code, graphics);
    }
    graphicDataByCode(c, graphics) {
        if (this._font_file == undefined) {
            return;
        }
        //通过code,找map;
        let charset_buffer = this._font_file.findBuffer(c);
        if (charset_buffer == undefined) {
            return;
        }
        this._parseCode(charset_buffer, graphics);
    }
    getGraphicData(str, font_height, color, matrix) {
        let lines = str.split('\r\n');
        let lineSpace = matrix.lineSpace | 0;
        let lineHeight = matrix.lineHeight | 0;
        let wordSpace = matrix.wordSpace | 0;
        let width_scale = matrix.wordSpace | 0;
        if (lines.length == 0)
            return null;
        let vOffset = (lines.length - 1) * (lineSpace + lineHeight);
        let hOffset = 0.0;
        let callback = new GrapicsCallback();
        for (let line of lines) {
            let result_graphics = ltrendermode_1.CadSetting._newGraphics();
            for (let c of line) {
                callback.offset(hOffset, vOffset);
                var shape = this.graphicData(c, font_height, callback);
                hOffset += this.penPos.x + wordSpace;
            }
            //完成之后做处理;
            hOffset = 0;
            vOffset -= lineSpace + lineHeight;
        }
        let main_graphics = ltrendermode_1.CadSetting._newGraphics();
        callback.toGraphics(main_graphics, color);
        //设置图形矩阵;
        return main_graphics;
    }
    getCode(c) {
        if (this._font_file == undefined) {
            return -1;
        }
        if (this._font_file.shxType == SHX_TYPE.BIGFONT) {
            let buf = window.iconv.encode(c, 'GB2312');
            let view = new DataView(buf.buffer);
            if (buf.byteLength < 4) {
                return view.getUint16(0, false);
            }
            return view.getInt32(0, false);
        }
        return c.charCodeAt(0);
    }
    _lineTo(graphics, point) {
        graphics.lineTo(point.x, point.y);
    }
    _moveTo(graphics, point) {
        graphics.moveTo(point.x, point.y);
    }
    _parseCode(buffer, graphics) {
        if (this._font_file == null) {
            return;
        }
        let view = new DataView(buffer);
        let isPenDown = false;
        for (let i = 0; i < view.byteLength; i++) {
            var cb = view.getUint8(i);
            switch (cb) {
                case 0x00:
                    break;
                case 0x01:
                    {
                        isPenDown = true;
                        this._moveTo(graphics, this._current_pos);
                    }
                    break;
                case 0x02:
                    {
                        isPenDown = false;
                    }
                    break;
                case 0x03:
                    {
                        i++;
                        this._scale /= view.getInt8(i);
                    }
                    break;
                case 0x04:
                    {
                        i++;
                        this._scale *= view.getInt8(i);
                    }
                    break;
                case 0x05:
                    {
                        this._stack_points.push(this._current_pos);
                    }
                    break;
                case 0x06:
                    {
                        let temp_current_pos = this._stack_points.pop();
                        if (temp_current_pos) {
                            this._current_pos = temp_current_pos;
                        }
                        if (isPenDown) {
                            this._lineTo(graphics, this._current_pos);
                        }
                    }
                    break;
                case 0x07:
                    {
                        let subCode = 0;
                        let size = this._scale * this._font_file.fontHeight;
                        let origin = this._current_pos;
                        switch (this._font_file.shxType) {
                            case SHX_TYPE.SHAPEFILE:
                                {
                                    i++;
                                    subCode = view.getUint8(i);
                                }
                                break;
                            case SHX_TYPE.BIGFONT:
                                {
                                    i++;
                                    subCode = view.getUint8(i);
                                    if (subCode == 0) {
                                        i++;
                                        subCode = view.getUint16(i, true);
                                        i += 2;
                                        origin.x = view.getInt8(i++) * this._scale;
                                        origin.y = view.getInt8(i++) * this._scale;
                                        var width = view.getInt8(i++) * this._scale;
                                        var height = view.getInt8(i++) * this._scale;
                                        size = height;
                                    }
                                }
                                break;
                            case SHX_TYPE.UNIFONT:
                                {
                                    i += 2;
                                    subCode = view.getUint16(i - 1, true);
                                }
                                break;
                        }
                        if (subCode != 0) {
                            //嵌套绘制;
                            // let offset_point=graphics.offsetPoint;
                            // graphics.offset(offset_point.x+origin.x,offset_point.y+origin.y);
                            this.graphicDataByCode(subCode, graphics);
                            //  graphics.offset(offset_point.x,offset_point.y);
                            // current_pos=offset_point;
                        }
                    }
                    break;
                case 0x08: //偏移绘制;
                    {
                        let vec = ltpoint_1.LTPoint.nullVector();
                        vec.x = view.getInt8(++i);
                        vec.y = view.getInt8(++i);
                        this._current_pos = this._current_pos.plus(vec.dot(this._scale));
                        if (isPenDown) {
                            this._lineTo(graphics, this._current_pos);
                        }
                    }
                    break;
                case 0x09:
                    {
                        while (true) {
                            let vec = ltpoint_1.LTPoint.nullVector();
                            vec.x = view.getInt8(++i);
                            vec.y = view.getInt8(++i);
                            if (vec.x == 0 && vec.y == 0)
                                break;
                            this._current_pos = this._current_pos.plus(vec.dot(this._scale));
                            if (isPenDown) {
                                this._lineTo(graphics, this._current_pos);
                            }
                        }
                    }
                    break;
                case 0x0a:
                    {
                        let r = view.getInt8(++i) * this._scale;
                        let flag = view.getInt8(++i);
                        let n1 = (flag & 0x70) >> 4;
                        let n2 = flag & 0x07;
                        if (n2 == 0) {
                            n2 = 8;
                        }
                        let pi_4 = Math.PI / 4;
                        let span = pi_4 * n2;
                        let delta = CICLESPAN;
                        if (flag < 0) {
                            delta = -delta;
                            span = -span;
                        }
                        var startRadian = pi_4 * n1;
                        var endRadian = startRadian + span;
                        let p1 = new ltpoint_1.LTPoint(Math.cos(startRadian), Math.sin(startRadian));
                        let center = this._current_pos.minus(p1.dot(r));
                        let p2 = new ltpoint_1.LTPoint(Math.cos(endRadian), Math.sin(endRadian));
                        this._current_pos = center.plus(p2.dot(r));
                        if (isPenDown) {
                            var currentRadian = startRadian;
                            while (true) {
                                currentRadian += delta;
                                if ((flag > 0 && currentRadian < endRadian) || (flag < 0 && currentRadian > endRadian)) {
                                    let temp_pos = center.plus(new ltpoint_1.LTPoint(Math.cos(currentRadian), Math.sin(currentRadian)).dot(r));
                                    this._lineTo(graphics, temp_pos);
                                }
                                else
                                    break;
                            }
                            this._lineTo(graphics, this._current_pos);
                        }
                    }
                    break;
                case 0x0b:
                    {
                        let startOffset = view.getInt8(++i);
                        let endOffset = view.getInt8(++i);
                        let hr = view.getInt8(++i);
                        let lr = view.getInt8(++i);
                        let r = (hr * 255 + lr) * this._scale;
                        let flag = view.getInt8(++i);
                        let n1 = (flag & 0x70) >> 4;
                        let n2 = flag & 0x07;
                        if (n2 == 0)
                            n2 = 8;
                        if (endOffset != 0)
                            n2--;
                        let pi_4 = Math.PI / 4;
                        let span = pi_4 * n2;
                        let delta = CICLESPAN;
                        let sign = 1;
                        if (flag < 0) {
                            delta = -delta;
                            span = -span;
                            sign = -1;
                        }
                        let startRadian = pi_4 * n1;
                        let endRadian = startRadian + span;
                        startRadian += pi_4 * startOffset / 256 * sign;
                        endRadian += pi_4 * endOffset / 256 * sign;
                        let center = this._current_pos.minus(new ltpoint_1.LTPoint(Math.cos(startRadian), Math.sin(startRadian)).dot(r));
                        this._current_pos = center.plus(new ltpoint_1.LTPoint(Math.cos(endRadian), Math.sin(endRadian)).dot(r));
                        if (isPenDown) {
                            var currentRadian = startRadian;
                            while (true) {
                                currentRadian += delta;
                                if ((flag > 0 && currentRadian < endRadian) || (flag < 0 && currentRadian > endRadian)) {
                                    let temp_pos = center.plus(new ltpoint_1.LTPoint(Math.cos(currentRadian), Math.sin(currentRadian)).dot(r));
                                    this._lineTo(graphics, temp_pos);
                                }
                                else
                                    break;
                            }
                            this._lineTo(graphics, this._current_pos);
                        }
                    }
                    break;
                case 0x0c:
                    {
                        let vec = ltpoint_1.LTPoint.nullVector();
                        vec.x = view.getInt8(++i) * this._scale;
                        vec.y = view.getInt8(++i) * this._scale;
                        let bulge = view.getInt8(++i);
                        let dist = Math.sqrt(vec.x * vec.x + vec.y * vec.y);
                        let chord_height = bulge * (dist / 2.0) / 127.0;
                        let current_postion = this._current_pos.clone();
                        let shx_circle = { center_x: 0, center_y: 0, radius: 0 };
                        let result = this.calcCenter(current_postion.x, current_postion.y, vec.x, vec.y, chord_height, shx_circle);
                        if (result == true) {
                            let start_angle = Math.atan2(current_postion.y - shx_circle.center_y, current_postion.x - shx_circle.center_x);
                            let sweep_angle = 2.0 * Math.asin((dist / 2.0) / shx_circle.radius);
                            if (isNaN(sweep_angle)) {
                                sweep_angle = Math.PI;
                            }
                            if (bulge < 0) {
                                sweep_angle = -sweep_angle;
                            }
                            this.drawArc(graphics, shx_circle.center_x, shx_circle.center_y, shx_circle.radius, start_angle, sweep_angle);
                        }
                        else {
                            let p = this._current_pos.plus(vec);
                            this._lineTo(graphics, p);
                        }
                        this._current_pos = this._current_pos.plus(vec);
                    }
                    break;
                case 0x0d:
                    {
                        while (true) {
                            let vec = ltpoint_1.LTPoint.nullVector();
                            vec.x = view.getInt8(++i) * this._scale;
                            vec.y = view.getInt8(++i) * this._scale;
                            if (vec.x == 0 && vec.y == 0) {
                                break;
                            }
                            let bulge = view.getInt8(++i);
                            let dist = Math.sqrt(vec.x * vec.x + vec.y * vec.y);
                            let chord_height = bulge * (dist / 2.0) / 127.0;
                            let current_postion = this._current_pos.clone();
                            let shx_circle = { center_x: 0, center_y: 0, radius: 0 };
                            let result = this.calcCenter(current_postion.x, current_postion.y, vec.x, vec.y, chord_height, shx_circle);
                            if (result == true) {
                                let start_angle = Math.atan2(current_postion.y - shx_circle.center_y, current_postion.x - shx_circle.center_x);
                                let sweep_angle = 2.0 * Math.asin((dist / 2.0) / shx_circle.radius);
                                if (bulge < 0) {
                                    sweep_angle = -sweep_angle;
                                }
                                this.drawArc(graphics, shx_circle.center_x, shx_circle.center_y, shx_circle.radius, start_angle, sweep_angle);
                            }
                            else {
                                let p = this._current_pos.plus(vec);
                                this._lineTo(graphics, p);
                            }
                            this._current_pos = this._current_pos.plus(vec);
                        }
                    }
                    break;
                case 0x0e:
                    {
                        i = this._skipCode(view, ++i);
                    }
                    break;
                default:
                    if (cb > 0x0f) {
                        let len = (cb & 0xf0) >> 4;
                        let dir = cb & 0x0f;
                        let vec = ltpoint_1.LTPoint.nullVector();
                        switch (dir) {
                            case 0:
                                vec.x = 1;
                                break;
                            case 1:
                                vec.x = 1;
                                vec.y = 0.5;
                                break;
                            case 2:
                                vec.x = 1;
                                vec.y = 1;
                                break;
                            case 3:
                                vec.x = 0.5;
                                vec.y = 1;
                                break;
                            case 4:
                                vec.y = 1;
                                break;
                            case 5:
                                vec.x = -0.5;
                                vec.y = 1;
                                break;
                            case 6:
                                vec.x = -1;
                                vec.y = 1;
                                break;
                            case 7:
                                vec.x = -1;
                                vec.y = 0.5;
                                break;
                            case 8:
                                vec.x = -1;
                                break;
                            case 9:
                                vec.x = -1;
                                vec.y = -0.5;
                                break;
                            case 10:
                                vec.x = -1;
                                vec.y = -1;
                                break;
                            case 11:
                                vec.x = -0.5;
                                vec.y = -1;
                                break;
                            case 12:
                                vec.y = -1;
                                break;
                            case 13:
                                vec.x = 0.5;
                                vec.y = -1;
                                break;
                            case 14:
                                vec.x = 1;
                                vec.y = -1;
                                break;
                            case 15:
                                vec.x = 1;
                                vec.y = -0.5;
                                break;
                        }
                        this._current_pos = this._current_pos.plus(vec.dot(len).dot(this._scale));
                        if (isPenDown) {
                            this._lineTo(graphics, this._current_pos);
                        }
                    }
                    break;
            }
        }
    }
    calcCenter(start_x, start_y, dx, dy, chordHeight, circle) {
        if (chordHeight == 0.0)
            return false;
        let end_x = dx + start_x;
        let end_y = dy + start_y;
        if (Math.abs(dx) <= 1E-3 && Math.abs(dy) <= 1E-3)
            return false;
        let len = Math.sqrt(dx * dx + dy * dy);
        let fabsH = Math.abs(chordHeight);
        let radius = fabsH / 2 + len * len / (8.0 * fabsH);
        let CCDist = 0; //distance of chord and center
        if (chordHeight > 0) {
            CCDist = chordHeight - radius;
        }
        else //chordHeight < 0
         {
            CCDist = chordHeight + radius;
        }
        let pp = CCDist / len;
        let center_x = (start_x + end_x) / 2.0 + dy * pp;
        let center_y = (start_y + end_y) / 2.0 - dx * pp;
        circle.center_x = center_x;
        circle.center_y = center_y;
        circle.radius = radius;
        return true;
    }
    drawArc(graphics, center_x, center_y, radius, startAng, sweepAng) {
        let segCount = Math.ceil(Math.abs(sweepAng) * 12.0 / Math.PI);
        let detAng = sweepAng / segCount;
        let curAng = startAng;
        let pointX = center_x + radius * Math.cos(curAng);
        let pointY = center_y + radius * Math.sin(curAng);
        graphics.moveTo(pointX, pointY);
        for (let i = 1; i <= segCount; ++i) {
            curAng += detAng;
            pointX = center_x + radius * Math.cos(curAng);
            pointY = center_y + radius * Math.sin(curAng);
            this._lineTo(graphics, new ltpoint_1.LTPoint(pointX, pointY));
        }
    }
    _skipCode(data, index) {
        if (this._font_file == undefined) {
            return index;
        }
        let cb = data.getUint8(index);
        switch (cb) {
            case 0x00:
                break;
            case 0x01:
                break;
            case 0x02:
                break;
            case 0x03:
            case 0x04:
                index++;
                break;
            case 0x05:
                break;
            case 0x06:
                break;
            case 0x07:
                switch (this._font_file.shxType) {
                    case SHX_TYPE.SHAPEFILE:
                        index++;
                        break;
                    case SHX_TYPE.BIGFONT:
                        index++;
                        let subCode = data.getUint8(index);
                        if (subCode == 0)
                            index += 6;
                        break;
                    case SHX_TYPE.UNIFONT:
                        index += 2;
                        break;
                }
                break;
            case 0x08:
                index += 2;
                break;
            case 0x09:
                while (true) {
                    let x = data.getInt8(++index);
                    let y = data.getInt8(++index);
                    if (x == 0 && y == 0)
                        break;
                }
                break;
            case 0x0a:
                index += 2;
                break;
            case 0x0b:
                index += 5;
                break;
            case 0x0c:
                index += 3;
                break;
            case 0x0d:
                while (true) {
                    let x = data.getInt8(++index);
                    let y = data.getInt8(++index);
                    if (x == 0 && y == 0)
                        break;
                    let bulge = data.getInt8(++index);
                }
                break;
            case 0x0e:
                break;
            default:
                break;
        }
        return index;
    }
}
exports.ShxFile = ShxFile;
class ShxRegBigFontFile extends abstractShxFile {
    constructor(reg_font_name, big_font_shx) {
        super(reg_font_name);
        this._reg_font_shx = new ShxFile(reg_font_name);
        this._big_font_shx = new ShxFile(big_font_shx);
    }
    valid() {
        return this._reg_font_shx.valid() && this._big_font_shx.valid();
    }
    load() {
        this._reg_font_shx.load();
        this._big_font_shx.load();
    }
    getGraphicData(str, font_height, color, matrix) {
        var lines = str.split('\r\n');
        let lineSpace = matrix.lineSpace | 0;
        let lineHeight = matrix.lineHeight | 0;
        let wordSpace = matrix.wordSpace | 0;
        let widthScale = matrix.width_factor;
        if (lines.length == 0)
            return null;
        var vOffset = (lines.length - 1) * (lineSpace + lineHeight);
        var hOffset = 0.0;
        let callback = new GrapicsCallback();
        let big_font_file = null;
        if (this._big_font_shx.fontFile instanceof BigFontFile) {
            big_font_file = this._big_font_shx.fontFile;
        }
        for (let line of lines) {
            let result_graphics = ltrendermode_1.CadSetting._newGraphics();
            for (let c of line) {
                callback.offset(hOffset, vOffset);
                let buf = window.iconv.encode(c, 'GB2312');
                if (big_font_file !== null && big_font_file.isEscapeChar(buf[0])) {
                    this._big_font_shx.graphicData(c, font_height, callback);
                    hOffset += this._big_font_shx.penPos.x + wordSpace;
                }
                else {
                    this._reg_font_shx.graphicData(c, font_height, callback);
                    hOffset += this._reg_font_shx.penPos.x + wordSpace;
                }
            }
            //完成之后做处理;
            hOffset = 0;
            vOffset -= lineSpace + lineHeight;
        }
        let main_graphics = ltrendermode_1.CadSetting._newGraphics();
        callback.toGraphics(main_graphics, color);
        return main_graphics;
    }
}
exports.ShxRegBigFontFile = ShxRegBigFontFile;
class DataStream {
    constructor(view) {
        this._offset = 0;
        this._view = view;
    }
    readInt() {
        let val = this._view.getInt32(this._offset);
        this._offset += 4;
        return val;
    }
    readShort() {
        let val = this._view.getInt16(this._offset);
        this._offset += 2;
        return val;
    }
    readBool() {
        const val = this._view.getUint8(this._offset);
        this._offset += 1;
        return val != 0;
    }
    readString() {
        let decoder = new TextDecoder('utf-8');
        let str_len = this._view.getInt32(this._offset);
        this._offset += 4;
        let result = decoder.decode(new Uint8Array(this._view.buffer, this._offset, str_len));
        this._offset += str_len;
        return result;
    }
}
