"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rotateCommand = void 0;
const commandfactory_1 = require("../map/commandfactory");
const ltutils_js_1 = require("../cad/ltutils.js");
const ltentity_1 = require("../cad/ltentity");
const drawutils_1 = require("./drawutils");
class RotateCommandImpl {
    constructor(cadmap) {
        this._map = {};
        this._canvas = {};
        this.selectFeatures = [];
        this.entitys_clone = [];
        this._map = cadmap;
        this._canvas = this._map.canvas;
    }
    destroy() {
        this._map.trackGraphics.clear();
    }
    draws(entitys) {
        this._map.trackGraphics.clear();
        entitys.forEach(entity => {
            entity.worldDraw(this._canvas, true, null, this._map.trackGraphics);
        });
        return commandfactory_1.CommandStatus.eOK;
    }
    drawsMove(pos, base_point) {
        let ang = ltutils_js_1.LTUtils.angle(base_point, pos);
        this.entitys_clone = [];
        this.selectFeatures.forEach(feature => {
            let geo = feature.getGeometry().clone();
            geo.rotate(ang, base_point);
            this.entitys_clone.push(geo);
        });
        this.draws(this.entitys_clone);
    }
    save(angle, base_point) {
        this._map.undoStack().beginMacro("scale");
        this.selectFeatures.forEach(feature => {
            var clone_ent = feature.getGeometry().clone();
            feature.getGeometry().rotate(angle, base_point);
            this._map.updateFeatureGeometry(feature, clone_ent);
        });
        this._map.undoStack().endMacro();
        this._map.select_command.show();
    }
}
function* rotateCommand(cadmap, param_map) {
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", cadmap, { command_tip: "请选择旋转对象" });
    let select_feature = yield;
    if (select_feature.done === commandfactory_1.CommandStatus.eCancel || select_feature.value.length === 0 || select_feature.done === commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eDone };
    }
    if (select_feature.value.length <= 0) {
        //退出
        cadmap.printMessage("无选择，退出命令！");
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(cadmap, "指定基点:");
    let base_point = yield;
    if (base_point.done === commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let command = new RotateCommandImpl(cadmap);
    command.selectFeatures = select_feature.value;
    let base_line = new ltentity_1.LTLine(base_point.value, base_point.value);
    function basemoveCallback(mouse_event) {
        base_line.setEnd(mouse_event.mapPos);
        command.drawsMove(mouse_event.mapPos, base_point.value);
        drawutils_1.DrawUtilImpl.drawDashLine(cadmap, base_line);
    }
    commandfactory_1.CommandFactory.getInstance().getAngle(cadmap, "指定旋转角度:", basemoveCallback, base_point.value);
    let base_sec_point = yield;
    if (base_sec_point.done === commandfactory_1.CommandStatus.eCancel || base_sec_point.done === commandfactory_1.CommandStatus.eDone) {
        drawutils_1.DrawUtilImpl.destroyDashEntity(cadmap, base_line);
        command.destroy();
        return { value: null, done: base_sec_point.done };
    }
    command.save(base_sec_point.value, base_point.value);
    command.destroy();
    drawutils_1.DrawUtilImpl.destroyDashEntity(cadmap, base_line);
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.rotateCommand = rotateCommand;
