"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SprinklerReactor = void 0;
const ltentity_1 = require("../../cadplugins/cad/ltentity");
const ltreactor_1 = require("../../cadplugins/cad/ltreactor");
const ltfields_1 = require("../../cadplugins/data/ltfields");
const sprinklerutils_1 = require("./sprinklerutils");
class SprinklerReactor extends ltreactor_1.LTReactor {
    constructor(map) {
        super(map);
    }
    featureAppended(feature) {
        let geometry = feature.getGeometry();
        if (feature.getValue(ltfields_1.FieldNames.BlockName) == sprinklerutils_1.STANDPIPE_NAME) {
            let block = geometry;
            if (block == null) {
                return false;
            }
            //   feature._xdata.riser_name = "ZL-01";
            return true;
        }
        else if (feature.getValue(ltfields_1.FieldNames.BlockName) == sprinklerutils_1.WATERFLOW_NAME) {
            let block = geometry;
            if (block == null) {
                return false;
            }
            //搜索主管道;
            function queryMainPipe(sub_feat) {
                if (!sub_feat._xdata.riser_name) {
                    return false;
                }
                if (sub_feat.getGeometry() instanceof ltentity_1.LTPolyline || (sub_feat.getGeometry() instanceof ltentity_1.LTLine)) {
                    //求相交;
                    return feature.getGeometry().intersectsWith(sub_feat.getGeometry());
                }
                return false;
            }
            //获取图层;
            let layer = this._map.getLayerByName(sprinklerutils_1.STANDPIPE_LAYER);
            if (layer === null) {
                return true;
            }
            //判断与其相交的，一般只有一个;
            let features = layer.getFeatures(feature.getGeometry().getBoundingBox(), queryMainPipe);
            if (features.length == 0) {
                return true;
            }
            feature._xdata.riser_name = features[0]._xdata.riser_name;
            return true;
        }
        else if (geometry instanceof ltentity_1.LTLine || geometry instanceof ltentity_1.LTPolyline) {
            //判断是否需要加入立管属性;
            //搜索主管道;
            if (feature.getGeometry().getLayerName() !== sprinklerutils_1.STANDPIPE_LAYER) {
                return true;
            }
            function queryRiser(sub_feat) {
                if (!sub_feat._xdata.riser_name) {
                    return false;
                }
                return feature.getGeometry().intersectsWith(sub_feat.getGeometry());
            }
            let layer = this._map.getLayerByName(sprinklerutils_1.STANDPIPE_LAYER);
            if (layer === null) {
                return false;
            }
            //判断与其相交的，一般只有一个;
            let features = layer.getFeatures(feature.getGeometry().getBoundingBox(), queryRiser);
            if (features.length == 0) {
                return true;
            }
            feature._xdata.riser_name = features[0]._xdata.riser_name;
            return true;
        }
        return true;
    }
}
exports.SprinklerReactor = SprinklerReactor;
