"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LTIntersection = void 0;
const ltmath_1 = require("./ltmath");
const ltpoint_1 = require("./ltpoint");
const ltutils_1 = require("./ltutils");
const segment_1 = require("./segment");
const epsTolerance = 1.0e-02;
class LTIntersection {
    static getIntersectionPointsAX(circle1, explodable2, limited) {
        let res = [];
        var sub = explodable2.getExploded();
        for (let m = 0; m < sub.length; ++m) {
            if (sub[m] instanceof segment_1.RLine) {
                res = res.concat(LTIntersection.getIntersectionPointsLA(sub[m], circle1, limited));
            }
            else if (sub[m] instanceof segment_1.RArc) {
                res = res.concat(LTIntersection.getIntersectionPointsAA(sub[m], circle1, limited));
            }
        }
        return res;
    }
    static ellipse2tr(x, y, AA, BB, CC, DD, EE, FF) {
        return (AA * x * x + BB * x * y + CC * y * y + DD * x + EE * y + FF);
    }
    static getIntersectionPointsLL(line1, line2, limited1, limited2) {
        var ret = [];
        var a1 = line1.getEndPoint().y - line1.getStartPoint().y;
        var b1 = line1.getStartPoint().x - line1.getEndPoint().x;
        var c1 = a1 * line1.getStartPoint().x + b1 * line1.getStartPoint().y;
        var a2 = line2.getEndPoint().y - line2.getStartPoint().y;
        var b2 = line2.getStartPoint().x - line2.getEndPoint().x;
        var c2 = a2 * line2.getStartPoint().x + b2 * line2.getStartPoint().y;
        var det = a1 * b2 - a2 * b1;
        if (Math.abs(det) < 1e-6) {
            return ret;
        }
        else {
            var v = new ltpoint_1.LTPoint((b2 * c1 - b1 * c2) / det, (a1 * c2 - a2 * c1) / det);
            if ((!limited1 || line1.isOnShape(v)) &&
                (!limited2 || line2.isOnShape(v))) {
                ret.push(v);
                return ret;
            }
        }
        return ret;
    }
    static getIntersectionPointsEE(ellipse1, ellipse2) {
        let ret = [];
        if (ellipse1.isFullEllipse() && ellipse2.isFullEllipse() &&
            !ellipse1.getBoundingBox().intersects(ellipse2.getBoundingBox())) {
            return ret;
        }
        // normalize ellipse ratios:
        let ellipse1Copy = ellipse1.clone();
        if (ellipse1Copy.getMajorRadius() < ellipse1Copy.getMinorRadius()) {
            ellipse1Copy.switchMajorMinor();
        }
        let ellipse2Copy = ellipse2;
        if (ellipse2Copy.getMajorRadius() < ellipse2Copy.getMinorRadius()) {
            ellipse2Copy.switchMajorMinor();
        }
        // for later comparison, make sure that major points are in
        // quadrant I or II (relative to the ellipse center):
        if (Math.abs(ellipse1Copy.getMajorPoint().y) < ltutils_1.PointTolerance) {
            if (ellipse1Copy.getMajorPoint().x < 0.0) {
                let temp_point = ellipse1Copy.getMajorPoint();
                ellipse1Copy.setMajorPoint(new ltpoint_1.LTPoint(-temp_point.x, -temp_point.y));
            }
        }
        else {
            if (ellipse1Copy.getMajorPoint().y < 0.0) {
                let temp_point = ellipse1Copy.getMajorPoint();
                ellipse1Copy.setMajorPoint(new ltpoint_1.LTPoint(-temp_point.x, -temp_point.y));
            }
        }
        if (Math.abs(ellipse2Copy.getMajorPoint().y) < ltutils_1.PointTolerance) {
            if (ellipse2Copy.getMajorPoint().x < 0.0) {
                let temp_point = ellipse2Copy.getMajorPoint();
                ellipse2Copy.setMajorPoint(new ltpoint_1.LTPoint(-temp_point.x, -temp_point.y));
            }
        }
        else {
            if (ellipse2Copy.getMajorPoint().y < 0.0) {
                let temp_point = ellipse2Copy.getMajorPoint();
                ellipse2Copy.setMajorPoint(new ltpoint_1.LTPoint(-temp_point.x, -temp_point.y));
            }
        }
        // for comparison:
        let identicalCenter = (ellipse1Copy.getCenter().minus(ellipse2Copy.getCenter())).getMagnitude() < ltutils_1.PointTolerance;
        let identicalRatio = Math.abs(ellipse1Copy.getRatio() - ellipse2Copy.getRatio()) < 1.0e-4;
        let angleDifference = Math.abs(ltmath_1.LTMath.getAngleDifference180(ellipse1Copy.getAngle(), ellipse2Copy.getAngle()));
        let identicalRotation = angleDifference < 1.0e-4 || angleDifference > Math.PI - 1.0e-4;
        if (identicalCenter && identicalRatio && identicalRotation) {
            return ret;
        }
        if (identicalRatio && identicalRotation) {
            let angle = -ellipse1Copy.getAngle();
            let yScale = 1.0 / ellipse1Copy.getRatio();
            let circleCenter1 = ellipse1Copy.getCenter();
            circleCenter1.rotate(angle);
            circleCenter1.scale(1.0, yScale);
            let circleCenter2 = ellipse2Copy.getCenter();
            circleCenter2.rotate(angle);
            circleCenter2.scale(1.0, yScale);
            let circle1 = new segment_1.RCircle(circleCenter1, ellipse1Copy.getMajorRadius());
            let circle2 = new segment_1.RCircle(circleCenter2, ellipse2Copy.getMajorRadius());
            ret = LTIntersection.getIntersectionPointsCC(circle1, circle2);
            ltpoint_1.LTPoint.scaleList(ret, new ltpoint_1.LTPoint(1.0, 1.0 / yScale), null);
            ltpoint_1.LTPoint.rotateList(ret, -angle);
            return ret;
        }
        // transform ellipse2 to coordinate system of ellipse1:
        let centerOffset = new ltpoint_1.LTPoint(-ellipse1Copy.getCenter().x, -ellipse1Copy.getCenter().y);
        let angleOffset = -ellipse1Copy.getAngle();
        let majorRadius1 = ellipse1Copy.getMajorRadius();
        let majorRadius2 = ellipse2Copy.getMajorRadius();
        // special case: treat first ellipse as a line:
        if (ellipse1Copy.getMinorRadius() < ltutils_1.PointTolerance ||
            ellipse1Copy.getRatio() < ltutils_1.PointTolerance) {
            ellipse2Copy.move(centerOffset);
            ellipse2Copy.rotate(angleOffset);
            let line = new segment_1.RLine(new ltpoint_1.LTPoint(-majorRadius1, 0.0), new ltpoint_1.LTPoint(majorRadius1, 0.0));
            ret = LTIntersection.getIntersectionPointsLE(line, ellipse2Copy);
            ltpoint_1.LTPoint.rotateList(ret, -angleOffset);
            ltpoint_1.LTPoint.moveList(ret, new ltpoint_1.LTPoint(-centerOffset.x, -centerOffset.y));
            //        qDebug() << "RShape::getIntersectionPointsEE: ellipse 1 as line";
            return ret;
        }
        // special case: treat second ellipse as a line:
        if (ellipse2Copy.getMinorRadius() < ltutils_1.PointTolerance ||
            ellipse2Copy.getRatio() < ltutils_1.PointTolerance) {
            ellipse2Copy.move(centerOffset);
            ellipse2Copy.rotate(angleOffset);
            let line = new segment_1.RLine(new ltpoint_1.LTPoint(-majorRadius2, 0.), new ltpoint_1.LTPoint(majorRadius2, 0.));
            line.rotate(ellipse2Copy.getAngle(), new ltpoint_1.LTPoint(0., 0.));
            line.move(ellipse2Copy.getCenter());
            ret = LTIntersection.getIntersectionPointsLE(line, ellipse1Copy);
            ltpoint_1.LTPoint.rotateList(ret, -angleOffset);
            ltpoint_1.LTPoint.moveList(ret, new ltpoint_1.LTPoint(-centerOffset.x, -centerOffset.y));
            return ret;
        }
        let phi_1 = ellipse1Copy.getAngle();
        let a1 = ellipse1Copy.getMajorRadius();
        let b1 = ellipse1Copy.getMinorRadius();
        let h1 = ellipse1Copy.getCenter().x;
        let k1 = ellipse1Copy.getCenter().y;
        let phi_2 = ellipse2Copy.getAngle();
        let a2 = ellipse2Copy.getMajorRadius();
        let b2 = ellipse2Copy.getMinorRadius();
        let h2 = ellipse2Copy.getCenter().x;
        let k2 = ellipse2Copy.getCenter().y;
        let i, j, k, nroots, nychk, nintpts;
        let AA, BB, CC, DD, EE, FF, H2_TR, K2_TR, A22, B22, PHI_2R;
        let cosphi, cosphi2, sinphi, sinphi2, cosphisinphi;
        let tmp0, tmp1, tmp2, tmp3;
        let cy = new Array(5);
        cy.fill(0.0);
        let py = new Array(5);
        py.fill(0);
        let r = new Array(3);
        for (let i = 0; i < r.length; i++) {
            r[i] = new Array(5).fill(0.0);
        }
        let x1, x2;
        let ychk = new Array(5);
        ychk.fill(0.0);
        let xint = new Array(5);
        xint.fill(0.0);
        let yint = new Array(5);
        yint.fill(0.0);
        if ((!(a1 > 0.0) || !(b1 > 0.0)) || (!(a2 > 0.0) || !(b2 > 0.0))) {
            return [];
        }
        // the rotation angles should be between -2pi and 2pi (?)
        if (Math.abs(phi_1) > 2 * Math.PI) {
            phi_1 = phi_1 % (2 * Math.PI);
        }
        if (Math.abs(phi_2) > 2 * Math.PI) {
            phi_2 = phi_2 % (2 * Math.PI);
        }
        cosphi = Math.cos(phi_1);
        sinphi = Math.sin(phi_1);
        H2_TR = (h2 - h1) * cosphi + (k2 - k1) * sinphi;
        K2_TR = (h1 - h2) * sinphi + (k2 - k1) * cosphi;
        PHI_2R = phi_2 - phi_1;
        if (Math.abs(PHI_2R) > 2 * Math.PI) {
            PHI_2R = PHI_2R % (2 * Math.PI);
        }
        cosphi = Math.cos(PHI_2R);
        cosphi2 = cosphi * cosphi;
        sinphi = Math.sin(PHI_2R);
        sinphi2 = sinphi * sinphi;
        cosphisinphi = 2.0 * cosphi * sinphi;
        A22 = a2 * a2;
        B22 = b2 * b2;
        tmp0 = (cosphi * H2_TR + sinphi * K2_TR) / A22;
        tmp1 = (sinphi * H2_TR - cosphi * K2_TR) / B22;
        tmp2 = cosphi * H2_TR + sinphi * K2_TR;
        tmp3 = sinphi * H2_TR - cosphi * K2_TR;
        // implicit polynomial coefficients for the second ellipse
        AA = cosphi2 / A22 + sinphi2 / B22;
        BB = cosphisinphi / A22 - cosphisinphi / B22;
        CC = sinphi2 / A22 + cosphi2 / B22;
        DD = -2.0 * cosphi * tmp0 - 2.0 * sinphi * tmp1;
        EE = -2.0 * sinphi * tmp0 + 2.0 * cosphi * tmp1;
        FF = tmp2 * tmp2 / A22 + tmp3 * tmp3 / B22 - 1.0;
        cy[4] = Math.pow(a1, 4.0) * AA * AA + b1 * b1 * (a1 * a1 * (BB * BB - 2.0 * AA * CC) + b1 * b1 * CC * CC);
        cy[3] = 2.0 * b1 * (b1 * b1 * CC * EE + a1 * a1 * (BB * DD - AA * EE));
        cy[2] = a1 * a1 * ((b1 * b1 * (2.0 * AA * CC - BB * BB) + DD * DD - 2.0 * AA * FF) - 2.0 * a1 * a1 * AA * AA) + b1 * b1 * (2.0 * CC * FF + EE * EE);
        cy[1] = 2.0 * b1 * (a1 * a1 * (AA * EE - BB * DD) + EE * FF);
        cy[0] = (a1 * (a1 * AA - DD) + FF) * (a1 * (a1 * AA + DD) + FF);
        if (Math.abs(cy[4]) > 0.0) {
            //qDebug() << "quartic";
            // quartic coefficient nonzero, use quartic formula:
            for (i = 0; i <= 3; i++) {
                py[4 - i] = cy[i] / cy[4];
            }
            py[0] = 1.0;
            //        for (i = 0; i < 5; i++) {
            //            qDebug() << "py[" << i << "]: " << py[i];
            //        }
            ltmath_1.LTMath.getBiQuadRoots(py, r);
            nroots = 4;
        }
        else if (Math.abs(cy[3]) > 0.0) {
            //qDebug() << "cubic";
            // quartic degenerates to cubic, use cubic formula:
            for (i = 0; i <= 2; i++) {
                py[3 - i] = cy[i] / cy[3];
            }
            py[0] = 1.0;
            ltmath_1.LTMath.getCubicRoots(py, r);
            nroots = 3;
        }
        else if (Math.abs(cy[2]) > 0.0) {
            //qDebug() << "quadratic";
            // quartic degenerates to quadratic, use quadratic formula:
            for (i = 0; i <= 1; i++) {
                py[2 - i] = cy[i] / cy[2];
            }
            py[0] = 1.0;
            ltmath_1.LTMath.getQuadRoots(py, r);
            nroots = 2;
        }
        else if (Math.abs(cy[1]) > 0.0) {
            //qDebug() << "linear";
            // quartic degenerates to linear: solve directly:
            // cy[1]*Y + cy[0] = 0
            r[1][1] = (-cy[0] / cy[1]);
            r[2][1] = 0.0;
            nroots = 1;
        }
        else {
            nroots = 0;
        }
        nychk = 0;
        for (i = 1; i <= nroots; i++) {
            if (Math.abs(r[2][i]) < epsTolerance) {
                nychk++;
                ychk[nychk] = r[1][i] * b1;
                //qDebug() << "real root: ychk[nychk]: " << ychk[nychk];
            }
        }
        // sort the real roots by straight insertion
        for (j = 2; j <= nychk; j++) {
            tmp0 = ychk[j];
            for (k = j - 1; k >= 1; k--) {
                if (ychk[k] <= tmp0) {
                    break;
                }
                ychk[k + 1] = ychk[k];
            }
            ychk[k + 1] = tmp0;
        }
        // determine whether polynomial roots are points of intersection
        // for the two ellipses
        nintpts = 0;
        for (i = 1; i <= nychk; i++) {
            //qDebug() << "ychk[i]: " << ychk[i];
            // check for multiple roots
            if ((i > 1) && (Math.abs(ychk[i] - ychk[i - 1]) < (epsTolerance / 2.0))) {
                //qDebug() << "multiple roots";
                continue;
            }
            // check intersection points for ychk[i]
            if (Math.abs(ychk[i]) > b1) {
                x1 = 0.0;
            }
            else {
                x1 = a1 * Math.sqrt(1.0 - (ychk[i] * ychk[i]) / (b1 * b1));
            }
            x2 = -x1;
            //        qDebug() << "fabs(ellipse2tr(x1, ychk[i], AA, BB, CC, DD, EE, FF)): " << fabs(ellipse2tr(x1, ychk[i], AA, BB, CC, DD, EE, FF));
            if (Math.abs(LTIntersection.ellipse2tr(x1, ychk[i], AA, BB, CC, DD, EE, FF)) < epsTolerance / 2.0) {
                //            qDebug() << "got intersection I...";
                nintpts++;
                if (nintpts > 4) {
                    return [];
                }
                xint[nintpts] = x1;
                yint[nintpts] = ychk[i];
            }
            if ((Math.abs(LTIntersection.ellipse2tr(x2, ychk[i], AA, BB, CC, DD, EE, FF)) < epsTolerance / 2.0) && (Math.abs(x2 - x1) > epsTolerance / 2.0)) {
                nintpts++;
                if (nintpts > 4) {
                    return [];
                }
                xint[nintpts] = x2;
                yint[nintpts] = ychk[i];
            }
        }
        for (let i = 1; i <= nintpts; i++) {
            let v = new ltpoint_1.LTPoint(xint[i], yint[i]);
            v.rotate(-angleOffset);
            v.move(-centerOffset.x, -centerOffset.y);
            ret.push(v);
        }
        return ret;
    }
    static getIntersectionPointsLA(line1, arc2, limited1, limited2 = true) {
        var candidates = LTIntersection.getIntersectionPointsLC(line1, new segment_1.RCircle(arc2.getCenter(), arc2.getRadius()), limited1);
        if (!limited2) {
            return candidates;
        }
        var res = [];
        for (var i = 0; i < candidates.length; i++) {
            if (arc2.isOnShape(candidates[i])) {
                res.push(candidates[i]);
            }
        }
        // ret.setTangent(tangent);
        return res;
    }
    static getIntersectionPointsEE_1(ellipse1, ellipse2, limited) {
        let candidates = this.getIntersectionPointsEE(ellipse1, ellipse2);
        if (!limited) {
            return candidates;
        }
        let ret = [];
        for (let i = 0; i < candidates.length; i++) {
            let c = candidates[i];
            let onShape = true;
            let a1 = ellipse1.getCenter().getAngleTo(ellipse1.getStartPoint());
            let a2 = ellipse1.getCenter().getAngleTo(ellipse1.getEndPoint());
            let a = ellipse1.getCenter().getAngleTo(c);
            if (!ltmath_1.LTMath.isAngleBetween(a, a1, a2, ellipse1.isReversed())) {
                onShape = false;
            }
            a1 = ellipse2.getCenter().getAngleTo(ellipse2.getStartPoint());
            a2 = ellipse2.getCenter().getAngleTo(ellipse2.getEndPoint());
            a = ellipse2.getCenter().getAngleTo(c);
            if (!ltmath_1.LTMath.isAngleBetween(a, a1, a2, ellipse2.isReversed())) {
                onShape = false;
            }
            if (onShape) {
                ret.push(c);
            }
        }
        return ret;
    }
    static getIntersectionPointsLC(line1, circle2, limited) {
        var res = [];
        var vLineCenter = line1.getVectorTo(circle2.getCenter(), false);
        if (vLineCenter == null) {
            return res;
        }
        var dist = vLineCenter.getMagnitude();
        if (ltmath_1.LTMath.fuzzyCompare(dist, circle2.getRadius(), 1.0e-6)) {
            var sol = circle2.getCenter().minus(vLineCenter);
            if (!limited || line1.isOnShape(sol, true, 1.0e-6)) {
                res.push(sol);
            }
            return res;
        }
        var p = line1.getStartPoint();
        var d = line1.getEndPoint().minus(line1.getStartPoint());
        if (d.getMagnitude() < 1.0e-6) {
            return res;
        }
        var delta = p.minus(circle2.getCenter());
        // root term:
        var term = Math.pow(ltpoint_1.LTPoint.getDotProduct(d, delta), 2.0) -
            Math.pow(d.getMagnitude(), 2.0) *
                (Math.pow(delta.getMagnitude(), 2.0) -
                    Math.pow(circle2.getRadius(), 2.0));
        // no intersection:
        if (term < 0.0) {
            return res;
        }
        // one or two intersections:
        var t1 = (-ltpoint_1.LTPoint.getDotProduct(d, delta) + Math.sqrt(term)) /
            Math.pow(d.getMagnitude(), 2.0);
        var t2;
        var tangent = false;
        // only one intersection:
        if (Math.abs(term) < ltutils_1.PointTolerance) {
            t2 = t1;
            tangent = true;
        }
        // two intersections
        else {
            t2 =
                (-ltpoint_1.LTPoint.getDotProduct(d, delta) - Math.sqrt(term)) /
                    Math.pow(d.getMagnitude(), 2.0);
        }
        var sol1;
        var sol2 = null;
        sol1 = p.plus(d.dot(t1));
        if (!tangent) {
            sol2 = p.plus(d.dot(t2));
        }
        if (!limited || line1.isOnShape(sol1, true, 1.0e-6)) {
            res.push(sol1);
        }
        if (sol2 != null) {
            if (!limited || line1.isOnShape(sol2, true, 1.0e-6)) {
                res.push(sol2);
            }
        }
        // ret.setTangent(tangent);
        // tangent with two intersections very close to each other:
        if (res.length == 2 && res[0].equalsFuzzy(res[1])) {
            res.slice(0, -1);
        }
        return res;
    }
    static getIntersectionPointsCC(circle1, circle2, limited = true) {
        var r1 = circle1.getRadius();
        var r2 = circle2.getRadius();
        if (r1 < r2) {
            // make sure circle 1 is the larger one (for tangency detection):
            // return Shape.getIntersectionPointsCC(circle2, circle1);
        }
        var res = [];
        var c1 = circle1.getCenter();
        var c2 = circle2.getCenter();
        var u = c2.minus(c1);
        var uMag = u.getMagnitude();
        // concentric
        if (uMag < ltutils_1.PointTolerance) {
            return res;
        }
        var tol = (r1 + r2) / 200000;
        // the two circles (almost) touch externally / internally in one point (tangent):
        if (ltmath_1.LTMath.fuzzyCompare(uMag, r1 + r2, tol) ||
            ltmath_1.LTMath.fuzzyCompare(uMag, Math.abs(r1 - r2), tol)) {
            u.setMagnitude(r1);
            res.push(c1.plus(u));
            return res;
        }
        var v = new ltpoint_1.LTPoint(u.y, -u.x);
        var s, t1, t2, term;
        s = (1.0 / 2.0) * ((r1 * r1 - r2 * r2) / Math.pow(uMag, 2.0) + 1.0);
        term = (r1 * r1) / Math.pow(uMag, 2.0) - s * s;
        // no intersection:
        if (term < 0.0) {
            return res;
        }
        // one or two intersections:
        t1 = Math.sqrt(term);
        t2 = -Math.sqrt(term);
        var sol1 = c1.plus(u.dot(s)).plus(v.dot(t1));
        var sol2 = c1.plus(u.dot(s)).plus(v.dot(t2));
        if (Math.abs(sol1.minus(sol2).getMagnitude()) < tol) {
            res.push(sol1);
        }
        else {
            res.push(sol1);
            res.push(sol2);
        }
        return res;
    }
    static getIntersectionPoints(shape1, shape2, limited, is_same = false) {
        var gotInfiniteShape = false;
        var empty = [];
        if (limited && !gotInfiniteShape) {
            var bb1 = shape1.getBoundingBox().grow(1e-2);
            var bb2 = shape2.getBoundingBox().grow(1e-2);
            if (!bb1.intersects(bb2)) {
                return empty;
            }
        }
        if (shape1.getShapeType() == ltutils_1.ShapeType.Line) {
            if (shape2.getShapeType() == ltutils_1.ShapeType.Line) {
                return LTIntersection.getIntersectionPointsLL(shape1, shape2, limited, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Arc) {
                return LTIntersection.getIntersectionPointsLA(shape1, shape2, limited, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Circle) {
                return LTIntersection.getIntersectionPointsLC(shape1, shape2, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Polyline) {
                return LTIntersection.getIntersectionPointsLX(shape1, shape2, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Point) {
                //获取块中的相交点;
                return LTIntersection.getIntersectionPointsLX(shape1, shape2, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Ellipse) {
                return LTIntersection.getIntersectionPointsLE(shape1, shape2, limited);
            }
        }
        else if (shape1.getShapeType() == ltutils_1.ShapeType.Circle) {
            if (shape2.getShapeType() == ltutils_1.ShapeType.Line) {
                return LTIntersection.getIntersectionPointsLA(shape2, shape1, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Circle) {
                return LTIntersection.getIntersectionPointsCC(shape2, shape1, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Arc) {
                return LTIntersection.getIntersectionPointsAC(shape2, shape1, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Polyline) {
                return LTIntersection.getIntersectionPointsCX(shape1, shape2, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Ellipse) {
                return LTIntersection.getIntersectionPointsCE(shape1, shape2);
            }
        }
        else if (shape1.getShapeType() == ltutils_1.ShapeType.Polyline) {
            if (shape2.getShapeType() == ltutils_1.ShapeType.Line) {
                return LTIntersection.getIntersectionPointsLX(shape2, shape1, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Circle) {
                return LTIntersection.getIntersectionPointsCX(shape2, shape1, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Arc) {
                return LTIntersection.getIntersectionPointsAX(shape2, shape1, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Polyline) {
                return LTIntersection.getIntersectionPointsXX(shape2, shape1, limited, false);
            }
        }
        else if (shape1.getShapeType() == ltutils_1.ShapeType.Arc) {
            if (shape2.getShapeType() == ltutils_1.ShapeType.Line) {
                return LTIntersection.getIntersectionPointsLA(shape2, shape1, limited, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Circle) {
                return LTIntersection.getIntersectionPointsAC(shape1, shape2, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Arc) {
                return LTIntersection.getIntersectionPointsAA(shape2, shape1, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Polyline) {
                return LTIntersection.getIntersectionPointsAX(shape1, shape2, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Ellipse) {
                return LTIntersection.getIntersectionPointsAE(shape1, shape2, limited);
            }
        }
        else if (shape1.getShapeType() == ltutils_1.ShapeType.Ellipse) {
            if (shape2.getShapeType() == ltutils_1.ShapeType.Line) {
                return LTIntersection.getIntersectionPointsLE(shape2, shape1, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Circle) {
                return LTIntersection.getIntersectionPointsCE(shape2, shape1);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Arc) {
                return LTIntersection.getIntersectionPointsAE(shape2, shape1, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Polyline) {
                return LTIntersection.getIntersectionPointsEX(shape1, shape2, limited);
            }
            else if (shape2.getShapeType() == ltutils_1.ShapeType.Ellipse) {
                return LTIntersection.getIntersectionPointsEE_1(shape1, shape2, limited);
            }
        }
        else if (shape1.getShapeType() == ltutils_1.ShapeType.Spline) {
            //暂时不支持，贝塞尔曲线的相交运算;
        }
        return empty;
    }
    static getIntersectionPointsEX(arg0, explodable2, limited) {
        let res = [];
        let sub = explodable2.getExploded();
        for (let m = 0; m < sub.length; ++m) {
            if (sub[m] instanceof segment_1.RLine) {
                let sub_points = LTIntersection.getIntersectionPointsLE(sub[m], arg0, limited, true);
                res = res.concat(sub_points);
                continue;
            }
            else if (sub[m] instanceof segment_1.RArc) {
                res = res.concat(LTIntersection.getIntersectionPointsAE(sub[m], arg0, limited));
            }
        }
        return res;
    }
    static getIntersectionPointsLX(line1, explodable2, limited) {
        let res = [];
        let sub = explodable2.getExploded();
        for (let m = 0; m < sub.length; ++m) {
            if (sub[m] instanceof segment_1.RLine) {
                let sub_points = LTIntersection.getIntersectionPointsLL(line1, sub[m], limited, true);
                res = res.concat(sub_points);
                continue;
            }
            else if (sub[m] instanceof segment_1.RArc) {
                res = res.concat(LTIntersection.getIntersectionPointsLA(line1, sub[m], limited, true));
            }
        }
        return res;
    }
    static getIntersectionPointsCX(circle1, explodable2, limited) {
        let res = [];
        var sub = explodable2.getExploded();
        for (let m = 0; m < sub.length; ++m) {
            if (sub[m] instanceof segment_1.RLine) {
                res = res.concat(LTIntersection.getIntersectionPointsLC(sub[m], circle1, limited));
            }
            else if (sub[m] instanceof segment_1.RArc) {
                res = res.concat(LTIntersection.getIntersectionPointsAC(sub[m], circle1, limited));
            }
        }
        return res;
    }
    static getIntersectionPointsAA(arc1, arc2, limited) {
        var candidates = LTIntersection.getIntersectionPoints(new segment_1.RCircle(arc1.getCenter(), arc1.getRadius()), new segment_1.RCircle(arc2.getCenter(), arc2.getRadius()), limited);
        if (!limited) {
            return candidates;
        }
        var res = [];
        for (var i = 0; i < candidates.length; i++) {
            if (arc1.isOnShape(candidates[i]) && arc2.isOnShape(candidates[i])) {
                res.push(candidates[i]);
            }
        }
        // ret.setTangent(tangent);
        return res;
    }
    static getIntersectionPointsAC(arc1, circle2, limited) {
        let circie1 = new segment_1.RCircle(arc1.center, arc1.radius);
        var candidates = LTIntersection.getIntersectionPoints(circie1, circle2, limited);
        if (!limited) {
            return candidates;
        }
        var res = [];
        for (var i = 0; i < candidates.length; i++) {
            if (arc1.isOnShape(candidates[i])) {
                res.push(candidates[i]);
            }
        }
        return res;
    }
    static getIntersectionPointsAE(arc1, ellipse2, limited) {
        let candidates = this.getIntersectionPointsCE(new segment_1.RCircle(arc1.getCenter(), arc1.getRadius()), ellipse2);
        if (!limited) {
            return candidates;
        }
        let res = [];
        for (let i = 0; i < candidates.length; i++) {
            let c = candidates[i];
            if (arc1.isOnShape(c)) {
                if (!ellipse2.isFullEllipse()) {
                    let a1 = ellipse2.getCenter().getAngleTo(ellipse2.getStartPoint());
                    let a2 = ellipse2.getCenter().getAngleTo(ellipse2.getEndPoint());
                    let a = ellipse2.getCenter().getAngleTo(c);
                    if (!ltmath_1.LTMath.isAngleBetween(a, a1, a2, ellipse2.isReversed())) {
                        continue;
                    }
                }
                res.push(c);
            }
        }
        return res;
    }
    static getIntersectionPointsCE(circle1, ellipse2) {
        let ellipse1 = new segment_1.REllipse(circle1.getCenter(), new ltpoint_1.LTPoint(circle1.getRadius(), 0.), 1.0, 0.0, 2.0 * Math.PI, false);
        return this.getIntersectionPointsEE(ellipse1, ellipse2);
    }
    static getIntersectionPointsLE(line1, ellipse2, limited1 = true, limited2 = true) {
        var res = [];
        //line1 = new RLine(new LTPoint(145.141452,-1.569419222), new LTPoint(136.90294966,-1.56941922236));
        // find out if line1 is (almost) a tangent:
        var tangents = ellipse2.getTangents(line1.getMiddlePoint());
        for (var i = 0; i < tangents.length; i++) {
            var a = tangents[i].getAngle();
            var ad1 = Math.abs(ltmath_1.LTMath.getAngleDifference180(a, line1.getDirection1()));
            var ad2 = Math.abs(ltmath_1.LTMath.getAngleDifference180(a, line1.getDirection2()));
            if (ad1 < 1.0e-2 || ad2 < 1.0e-2) {
                var candidate = tangents[i].getEndPoint();
                if (!limited1 || line1.isOnShape(candidate)) {
                    res.push(candidate);
                }
                // no need to continue: max. one tangent possible:
                return res;
            }
        }
        // rotate into normal position:
        var ang = ellipse2.getAngle();
        var rx = ellipse2.getMajorRadius();
        var ry = ellipse2.getMinorRadius();
        var center = ellipse2.getCenter();
        var a1 = line1.getStartPoint().clone();
        a1.rotate(-ang, center);
        var a2 = line1.getEndPoint().clone();
        a2.rotate(-ang, center);
        var origin = a1;
        var dir = a2.minus(a1);
        var diff = origin.minus(center);
        var mDir = new ltpoint_1.LTPoint(dir.x / (rx * rx), dir.y / (ry * ry));
        var mDiff = new ltpoint_1.LTPoint(diff.x / (rx * rx), diff.y / (ry * ry));
        var a = ltpoint_1.LTPoint.getDotProduct(dir, mDir);
        var b = ltpoint_1.LTPoint.getDotProduct(dir, mDiff);
        var c = ltpoint_1.LTPoint.getDotProduct(diff, mDiff) - 1.0;
        var d = b * b - a * c;
        var res1 = null;
        var res2 = null;
        if (d < 0) {
            // no solution
        }
        else if (d > 0) {
            var root = Math.sqrt(d);
            var t_a = (-b - root) / a;
            var t_b = (-b + root) / a;
            res1 = a1.getLerp(a2, t_a).rotate(ang, center);
            res2 = a1.getLerp(a2, t_b).rotate(ang, center);
        }
        else {
            var t = -b / a;
            if (0 <= t && t <= 1) {
                // one solution:
                res1 = a1.getLerp(a2, t).rotate(ang, center);
            }
            else {
                // no solution
            }
        }
        if (res1 != null) {
            if ((!limited1 || line1.isOnShape(res1)) &&
                (!limited2 || ellipse2.isOnShape(res1))) {
                res.push(res1);
            }
        }
        if (res2 != null) {
            if ((!limited1 || line1.isOnShape(res2)) &&
                (!limited2 || ellipse2.isOnShape(res2))) {
                res.push(res2);
            }
        }
        return res;
    }
    static getIntersectionPointsXX(explodable1, explodable2, limited, same) {
        let sub1 = explodable1.getExploded();
        let sub2 = explodable2.getExploded();
        let res = [];
        let c1 = 0, c2 = 0;
        for (let i = 0; i < sub1.length; i++, ++c1) {
            for (let j = 0; j < sub2.length; j++, ++c2) {
                if (true === same) {
                    if (Math.abs(c1 - c2) <= 1) {
                        continue;
                    }
                }
                let r = LTIntersection.getIntersectionPoints(sub1[i], sub2[j], limited, same);
                for (let k = 0; k < r.length; ++k) {
                    res.push(r[k]);
                }
            }
        }
        return res;
    }
}
exports.LTIntersection = LTIntersection;
