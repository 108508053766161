"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawArc = exports.drawCircle = void 0;
const ltentity_1 = require("../cad/ltentity");
const segment_1 = require("../cad/segment");
const commandfactory_1 = require("../map/commandfactory");
const drawutils_1 = require("./drawutils");
function* drawCircle(map, command_param) {
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定圆的圆心或[三点(3P) 两点(2P)]:");
    let mouse = yield;
    if (mouse.done == commandfactory_1.CommandStatus.eCancel || mouse.done == commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    else if (mouse.done == commandfactory_1.CommandStatus.eKeyWord) {
        if (mouse.value == "3P") {
            commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定圆上的第一点:");
            let start_point = yield;
            if (start_point.done == commandfactory_1.CommandStatus.eCancel || start_point.done == commandfactory_1.CommandStatus.eDone) {
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            let line = new ltentity_1.LTLine(start_point.value, start_point.value);
            function secondCallback(mouse) {
                line.setEnd(mouse.mapPos);
                drawutils_1.DrawUtilImpl.trackEntity(map, line);
            }
            commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定圆上的第二点:", secondCallback);
            let second_point = yield;
            if (second_point.done == commandfactory_1.CommandStatus.eCancel || second_point.done == commandfactory_1.CommandStatus.eDone) {
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            drawutils_1.DrawUtilImpl.destroyEntity(map, line);
            line = new ltentity_1.LTLine(second_point.value, second_point.value);
            let circle = new ltentity_1.LTCircle(start_point.value, 10);
            circle.setLineType(ltentity_1.linetype_continuous);
            circle.setStrokeWidth(0);
            function thirdCallback(mouse) {
                line.setEnd(mouse.mapPos);
                drawutils_1.DrawUtilImpl.drawDashLine(map, line);
                let sub_circle = segment_1.RCircle.createFrom3Points(start_point.value, second_point.value, mouse.mapPos);
                if (sub_circle !== null) {
                    circle.data = sub_circle;
                    drawutils_1.DrawUtilImpl.trackEntity(map, circle);
                }
            }
            commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定圆上的第三点:", thirdCallback);
            let third_point = yield;
            if (third_point.done == commandfactory_1.CommandStatus.eCancel || third_point.done == commandfactory_1.CommandStatus.eDone) {
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            let sub_circle = segment_1.RCircle.createFrom3Points(start_point.value, second_point.value, third_point.value);
            if (sub_circle !== null) {
                circle.data = sub_circle;
                drawutils_1.DrawUtilImpl.saveEntity(map, circle);
            }
            drawutils_1.DrawUtilImpl.destroyDashEntity(map, line);
            drawutils_1.DrawUtilImpl.destroyEntity(map, circle);
        }
        else if (mouse.value == "2P") {
            commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定圆直径的第一个端点:");
            let start_point = yield;
            if (start_point.done == commandfactory_1.CommandStatus.eCancel || start_point.done == commandfactory_1.CommandStatus.eDone) {
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            let line = new ltentity_1.LTLine(start_point.value, start_point.value);
            let circle = new ltentity_1.LTCircle(start_point.value, 0);
            circle.setLineType(ltentity_1.linetype_continuous);
            circle.setStrokeWidth(0);
            function secondCallback(mouse) {
                line.setEnd(mouse.mapPos);
                drawutils_1.DrawUtilImpl.drawDashLine(map, line);
                circle.data = segment_1.RCircle.createFrom2Points(start_point.value, mouse.mapPos);
                drawutils_1.DrawUtilImpl.trackEntity(map, circle);
            }
            commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定圆直径的第二个端点:", secondCallback);
            let second_point = yield;
            if (second_point.done == commandfactory_1.CommandStatus.eCancel || second_point.done == commandfactory_1.CommandStatus.eDone) {
                drawutils_1.DrawUtilImpl.destroyDashEntity(map, line);
                drawutils_1.DrawUtilImpl.destroyEntity(map, circle);
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            drawutils_1.DrawUtilImpl.destroyDashEntity(map, line);
            drawutils_1.DrawUtilImpl.saveEntity(map, circle);
            drawutils_1.DrawUtilImpl.destroyEntity(map, circle);
        }
        return { value: null, done: commandfactory_1.CommandStatus.eDone };
    }
    let center_point = mouse.value;
    let circle = new ltentity_1.LTCircle(center_point, 0);
    circle.setLineType(ltentity_1.linetype_continuous);
    circle.setStrokeWidth(0);
    function drawCallback(mouse) {
        let distance = center_point.getDistanceTo(mouse.mapPos);
        circle.subData().setRadius(distance);
        drawutils_1.DrawUtilImpl.trackEntity(map, circle);
    }
    let command_tip = "指定圆的半径:";
    commandfactory_1.CommandFactory.getInstance().getDistance(map, "请输入半径:", drawCallback, center_point);
    let radius_val = yield;
    if (radius_val.done == commandfactory_1.CommandStatus.eCancel) {
        drawutils_1.DrawUtilImpl.destroyEntity(map, circle);
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    if (radius_val.done == commandfactory_1.CommandStatus.eDone) {
        drawutils_1.DrawUtilImpl.destroyEntity(map, circle);
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    circle.subData().setRadius(radius_val.value);
    drawutils_1.DrawUtilImpl.saveEntity(map, circle);
    drawutils_1.DrawUtilImpl.destroyEntity(map, circle);
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.drawCircle = drawCircle;
function* drawArc(map, command_param) {
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定圆弧的起点或[圆心(C)]:");
    let mouse_start = yield;
    let center_point = null;
    let is_center = false;
    if (mouse_start.done == commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    else if (mouse_start.done == commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    else if (mouse_start.done == commandfactory_1.CommandStatus.eKeyWord) {
        commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定圆弧的圆心:", null);
        is_center = true;
        let center_point_yelid = yield;
        if (center_point_yelid.done == commandfactory_1.CommandStatus.eCancel) {
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
        else if (center_point_yelid.done == commandfactory_1.CommandStatus.eDone) {
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
        center_point = center_point_yelid.value;
    }
    else {
        center_point = mouse_start.value;
    }
    if (center_point == null) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let track_line = new ltentity_1.LTLine(center_point, center_point);
    function drawCallback1(mouse) {
        track_line.setEnd(mouse.mapPos);
        drawutils_1.DrawUtilImpl.drawDashLine(map, track_line);
    }
    let command_tip = "指定圆弧第二点:";
    if (is_center) {
        command_tip = "指定圆弧起点:";
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(map, command_tip, drawCallback1);
    let start_point = yield;
    if (start_point.done == commandfactory_1.CommandStatus.eCancel) {
        drawutils_1.DrawUtilImpl.destroyDashEntity(map, track_line);
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    else if (start_point.done == commandfactory_1.CommandStatus.eDone) {
        drawutils_1.DrawUtilImpl.destroyDashEntity(map, track_line);
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    drawutils_1.DrawUtilImpl.destroyDashEntity(map, track_line);
    let begin_point = start_point.value;
    track_line = new ltentity_1.LTLine(begin_point, begin_point);
    let sub_arc = new ltentity_1.LTArc(center_point, 10, 0, 1.57);
    function drawCallback2(mouse) {
        if (center_point === null) {
            return;
        }
        track_line.setEnd(mouse.mapPos);
        drawutils_1.DrawUtilImpl.destroyDashEntity(map, track_line);
        //绘制圆弧;
        if (is_center) {
            let temp_arc = segment_1.RArc.createFromCenterPos(begin_point, center_point, mouse.mapPos);
            sub_arc.data = temp_arc;
        }
        else {
            let temp_arc = segment_1.RArc.createFrom3Points(center_point, begin_point, mouse.mapPos);
            if (temp_arc !== null) {
                sub_arc.data = temp_arc;
            }
        }
        drawutils_1.DrawUtilImpl.trackEntity(map, sub_arc);
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定圆弧端点:", drawCallback2);
    let end_point = yield;
    if (end_point.done == commandfactory_1.CommandStatus.eCancel) {
        drawutils_1.DrawUtilImpl.destroyDashEntity(map, track_line);
        drawutils_1.DrawUtilImpl.destroyEntity(map, sub_arc);
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    else if (end_point.done == commandfactory_1.CommandStatus.eDone) {
        drawutils_1.DrawUtilImpl.destroyDashEntity(map, track_line);
        drawutils_1.DrawUtilImpl.destroyEntity(map, sub_arc);
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    if (is_center) {
        let arc = segment_1.RArc.createFromCenterPos(start_point.value, center_point, end_point.value);
        sub_arc.data = arc;
    }
    else {
        let arc = segment_1.RArc.createFrom3Points(center_point, start_point.value, end_point.value);
        if (arc !== null) {
            sub_arc.data = arc;
        }
    }
    drawutils_1.DrawUtilImpl.destroyEntity(map, track_line);
    drawutils_1.DrawUtilImpl.destroyEntity(map, sub_arc);
    drawutils_1.DrawUtilImpl.saveEntity(map, sub_arc);
}
exports.drawArc = drawArc;
