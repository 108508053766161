"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SprayCheck = void 0;
const pipeNode_1 = require("./pipe/pipeNode");
class SprayCheck {
    constructor() {
        this._sum = 0;
    }
    check(rootNode, cadMap) {
        this._sum = 0;
        this.traverseToGetSprinklerNum(rootNode);
        if (this._sum < 6) {
            cadMap.printMessage("生成管线失败，请重新检查并选择计算图层");
        }
    }
    traverseToGetSprinklerNum(node) {
        for (let child of node._children) {
            if (child._type == pipeNode_1.PipeNodeType.Child)
                this._sum++;
            this.traverseToGetSprinklerNum(child);
        }
    }
}
exports.SprayCheck = SprayCheck;
