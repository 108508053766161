"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PipeResultV2 = void 0;
class PipeResultV2 {
    constructor(pipeNodeGroups, coordinateUtil) {
        this._polygonPoints = []; //原始的多边形点
        this._polygonSegsLocal = []; //转为当地坐标系下的多边形线段
        this._doors = []; //门线段
        this._pipeNodeGroups = pipeNodeGroups;
        this._coordinateUtil = coordinateUtil;
    }
}
exports.PipeResultV2 = PipeResultV2;
