"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addBlockWipeOut = exports.addBlock = void 0;
const converthull_1 = require("../cad/converthull");
const ltentity_1 = require("../cad/ltentity");
const ltpoint_1 = require("../cad/ltpoint");
const ltfeature_1 = require("../data/ltfeature");
const commandfactory_1 = require("../map/commandfactory");
function blockHull(block) {
    let shapes = [];
    block._entitys.forEach(entity => {
        shapes = shapes.concat(entity.data.getShapes(null));
    });
    //计算凸包;
    let result_points = [];
    for (let i = 0; i < shapes.length; i++) {
        // if(shapes[i] instanceof RLine)
        // {
        //   result_points.push(shapes[i].getStartPoint());
        //   result_points.push(shapes[i].getEndPoint());
        // }
        let l = shapes[i].getHull();
        result_points = result_points.concat(l.subData().vertices);
    }
    return (0, converthull_1.concaveHull)(result_points);
}
function* addBlock(map, command_param) {
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, null);
    let select_feature_command = yield;
    if (select_feature_command.done === commandfactory_1.CommandStatus.eCancel || select_feature_command.done === commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    if (map.selectedFeatures().length == 0) {
        map.printMessage("未选择对象，请选择要添加的图形对象");
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let block_name = command_param.blockName;
    if (command_param.blockName == undefined || command_param.blockName == null) {
        commandfactory_1.CommandFactory.getInstance().getString(map, "指定新增块的块名:");
        let input_block_name = yield;
        if (input_block_name.done === commandfactory_1.CommandStatus.eCancel || input_block_name.done === commandfactory_1.CommandStatus.eDone) {
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
        if (input_block_name.value === "") {
            map.printMessage("块名不能为空");
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
        block_name = input_block_name.value;
    }
    let server = map.dwgservice;
    if (server == null) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    if (server.hasBlock(block_name)) {
        commandfactory_1.CommandFactory.getInstance().getString(map, "块名已经存在，是否替换[是(Y) 否(N)]:");
        let input_replace = yield;
        if (input_replace.done === commandfactory_1.CommandStatus.eCancel || input_replace.done === commandfactory_1.CommandStatus.eDone) {
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
        if (input_replace.value === "N") {
            map.printMessage("取消添加块");
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定插入点:");
    let input_point = yield;
    if (input_point.done === commandfactory_1.CommandStatus.eCancel || input_point.done === commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let p = input_point.value;
    let blockRef = new ltentity_1.LTBlockReference();
    let selectFeatures = map.selectedFeatures();
    selectFeatures.forEach(feature => {
        let geo = feature.getGeometry().clone();
        geo.move(new ltpoint_1.LTPoint(-p.x, -p.y));
        blockRef.addEntity(geo);
        map.removeFeatures(feature);
    });
    server.setBlock(block_name, blockRef);
    //增加块
    let blockEntity = new ltentity_1.LTEntityPoint();
    blockEntity.blockName = block_name;
    blockEntity.setPoint(input_point.value);
    blockEntity.setLayerName(map.activeLayer().name);
    var fea = new ltfeature_1.LTFeature({
        geometry: blockEntity,
        layer: map.activeLayer()
    });
    map.addFeature(fea);
    map.printMessage("完成块增加");
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.addBlock = addBlock;
function* addBlockWipeOut(map, command_param) {
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, null);
    let select_feature_command = yield;
    if (select_feature_command.done === commandfactory_1.CommandStatus.eCancel || select_feature_command.done === commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    if (map.selectedFeatures().length == 0) {
        map.printMessage("未选择对象，请选择要添加的图形对象");
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    commandfactory_1.CommandFactory.getInstance().getString(map, "指定新增块的块名:");
    let input_block_name = yield;
    if (input_block_name.done === commandfactory_1.CommandStatus.eCancel || input_block_name.done === commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    if (input_block_name.value === "") {
        map.printMessage("块名不能为空");
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let server = map.dwgservice;
    if (server == null) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    if (server.hasBlock(input_block_name.value)) {
        commandfactory_1.CommandFactory.getInstance().getString(map, "块名已经存在，是否替换[是(Y) 否(N)]:");
        let input_replace = yield;
        if (input_replace.done === commandfactory_1.CommandStatus.eCancel || input_replace.done === commandfactory_1.CommandStatus.eDone) {
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
        if (input_replace.value === "N") {
            map.printMessage("取消添加块");
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定插入点:");
    let input_point = yield;
    if (input_point.done === commandfactory_1.CommandStatus.eCancel || input_point.done === commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let p = input_point.value;
    let blockRef = new ltentity_1.LTBlockReference();
    let selectFeatures = map.selectedFeatures();
    selectFeatures.forEach(feature => {
        let geo = feature.getGeometry().clone();
        geo.move(new ltpoint_1.LTPoint(-p.x, -p.y));
        blockRef.addEntity(geo);
        map.removeFeatures(feature);
    });
    let result_polyline = blockHull(blockRef);
    result_polyline.subData().isWipeOut = true;
    blockRef.unshift(result_polyline);
    let block_name = input_block_name.value;
    server.setBlock(block_name, blockRef);
    //增加块
    let blockEntity = new ltentity_1.LTEntityPoint();
    blockEntity.blockName = block_name;
    blockEntity.setPoint(input_point.value);
    blockEntity.setLayerName(map.activeLayer().name);
    var fea = new ltfeature_1.LTFeature({
        geometry: blockEntity,
        layer: map.activeLayer()
    });
    map.addFeature(fea);
    map.printMessage("完成块增加");
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.addBlockWipeOut = addBlockWipeOut;
