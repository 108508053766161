import { saveAs } from "file-saver";
import { CommandFactory } from "@/cadplugins/map/commandfactory";
import {
  DangerLevel,
  SprayConfigs,
  SprinklerType,
  SprinklerSettingMethod,
  getActionAreaList,
  getSprayStrengthList
} from "@/business/rule/config.js";
export const otherMixin = {
  data() {
    return {
      // 喷淋设计界面
      initSpray: false,
      showSpray: false,
      sprayList: [],
      selectLayers: [],
      layersList: [],
      showRelation: false,
      relationshipList: [],
      // 危险级别
      warningVal: "",
      warningList: [],
      areaVal: "",
      areaList: [],
      strongVal: "",
      strongList: [],
      psTypeVal: "",
      psTypeList: [],
      sprinklerSettingVal: "",
      sprinklerSettingList: [],
      distanceToWall: 400
    };
  },
  methods: {
    // 喷淋设计-start
    initSprayDialog() {
      const _this = this;
      //危险级别
      this.warningList = [];
      this.distanceToWall = 400;
      for (let level of DangerLevel) {
        this.warningList.push({
          name: level,
          value: level
        });
      }
      this.warningVal = DangerLevel[0];
      //作用面积
      this.areaList = [];
      let actionAreaList = getActionAreaList(this.warningVal);
      for (let area of actionAreaList) {
        this.areaList.push({
          name: area,
          value: area
        });
      }
      this.areaVal = actionAreaList[0];
      //喷水强度
      this.strongList = [];
      let sprayStrengthList = getSprayStrengthList(
        this.warningVal,
        this.areaVal
      );
      for (let strength of sprayStrengthList) {
        this.strongList.push({
          name: strength,
          value: strength
        });
      }
      this.strongVal = sprayStrengthList[0];
      //喷头类型
      this.psTypeList = [];
      for (let type of SprinklerType) {
        this.psTypeList.push({
          name: type,
          value: type
        });
      }
      this.psTypeVal = SprinklerType[0];
      //喷头设置方式
      this.sprinklerSettingList = [];
      for (let method of SprinklerSettingMethod) {
        this.sprinklerSettingList.push({
          name: method,
          value: method
        });
      }
      this.sprinklerSettingVal = SprinklerSettingMethod[0];

      /*let blocksMap = this.pixiMap._canvas.getBlocks();
      this.sprayList = [];
      for (let blockName of blocksMap.keys()) {
        this.sprayList.push({ id: blockName, name: blockName });
      }*/

      //参与计算的图层
      let layers = this.pixiMap.getLayers();
      this.layersList = [];
      for (let layer of layers) {
        if (layer.name.indexOf("D-") != 0)
          //过滤掉后台添加的图层
          this.layersList.push({ id: layer.name, name: layer.name });
      }
      //默认选择这些图层
      this.selectLayers = ["WALL", "WINDOW", "COLUMN"];
      this.getProjectData().then(res => {
        if (res && res.spray) {
          let sprayData = JSON.parse(res.spray);
          this.selectLayers = sprayData.selectlayer;
          this.warningVal = sprayData.warningVal;
          this.areaVal = sprayData.areaVal;
          this.strongVal = sprayData.strongVal;
          this.psTypeVal = sprayData.psTypeVal;
          this.sprinklerSettingVal = sprayData.sprinklerSettingVal;
          this.distanceToWall = sprayData.distanceToWall;
        }
        _this.showSpray = true;
      });
    },
    // 唤起事件
    onShowSpray() {
      this.$refs.sprayVue.onShowSpray(this.pixiMap, this.productId);
    },
    //选择危险级别
    changeWarn() {
      //作用面积
      this.areaList = [];
      let actionAreaList = getActionAreaList(this.warningVal);
      for (let area of actionAreaList) {
        this.areaList.push({
          name: area,
          value: area
        });
      }
      this.areaVal = actionAreaList[0];
      //喷水强度
      this.strongList = [];
      let sprayStrengthList = getSprayStrengthList(
        this.warningVal,
        this.areaVal
      );
      for (let strength of sprayStrengthList) {
        this.strongList.push({
          name: strength,
          value: strength
        });
      }
      this.strongVal = sprayStrengthList[0];
      console.log("changeWarn", this.warningVal);
    },
    //选择作用面积
    changeArea() {
      //喷水强度
      this.strongList = [];
      let sprayStrengthList = getSprayStrengthList(
        this.warningVal,
        this.areaVal
      );
      for (let strength of sprayStrengthList) {
        this.strongList.push({
          name: strength,
          value: strength
        });
      }
      this.strongVal = sprayStrengthList[0];
      console.log("changeArea", this.areaVal);
    },
    //选择喷水强度
    changeStrong() {
      console.log("changeStrong", this.strongVal);
    },
    //选择喷头类型
    changePsType() {
      console.log("changePsType", this.psTypeVal);
    },
    // 点击确定事件
    sureSpray() {
      if (this.selectLayers.length == 0) {
        this.$message.warning("请先选择图层");
        return;
      }
      let val = {
        selectlayer: this.selectLayers,
        warningVal: this.warningVal,
        areaVal: this.areaVal,
        strongVal: this.strongVal,
        psTypeVal: this.psTypeVal,
        sprinklerSettingVal: this.sprinklerSettingVal,
        distanceToWall: this.distanceToWall
      };
      this.saveProjectData("spray", JSON.stringify(val)).then(res => {
        res;
        CommandFactory.getInstance().execCommand(
          "drawsprinkler",
          this.pixiMap,
          val
        );
        this.showSpray = false;
      });

      //let bb = yield;
    },
    // 取消喷淋事件
    cancelSpray() {
      this.showSpray = false;
      this.showRelation = false;
    },
    // 导入喷淋数据
    addSpray() {
      this.$refs.fileBtn.click();
    },
    // 文件上传之后的回调
    uploadFile(event) {
      const fileList = event.target.files || event.dataTransfer.files; // 上传的文件数组
      const fileData = fileList[0];
      console.log("fileData", fileData);
      if (fileData && fileData.type && fileData.type == "application/json") {
        const reader = new FileReader();
        reader.readAsText(fileData);
        const _this = this;
        reader.onload = function() {
          // this.result为读取到的json字符串，需转成json对象

          // 检测是否导入成功
          if (this.result && typeof this.result == "string") {
            const datas = JSON.parse(this.result);
            console.log("importJSON", datas);
            if (datas.relationshipList && datas.selectLayers) {
              _this.relationshipList = datas.relationshipList || [];
              _this.selectLayers = datas.selectLayers || [];
            } else _this.$message.warning("文件格式错误");
          } else _this.$message.warning("文件格式错误");
        };
        this.$refs.fileBtn.value = null; // 移除文件，可以保证上传同样的文件时，也会触发change事件
      } else this.$message.warning("请上传JSON格式文件");
    },
    // 导出喷淋数据
    exportData() {
      let datas = {
        selectLayers: this.selectLayers,
        relationshipList: this.relationshipList
      };
      let strData = new Blob([JSON.stringify(datas, null, "  ")], {
        type: "application/json" //"text/plain;charset=utf-8"
      });
      saveAs(strData, "喷淋数据.json");
    },
    // 喷淋设计-end
    cancelRelation() {
      this.showRelation = false;
      this.relationshipList = [];
    },
    addRelationShip() {
      this.relationshipList.push({
        id: this.$getUUId(),
        radius: "",
        maxVal: ""
      });
    },
    delRelationShip(idx) {
      this.relationshipList.splice(idx, 1);
    },
    sureRelation() {
      this.showRelation = false;
      console.log("sureRelation", this.relationshipList);
    },
    // 颜色-start
    // 颜色选择
    changeColor(val) {
      console.log("changeColor", val);
    },
    // 通过颜色或下标获取颜色属性
    getColorIndex(val) {
      // false为数字
      console.log("getColorData", Number.isNaN(Number(val)));
      if (!Number.isNaN(Number(val))) {
        return Number(val);
      } else {
        let index = -1;
        this.colorLists.some((item, idx) => {
          if (this.$judgeColor(val.replace(/#/gi, "0x"), item.color)) {
            index = idx;
          }
        });
        if (index == -1) return "";
        else return index;
      }
      // if(Number.isNaN(val))
    },
    // 弹出颜色选择
    onShowTypeColor(color_index = "", color = "", index = -1, type = "") {
      if (index < 0 || !type) return;
      this.colorIndex = index;
      this.colorType = type;
      this.selectColorVal = color_index;
      this.colorVal = color;
      // this.showTypeColor = true;
      this.$refs.dialogColor.showDialog(this.colorVal, this.pixiMap, type);
    },
    callBackDialogColor(val) {
      console.log("callBackDialogColor", val);
      this.selectColorVal = val.index || 0;
      this.selectColorData(val.value);
    },
    // 选择背景色
    onShowBgColor(type) {
      this.colorIndex = -1;
      this.colorType = type;
      this.selectColorVal = "";
      this.colorVal = "";
      this.showTypeColor = true;
    },
    // 选择颜色
    selectColor(item, index = "") {
      this.colorVal = item && item.color ? this.Rgb2Hex(item.color) : "";
      this.selectColorVal = index == "" ? "" : Number(index);
      console.log("selectColor", this.colorVal, this.selectColorVal);
    },
    // 颜色确定
    sureColorVal() {
      if (!this.colorVal) this.$message.warning("请先选择颜色!!!");
      this.selectColorData(this.colorVal);
      this.showTypeColor = false;
    },
    selectColorData(value) {
      console.log(
        "selectColorData",
        this.colorType,
        value,
        this.colorIndex,
        this.selectColorVal
      );
      if (this.colorType == "tc")
        this.changeTcColor(this.colorIndex, value, this.selectColorVal);
      else if (this.colorType == "showColor") {
        this.cgtcColor.val = `颜色 ${this.selectColorVal}`;
        this.cgtcColor.value = value;
        this.cgtcColor.index = this.selectColorVal;
        this.setConventionData({
          sname: "color",
          value: value.replace(/#/gi, "0x"),
          index: this.selectColorVal
        });
      } else if (this.colorType == "selectionColor") {
        this.sxColor.val = `颜色 ${this.selectColorVal}`;
        this.sxColor.value = value;
        this.sxColor.index = this.selectColorVal;
        this.setPixColor(this.sxColor, this.selectColorVal);
      } else if (this.colorType == "ksxz") {
        this.descData.val = `颜色 ${this.selectColorVal}`;
        this.descData.value = value;
        this.descData.index = this.selectColorVal;
        this.selectionData.list[4].val = `颜色 ${this.selectColorVal}`;
        this.selectionData.list[4].value = value;
        this.selectionData.list[4].index = this.selectColorVal;
      } else if (this.colorType == "background") {
        this.pixiMap.backgroundColor = this.colorVal;
      }
      this.colorIndex = -1;
      this.colorType = "";
      this.selectColorVal = "";
      this.colorVal = "";
    },
    // 颜色搜索
    searchColor() {
      let num = this.selectColorVal.replace(/[^\-?\d]/g, "");
      if (num == "") {
        this.colorVal = "";
        this.$message.warning("请输入颜色下标!!!");
      } else {
        num = Number(this.selectColorVal.replace(/[^\-?\d]/g, ""));
        if (num >= 0 && num <= this.colorLists.length - 1) {
          this.colorVal = this.Rgb2Hex(this.colorLists[num].color);
        } else {
          this.colorVal = "";
          this.$message.warning("请输入颜色下标!!!");
        }
      }
    }

    // 颜色-end
  }
};
