"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawAngular2LDimension = exports.drawDimension = void 0;
const commandfactory_1 = require("../map/commandfactory");
const ltentity_js_1 = require("../cad/ltentity.js");
const ltpoint_1 = require("../cad/ltpoint");
const ltfeature_js_1 = require("../data/ltfeature.js");
const ltfields_1 = require("../data/ltfields");
const dimension_1 = require("../cad/dimension");
const segment_1 = require("../cad/segment");
const selectcommands_1 = require("./selectcommands");
class DrawDimensionCommandImpl {
    constructor(map, command_param) {
        this._map = {};
        this._map = map;
        let dimtype = dimension_1.DimensionType.EntityDimAligned;
        if (command_param == undefined || command_param.rotate == false) {
            dimtype = dimension_1.DimensionType.EntityDimRotated;
        }
        this._dimension = new ltentity_js_1.LTDimension(ltpoint_1.LTPoint.nullVector(), ltpoint_1.LTPoint.nullVector(), "", ltpoint_1.LTPoint.nullVector(), ltpoint_1.LTPoint.nullVector(), dimtype);
        let dimstyle = map.dwgservice._dimStyle.keys();
        let name = "";
        for (let value of dimstyle) {
            name = value;
        }
        this._dimension.subData().setDimStyle(name, true);
    }
    setDimRotate(rotate) {
        this._dimension.subData().setRotate(rotate);
    }
    setfirstPoint(pos) {
        this._dimension.subData().extensionPoint1 = pos;
    }
    setSecondPoint(mapPos) {
        this._dimension.subData().extensionPoint2 = mapPos;
        let angle = this._dimension.subData().getAngle() + Math.PI / 2.0;
        var dp = mapPos.plus(ltpoint_1.LTPoint.createPolar(10, angle));
        this._dimension.subData().setDefinitionPoint(dp);
        this.draw();
    }
    setDefinitionPoint(pos) {
        this._dimension.subData().setDefinitionPoint(pos);
        this.draw();
    }
    destroy() {
        this._map.removeGraphics(this._dimension, this._map.trackGraphics);
    }
    draw() {
        this._map.removeGraphics(this._dimension, this._map.trackGraphics);
        this._dimension.worldDraw(this._map.canvas, true, null, this._map.trackGraphics);
    }
    save() {
        var fea = new ltfeature_js_1.LTFeature({
            geometry: this._dimension.clone(),
            layer: this._map.activeLayer()
        });
        //线
        fea.setValue(ltfields_1.FieldNames.EntityLayer, this._map.activeLayer().name);
        fea.setValue(ltfields_1.FieldNames.Linetype, ltentity_js_1.dwg_bylayer);
        fea.setValue(ltfields_1.FieldNames.Color, ltentity_js_1.dwg_bylayer);
        this._map.addFeature(fea, true);
    }
}
function* drawDimension(map, command_param) {
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定第一个尺寸界线原点:");
    let fis_point = yield;
    if (fis_point.done == commandfactory_1.CommandStatus.eCancel || fis_point.done == commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: fis_point.done };
    }
    let start_point = fis_point.value;
    let imp = new DrawDimensionCommandImpl(map, command_param);
    imp.setfirstPoint(start_point);
    function lineCallback(mouse) {
        imp.setSecondPoint(mouse.mapPos);
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定第二个尺寸界线原点:", lineCallback);
    let sec_point = yield;
    if (sec_point.done == commandfactory_1.CommandStatus.eCancel || sec_point.done == commandfactory_1.CommandStatus.eDone) {
        imp.destroy();
        return { value: null, done: sec_point.done };
    }
    imp.setSecondPoint(sec_point.value);
    function centerCallback(mouse) {
        imp.setDefinitionPoint(mouse.mapPos);
    }
    if (command_param == undefined || command_param.rotate == false) {
        //角度标注
        let iscontinue = true;
        while (iscontinue) {
            commandfactory_1.CommandFactory.getInstance().getDistance(map, "指定尺寸界线位置[水平(H) 垂直(V)]:", centerCallback);
            let def_point = yield;
            if (def_point.done == commandfactory_1.CommandStatus.eKeyWord) {
                if (def_point.value == "H") {
                    imp.setDimRotate(0);
                }
                else if (def_point.value == "V") {
                    imp.setDimRotate(Math.PI / 2);
                }
                continue;
            }
            if (def_point.done == commandfactory_1.CommandStatus.eCancel || def_point.done == commandfactory_1.CommandStatus.eDone) {
                imp.destroy();
                return { value: null, done: def_point.done };
            }
            imp.setDefinitionPoint(def_point.value);
            iscontinue = false;
        }
    }
    else {
        commandfactory_1.CommandFactory.getInstance().getDistance(map, "指定尺寸界线位置:", centerCallback);
        let def_point = yield;
        if (def_point.done == commandfactory_1.CommandStatus.eCancel || def_point.done == commandfactory_1.CommandStatus.eDone) {
            imp.destroy();
            return { value: null, done: def_point.done };
        }
        imp.setDefinitionPoint(def_point.value);
    }
    imp.save();
    imp.destroy();
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.drawDimension = drawDimension;
function* drawAngular2LDimension(map, command_param) {
    let line = null;
    function lineCallback(fea, bound) {
        let data = fea.getGeometry().data;
        if (data instanceof segment_1.RLine) {
            line = data;
            return true;
        }
        if (data instanceof segment_1.RPolyline && line !== null && bound == null) {
            return true;
        }
        if (data instanceof segment_1.RPolyline && bound != null) {
            let shape = data.getClosestShape(bound.getCenter(), bound.getWidth() / 2, true);
            if (shape instanceof segment_1.RLine) {
                line = shape;
                return true;
            }
        }
        return false;
    }
    map.select_command.clearSelection();
    let param = { command_tip: "选择第一条直线:", select_type: selectcommands_1.SelectType.eSelectPoint, callback: lineCallback };
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, param);
    let select_feature_command = yield;
    if (select_feature_command.done === commandfactory_1.CommandStatus.eCancel || select_feature_command.done === commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let firstfeatures = select_feature_command.value;
    if (firstfeatures.length == 0) {
        map.printMessage("未选择直线,退出命令。");
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let firstfea = firstfeatures[0];
    //清空上一次选择;
    map.select_command.clearSelection();
    let firstline = firstfea === null || firstfea === void 0 ? void 0 : firstfea.getGeometry().data;
    if ((firstline instanceof segment_1.RLine) == false && line !== null) {
        firstline = line;
    }
    //设置渲染效果
    let firstshape = new ltentity_js_1.LTLine(firstline.getStartPoint(), firstline.getEndPoint());
    firstshape.worldDraw(map.canvas, true, null, map.trackGraphics);
    ///选择第二条直线
    line = null;
    let secparam = { command_tip: "选择第二条直线:", select_type: selectcommands_1.SelectType.eSelectPointAndBox, callback: lineCallback };
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, secparam);
    let select_sec_command = yield;
    if (select_sec_command.done === commandfactory_1.CommandStatus.eCancel || select_sec_command.done === commandfactory_1.CommandStatus.eDone) {
        map.select_command.clearSelection();
        map.removeGraphics(firstshape, map.trackGraphics);
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let secfeatures = select_sec_command.value;
    if (secfeatures.length == 0) {
        map.printMessage("未选择直线,退出命令。");
        map.select_command.clearSelection();
        map.removeGraphics(firstshape, map.trackGraphics);
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let secfea = secfeatures[0];
    map.select_command.clearSelection();
    //创建角度标注
    let type = dimension_1.DimensionType.EntityDimAngular2L;
    let dimension = new ltentity_js_1.LTDimension(ltpoint_1.LTPoint.nullVector(), ltpoint_1.LTPoint.nullVector(), "", ltpoint_1.LTPoint.nullVector(), ltpoint_1.LTPoint.nullVector(), type);
    let dimstyle = map.dwgservice._dimStyle.keys();
    let name = "";
    for (let value of dimstyle) {
        name = value;
    }
    dimension.subData().setDimStyle(name, true);
    let dimensiondata = dimension.data;
    let secline = secfea === null || secfea === void 0 ? void 0 : secfea.getGeometry().data;
    if ((secline instanceof segment_1.RLine) == false && line !== null) {
        secline = line;
    }
    //设置渲染效果
    let sectshape = new ltentity_js_1.LTLine(secline.getStartPoint(), secline.getEndPoint());
    sectshape.worldDraw(map.canvas, true, null, map.trackGraphics);
    line = null;
    dimensiondata.setExtensionLine1Start(firstline.getStartPoint());
    dimensiondata.setExtensionLine1End(firstline.getEndPoint());
    dimensiondata.setExtensionLine2Start(secline.getStartPoint());
    dimensiondata.setExtensionLine2End(secline.getEndPoint());
    //设置中心点
    function centerCallback(mouse) {
        dimensiondata.setDimArcPosition(mouse.mapPos);
        map.removeGraphics(dimension, map.trackGraphics);
        dimension.worldDraw(map.canvas, true, null, map.trackGraphics);
    }
    commandfactory_1.CommandFactory.getInstance().getDistance(map, "指定尺寸界线位置:", centerCallback);
    let def_point = yield;
    if (def_point.done == commandfactory_1.CommandStatus.eCancel || def_point.done == commandfactory_1.CommandStatus.eDone) {
        map.select_command.clearSelection();
        map.removeGraphics(firstshape, map.trackGraphics);
        map.removeGraphics(sectshape, map.trackGraphics);
        map.removeGraphics(dimension, map.trackGraphics);
        return { value: null, done: def_point.done };
    }
    dimensiondata.setDimArcPosition(def_point.value);
    //save
    var fea = new ltfeature_js_1.LTFeature({
        geometry: dimension.clone(),
        layer: map.activeLayer()
    });
    //线
    fea.setValue(ltfields_1.FieldNames.EntityLayer, map.activeLayer().name);
    fea.setValue(ltfields_1.FieldNames.Linetype, ltentity_js_1.dwg_bylayer);
    fea.setValue(ltfields_1.FieldNames.Color, ltentity_js_1.dwg_bylayer);
    map.addFeature(fea, true);
    map.select_command.clearSelection();
    map.removeGraphics(firstshape, map.trackGraphics);
    map.removeGraphics(sectshape, map.trackGraphics);
    map.removeGraphics(dimension, map.trackGraphics);
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.drawAngular2LDimension = drawAngular2LDimension;
