"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RiserGenerator = void 0;
const sprinklerGroup_1 = require("./sprinklerGroup");
class RiserGenerator {
    //偶数层和主管线的情况，需要计算一次奇数层的点，以得到立管位置
    generate(points, oddLevelPoints, currentRiserIndex) {
        let sprinklerGroups = this.groupingByNum(points, oddLevelPoints.length == 0 ? points : oddLevelPoints);
        for (let sprinklerGroup of sprinklerGroups) {
            sprinklerGroup._riserName = "ZL-" + currentRiserIndex++;
            for (let sub_point of sprinklerGroup._points) {
                sub_point.riser_name = sprinklerGroup._riserName;
            }
        }
        return sprinklerGroups;
    }
    //按8个一组分组
    groupingByNum(points, oddLevelPoints) {
        //统一通过奇数层的数量进行分组（因为偶数层比奇数层喷头数少一个，所以如果偶数层也按标准来划分的话，组数可能不一样）
        let groupCount = oddLevelPoints.length % 8 <= 3 ? Math.floor(oddLevelPoints.length / 8) : Math.ceil(oddLevelPoints.length / 8); //按8个一组分组后，如果最后剩余的点数不超过3个，则并入最后一组
        //喷头数只有3个或以下的特殊情况
        if (groupCount == 0)
            groupCount++;
        let groups = new Array();
        for (let i = 0; i < groupCount; i++) {
            if (i == groupCount - 1) {
                groups.push(new sprinklerGroup_1.SprinklerGroup(points.slice(i * 8, points.length), oddLevelPoints.slice(i * 8, points.length)));
            }
            else {
                groups.push(new sprinklerGroup_1.SprinklerGroup(points.slice(i * 8, (i + 1) * 8), oddLevelPoints.slice(i * 8, (i + 1) * 8)));
            }
        }
        return groups;
    }
}
exports.RiserGenerator = RiserGenerator;
