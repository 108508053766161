"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeometryDeleteCommand = exports.GeometryUpdateCommand2 = exports.GeometryUpdateCommand = exports.GeometryAddCommand = exports.UndoStack = exports.UndoCommand = void 0;
const ltfeature_1 = require("../data/ltfeature");
class UndoCommand {
    constructor() {
        this._id = -1;
        this._child_list = [];
        this._text = "";
    }
    undo() {
        for (let i = this._child_list.length - 1; i >= 0; --i) {
            if (this._child_list[i].undo() == true) {
                continue;
            }
            return false;
        }
        return true;
    }
    redo() {
        for (let i = this._child_list.length - 1; i >= 0; --i) {
            if (this._child_list[i].redo() == true) {
                continue;
            }
            return false;
        }
        return true;
    }
    id() {
        return this._id;
    }
    childCount() {
        return this._child_list.length;
    }
    mergeWith(command) {
        return false;
    }
}
exports.UndoCommand = UndoCommand;
class UndoStack {
    constructor(map) {
        this._command_list = [];
        this._macro_stack = [];
        this._index = 0;
        this._clean_index = 0;
        this._undo_limit = 50;
        this._cad_map = map;
    }
    clear() {
        if (this._command_list.length == 0) {
            return;
        }
        this._macro_stack = [];
        this._command_list = [];
        this._clean_index = 0;
        this._index = 0;
    }
    push(commmand) {
        //执行一次redo;
        commmand.redo();
        var macro = this._macro_stack.length > 0;
        let current_command = null;
        if (macro) {
            var macro_cmd = this._macro_stack.slice(-1)[0];
            if (macro_cmd._child_list.length > 0) {
                current_command = macro_cmd._child_list.slice(-1)[0];
            }
        }
        else {
            if (this._index > 0) {
                current_command = this._command_list[this._index - 1];
            }
            while (this._index < this._command_list.length) {
                var undo_command = this._command_list.slice(-1)[0];
                this._command_list.pop();
            }
            if (this._clean_index > this._index) {
                this._clean_index = -1;
            }
        }
        var try_merge = current_command != null &&
            current_command.id() != -1 &&
            current_command.id() == commmand.id() &&
            (macro || this._index != this._clean_index);
        if (try_merge && current_command !== null && current_command.mergeWith(commmand)) {
        }
        else {
            if (macro) {
                var macro_cmd = this._macro_stack.slice(-1)[0];
                macro_cmd._child_list.push(commmand);
            }
            else {
                this._command_list.push(commmand);
                this.checkUndoLimit();
                this.setIndex(this._index + 1, false);
            }
        }
    }
    canUndo() {
        if (this._macro_stack.length > 0) {
            return false;
        }
        return this._index > 0;
    }
    canRedo() {
        if (this._macro_stack.length > 0) {
            return false;
        }
        return this._index < this._command_list.length;
    }
    undo() {
        if (this._index == 0) {
            return true;
        }
        if (this._macro_stack.length > 0) {
            return true;
        }
        var idx = this._index - 1;
        var command = this._command_list[idx];
        var is_ok = command.undo();
        this.setIndex(idx, false);
        return is_ok;
    }
    redo() {
        if (this._index == this._command_list.length) {
            return true;
        }
        if (this._macro_stack.length > 0) {
            return true;
        }
        var idx = this._index;
        var command = this._command_list[idx];
        var is_ok = command.redo();
        this.setIndex(idx + 1, false);
        return is_ok;
    }
    setIndex(idx, clean) {
        if (idx != this._index) {
            this._index = idx;
        }
        if (clean) {
            this._clean_index = this._index;
        }
    }
    checkUndoLimit() {
        if (this._clean_index <= 0 ||
            this._macro_stack.length > 0 ||
            this._undo_limit >= this._command_list.length) {
            return false;
        }
        var del_count = this._command_list.length - this._undo_limit;
        this._command_list.splice(0, del_count);
        this._index -= del_count;
        if (this._clean_index != -1) {
            if (this._clean_index < del_count) {
                this._clean_index = -1;
            }
            else {
                this._clean_index -= del_count;
            }
        }
        return true;
    }
    beginMacro(text) {
        var cmd = new UndoCommand();
        cmd._text = text;
        if (this._macro_stack.length == 0) {
            while (this._index < this._command_list.length) {
                //移除现有的最后一个;
                this._command_list.pop();
            }
            if (this._clean_index > this._index) {
                this._clean_index = -1; // we've deleted the clean state
            }
            this._command_list.push(cmd);
        }
        else {
            this._macro_stack.slice(-1)[0]._child_list.push(cmd);
        }
        this._macro_stack.push(cmd);
    }
    isInMacro() {
        return this._macro_stack.length > 0;
    }
    endMacro() {
        if (this._macro_stack.length == 0) {
            return;
        }
        this._macro_stack.pop();
        //this._cad_map.invalidate();
        if (this._macro_stack.length == 0) {
            this.checkUndoLimit();
            this.setIndex(this._index + 1, false);
        }
        this._cad_map;
    }
}
exports.UndoStack = UndoStack;
//实现增删改的撤销回退功能;
class GeometryAddCommand extends UndoCommand {
    constructor(cadmap, features) {
        super();
        this._map = cadmap;
        if (features instanceof ltfeature_1.LTFeature) {
            this._features = [];
            this._features.push(features);
        }
        else {
            this._features = features;
        }
    }
    undo() {
        //从空间索引中删除;
        for (var i = 0; i < this._features.length; ++i) {
            this._features[i].commit(ltfeature_1.CommitFlag.Delete, true);
            this._map.removeFeatureGraph(this._features[i]);
        }
        return true;
    }
    redo() {
        //从空间索引中添加;
        for (var i = 0; i < this._features.length; ++i) {
            this._features[i].commit(ltfeature_1.CommitFlag.Add, true);
            this._map.addFeatureGraph(this._features[i]);
        }
        return true;
    }
}
exports.GeometryAddCommand = GeometryAddCommand;
class GeometryUpdateCommand extends UndoCommand {
    constructor(cadmap, feature, old_feature) {
        super();
        this._map = cadmap;
        //旧的几何对象，如果更新几何，我只要复制几何就可的。
        this._old_feature = old_feature;
        this._feature = feature;
        this._new_feature = this._feature.clone();
    }
    undo() {
        let old_geo = this._feature.getGeometry();
        this._feature.setGeometry(this._old_feature.getGeometry());
        this._feature.commit(ltfeature_1.CommitFlag.Update, true);
        // this._map.updateFeatureGraph(this._feature,old_geo);
        return true;
    }
    redo() {
        let old_geo = this._feature.getGeometry();
        this._feature.setGeometry(this._new_feature.getGeometry());
        this._feature.commit(ltfeature_1.CommitFlag.Update, true);
        // this._map.updateFeatureGraph(this._feature,old_geo);
        return true;
    }
}
exports.GeometryUpdateCommand = GeometryUpdateCommand;
class GeometryUpdateCommand2 extends UndoCommand {
    constructor(cadmap, feature, old_ent) {
        super();
        this._map = cadmap;
        //旧的几何对象，如果更新几何，我只要复制几何就可的。
        this._old_ent = old_ent;
        this._feature = feature;
        this._new_net = this._feature.getGeometry().clone();
    }
    undo() {
        this._feature.getGeometry().cloneFrom(this._old_ent);
        this._feature.commit(ltfeature_1.CommitFlag.Update, true);
        this._feature.getGeometry().worldDraw(this._map.canvas, true, null, this._feature.layer.layerGraphics);
        return true;
    }
    redo() {
        this._feature.getGeometry().cloneFrom(this._new_net);
        this._feature.commit(ltfeature_1.CommitFlag.Update, true);
        this._feature.getGeometry().worldDraw(this._map.canvas, true, null, this._feature.layer.layerGraphics);
        return true;
    }
}
exports.GeometryUpdateCommand2 = GeometryUpdateCommand2;
class GeometryDeleteCommand extends UndoCommand {
    constructor(cadmap, features) {
        super();
        this._map = cadmap;
        if (features instanceof ltfeature_1.LTFeature) {
            this._features = [];
            this._features.push(features);
        }
        else {
            this._features = features;
        }
    }
    undo() {
        for (var i = 0; i < this._features.length; ++i) {
            this._features[i].commit(ltfeature_1.CommitFlag.Add, true);
            this._map.addFeatureGraph(this._features[i]);
        }
        return true;
    }
    redo() {
        //从空间索引中添加;
        for (var i = 0; i < this._features.length; ++i) {
            this._features[i].commit(ltfeature_1.CommitFlag.Delete, true);
            this._map.removeFeatureGraph(this._features[i]);
        }
        return true;
    }
}
exports.GeometryDeleteCommand = GeometryDeleteCommand;
