"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RenderText = void 0;
const ltrendermode_1 = require("../ltrendermode");
const segment_1 = require("./segment");
const MAX_BEZIER_STEPS = 10;
const BEZIER_STEP_SIZE = 3.0;
const EPSILON = 1e-6;
class RenderPolygon {
    constructor() {
        this.points = [];
        this.children = [];
        this.area = 0.0;
    }
    static distance(p1, p2) {
        const dx = p1.x - p2.x, dy = p1.y - p2.y;
        return Math.sqrt(dx * dx + dy * dy);
    }
    static lerp(p1, p2, t) {
        return { x: (1 - t) * p1.x + t * p2.x, y: (1 - t) * p1.y + t * p2.y };
    }
    static cross(p1, p2) {
        return p1.x * p2.y - p1.y * p2.x;
    }
    moveTo(p) {
        this.points.push(p);
    }
    lineTo(p) {
        this.points.push(p);
    }
    close() {
        let cur = this.points[this.points.length - 1];
        this.points.forEach(next => {
            this.area += 0.5 * RenderPolygon.cross(cur, next);
            cur = next;
        });
    }
    conicTo(p, p1) {
        const p0 = this.points[this.points.length - 1];
        const dist = RenderPolygon.distance(p0, p1) + RenderPolygon.distance(p1, p);
        const steps = Math.max(2, Math.min(MAX_BEZIER_STEPS, dist / BEZIER_STEP_SIZE));
        for (let i = 1; i <= steps; ++i) {
            const t = i / steps;
            this.points.push(RenderPolygon.lerp(RenderPolygon.lerp(p0, p1, t), RenderPolygon.lerp(p1, p, t), t));
        }
    }
    cubicTo(p, p1, p2) {
        const p0 = this.points[this.points.length - 1];
        const dist = RenderPolygon.distance(p0, p1) + RenderPolygon.distance(p1, p2) + RenderPolygon.distance(p2, p);
        const steps = Math.max(2, Math.min(MAX_BEZIER_STEPS, dist / BEZIER_STEP_SIZE));
        for (let i = 1; i <= steps; ++i) {
            const t = i / steps;
            const a = RenderPolygon.lerp(RenderPolygon.lerp(p0, p1, t), RenderPolygon.lerp(p1, p2, t), t);
            const b = RenderPolygon.lerp(RenderPolygon.lerp(p1, p2, t), RenderPolygon.lerp(p2, p, t), t);
            this.points.push(RenderPolygon.lerp(a, b, t));
        }
    }
    //点在多边形内;
    inside(p) {
        let count = 0, cur = this.points[this.points.length - 1];
        this.points.forEach(next => {
            const p0 = (cur.y < next.y ? cur : next);
            const p1 = (cur.y < next.y ? next : cur);
            if (p0.y < p.y + EPSILON && p1.y > p.y + EPSILON) {
                if ((p1.x - p0.x) * (p.y - p0.y) > (p.x - p0.x) * (p1.y - p0.y)) {
                    count += 1;
                }
            }
            cur = next;
        });
        return (count % 2) !== 0;
    }
}
class RenderText {
    constructor(font_name, text, font_size, width_factor, ref_width) {
        this._des = 0;
        this._ref_width = 1;
        this._width_factor = 1;
        this._font_name = font_name;
        this._text = text;
        this._font_size = font_size;
        this._des = window.font.descender * 0.002 * this._font_size;
        this._ref_width = ref_width;
        this._width_factor = width_factor;
    }
    _getGraphics(color, width_scale) {
        if (width_scale === undefined || width_scale === null || isNaN(width_scale)) {
            width_scale = 1;
        }
        const path = window.font.getPath(this._text, 0, 0, this._font_size);
        //宽度因子;
        let polys = [];
        let commands = path.commands;
        let minX = 99999;
        for (let sub_command of commands) {
            let type = sub_command.type;
            let current_x = sub_command.x * width_scale;
            if (minX > current_x) {
                minX = current_x;
            }
            let current_y = sub_command.y;
            switch (type) {
                case 'M':
                    polys.push(new RenderPolygon());
                    polys[polys.length - 1].moveTo({ x: current_x, y: current_y });
                    break;
                case 'L':
                    polys[polys.length - 1].moveTo({ x: current_x, y: current_y });
                    break;
                case 'C':
                    polys[polys.length - 1].cubicTo({ x: current_x, y: current_y }, { x: sub_command.x1 * width_scale, y: sub_command.y1 }, { x: sub_command.x2 * width_scale, y: sub_command.y2 });
                    break;
                case 'Q':
                    polys[polys.length - 1].conicTo({ x: current_x, y: current_y }, { x: sub_command.x1 * width_scale, y: sub_command.y1 });
                    break;
                case 'Z':
                    polys[polys.length - 1].close();
                    break;
            }
        }
        // sort contours by area, descending
        polys.sort((a, b) => Math.abs(b.area) - Math.abs(a.area));
        //对生成的图形分组;
        const root_polygons = [];
        for (let i = 0; i < polys.length; ++i) {
            let parent = null;
            for (let j = i - 1; j >= 0; --j) {
                // a contour is a hole if it is inside its parent and has different winding
                if (polys[j].inside(polys[i].points[0]) && polys[i].area * polys[j].area < 0) {
                    parent = polys[j];
                    break;
                }
            }
            if (parent) {
                parent.children.push(polys[i]);
            }
            else {
                root_polygons.push(polys[i]);
            }
        }
        let sub_graph = ltrendermode_1.CadSetting._newGraphics();
        function process(poly) {
            if (poly.area > 0) {
                sub_graph.beginFill(color, 1);
            }
            else {
                sub_graph.beginHole();
            }
            let index = 0;
            for (let point of poly.points) {
                if (index == 0) {
                    sub_graph.moveTo(point.x, point.y);
                    ++index;
                }
                else {
                    sub_graph.lineTo(point.x, point.y);
                }
            }
            if (poly.area > 0) {
                sub_graph.endFill();
            }
            else {
                sub_graph.endHole();
            }
            poly.children.forEach(process);
        }
        root_polygons.forEach(process);
        return { sub_graph: sub_graph, minX: minX };
    }
    getGraphicsWH(color) {
        let graph = this._getGraphics(color, 1);
        let bounds = graph.sub_graph.getBounds();
        if (this._ref_width > 0) {
            let scale = this._ref_width / bounds.width;
            graph = this._getGraphics(color, scale);
        }
        else {
            graph = this._getGraphics(color, this._width_factor);
        }
        let width = bounds.width;
        let height = bounds.height;
        return { width: width, height: height, des: 0 };
    }
    getGraphics(color, vmode, hmode, matrixInfo, subMatrix) {
        let graph = this._getGraphics(color, 1);
        let bounds = graph.sub_graph.getBounds();
        if (this._ref_width > 0 && (this._ref_width < bounds.width)) {
            let scale = this._ref_width / bounds.width;
            graph = this._getGraphics(color, scale);
        }
        else {
            graph = this._getGraphics(color, this._width_factor);
        }
        bounds = graph.sub_graph.getBounds();
        let des = 0; //Math.abs(window.font.descender *0.0002 * this._font_size);
        let width = 0;
        let height = 0;
        if (hmode == segment_1.TextHorzMode.kTextLeft) {
            width = 0;
        }
        else if (hmode == segment_1.TextHorzMode.kTextCenter) {
            width = bounds.width / 2 + graph.minX;
        }
        else if (hmode == segment_1.TextHorzMode.kTextRight) {
            width = bounds.width + graph.minX;
        }
        else if (hmode == segment_1.TextHorzMode.kTextAlign) {
            width = bounds.width + graph.minX;
        }
        if (vmode == segment_1.TextVertMode.kTextBase) {
            height = 0;
        }
        else if (vmode == segment_1.TextVertMode.kTextBottom) {
            height = des;
        }
        else if (vmode == segment_1.TextVertMode.kTextVertMid) {
            height = bounds.height / 2 + des;
        }
        else if (vmode == segment_1.TextVertMode.kTextTop) {
            height = bounds.height + des;
        }
        //特殊
        if (hmode == segment_1.TextHorzMode.kTextMid) {
            width = bounds.width / 2;
            height = bounds.height / 2 + des;
        }
        else if (hmode == segment_1.TextHorzMode.kTextFit) {
            // width = bounds.width;
            height = 0;
        }
        let matrix = new window.PIXI.Matrix();
        matrix.scale(1, -1);
        matrix.translate(-width, -height);
        matrix.rotate(matrixInfo.rotate);
        matrix.translate(matrixInfo.x, matrixInfo.y);
        if (subMatrix != undefined || subMatrix != null) {
            matrix = subMatrix.clone().append(matrix);
        }
        let datas = graph.sub_graph.geometry.graphicsData;
        for (let sub_data of datas) {
            sub_data.matrix = matrix;
            sub_data.isExpression = matrixInfo.isexpression;
            for (let sub_hole of sub_data.holes) {
                sub_hole.matrix = matrix;
            }
        }
        return graph.sub_graph;
    }
}
exports.RenderText = RenderText;
