"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.breakCommand = exports.intersectBreakCommand = void 0;
const ltentity_1 = require("../cad/ltentity");
const ltutils_1 = require("../cad/ltutils");
const commandfactory_1 = require("../map/commandfactory");
const drawutils_1 = require("./drawutils");
const segment_1 = require("../cad/segment");
const ltmath_1 = require("../cad/ltmath");
class BreakCommandImpl {
    static autoBreak(base_feature, cadmap) {
        //找到相交的图形;
        let base_entity = base_feature.getGeometry();
        let features = cadmap.selectFeatures(base_entity.getBoundingBox());
        if (features.length == 0) {
            return;
        }
        let query_features = [];
        features.forEach(feature => {
            if (base_feature.id === feature.id) {
                return;
            }
            let geo = feature.getGeometry();
            let is_intersect = geo.intersectsWith(base_entity);
            if (is_intersect === false) {
                return;
            }
            query_features.push(feature);
            cadmap.undoStack().beginMacro("break");
            for (let i = 0; i < query_features.length; ++i) {
                let clone_geo = query_features[i].getGeometry();
                //获取相交点;
                let intersection_points = clone_geo.data.getIntersectionPoints(base_entity.data, true);
                if (intersection_points.length == 0) {
                    continue;
                }
                let shapes = clone_geo.data.splitAt(intersection_points);
                shapes.forEach(sub_shape => {
                    let sub_entity = ltutils_1.LTUtils.shapeToEntity(sub_shape);
                    if (sub_entity === null) {
                        return;
                    }
                    let clone_feature = query_features[i].clone();
                    clone_feature.setGeometry(sub_entity);
                    cadmap.addFeature(clone_feature);
                });
                //删除旧的图形;
                cadmap.removeFeatures(query_features[i]);
            }
            cadmap.undoStack().endMacro();
        });
    }
    static getSweep(arc, current_point) {
        var ret = 0.0;
        let angle = arc.center.getAngleTo(current_point);
        if (arc.isReversed) {
            if (arc.getStartAngle() <= angle) {
                ret = -(arc.getStartAngle() + 2 * Math.PI - angle);
            }
            else {
                ret = -(arc.getStartAngle() - angle);
            }
        }
        else {
            if (angle <= arc.getStartAngle()) {
                ret = angle + 2 * Math.PI - arc.getStartAngle();
            }
            else {
                ret = angle - arc.getStartAngle();
            }
        }
        return ret;
    }
    //点打断;
    static break(cadmap, feat, start_point, end_point) {
        let range_distance = 6.0 / cadmap.mapScale();
        let ent = feat.getGeometry();
        let base_trimed_shape = ent.data.clone();
        if (ent instanceof ltentity_1.LTCircle) {
            let circle_shape = ent.subData();
            let center = circle_shape.getCenter();
            let radius = circle_shape.getRadius();
            let start_angle = center.getAngleTo(start_point);
            let end_angle = center.getAngleTo(end_point);
            if (start_angle > end_angle) {
                let temp_point = start_point;
                start_point = end_point;
                end_point = temp_point;
            }
            start_angle = center.getAngleTo(start_point);
            let a1 = ltmath_1.LTMath.getNormalizedAngle(start_angle - Math.PI / 2);
            let a2 = ltmath_1.LTMath.getNormalizedAngle(start_angle + Math.PI / 2);
            base_trimed_shape = new segment_1.RArc(center, radius, a1, a2, false);
        }
        else if (segment_1.Shape.isFullEllipseShape(base_trimed_shape) && base_trimed_shape instanceof segment_1.REllipse) {
            let center = base_trimed_shape.getCenter();
            let mp = base_trimed_shape.getMajorPoint();
            let r = base_trimed_shape.getRatio();
            let am = base_trimed_shape.getParamTo(start_point);
            let a1 = ltmath_1.LTMath.getNormalizedAngle(am - Math.PI / 2);
            let a2 = ltmath_1.LTMath.getNormalizedAngle(am + Math.PI / 2);
            base_trimed_shape = new segment_1.REllipse(center, mp, r, a1, a2, false);
        }
        else {
            let temp_shape = ent.data;
            if (temp_shape.getDistanceFromStart(start_point) > temp_shape.getDistanceFromStart(end_point)) {
                let temp_point = start_point;
                start_point = end_point;
                end_point = temp_point;
            }
        }
        //获取前半部分;
        let closeset_point = ent.getClosestPointOnEntity(start_point, range_distance, true);
        if (closeset_point == null) {
            return;
        }
        let start_shape = base_trimed_shape.clone();
        start_shape.trimEndPoint(closeset_point, closeset_point, false);
        let start_feature = feat.clone();
        //重新创建geometry;
        if (ent instanceof ltentity_1.LTCircle) {
            let closeset_point2 = ent.getClosestPointOnEntity(end_point, -1, true);
            if (closeset_point2 !== null) {
                start_shape.trimStartPoint(closeset_point2, closeset_point2, false);
            }
        }
        let start_ent = ltutils_1.LTUtils.shapeToEntity(start_shape);
        if (start_ent !== null) {
            start_ent.cloneFrom(ent);
            start_ent.data = start_shape;
            start_feature.setGeometry(start_ent);
        }
        let closeset_point2 = ent.getClosestPointOnEntity(end_point, -1, true);
        if (closeset_point2 == null) {
            return;
        }
        let end_feature = null;
        if (ent instanceof ltentity_1.LTCircle) {
            //什么都不做;
        }
        else {
            let end_shape = base_trimed_shape.clone();
            end_shape.trimStartPoint(closeset_point2, closeset_point2, false);
            end_feature = feat.clone();
            //重新创建geometry;
            let end_ent = ltutils_1.LTUtils.shapeToEntity(start_shape);
            if (end_ent !== null) {
                end_ent.cloneFrom(ent);
                end_ent.data = end_shape;
                end_feature.setGeometry(end_ent);
            }
        }
        cadmap.undoStack().beginMacro("break");
        cadmap.removeFeatures(feat);
        let features = [];
        features.push(start_feature);
        if (end_feature !== null) {
            features.push(end_feature);
        }
        cadmap.addFeature(features);
        cadmap.undoStack().endMacro();
    }
}
class BreakImpl {
    constructor() {
        this._start_ent = null;
        this._end_ent = null;
    }
    startDrag(map, ent, start_point) {
        let range_distance = 6.0 / map.mapScale();
        let closeset_point = ent.getClosestPointOnEntity(start_point, range_distance, true);
        if (closeset_point == null) {
            return;
        }
        //获取前半部分;
        this._start_ent = ent.clone();
        this._start_ent.data.trimStartPoint(closeset_point, closeset_point, false);
        drawutils_1.DrawUtilImpl.highlightEntity(map, this._start_ent);
    }
    moveDrag(map, ent, end_point) {
        let closeset_point = ent.getClosestPointOnEntity(end_point, -1, true);
        if (closeset_point == null) {
            return;
        }
        if (this._end_ent === null) {
            this._end_ent = ent.clone();
        }
        let target_ent = ent.clone();
        target_ent.data.trimEndPoint(closeset_point, closeset_point, false);
        this._end_ent.cloneFrom(target_ent);
        drawutils_1.DrawUtilImpl.highlightEntity(map, this._end_ent);
    }
    destory(map) {
        if (this._start_ent == null || this._end_ent == null) {
            return;
        }
        drawutils_1.DrawUtilImpl.cancelhighlightEntity(map, this._start_ent);
        drawutils_1.DrawUtilImpl.cancelhighlightEntity(map, this._end_ent);
    }
}
//两两交接打断;
function* intersectBreakCommand(cadmap, param_map) {
    let select_features = [];
    commandfactory_1.CommandFactory.getInstance().getPoint(cadmap, "指定基对象：");
    let input_base_feature = yield;
    if (input_base_feature.done == commandfactory_1.CommandStatus.eCancel || input_base_feature.done == commandfactory_1.CommandStatus.eDone) {
        return input_base_feature;
    }
    let base_feature = cadmap.selectSingleFeature(input_base_feature.value);
    if (base_feature == null) {
        return { value: null, done: commandfactory_1.CommandStatus.eDone };
    }
    BreakCommandImpl.autoBreak(base_feature, cadmap);
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.intersectBreakCommand = intersectBreakCommand;
//实现打断;
function* breakCommand(cadmap, param_map) {
    let select_features = [];
    let target_feature = null;
    let start_point = null;
    let break_impl = new BreakImpl();
    while (true) {
        commandfactory_1.CommandFactory.getInstance().getPoint(cadmap, "指定打断的对象:");
        let input_select_point = yield;
        if (input_select_point.done == commandfactory_1.CommandStatus.eCancel || input_select_point.done == commandfactory_1.CommandStatus.eDone) {
            return input_select_point;
        }
        start_point = input_select_point.value;
        target_feature = cadmap.selectSingleFeature(start_point);
        if (target_feature == null) {
            continue;
        }
        break_impl.startDrag(cadmap, target_feature.getGeometry(), start_point);
        break;
    }
    if (target_feature == null) {
        return { value: null, done: commandfactory_1.CommandStatus.eDone };
    }
    function moveCallback(e) {
        if (target_feature == null) {
            return;
        }
        break_impl.moveDrag(cadmap, target_feature.getGeometry(), e.mapPos);
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(cadmap, "指定第二个打断点:", moveCallback);
    let select_break2_point = yield;
    if (select_break2_point.done == commandfactory_1.CommandStatus.eCancel || select_break2_point.done == commandfactory_1.CommandStatus.eDone) {
        break_impl.destory(cadmap);
        return select_break2_point;
    }
    break_impl.destory(cadmap);
    BreakCommandImpl.break(cadmap, target_feature, start_point, select_break2_point.value);
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.breakCommand = breakCommand;
