export const setMixin = {
  data() {
    return {
      tabPosition: "left",
      dwgSettings: {
        scale: "",
        scaleVal: 1,
        scales: [
          {
            name: "1:1",
            value: 1
          },
          {
            name: "1:10",
            value: 10
          },
          {
            name: "1:100",
            value: 100
          },
          {
            name: "1:500",
            value: 500
          },
          {
            name: "1:1000",
            value: 1000
          },
          {
            name: "自定义",
            value: "自定义"
          }
        ]
      },
      settingVal: "极轴设置",
      showSetting: false,
      setColor: "",
      delIndex: -1,
      polarAxis: {
        isOpen: true,
        angle: 30, //角度
        angleList: [30, 45, 60, 90], //角度列表
        isAdd: false, //是否添加角度
        addVal: "", //添加角度值
        angleValList: [],
        objectSnap: true, //对象捕捉
        angleMeasure: true //极轴角测量
      }
    };
  },
  computed: {},
  methods: {
    // 唤起设置
    onShowSet(piximap) {
      this.showSetting = true;

      this.dwgSettings.scale = this.pixiMap.dwgservice.dwgScale;

      //从当前地图对象获取;
      let snap = piximap.snap;
      //是否开启极轴;
      this.polarAxis.isOpen = snap.polarAxisEnable;
      //极轴角度;
      this.polarAxis.angle = snap.angleIncrement;
      //极轴角度列表;
      this.polarAxis.angleValList = snap.polarAngles;
      //是否为相对极轴
      this.polarAxis.angleMeasure = snap.relativePolarAxis;
      //是否为特定角度;
      this.polarAxis.objectSnap = snap.fixedAngle;

      console.log("onShowSet", piximap);
    },
    // 添加角度
    addAngle() {
      this.polarAxis.isAdd = true;
      // this.$nextTick(() => {
      //   this.$refs.polarAxisInput.focus();
      // });
      // if (!this.polarAxis.addVal)
      //   this.polarAxis.angleValList.push(this.polarAxis.addVal);
      // else this.$message.warning("角度不能为空");
    },
    changeScale(val) {
      if (val != "自定义") this.dwgSettings.scale = val;
      else this.dwgSettings.scale = "";
    },

    changeSetting() {
      console.log("changeSetting", this.dwgSettings);
    },
    // 删除角度
    selectDelPolar(index) {
      if (this.delIndex == index) return;
      this.delIndex = index;
    },
    changeAngle() {
      // this.polarAxis.addVal =
      //   this.polarAxis.addVal
      //     .replace(/\D*(\d*)(\.?)(\d{0,2})\d*/, "$1$2$3") // 只能输入数字、小数点限制3位
      //     .replace(/^0+(\d)/, "$1") // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      //     .replace(/^\./, "0.") // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
      //     .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 数字开头、小数点3位
      console.log("数据", this.polarAxis.addVal);
    },
    sureAngle() {
      if (!this.polarAxis.addVal) {
        this.$message.warning("角度不能为空!!!");
        // this.polarAxis.isAdd = false;
      } else if (this.polarAxis.addVal >= 0 && this.polarAxis.addVal <= 360) {
        if (this.polarAxis.angleValList.length == 0) {
          this.polarAxis.angleValList.push(this.polarAxis.addVal);
          // this.polarAxis.isAdd = false;
          this.polarAxis.addVal = "";
        } else {
          let index = -1;
          this.polarAxis.angleValList.some((item, idx) => {
            if (String(item) == this.polarAxis.addVal) {
              index = idx;
              return true;
            }
          });
          if (index == -1) {
            this.polarAxis.angleValList.push(this.polarAxis.addVal);
            // this.polarAxis.isAdd = false;
            this.polarAxis.addVal = "";
          } else this.$message.warning("角度不能重复!!!");
        }
      } else this.$message.warning("角度要0~360范围内!!!");
    },

    // 删除角度
    delAngle() {
      console.log("delAngle");
      this.polarAxis.angleValList.splice(this.delIndex, 1);
      this.delIndex = -1;
    },
    // 保存设置
    sureSetting() {
      //比例

      let scale = this.dwgSettings.scale;

      let snap = this.pixiMap.snap;
      //是否开启极轴;
      snap.polarAxisEnable = this.polarAxis.isOpen;
      snap.angleIncrement = this.polarAxis.angle; //角度;
      //极轴角度列表;
      snap.polarAngles = this.polarAxis.angleValList;
      //是否为相对极轴
      snap.relativePolarAxis = this.polarAxis.angleMeasure;
      //是否为特定角度;
      snap.fixedAngle = this.polarAxis.objectSnap;

      if (this.pixiMap.dwgservice.dwgScale !== scale) {
        this.pixiMap.dwgservice.dwgScale = scale;
        this.pixiMap.regen();
      }

      this.saveUserSettingData("polaraxis", JSON.stringify(snap));
      this.saveUserSettingData("dwgsetting", JSON.stringify(this.dwgSettings));

      this.showSetting = false;
    }
  }
};
