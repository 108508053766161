"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigManager = void 0;
const pipeSizeConfig_1 = require("./pipeSizeConfig");
const protectAreaInfoConfig_1 = require("./protectAreaInfoConfig");
class ConfigManager {
    static getInfo(sprinklerCategory, isTallRoom, buildingCategory, IsSetSprayInShelves, actionArea, sprayStrength, dangerLevel, coefficientK, minPressure, minPressureOfShelf, sprinklerSettingMethod, shelfCoefficientK, shelfFloors, customMaxDisBetweenSprinklers, customMinDisBetweenSprinklers, customMaxDisWithWall, customMinDisWithWall) {
        //查找喷头块名
        let sprinklerBlockName;
        switch (sprinklerSettingMethod) {
            case "直立型（上喷）":
                sprinklerBlockName = "直立型喷头";
                break;
            case "下垂型（下喷）":
                sprinklerBlockName = "下垂型喷头";
                break;
            case "吊顶型（上下喷）":
                sprinklerBlockName = "吊顶型上下喷";
                break;
            default:
                throw Error("喷头块名错误");
        }
        //查找管径关系和喷淋范围
        if (buildingCategory == "仓库" && IsSetSprayInShelves === true) {
            let maxSprinklerNumArr;
            let maxSprinklerNum;
            switch (dangerLevel) {
                case "仓库危险级I级":
                    maxSprinklerNumArr = [6, 12, 14];
                    break;
                case "仓库危险级II级":
                    maxSprinklerNumArr = [8, 14, 14];
                    break;
                case "仓库危险级III级-货架储存时":
                case "仓库危险级III级-堆垛储存时":
                    maxSprinklerNumArr = [10, 14, 14];
                    break;
                default:
                    throw Error("火灾危险等级未选择为仓库危险级。");
            }
            if (shelfFloors == 1) {
                maxSprinklerNum = maxSprinklerNumArr[0];
            }
            else if (shelfFloors == 2) {
                maxSprinklerNum = maxSprinklerNumArr[1];
            }
            else if (shelfFloors > 2) {
                maxSprinklerNum = maxSprinklerNumArr[2];
            }
            else {
                throw Error("货架内置喷头层数非法，值必须为正整数。");
            }
            let pipeSizeMap = [];
            if (shelfCoefficientK == 80 && minPressureOfShelf == 0.2) {
                pipeSizeMap = [
                    { pipeDiameter: 25, maxNum: 1 },
                    { pipeDiameter: 32, maxNum: 2 },
                    { pipeDiameter: 40, maxNum: 3 },
                    { pipeDiameter: 50, maxNum: 5 },
                    { pipeDiameter: 65, maxNum: 8 },
                    { pipeDiameter: 80, maxNum: 13 },
                    { pipeDiameter: 100, maxNum: 14 },
                ];
            }
            else if (shelfCoefficientK == 115 && minPressureOfShelf == 0.1) {
                pipeSizeMap = [
                    { pipeDiameter: 25, maxNum: 1 },
                    { pipeDiameter: 32, maxNum: 2 },
                    { pipeDiameter: 40, maxNum: 3 },
                    { pipeDiameter: 50, maxNum: 5 },
                    { pipeDiameter: 65, maxNum: 8 },
                    { pipeDiameter: 80, maxNum: 13 },
                    { pipeDiameter: 100, maxNum: 14 },
                ];
            }
            else {
                throw Error("查找货架喷头管径关系错误。请检查货架内最不利喷头工作压力和货架内置喷头流量系数K值。");
            }
            //移除大于maxNum的要素
            for (let i = pipeSizeMap.length - 1; i >= 0; i--) {
                if (pipeSizeMap[i].maxNum > maxSprinklerNum) {
                    pipeSizeMap.splice(i, 1);
                }
            }
            return {
                sprinklerBlockName: sprinklerBlockName,
                pipeSizeMap: pipeSizeMap
            };
        }
        else {
            let projectAreaInfo = protectAreaInfoConfig_1.ProtectAreaInfoConfig.getProtectAreaInfo(sprinklerCategory, isTallRoom, dangerLevel, customMaxDisBetweenSprinklers, customMinDisBetweenSprinklers, customMaxDisWithWall, customMinDisWithWall);
            return {
                sprinklerBlockName: sprinklerBlockName,
                projectAreaInfo: projectAreaInfo,
                sprayRadius: protectAreaInfoConfig_1.ProtectAreaInfoConfig.getSprayRadius(sprinklerCategory, isTallRoom, dangerLevel, projectAreaInfo.maxDisWithWall),
                pipeSizeMap: pipeSizeConfig_1.PipeSizeConfig.getPipeSizeRelationship(sprinklerCategory, isTallRoom, actionArea, sprayStrength, dangerLevel, coefficientK, minPressure),
            };
        }
    }
}
exports.ConfigManager = ConfigManager;
