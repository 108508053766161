"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Column = void 0;
const boundingBox_1 = require("../boundingBox");
class Column {
    constructor(segments) {
        this._segments = segments;
        this._boundingBox = new boundingBox_1.BoundingBox();
        for (let seg of segments) {
            this._boundingBox.expand(seg.p0);
            this._boundingBox.expand(seg.p1);
        }
    }
}
exports.Column = Column;
