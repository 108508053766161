"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PanCommand = void 0;
const commandfactory_1 = require("../map/commandfactory");
const ltutils_1 = require("../cad/ltutils");
class PanCommand extends commandfactory_1.AbstractCommand {
    constructor(cadmap) {
        super();
        this.startPoint = null;
        this.dragFlag = false;
        this.cadmap = cadmap;
        this.dragFlag = false;
        //拖动的开始点;
        this.startPoint = null;
    }
    active(cadmap) {
        return commandfactory_1.CommandStatus.eOK;
    }
    destroy() {
    }
    isValid() {
        return this.dragFlag;
    }
    reset() {
        this.startPoint = null;
        this.dragFlag = false;
    }
    onMouseDown(event) {
        this.dragFlag = false;
        if (event.button == 1) {
            this.dragFlag = true;
            this.startPoint = new window.PIXI.Point(event.offsetX, event.offsetY);
        }
        return commandfactory_1.CommandStatus.eOK;
    }
    onMouseMove(event) {
        if (this.startPoint === null) {
            return commandfactory_1.CommandStatus.eError;
        }
        if (this.dragFlag) {
            const dx = event.offsetX - this.startPoint.x;
            const dy = event.offsetY - this.startPoint.y;
            this.cadmap.move(dx, dy);
            this.startPoint = new window.PIXI.Point(event.offsetX, event.offsetY);
            return commandfactory_1.CommandStatus.eOK;
        }
        return commandfactory_1.CommandStatus.eError;
    }
    onMouseUp(event) {
        if (event.buttonstatus === ltutils_1.ButtonStatus.RightButton) {
            this.destroy();
            return commandfactory_1.CommandStatus.eDone;
        }
        this.dragFlag = false;
        return commandfactory_1.CommandStatus.eOK;
    }
}
exports.PanCommand = PanCommand;
