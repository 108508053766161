"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RSpline = void 0;
const segment_1 = require("./segment");
const ltpoint_1 = require("./ltpoint");
const ltfields_1 = require("../data/ltfields");
const ltmath_1 = require("./ltmath");
class RSpline extends segment_1.Shape {
    constructor(control_points, degree = 3, is_fit = false) {
        super();
        this._exploded = [];
        this._controlPoints = [];
        this._degree = 0;
        this._controlPoints = control_points;
        this._degree = degree;
        this._is_fit = is_fit;
        this._update();
    }
    get controlPoints() {
        return this._controlPoints;
    }
    set controlPoints(points) {
        this._controlPoints = points;
        this._update();
    }
    get degree() {
        return this._degree;
    }
    _update() {
        let interp_points = [];
        for (let sub_point of this._controlPoints) {
            interp_points.push([sub_point.x, sub_point.y, 0]);
        }
        if (this._is_fit == false) {
            this._interpCurve = window.verb.geom.NurbsCurve.byPoints(interp_points, this._degree);
        }
        else {
            this._interpCurve = new window.verb.geom.BezierCurve(interp_points);
        }
        this._invalidate();
    }
    getBoundingBox() {
        return new ltpoint_1.LTPoints(this._controlPoints).getRBox();
    }
    isValid() {
        if (this._degree < 1) {
            return false;
        }
        if (this._controlPoints.length < this._degree + 1) {
            return false;
        }
        return true;
    }
    trimStartPoint(trimPoint, clickPoint, extend = false) {
        let ret = this.splitAt([trimPoint]);
        if (trimPoint.equals(this.getStartPoint())) {
            return false;
        }
        else if (trimPoint.equals(this.getEndPoint())) {
            return false;
        }
        if (ret.length != 2) {
            return false;
        }
        let sub_spline = ret[1];
        this._controlPoints = [];
        for (let i = 0; i < sub_spline._controlPoints.length; ++i) {
            sub_spline._controlPoints[i] = sub_spline._controlPoints[i].clone();
        }
        return true;
    }
    getMiddlePoint() {
        let ret = this._interpCurve.lengthAtParam(0.5);
        return new ltpoint_1.LTPoint(ret[0], ret[1]);
    }
    getMiddlePoints() {
        var ret = [];
        ret.push(this.getMiddlePoint());
        return ret;
    }
    trimEndPoint(trimPoint, clickPoint, extend = false) {
        let ret = this.splitAt([trimPoint]);
        if (trimPoint.equals(this.getStartPoint())) {
            return false;
        }
        else if (trimPoint.equals(this.getEndPoint())) {
            return false;
        }
        if (ret.length != 2) {
            return false;
        }
        let sub_spline = ret[0];
        this._controlPoints = [];
        for (let i = 0; i < sub_spline._controlPoints.length; ++i) {
            sub_spline._controlPoints[i] = sub_spline._controlPoints[i].clone();
        }
        return true;
    }
    rotate(rotation, center) {
        for (let sub_point of this._controlPoints) {
            sub_point.rotate(rotation, center);
        }
        this._update();
    }
    getVectorTo(point, limited, strictRange) {
        let ret = this._interpCurve.closestPoint([point.x, point.y, 0]);
        if (ret === null) {
            return null;
        }
        return new ltpoint_1.LTPoint(ret[0], ret[1]);
    }
    getReferencePoints() {
        return this._controlPoints;
    }
    moveReferencePoint(referencePoint, targetPoint) {
        for (let i = 0; i < this._controlPoints.length; ++i) {
            if (this._controlPoints[i].equalsFuzzy(referencePoint) == true) {
                this._controlPoints[i] = targetPoint;
                this._update();
                return true;
            }
        }
        return false;
    }
    getPointsWithDistanceToEnd(distance, from) {
        if (from === segment_1.From.FromStart) {
            let param = this._interpCurve.paramAtLength(distance);
            let ret = this._interpCurve.split(param);
            if (ret.length > 0) {
                return RSpline.toPolyline(ret[0]).vertices;
            }
        }
        else {
            let param = this._interpCurve.paramAtLength(this._interpCurve.length() - distance);
            let ret = this._interpCurve.split(param);
            if (ret.length == 2) {
                return RSpline.toPolyline(ret[1]).vertices;
            }
        }
        return [];
    }
    toPolyline() {
        return RSpline.toPolyline(this._interpCurve);
    }
    static toPolyline(interp) {
        let result_points = [];
        for (let i = 0; i <= 1.01; i += 0.02) {
            let t1 = interp.point(i);
            let sub_point1 = new ltpoint_1.LTPoint(t1[0], t1[1]);
            result_points.push(sub_point1);
        }
        return new segment_1.RPolyline(result_points);
    }
    getEndPoint() {
        throw new Error("Method not implemented.");
    }
    tangent(index) {
        //获取切点;
        let ret = this._interpCurve.tangent(index);
        return new ltpoint_1.LTPoint(ret[0], ret[1]);
    }
    getControlPointAt(i) {
        if (i >= 0 && i < this._controlPoints.length) {
            return this._controlPoints[i];
        }
        return null;
    }
    clone() {
        let sub_clones = [];
        for (let sub_point of this._controlPoints) {
            sub_clones.push(sub_point.clone());
        }
        let clone = new RSpline(sub_clones, this._degree);
        return clone;
    }
    getExploded() {
        if (this._exploded.length > 0) {
            return this._exploded;
        }
        let points = this._interpCurve.tessellate(10);
        for (let i = 0; i < points.length - 1; ++i) {
            let p1 = new ltpoint_1.LTPoint(points[i][0], points[i][1]);
            let p2 = new ltpoint_1.LTPoint(points[i + 1][0], points[i + 1][1]);
            let line = new segment_1.RLine(p1, p2);
            this._exploded.push(line);
        }
        return this._exploded;
    }
    move(offset) {
        for (let sub_point of this._controlPoints) {
            sub_point.move(offset.x, offset.y);
        }
        this._update();
    }
    scale(scale_factors, c = null) {
        for (let sub_point of this._controlPoints) {
            sub_point.scale(scale_factors.x, scale_factors.y, c);
        }
        this._update();
    }
    getLength() {
        return this._interpCurve.length();
    }
    splitAt(points) {
        //分割曲线;
        let ret = [];
        let params = [];
        for (let sub_point of points) {
            let sub_param = this._interpCurve.closestParam(sub_point);
            params.push(sub_param);
        }
        params.sort((a, b) => { return a - b; });
        ret = this.splitAtParams(params);
        return ret;
    }
    splitAtParams(params) {
        //分割曲线;
        let ret = [];
        if (params.length === 0) {
            return [];
        }
        else if (params.length === 1) {
            let sub_splines = this._interpCurve.split(params[0]);
            if (sub_splines == 2) {
                let sub_spline1 = new RSpline(sub_splines[0].controlPoints(), sub_splines[0].degree());
                let sub_spline2 = new RSpline(sub_splines[1].controlPoints(), sub_splines[1].degree());
                ret.push(sub_spline1);
                ret.push(sub_spline2);
            }
        }
        else {
            let merge_curve = this._interpCurve;
            let sub_params = [];
            for (let i = 0; i < params.length; ++i) {
                let sub_param = params[i];
                for (let j = 0; j < i; ++j) {
                    sub_param -= sub_params[j];
                }
                sub_params.push(sub_param);
            }
            for (let i = 0; i < sub_params.length; ++i) {
                let sub_splines = merge_curve.split(sub_params[i]);
                if (sub_splines != 2) {
                    continue;
                }
                let sub_spline1 = new RSpline(sub_splines[0].controlPoints(), sub_splines[0].degree());
                merge_curve = new RSpline(sub_splines[1].controlPoints(), sub_splines[1].degree());
                ret.push(sub_spline1);
                if (i === params.length - 1) {
                    ret.push(merge_curve);
                }
            }
        }
        return ret;
    }
    _invalidate() {
        this._exploded = [];
    }
    getShapeName() {
        return "样条曲线";
    }
    getEntityName() {
        return "acdbspline";
    }
    getSaveValue() {
        let dict = [];
        const json = {
            "degree": this._degree,
            "periodic": false, "rational": false,
            "closed": false,
            "ctrlPts": ltpoint_1.LTPoints.toJson(this._controlPoints)
        };
        let str = JSON.stringify(json);
        dict[ltfields_1.FieldNames.Cadgeo] = str;
        return dict;
    }
    domainV() {
        return this._interpCurve.domainV();
    }
    domainU() {
        return this._interpCurve.domainU();
    }
    getDirection1() {
        let ontan = this.tangent(this.domainU());
        return ontan.getAngle();
    }
    getDirection2() {
        let ontan = this.tangent(this.domainV());
        return ltmath_1.LTMath.getNormalizedAngle(ontan.getAngle() + Math.PI);
    }
    getSideOfPoint(point) {
        let pl = RSpline.toPolyline(this._interpCurve);
        return pl.getSideOfPoint(point);
    }
}
exports.RSpline = RSpline;
