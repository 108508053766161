"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Shelve = void 0;
const boundingBox_1 = require("../boundingBox");
class Shelve {
    constructor(firstSegment) {
        this._segments = [];
        this._segments.push(firstSegment);
    }
    add(segment) {
        this._segments.push(segment);
    }
    getBoundingBox() {
        if (this._boundingBox)
            return this._boundingBox;
        this.updateBoundingBox();
        return this._boundingBox;
    }
    updateBoundingBox() {
        this._boundingBox = new boundingBox_1.BoundingBox();
        for (let seg of this._segments) {
            this._boundingBox.expand(seg.p0);
            this._boundingBox.expand(seg.p1);
        }
    }
}
exports.Shelve = Shelve;
