"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RDimAngular3PData = exports.RDimAngular2LData = exports.RDimAngularData = exports.RDimAlignedData = exports.RDimRotatedData = exports.RDimension = exports.DimensionType = void 0;
const exporter_1 = require("./exporter");
const ltentity_1 = require("./ltentity");
const ltintersection_1 = require("./ltintersection");
const ltmath_1 = require("./ltmath");
const ltpoint_1 = require("./ltpoint");
const ltutils_1 = require("./ltutils");
const rdimstyle_1 = require("./rdimstyle");
const segment_1 = require("./segment");
var DimensionType;
(function (DimensionType) {
    DimensionType[DimensionType["EntityDimension"] = 0] = "EntityDimension";
    DimensionType[DimensionType["EntityDimLinear"] = 1] = "EntityDimLinear";
    DimensionType[DimensionType["EntityDimAligned"] = 2] = "EntityDimAligned";
    DimensionType[DimensionType["EntityDimRotated"] = 3] = "EntityDimRotated";
    DimensionType[DimensionType["EntityDimRadial"] = 4] = "EntityDimRadial";
    DimensionType[DimensionType["EntityDimDiametric"] = 5] = "EntityDimDiametric";
    DimensionType[DimensionType["EntityDimAngular"] = 6] = "EntityDimAngular";
    DimensionType[DimensionType["EntityDimAngular2L"] = 7] = "EntityDimAngular2L";
    DimensionType[DimensionType["EntityDimAngular3P"] = 8] = "EntityDimAngular3P";
    DimensionType[DimensionType["EntityDimArcLength"] = 9] = "EntityDimArcLength";
    DimensionType[DimensionType["EntityDimOrdinate"] = 10] = "EntityDimOrdinate";
})(DimensionType || (exports.DimensionType = DimensionType = {}));
;
class RDimension extends segment_1.Shape {
    constructor(ext_point1, ext_point2, text, dim_point, text_position = {}) {
        super();
        this._height = 0;
        this._width = 0;
        this._rbox = undefined;
        this.outsideArrow2 = false;
        this.outsideArrow1 = false;
        this.arrow1Flipped = false;
        this.arrow2Flipped = false;
        this.autoTextPos = false;
        this.extLineFix = false;
        this.extLineFixLength = 0;
        this._style = null;
        this.ext1info = {};
        this.ext2info = {};
        this.dimposInfo = {};
        this.blockscale = 1;
        this._scale = 1;
        this.render_midpoint1 = null;
        this.render_midpoint2 = null;
        this.render_point1 = null;
        this.render_point2 = null;
        this.render_point3 = null;
        this.render_point4 = null;
        this.arrow1Pos = {};
        this.arrow2Pos = {};
        this.textPositionCenter = {};
        this.text = "";
        //第一尺寸界限
        this._extension_point1 = ext_point1;
        //第二尺寸界限;
        this._extension_point2 = ext_point2;
        //尺寸点;
        this._dim_point = dim_point;
        this._text_angle = 0;
        this._height = 0;
        this.dimposInfo = new Object();
        this._dirty = true;
        this._dirty_dimPoints = true;
        //文字
        this._rtext = new segment_1.RText(text_position || new ltpoint_1.LTPoint(0, 0), text, 50);
        this.textPositionSide = new ltpoint_1.LTPoint(0, 0, 0, false);
        this.newTextPos = new ltpoint_1.LTPoint(0, 0, 0, false);
    }
    static createDimension(ext_point1, ext_point2, text, dim_point, text_position = {}, dimtype) {
        let dim;
        switch (dimtype) {
            case DimensionType.EntityDimRotated:
                dim = new RDimRotatedData(ext_point1, ext_point2, text, dim_point, text_position);
                break;
            case DimensionType.EntityDimAligned:
                dim = new RDimAlignedData(ext_point1, ext_point2, text, dim_point, text_position);
                break;
            // case DimensionType.EntityDimOrdinate:
            //   this.renderDimOrdinate(dimension);
            //     break;
            // case DimensionType.EntityDimRadial:
            //   this.renderDimRadial(dimension);
            //     break;
            // case DimensionType.EntityDimDiametric:
            //   this.renderDimDiametric(dimension);
            //     break;
            case DimensionType.EntityDimAngular2L:
                dim = new RDimAngular2LData(ext_point1, ext_point2, text, dim_point, text_position);
                break;
            case DimensionType.EntityDimAngular3P:
                dim = new RDimAngular3PData(ext_point1, ext_point2, text, dim_point, text_position);
                break;
            // case DimensionType.EntityDimArcLength:
            //   this.renderDimAngular(dimension);
            //     break;
            default:
                break;
        }
        return dim;
    }
    getDirection1() {
        return 0;
    }
    getDirection2() {
        return 0;
    }
    getSideOfPoint(point) {
        return segment_1.Side.NoSide;
    }
    getShapeName() {
        return "标注";
    }
    getDimXBool(v) {
        if (this._style !== undefined && this._style !== null) {
            return this._style.getDimXBool(v);
        }
        return true;
    }
    getDimXDouble(v) {
        if (this._style !== undefined && this._style !== null) {
            return this._style.getDimXDouble(v);
        }
        return 0.0;
    }
    getDimXInt(v) {
        if (this._style !== undefined && this._style !== null) {
            return this._style.getDimXInt(v);
        }
        return 0.0;
    }
    getDimString(v) {
        if (this._style !== undefined && this._style !== null) {
            return this._style.getDimString(v);
        }
        return "";
    }
    // setDimXDouble(v:string):number
    // {
    //   if(this._style !== undefined && this._style !==null)
    //   {
    //     return this._style.getDimXDouble(v)
    //   }
    //   return 0.0;
    // }
    getDimscale() {
        return this._scale;
        // if (this._style !== undefined && this._style !== null) {
        //   return this._style.getDimscale()
        // }
        //return 1;
    }
    getDimexo() {
        return this.getDimXDouble("DIMEXO") * this.getDimscale();
    }
    //  setDimexo(v:) {
    //     setDimXDouble(RS::DIMEXO, v);
    // }
    getDimexe() {
        return this.getDimXDouble("DIMEXE") * this.getDimscale();
    }
    //  setDimexe(double v) {
    //     setDimXDouble(RS::DIMEXE, v);
    // }
    getDimasz() {
        return this.getDimXDouble("DIMASZ") * this.getDimscale();
    }
    //  setDimasz(double v) {
    //     setDimXDouble(RS::DIMASZ, v);
    // }
    getDimdli() {
        return this.getDimXDouble("DIMDLI") * this.getDimscale();
    }
    //  setDimdli(double v) {
    //     setDimXDouble(RS::DIMDLI, v);
    // }
    getDimgap() {
        return this.getDimXDouble("DIMGAP") * this.getDimscale();
    }
    //  setDimgap(double v) {
    //     setDimXDouble(RS::DIMGAP, v);
    // }
    getDimtxt() {
        return this.getDimXDouble("DIMTXT") * this.getDimscale();
    }
    getDimtxsty() {
        return this._rtext.fontName;
    }
    //  setDimtxt(double t) {
    //     setDimXDouble(RS::DIMTXT, t);
    // }
    getDimlunit() {
        return this.getDimXInt("DIMLUNIT");
    }
    //  setDimlunit(int l) {
    //     setDimXInt(RS::DIMLUNIT, l);
    // }
    getDimjust() {
        return this.getDimXInt("DIMJUST");
    }
    getDimtad() {
        return this.getDimXInt("DIMTAD");
    }
    //  setDimtad(int v) {
    //     setDimXInt(RS::DIMTAD, v);
    // }
    getDimtih() {
        return this.getDimXBool("DIMTIH");
    }
    //  setDimtih(v:boolean) {
    //     setDimXBool(RS::DIMTIH, v);
    // }
    getDimtsz() {
        return this.getDimXDouble("DIMTSZ") * this.getDimscale();
    }
    // setDimtsz(t:number) {
    //     setDimXDouble(RS::DIMTSZ, t);
    // }
    getDimzin() {
        return this.getDimXInt("DIMZIN");
    }
    // setDimzin(v:number)
    //     setDimXInt(RS::DIMZIN, v);
    // }
    getDimaunit() {
        return this.getDimXInt("DIMAUNIT");
    }
    // setDimaunit(v: number) {
    //     setDimXInt(RS::DIMAUNIT, v);
    // }
    getDimadec() {
        return this.getDimXInt("DIMADEC");
    }
    // setDimadec(v:number) {
    //     setDimXInt(RS::DIMADEC, v);
    // }
    getDimdec() {
        return this.getDimXInt("DIMDEC");
    }
    // setDimdec(v:number){ {
    //     setDimXInt(RS::DIMDEC, v);
    // }
    getDimazin() {
        return this.getDimXInt("DIMAZIN");
    }
    // setDimazin(v:number) {
    //     setDimXInt(RS::DIMAZIN, v);
    // }
    getDimdsep() {
        return this.getDimXInt("DIMDSEP");
    }
    // setDimdsep(v:number) {
    //     setDimXInt(RS::DIMDSEP, v);
    // }
    useArchTick() {
        return this.getDimXDouble("DIMTSZ") > 0.0;
    }
    getDimblk() {
        return this.getDimString("DIMBLK");
    }
    getDimblk1() {
        return this.getDimString("DIMBLK1");
    }
    getDimblk2() {
        return this.getDimString("DIMBLK2");
    }
    isExtLineFix() {
        return this.extLineFix;
    }
    setExtLineFix(on) {
        this.extLineFix = on;
    }
    getExtLineFixLength() {
        return this.extLineFixLength;
    }
    setExtLineFixLength(v) {
        this.extLineFixLength = v;
    }
    get extensionPoint1() {
        return this._extension_point1;
    }
    set extensionPoint1(p) {
        this._extension_point1 = p;
        this._dirty_dimPoints = true;
    }
    get extensionPoint2() {
        return this._extension_point2;
    }
    set extensionPoint2(p) {
        this._extension_point2 = p;
        this._dirty_dimPoints = true;
    }
    get height() {
        return this._height;
    }
    set height(h) {
        this._height = h;
    }
    setTextHeight(height) {
        this._rtext.height = height;
    }
    updateArrowPos1(p) {
        this.arrow1Pos = p;
    }
    updateArrowPos2(p) {
        this.arrow2Pos = p;
    }
    setDimStyle(name, updateInfo) {
        var _a, _b;
        if (name == undefined) {
            return;
        }
        let dimstyle = window.cadmap.dwgservice.getDimStyle(name);
        if (dimstyle === undefined || dimstyle === null) {
            return;
        }
        if (dimstyle.name === ((_a = this._style) === null || _a === void 0 ? void 0 : _a.name)) {
            return;
        }
        this._style = dimstyle;
        if (updateInfo == true) {
            this._rtext.height = this.getDimtxt();
            if (this.getDimblk1() !== "") {
                this.ext1info.blockname = this.getDimblk1();
            }
            else {
                this.ext1info.blockname = this.getDimblk();
            }
            if (this.getDimblk2() !== "") {
                this.ext2info.blockname = this.getDimblk2();
            }
            else {
                this.ext2info.blockname = this.getDimblk();
            }
            let scale = this.getDimasz();
            this.blockscale = scale;
            //获取字体
            if (this._style !== null) {
                this._rtext.fontName = (_b = this._style) === null || _b === void 0 ? void 0 : _b.getDimString("dimtxsty");
            }
        }
    }
    upDateTextWidthFactor() {
        //实时更新宽度信息
        let textstyle = window.cadmap.dwgservice.getTextStyle(this._rtext.fontName);
        if (textstyle !== null) {
            if (this._rtext.widthFactor != textstyle.xScale) {
                this._rtext.widthFactor = textstyle.xScale;
            }
        }
    }
    getDimStyle() {
        return this._style;
    }
    getExploded() {
        return [];
    }
    getPointsWithDistanceToEnd(distance, from = segment_1.From.FromAny) {
        return [];
    }
    getVectorTo(point, limited, strictRange) {
        let shapes = this._getShape();
        let dv = null;
        let mindis = 100000;
        shapes.forEach(out => {
            let sdv = out.getVectorTo(point, limited, strictRange);
            if (sdv === null) {
                return;
            }
            let dis = sdv.getMagnitude();
            if (dis < mindis) {
                mindis = dis;
                dv = sdv;
            }
        });
        return dv;
    }
    getLength() {
        return 0;
    }
    _mirror(axis) {
        this._dim_point.mirror(axis);
        this._rtext.mirror(axis);
        if (this.textPositionSide.isValid()) {
            this.textPositionSide.mirror(axis);
        }
        this._extension_point1.mirror(axis);
        this._extension_point2.mirror(axis);
    }
    intersectsWith(other, limited) {
        let bound = this.getBoundingBox();
        if (other.getBoundingBox().contain(bound)) {
            return true;
        }
        let ret = [];
        let shapes = this._getShape();
        shapes.forEach(out => {
            if (out.intersectsWith(other, true)) {
                ret.push(out);
            }
        });
        if (ret.length > 0) {
            return true;
        }
        return false;
    }
    getBoundingBox() {
        if (this._dirty == true || this._rbox == null) {
            let shapes = this._getShape();
            this._rbox = ltpoint_1.RBox.nullBox();
            shapes.forEach(out => {
                if (this._rbox == undefined) {
                    this._rbox = ltpoint_1.RBox.nullBox();
                }
                this._rbox.growToInclude(out.getBoundingBox());
            });
        }
        if (Number.isNaN(this._rbox.getWidth())) {
            let shapes = this._getShape();
        }
        return this._rbox;
    }
    getShapeType() {
        return ltutils_1.ShapeType.RotateDim;
    }
    setDefinitionPoint(p) {
        this._dim_point = p;
        this.recomputeDefinitionPoint(this._extension_point1, this._extension_point2, this._extension_point1, this._extension_point2);
        this._dirty_dimPoints = true;
        this._dirty = true;
    }
    _getReferencePoints() {
        let ret = [];
        if (this._rtext.position !== null) {
            ret.push(this._rtext.position);
        }
        ret.push(this._extension_point1);
        ret.push(this._extension_point2);
        ret.push(this._dim_point);
        return ret;
    }
    setTextPosition(p) {
        if (p.isSane()) {
            this.textPositionCenter = p;
        }
        this.textPositionSide = ltpoint_1.LTPoint.nullVector();
        this._rtext.position = p;
        this.setUseCustomTextPosition();
        this.update();
    }
    updateTextPositionCenter(p) {
        this.textPositionCenter = p;
        this._rtext.position = this.textPositionCenter;
    }
    getTextPosition() {
        return this._rtext.position;
    }
    update() {
        this._dirty = true;
    }
    _moveReferencePoint(referencePoint, targetPoint) {
        let ret = false;
        if (referencePoint.equalsFuzzy(this._dim_point) == true) {
            this._dim_point = targetPoint;
            ret = true;
        }
        if (referencePoint.equalsFuzzy(this._extension_point1) == true) {
            this.recomputeDefinitionPoint(this._extension_point1, this._extension_point2, targetPoint, this._extension_point2);
            this._extension_point1 = targetPoint;
            this.autoTextPos = false;
            ret = true;
        }
        if (referencePoint.equalsFuzzy(this._extension_point2) == true) {
            this.recomputeDefinitionPoint(this._extension_point1, this._extension_point2, this._extension_point1, targetPoint);
            this._extension_point2 = targetPoint;
            this.autoTextPos = false;
            ret = true;
        }
        let temp_pos = this._rtext.position || new ltpoint_1.LTPoint(0, 0);
        if (referencePoint.equalsFuzzy(temp_pos) == true) {
            this._rtext.position = targetPoint;
            ret = true;
            this.autoTextPos = true;
        }
        if (ret == true) {
            this._dirty = true;
            this._dirty_dimPoints = true;
        }
        return ret;
    }
    tostrting(num) {
        if (num === undefined) {
            return "";
        }
        return num.toString();
    }
    getBlock1() {
        return this.ext1info.blockname;
    }
    setBlock1(n) {
        this.ext1info.blockname = n;
        this.update();
    }
    getBlock2() {
        return this.ext2info.blockname;
    }
    setBlock2(n) {
        this.ext2info.blockname = n;
        this.update();
    }
    getDimSacle() {
        return this._scale;
    }
    setDimSacle(v) {
        this._dirty = true;
        this._dirty_dimPoints = true;
        this._scale = v;
        this.autoTextPos = false;
        this.initTextData();
    }
    getDimBlockSacle() {
        return this.blockscale;
    }
    setDimBlockSacle(v) {
        this.blockscale = v;
    }
    _getSaveValue() {
        var _a, _b, _c, _d;
        const ext1 = { x: this._extension_point1.x, y: this._extension_point1.y };
        const ext2 = { x: this._extension_point2.x, y: this._extension_point2.y };
        const diminfo = { "x": this._dim_point.x, y: this._dim_point.y };
        const textinfo = {
            height: this._rtext.height, rotate: this._rtext.getRotate(), "color": this.tostrting(this._rtext.color),
            x: (_b = (_a = this._rtext) === null || _a === void 0 ? void 0 : _a.position) === null || _b === void 0 ? void 0 : _b.x, y: (_d = (_c = this._rtext) === null || _c === void 0 ? void 0 : _c.position) === null || _d === void 0 ? void 0 : _d.y, "text": this.tostrting(this._rtext.text),
            "textstyle": this.tostrting(this._rtext.fontName)
        };
        return { ext1: ext1, ext2: ext2, diminfo: diminfo, textinfo: textinfo, dimblk1: this.ext1info.blockname, dimblk2: this.ext1info.blockname,
            blockscale: this.blockscale, dimscale: this._scale };
    }
    //设置信息
    setInfo(info) {
        if (info.dimblk1 !== "") {
            this.ext1info.blockname = info.dimblk1;
        }
        else {
            this.ext1info.blockname = info.dimblk;
        }
        if (info.dimblk2 !== "") {
            this.ext2info.blockname = info.dimblk2;
        }
        else {
            this.ext2info.blockname = info.dimblk;
        }
        //手动替换下
        // if(this.ext1info.blockname == "ArchTick")
        // {
        //   this.ext1info.blockname = "_ArchTick";
        // }
        // if (this.ext2info.blockname == "ArchTick")
        // {
        //   this.ext2info.blockname = "_ArchTick";
        // }
        this.blockscale = info.blockscale;
        if (info.dimscale !== undefined) {
            this._scale = info.dimscale;
        }
        if (info.textinfo !== "") {
            this._rtext.height = info.textinfo.height;
            this._rtext.text = info.textinfo.text;
            this._rtext.fontName = info.textinfo.textstyle;
            this.autoTextPos = !info.textinfo.isUsingDefaultTextPosition;
            this._rtext.position = new ltpoint_1.LTPoint(info.textinfo.x * 1, info.textinfo.y * 1);
            if (info.textinfo.color !== undefined) {
                this._rtext.color = info.textinfo.color;
            }
        }
    }
    getEndPoint() {
        return this.position || new ltpoint_1.LTPoint(0, 0);
    }
    renderLine(info, points, canvas, entity, graphics) {
        if (info.color != undefined && info.color.toLowerCase() != ltentity_1.dwg_bylayer) {
            canvas.setColor(info.color, entity.getLayerName());
        }
        else {
            canvas.setColor(entity.entityColor(canvas.map), entity.getLayerName());
        }
        canvas.setStrokeWidth(0);
        if (info.linetype != undefined && info.linetype.toLowerCase() != ltentity_1.dwg_bylayer && info.linetype.toLowerCase() != "continuous") {
            //获取线型
            let current_server = canvas.map.dwgservice;
            if (current_server !== null) {
                let linetype = current_server.getLineType(info.linetype);
                if (linetype != null) {
                    canvas.save();
                    linetype.setSacle(info.scale *
                        current_server.getLtScale());
                    let polyline = new ltentity_1.LTPolyline(points);
                    let porter = new exporter_1.RPainterPathExporter();
                    porter.exportEntity(canvas, polyline, linetype, 0, graphics);
                    //linetype.worldDraw(canvas, new LTPoints(points), 1, entity);
                    canvas.restore();
                    return;
                }
            }
        }
        canvas.drawLineString(points);
    }
    getShapes(queryBox, ignoreComplex = true, issegment) {
        let ret = [];
        if (queryBox == null) {
            ret.push(this);
            return ret;
        }
        let shapes = this._getShape();
        shapes.forEach(out => {
            if (out.getBoundingBox().intersects(queryBox)) {
                ret.push(out);
            }
        });
        return ret;
    }
    hasCustomTextPosition() {
        return this.autoTextPos;
    }
    setUseCustomTextPosition() {
        this.autoTextPos = true;
    }
    updateTextPosition(text, textWidth, dimLine1, dimLine2, corrected) {
        if (this.hasCustomTextPosition() == false) {
            let dimtxt = this.getDimtxt();
            let dimgap = this.getDimgap();
            let dimasz = this.getDimasz();
            let dimtad = this.getDimtad();
            let dimtih = this.getDimtih();
            let newTextPos = ltpoint_1.LTPoint.getAverage(dimLine1, dimLine2);
            let dimAngle1 = dimLine1.getAngleTo(dimLine2);
            let distV = new ltpoint_1.LTPoint(0, 0);
            if (corrected) {
                distV.setPolar(dimgap + dimtxt / 2.0, dimAngle1 - Math.PI / 2.0);
            }
            else {
                distV.setPolar(dimgap + dimtxt / 2.0, dimAngle1 + Math.PI / 2.0);
            }
            newTextPos = newTextPos.plus(distV); //+=distV;
            let dimLineLength = dimLine1.getDistanceTo(dimLine2);
            let angle = dimLine1.getAngleTo(dimLine2);
            let textPositionSide = new ltpoint_1.LTPoint(0, 0, 0, false);
            // minimum space required for text:
            let minSpace = dimLineLength;
            if (dimtad == 0 || dimtih) {
                if (!this.outsideArrow1) {
                    minSpace -= dimasz;
                }
                if (!this.outsideArrow2) {
                    minSpace -= dimasz;
                }
                // if text is centered on dim line, we need more space:
                minSpace -= dimgap * 2;
            }
            else {
                if (this.outsideArrow1 && this.outsideArrow2) {
                    minSpace -= dimgap * 2;
                }
            }
            if (!isNaN(minSpace) && textWidth > minSpace && !dimtih) {
                let h = textWidth / 2.0 + dimLineLength / 2.0 + dimgap;
                if (dimtad == 0) {
                    if (this.outsideArrow2) {
                        h += dimasz;
                    }
                }
                let distH = new ltpoint_1.LTPoint(0, 0);
                distH.setPolar(h, angle);
                textPositionSide = newTextPos;
                textPositionSide = textPositionSide.plus(distH); // += distH;
            }
            else {
                textPositionSide.valid = false;
            }
            if (textPositionSide.valid == true) {
                this._rtext.position = textPositionSide;
            }
            else {
                this._rtext.position = newTextPos;
            }
            // this.textPositionSide = textPositionSide;
            // this.newTextPos = newTextPos;
        }
    }
    updateOutsideArrow(p1, p2) {
        // length of dimension line:
        let dimLineLength = p1.getDistanceTo(p2);
        // do we have to put the arrows outside of the line?
        this.outsideArrow1 = !this.hasSpaceForArrows(dimLineLength);
        this.outsideArrow2 = this.outsideArrow1;
        // force flipping arrows (against logic above):
        if (this.isArrow1Flipped()) {
            this.outsideArrow1 = !this.outsideArrow1;
        }
        if (this.isArrow2Flipped()) {
            this.outsideArrow2 = !this.outsideArrow2;
        }
    }
    hasSpaceForArrows(dimLineLength) {
        return dimLineLength >= 2.5 * 2.5;
    }
    isArrow1Flipped() {
        return this.arrow1Flipped;
    }
    setArrow1Flipped(on) {
        this.arrow1Flipped = on;
    }
    isArrow2Flipped() {
        return this.arrow2Flipped;
    }
    setArrow2Flipped(on) {
        this.arrow2Flipped = on;
    }
    getMeasurement(resolveAutoMeasurement) {
        if (this.text == "") {
            if (resolveAutoMeasurement) {
                return this.getAutoLabel();
            }
            else {
                return this.getMeasuredValue().toFixed(0).toString();
            }
        }
        return this.text;
    }
    initTextData() {
        let textheight = this.getDimtxt();
        let text = this.getMeasurement(true);
        this._rtext = new segment_1.RText(new ltpoint_1.LTPoint(0, 0), text, textheight);
    }
    move(offset) {
        if (offset == null || offset.getMagnitude() < 1e-9) {
            return false;
        }
        this._dim_point = this._dim_point.plus(offset);
        this._extension_point1 = this._extension_point1.plus(offset);
        this._extension_point2 = this._extension_point2.plus(offset);
        this._rtext.move(offset); // =  this._extension_point1.plus(offset);
        this._dirty = true;
        this._dirty_dimPoints = true;
        return true;
    }
    scale(scaleFactors, center) {
        this._dim_point.scale(scaleFactors.x, scaleFactors.y, center);
        this._extension_point1.scale(scaleFactors.x, scaleFactors.y, center);
        this._extension_point2.scale(scaleFactors.x, scaleFactors.y, center);
        this._rtext.scale(scaleFactors, center);
        this.update();
        return true;
    }
    rotate(rotation, center) {
        this._dim_point.rotate(rotation, center);
        this._extension_point1.rotate(rotation, center);
        this._extension_point2.rotate(rotation, center);
        this._rtext.rotate(rotation, center);
        this.update();
    }
}
exports.RDimension = RDimension;
class RDimRotatedData extends RDimension {
    getAutoLabel() {
        this._rtext.text = this.getMeasuredValue().toFixed(0).toString();
        return this._rtext.text;
    }
    _getShape() {
        if (this._dirty == true) {
            this.getDimPoints();
        }
        if (this.render_point1 === null || this.render_point2 == null || this.render_point3 == null || this.render_point4 == null) {
            return [];
        }
        let ret = [];
        ret.push(new segment_1.RLine(this.render_point1, this.render_point2));
        ret.push(new segment_1.RLine(this.render_point3, this.render_point4));
        ret.push(new segment_1.RLine(this.render_midpoint1 || new ltpoint_1.LTPoint(0, 0), this.render_midpoint2 || new ltpoint_1.LTPoint(0, 0)));
        ret.push(this._rtext);
        return ret;
    }
    getEntityName() {
        return "acdbrotateddimension";
    }
    moveReferencePoint(referencePoint, targetPoint) {
        return this._moveReferencePoint(referencePoint, targetPoint);
    }
    constructor(ext_point1, ext_point2, text, dim_point, text_position = {}) {
        super(ext_point1, ext_point2, text, dim_point, text_position);
    }
    getMeasuredValue() {
        if (this.render_midpoint1 != null && this.render_midpoint2 != null) {
            return this.render_midpoint1.getDistanceTo(this.render_midpoint2);
        }
        return 0;
    }
    clone() {
        var _a;
        if (this._extension_point1 != undefined) {
            let dim = new RDimRotatedData(this._extension_point1.clone(), this._extension_point2.clone(), "", this._dim_point.clone());
            dim._rtext = this._rtext.clone();
            super.cloneFrom(dim);
            dim.autoTextPos = this.autoTextPos;
            dim.ext1info = this.ext1info;
            dim.ext2info = this.ext2info;
            dim.dimposInfo = this.dimposInfo;
            dim.blockscale = this.blockscale;
            dim._scale = this._scale;
            dim.setDimStyle((_a = this._style) === null || _a === void 0 ? void 0 : _a.name, false);
            return dim;
        }
        return {};
    }
    getDimensionType() {
        return DimensionType.EntityDimRotated;
    }
    mirror(axis) {
        super._mirror(axis);
        let neutralAxis = axis;
        neutralAxis.move(new ltpoint_1.LTPoint(-neutralAxis.getStartPoint().x, -neutralAxis.getStartPoint().y));
        let vec = ltpoint_1.LTPoint.createPolar(1.0, this._rotate);
        vec.mirror(neutralAxis);
        this._rotate = vec.getAngle();
    }
    recomputeDefinitionPoint(oldExtPoint1, oldExtPoint2, newExtPoint1, newExtPoint2) {
        let dirDim = ltpoint_1.LTPoint.createPolar(1.0, this.getRotate());
        var dimLine = new segment_1.RLine(this._dim_point, this._dim_point.plus(dirDim));
        let dimP1 = dimLine.getClosestPointOnShape(newExtPoint1, false);
        let dimP2 = dimLine.getClosestPointOnShape(newExtPoint2, false);
        if (dimP1 === null || dimP2 === null) {
            return;
        }
        // make sure the dimension line is movable if dimension point == extension point
        if (dimP1.equalsFuzzy(newExtPoint1) || dimP1.equalsFuzzy(newExtPoint2)) {
            dimP1 = ltpoint_1.LTPoint.getAverage(dimP1, dimP2);
        }
        if (dimP1.isValid()) {
            this._dim_point = dimP1;
        }
    }
    getAngle() {
        if (this.render_midpoint1 != null && this.render_midpoint2 != null) {
            return this.render_midpoint1.getAngleTo(this.render_midpoint2);
            ;
        }
        return 0;
    }
    getSaveValue() {
        var _a;
        let saveinfo = super._getSaveValue();
        let str;
        const json = { "ext1pos": saveinfo.ext1, "ext2pos": saveinfo.ext2, "dimpos": saveinfo.diminfo,
            "textinfo": saveinfo.textinfo, "rotation": this._rotate, "name": (_a = this._style) === null || _a === void 0 ? void 0 : _a.name,
            dimblk1: this.ext1info.blockname, dimblk2: this.ext1info.blockname,
            blockscale: this.blockscale, dimscale: this._scale };
        str = JSON.stringify(json);
        let dict = [];
        dict["cadgeo"] = str;
        return dict;
    }
    getDimPoints() {
        if (this._dirty_dimPoints == false) {
            return;
        }
        let dirDim = ltpoint_1.LTPoint.createPolar(1.0, this.getRotate());
        var extension_line = new segment_1.RLine(this._dim_point, this._dim_point.plus(dirDim));
        this.render_midpoint1 = extension_line.getClosestPointOnShape(this._extension_point1, false);
        this.render_midpoint2 = extension_line.getClosestPointOnShape(this._extension_point2, false);
        //获取最近的点
        this.render_point1 = this._extension_point1;
        this.render_point2 = this.render_midpoint1 || new ltpoint_1.LTPoint(0, 0);
        this.render_point3 = this._extension_point2;
        this.render_point4 = this.render_midpoint2 || new ltpoint_1.LTPoint(0, 0);
        this._dim_point = this.render_point2;
        let dimexe = this.getDimexe();
        let dimexo = this.getDimexo();
        let dimtih = this.getDimtih();
        let g = this.getAngle();
        let corrected = false;
        this._rtext.text = this.getMeasurement(true);
        let a = ltmath_1.LTMath.makeAngleReadable(g, true, corrected);
        this.updateOutsideArrow(this.render_point1, this.render_point3);
        this.upDateTextWidthFactor();
        this.updateTextPosition(this._rtext.text, this._rtext.width, this.render_point2, this.render_point4, corrected);
        this._rtext.setRotate(a);
        if (this.render_midpoint1 == null || this.render_midpoint2 == null) {
            return;
        }
        rdimstyle_1.RDimStyle.getDimensionLineShapes(this.render_midpoint1, this.render_midpoint2, true, true, this);
        let extLine1 = new segment_1.RLine(this.render_point1, this.render_point2);
        let extLine2 = new segment_1.RLine(this.render_point3, this.render_point4);
        // extension lines:
        let vDimexo1 = new ltpoint_1.LTPoint(0, 0);
        let vDimexe1 = new ltpoint_1.LTPoint(0, 0);
        let vDimexo2 = new ltpoint_1.LTPoint(0, 0);
        let vDimexe2 = new ltpoint_1.LTPoint(0, 0);
        if (!this._extension_point1.equalsFuzzy(this.render_midpoint1)) {
            let a1 = this._extension_point1.getAngleTo(this.render_midpoint1);
            vDimexe1.setPolar(dimexe, a1);
            vDimexo1.setPolar(dimexo, a1);
            extLine1 = new segment_1.RLine(this._extension_point1.plus(vDimexo1), this.render_midpoint1.plus(vDimexe1));
        }
        if (!this._extension_point2.equalsFuzzy(this.render_midpoint2)) {
            let a2 = this._extension_point2.getAngleTo(this.render_midpoint2);
            vDimexe2.setPolar(dimexe, a2);
            vDimexo2.setPolar(dimexo, a2);
            extLine2 = new segment_1.RLine(this._extension_point2.plus(vDimexo2), this.render_midpoint2.plus(vDimexe2));
        }
        rdimstyle_1.RDimStyle.adjustExtensionLineFixLength(extLine1, extLine2, true, this);
        this.render_point1 = extLine1.getStartPoint();
        this.render_point2 = extLine1.getEndPoint();
        this.render_point3 = extLine2.getStartPoint();
        this.render_point4 = extLine2.getEndPoint();
        this._dirty_dimPoints = false;
        this._dirty = false;
    }
    render(canvas, entity, main, graphics) {
        if (this._dirty == true) {
            this.getDimPoints();
        }
        rdimstyle_1.RDimStyle.render(canvas, entity, main, this, graphics);
    }
    getReferencePoints() {
        return this._getReferencePoints();
    }
}
exports.RDimRotatedData = RDimRotatedData;
class RDimAlignedData extends RDimension {
    _getShape() {
        if (this._dirty == true) {
            this.getDimPoints();
        }
        if (this.render_point1 === null || this.render_point2 == null || this.render_point3 == null || this.render_point4 == null) {
            return [];
        }
        let ret = [];
        ret.push(new segment_1.RLine(this.render_point1, this.render_point2));
        ret.push(new segment_1.RLine(this.render_point3, this.render_point4));
        ret.push(new segment_1.RLine(this.render_midpoint1 || new ltpoint_1.LTPoint(0, 0), this.render_midpoint2 || new ltpoint_1.LTPoint(0, 0)));
        ret.push(this._rtext);
        return ret;
    }
    getEntityName() {
        return "acdbaligneddimension";
    }
    getAutoLabel() {
        this._rtext.text = this.getMeasuredValue().toFixed(0).toString();
        return this._rtext.text;
    }
    moveReferencePoint(referencePoint, targetPoint) {
        return this._moveReferencePoint(referencePoint, targetPoint);
    }
    constructor(ext_point1, ext_point2, text, dim_point, text_position = {}) {
        super(ext_point1, ext_point2, text, dim_point, text_position);
    }
    clone() {
        var _a;
        if (this._extension_point1 != undefined) {
            let dim = new RDimAlignedData(this._extension_point1.clone(), this._extension_point2.clone(), "", this._dim_point.clone());
            dim._rtext = this._rtext.clone();
            dim.autoTextPos = this.autoTextPos;
            super.cloneFrom(dim);
            dim.ext1info = this.ext1info;
            dim.ext2info = this.ext2info;
            dim.dimposInfo = this.dimposInfo;
            dim.blockscale = this.blockscale;
            dim._scale = this._scale;
            dim.setDimStyle((_a = this._style) === null || _a === void 0 ? void 0 : _a.name, false);
            return dim;
        }
        return {};
    }
    getMeasuredValue() {
        return this._extension_point1.getDistanceTo(this._extension_point2);
    }
    getDimensionType() {
        return DimensionType.EntityDimAligned;
    }
    mirror(axis) {
        super._mirror(axis);
    }
    recomputeDefinitionPoint(oldExtPoint1, oldExtPoint2, newExtPoint1, newExtPoint2) {
        let dline = new segment_1.RLine(oldExtPoint1, oldExtPoint2);
        let d = dline.getDistanceTo(this._dim_point, false);
        if (!ltmath_1.LTMath.isSane(d)) {
            this._dim_point.valid = false; // = RVector::nullVector;
            return;
        }
        let s = dline.getSideOfPoint(this._dim_point);
        let a = newExtPoint1.getAngleTo(newExtPoint2);
        if (s == segment_1.Side.LeftHand) {
            a += Math.PI / 2.0;
        }
        else {
            a -= Math.PI / 2.0;
        }
        let v = ltpoint_1.LTPoint.createPolar(d, a);
        let dp = newExtPoint1.plus(v);
        if (dp.isValid()) {
            this._dim_point = dp;
        }
    }
    getAngle() {
        return this._extension_point2.getAngleTo(this._extension_point1);
    }
    getDimPoints() {
        if (this._dirty_dimPoints == false) {
            return;
        }
        //计算垂直方向上的角度;
        var extension_line = new segment_1.RLine(this._extension_point1, this._extension_point2);
        let ext_angle = this._extension_point1.getAngleTo(this._extension_point2);
        var side = extension_line.getSideOfPoint(this._dim_point);
        if (side == segment_1.Side.RightHand) {
            ext_angle -= Math.PI / 2;
        }
        else {
            ext_angle += Math.PI / 2;
        }
        var ext_length = extension_line.getDistanceTo(this._dim_point, false, NaN);
        if (isNaN(ext_length)) {
            var ext_lengt222h = extension_line.getDistanceTo(this._dim_point, false, NaN);
            return;
        }
        let dimexo = this.getDimexo();
        let dimexe = this.getDimexe();
        let dimtih = this.getDimtih();
        var v1 = new ltpoint_1.LTPoint(0, 0);
        v1.setPolar(dimexo, ext_angle);
        var v2 = new ltpoint_1.LTPoint(0, 0);
        v2.setPolar(dimexe, ext_angle);
        var e1 = new ltpoint_1.LTPoint(0, 0);
        e1.setPolar(ext_length, ext_angle);
        this.render_point1 = this._extension_point1.plus(v1);
        this.render_point2 = this._extension_point1.plus(e1).plus(v2);
        this.render_point3 = this._extension_point2.plus(v1);
        this.render_point4 = this._extension_point2.plus(e1).plus(v2);
        this.render_midpoint1 = this._extension_point1.plus(e1);
        this.render_midpoint2 = this._extension_point2.plus(e1);
        if (this.render_midpoint1.isSane()) {
            this._dim_point = this.render_midpoint1;
        }
        else {
            this._dim_point = new ltpoint_1.LTPoint(0, 0, 0, false);
        }
        let g = this.getAngle();
        let corrected = false;
        this._rtext.text = this.getMeasurement(true);
        let a = ltmath_1.LTMath.makeAngleReadable(g, true, corrected);
        this.updateOutsideArrow(this.render_midpoint1, this.render_midpoint2);
        this.upDateTextWidthFactor();
        this.updateTextPosition(this._rtext.text, this._rtext.width, this.render_midpoint1, this.render_midpoint2, corrected);
        this._rtext.setRotate(a);
        this._dirty_dimPoints = false;
        this._dirty = false;
    }
    getSaveValue() {
        var _a;
        let saveinfo = super._getSaveValue();
        let str;
        const json = { "ext1pos": saveinfo.ext1, "ext2pos": saveinfo.ext2, "dimpos": saveinfo.diminfo, "textinfo": saveinfo.textinfo,
            "name": (_a = this._style) === null || _a === void 0 ? void 0 : _a.name, dimblk1: this.ext1info.blockname, dimblk2: this.ext2info.blockname,
            blockscale: this.blockscale, dimscale: this._scale };
        str = JSON.stringify(json);
        let dict = [];
        dict["cadgeo"] = str;
        return dict;
    }
    render(canvas, entity, main, graphics) {
        if (this._dirty == true) {
            this.getDimPoints();
        }
        rdimstyle_1.RDimStyle.render(canvas, entity, main, this, graphics);
    }
    getReferencePoints() {
        return this._getReferencePoints();
    }
}
exports.RDimAlignedData = RDimAlignedData;
class RDimAngularData extends RDimension {
    getAutoLabel() {
        let v = this.getMeasuredValue() * 180.0 / Math.PI;
        this._rtext.text = v.toFixed(0).toString() + '°';
        return this._rtext.text;
    }
    getReferencePoints() {
        let ret = this._getReferencePoints();
        ret.push(this.getTextPosition());
        ret.push(this.getExtensionLine1End());
        ret.push(this.getExtensionLine2End());
        ret.push(this.getDimArcPosition());
        return ret;
    }
    moveReferencePoint(referencePoint, targetPoint) {
        let ret = false;
        if (referencePoint.equalsFuzzy(this.getExtensionLine1End())) {
            this.setExtensionLine1End(targetPoint);
            this.autoTextPos = false;
            ret = true;
        }
        else if (referencePoint.equalsFuzzy(this.getExtensionLine2End())) {
            this.setExtensionLine2End(targetPoint);
            this.autoTextPos = false;
            ret = true;
        }
        else if (referencePoint.equalsFuzzy(this.getDimArcPosition())) {
            this.setDimArcPosition(targetPoint);
            this.autoTextPos = false;
            ret = true;
        }
        // if (!ret) {
        //   ret = super._moveReferencePoint(referencePoint, targetPoint);
        // }
        if (ret) {
            this.update();
        }
        return ret;
    }
    getAngle() {
        let ang = this.getAngles();
        let ret;
        if (!ang.reversed) {
            if (ang.ang2 < ang.ang1) {
                ang.ang2 += 2 * Math.PI;
            }
            ret = ang.ang2 - ang.ang1;
        }
        else {
            if (ang.ang1 < ang.ang2) {
                ang.ang1 += 2 * Math.PI;
            }
            ret = ang.ang1 - ang.ang2;
        }
        return ret;
    }
    getMeasuredValue() {
        return this.getAngle();
    }
    getDimensionArc() {
        let center = this.getCenter();
        let radius = center.getDistanceTo(this.getDimArcPosition());
        let ang = this.getAngles();
        return new segment_1.RArc(center, ang.radius, ang.ang1, ang.ang2, ang.reversed);
    }
    update() {
        this._dirty = true;
        //   textData.update();
        //   boundingBox = RBox();
        //   if (!autoUpdatesBlocked) {
        //       //qDebug() << "clear dim block name";
        //       dimBlockName = "";
        //   }
        //   shapes.clear();
    }
    _calculateRender() {
        let parallel = false;
        let dimexo = this.getDimexo();
        let dimexe = this.getDimexe();
        let dimtxt = this.getDimtxt();
        let dimgap = this.getDimgap();
        let dimasz = this.getDimasz();
        let dimtad = this.getDimtad();
        let dimtih = this.getDimtih();
        // find out center:
        let center = this.getCenter();
        if (!center.isValid()) {
            //return ret;
        }
        let ang = this.getAngles();
        let rad = this.getDimArcPosition().getDistanceTo(center);
        // dimension line arc:
        let dimArc = new segment_1.RArc(center, rad, ang.ang1, ang.ang2, ang.reversed);
        if (parallel) {
            let dir = new ltpoint_1.LTPoint(0, 0);
            let midAngle = center.getAngleTo(dimArc.getMiddlePoint());
            let arcRad = center.getDistanceTo(this.getExtensionLine1End());
            dir.setPolar(1.0, midAngle);
            if (rad < arcRad) {
                // dimension inside of arc: use same radius as for arc:
                rad = arcRad;
                dir.rotate(Math.PI);
            }
            let c = new segment_1.RCircle(this.getDimArcPosition(), rad);
            let middleLine = segment_1.RLine.createAngle(center, midAngle, 1.0);
            let ips = ltintersection_1.LTIntersection.getIntersectionPointsLC(middleLine, c, false);
            let cent = center.getClosest(ips);
            if (cent == null) {
                return;
            }
            dimArc = new segment_1.RArc(cent, rad, ang.ang1, ang.ang2, ang.reversed);
            // arc we are dimensioning:
            let arc = new segment_1.RArc(center, arcRad, ang.ang1, ang.ang2, ang.reversed);
            let ip1 = ltpoint_1.LTPoint.nullVector(), ip2 = ltpoint_1.LTPoint.nullVector();
            let line1 = new segment_1.RLine(arc.getStartPoint(), arc.getStartPoint().plus(dir));
            let ips1 = ltintersection_1.LTIntersection.getIntersectionPointsLA(line1, dimArc, false, true);
            if (ips1.length == 1) {
                ip1 = ips1[0];
            }
            let line2 = new segment_1.RLine(arc.getEndPoint(), arc.getEndPoint().plus(dir));
            let ips2 = ltintersection_1.LTIntersection.getIntersectionPointsLA(line2, dimArc, false, true);
            if (ips2.length == 1) {
                ip2 = ips2[0];
            }
            dimArc.trimStartPoint(ip1, ltpoint_1.LTPoint.nullVector(), false);
            dimArc.trimEndPoint(ip2, ltpoint_1.LTPoint.nullVector(), false);
            // extension line:
            let extLine1 = new segment_1.RLine(arc.getStartPoint().plus(dir.dot(dimexo)), ip1.plus(dir.dot(dimexe)));
            let extLine2 = new segment_1.RLine(arc.getEndPoint().plus(dir.dot(dimexo)), ip2.plus(dir.dot(dimexe)));
            rdimstyle_1.RDimStyle.adjustExtensionLineFixLength(extLine1, extLine2, true, this);
            this.render_point1 = extLine1.getStartPoint();
            this.render_point2 = extLine1.getEndPoint();
            this.render_point3 = extLine2.getStartPoint();
            this.render_point4 = extLine2.getEndPoint();
            //canvas.drawLine(extLine1.getStartPoint(),extLine1.getEndPoint());
            //canvas.drawLine(extLine2.getStartPoint(),extLine2.getEndPoint());
        }
        else {
            let dir = new ltpoint_1.LTPoint(0, 0);
            let len;
            let dist;
            let f;
            // 1st extension line:
            dist = center.getDistanceTo2D(ang.p1);
            f = 1;
            if (rad < dist) {
                f = -1;
            }
            len = rad - dist + dimexe * f;
            dir.setPolar(1.0, ang.ang1);
            let extLine1 = new segment_1.RLine(center.plus(dir.dot(dist)).plus(dir.dot(dimexo * f)), center.plus(dir.dot(dist)).plus(dir.dot(len)));
            // 2nd extension line:
            dist = center.getDistanceTo2D(ang.p2);
            f = 1;
            if (rad < dist) {
                f = -1;
            }
            len = rad - dist + dimexe * f;
            dir.setPolar(1.0, ang.ang2);
            let extLine2 = new segment_1.RLine(center.plus(dir.dot(dist)).plus(dir.dot(dimexo * f)), center.plus(dir.dot(dist)).plus(dir.dot(len)));
            rdimstyle_1.RDimStyle.adjustExtensionLineFixLength(extLine1, extLine2, true, this);
            this.render_point1 = extLine1.getStartPoint();
            this.render_point2 = extLine1.getEndPoint();
            this.render_point3 = extLine2.getStartPoint();
            this.render_point4 = extLine2.getEndPoint();
        }
        // Create dimension line (arc):
        let dimArc2 = dimArc;
        // length of dimension arc:
        let distance = dimArc.getLength();
        // do we have to put the arrows outside of the arc?
        let outsideArrow1 = (distance < dimasz * 2);
        let outsideArrow2 = outsideArrow1;
        // force flipping arrows (against logic above):
        if (this.isArrow1Flipped()) {
            outsideArrow1 = !outsideArrow1;
        }
        if (this.isArrow2Flipped()) {
            outsideArrow2 = !outsideArrow2;
        }
        // extend arc outside arrows
        let a = this.getDimasz() * 2 / dimArc.getRadius();
        if (outsideArrow1) {
            dimArc2.setStartAngle(dimArc.isReversed ? dimArc.getStartAngle() + a : dimArc.getStartAngle() - a);
        }
        if (outsideArrow2) {
            dimArc2.setEndAngle(dimArc.isReversed ? dimArc.getEndAngle() - a : dimArc.getEndAngle() + a);
        }
        this.setArc(dimArc2);
        let arrowAngle1, arrowAngle2;
        let arrowAng;
        if (rad > 1.0e-6) {
            arrowAng = this.getDimasz() / rad;
        }
        else {
            arrowAng = 0.0;
        }
        let v1 = new ltpoint_1.LTPoint(0, 0);
        if (!dimArc.isReversed) {
            if (outsideArrow1) {
                v1.setPolar(rad, dimArc.getStartAngle() - arrowAng);
            }
            else {
                v1.setPolar(rad, dimArc.getStartAngle() + arrowAng);
            }
        }
        else {
            if (outsideArrow1) {
                v1.setPolar(rad, dimArc.getStartAngle() + arrowAng);
            }
            else {
                v1.setPolar(rad, dimArc.getStartAngle() - arrowAng);
            }
        }
        v1 = v1.plus(dimArc.getCenter());
        arrowAngle1 = dimArc.getStartPoint().getAngleTo(v1);
        arrowAngle1 = arrowAngle1 + Math.PI;
        let v2 = new ltpoint_1.LTPoint(0, 0);
        if (!dimArc.isReversed) {
            if (outsideArrow2) {
                v2.setPolar(rad, dimArc.getEndAngle() + arrowAng);
            }
            else {
                v2.setPolar(rad, dimArc.getEndAngle() - arrowAng);
            }
        }
        else {
            if (outsideArrow2) {
                v2.setPolar(rad, dimArc.getEndAngle() - arrowAng);
            }
            else {
                v2.setPolar(rad, dimArc.getEndAngle() + arrowAng);
            }
        }
        v2 = v2.plus(dimArc.getCenter());
        arrowAngle2 = dimArc.getEndPoint().getAngleTo(v2);
        arrowAngle2 = arrowAngle2 + Math.PI;
        this.render_midpoint1 = dimArc.getStartPoint();
        this.ext1info.angle = arrowAngle1;
        this.render_midpoint2 = dimArc.getEndPoint();
        this.ext2info.angle = arrowAngle2;
        let arrow1Pos = dimArc.getStartPoint().plus(ltpoint_1.LTPoint.createPolar(dimasz, arrowAngle1 + Math.PI));
        let arrow2Pos = dimArc.getEndPoint().plus(ltpoint_1.LTPoint.createPolar(dimasz, arrowAngle2 + Math.PI));
        let textPos = dimArc.getMiddlePoint();
        let dimAngle1 = textPos.getAngleTo(dimArc.getCenter()) - Math.PI / 2.0;
        if (this.hasCustomTextPosition()) {
            dimAngle1 = this.getTextPosition().getAngleTo(dimArc.getCenter()) - Math.PI / 2.0;
        }
        let distV = new ltpoint_1.LTPoint(0, 0);
        let textAngle;
        if (dimtih) {
            textAngle = 0.0;
        }
        else {
            if (ltmath_1.LTMath.isAngleReadable(dimAngle1)) {
                distV.setPolar(dimgap + dimtxt / 2, dimAngle1 + Math.PI / 2.0);
                textAngle = dimAngle1;
            }
            else {
                distV.setPolar(dimgap + dimtxt / 2, dimAngle1 - Math.PI / 2.0);
                textAngle = dimAngle1 + Math.PI;
            }
        }
        if (this.hasCustomTextPosition() == false) {
            let f = 1.0;
            if (dimtad != 0 && !dimtih) {
                textPos = textPos.plus(distV.dot(f));
            }
            this.updateTextPositionCenter(textPos);
        }
        this._rtext.text = this.getMeasurement(true);
        this._rtext.setRotate(textAngle);
        this.updateArrowPos1(arrow1Pos);
        this.updateArrowPos1(arrow2Pos);
        this._dirty = false;
    }
}
exports.RDimAngularData = RDimAngularData;
class RDimAngular2LData extends RDimAngularData {
    constructor(ext_point1, ext_point2, text, dim_point, text_position = {}) {
        super(ext_point1, ext_point2, text, dim_point, text_position);
        this.rArc = null;
        this.extensionLine1Start = new ltpoint_1.LTPoint(0, 0, 0, false);
        this.extensionLine1End = new ltpoint_1.LTPoint(0, 0, 0, false);
        this.extensionLine2Start = new ltpoint_1.LTPoint(0, 0, 0, false);
        this.dimArcPosition = new ltpoint_1.LTPoint(0, 0, 0, false);
    }
    getEntityName() {
        return "acdb2lineangulardimension";
    }
    setArc(arc) {
        this.rArc = arc;
    }
    moveReferencePoint(referencePoint, targetPoint) {
        let ret = false;
        if (referencePoint.equalsFuzzy(this.extensionLine1Start) == true) {
            this.extensionLine1Start = targetPoint;
            this.autoTextPos = false;
            ret = true;
        }
        else if (referencePoint.equalsFuzzy(this.extensionLine2Start)) {
            this.extensionLine2Start = targetPoint;
            this.autoTextPos = false;
            ret = true;
        }
        else if (referencePoint.equalsFuzzy(this._rtext.position)) {
            this._rtext.position = targetPoint;
            this.autoTextPos = true;
            ret = true;
        }
        if (ret == false) {
            ret = super.moveReferencePoint(referencePoint, targetPoint);
        }
        if (ret == true) {
            this._dirty = true;
        }
        return ret;
    }
    _getShape() {
        if (this._dirty == true) {
            this.getDimPoints();
        }
        if (this.render_point1 === null || this.render_point2 == null || this.render_point3 == null || this.render_point4 == null) {
            return [];
        }
        let ret = [];
        ret.push(new segment_1.RLine(this.render_point1, this.render_point2));
        ret.push(new segment_1.RLine(this.render_point3, this.render_point4));
        if (this.rArc != null) {
            ret.push(this.rArc);
        }
        ret.push(this._rtext);
        return ret;
    }
    getAutoLabel() {
        return super.getAutoLabel();
    }
    getCenter() {
        let l1 = new segment_1.RLine(this.extensionLine1End, this.extensionLine1Start);
        let l2 = new segment_1.RLine(this.extensionLine2Start, this._dim_point);
        let vs = l1.getIntersectionPoints(l2, false);
        if (vs.length <= 0) {
            return ltpoint_1.LTPoint.nullVector();
        }
        return vs[0];
    }
    getAngles() {
        let ang1 = 0.0;
        let ang2 = 0.0;
        let reversed = false;
        let p1 = ltpoint_1.LTPoint.nullVector();
        let p2 = ltpoint_1.LTPoint.nullVector();
        let done = false;
        let center = this.getCenter();
        let ang = center.getAngleTo(this.dimArcPosition);
        // find out the angles this dimension refers to:
        for (let f1 = 0; f1 <= 1 && !done; ++f1) {
            ang1 = ltmath_1.LTMath.getNormalizedAngle(this.extensionLine1End.getAngleTo(this.extensionLine1Start) + f1 * Math.PI);
            if (f1 == 0) {
                p1 = this.extensionLine1Start;
            }
            else {
                p1 = this.extensionLine1End;
            }
            for (let f2 = 0; f2 <= 1 && !done; ++f2) {
                ang2 = ltmath_1.LTMath.getNormalizedAngle(this.extensionLine2Start.getAngleTo(this._dim_point) + f2 * Math.PI);
                if (f2 == 0) {
                    p2 = this._dim_point;
                }
                else {
                    p2 = this.extensionLine2Start;
                }
                for (let t = 0; t <= 1 && !done; ++t) {
                    if (t == 0) {
                        reversed = false;
                    }
                    else {
                        reversed = true;
                    }
                    let angDiff;
                    if (!reversed) {
                        if (ang2 < ang1) {
                            ang2 += 2 * Math.PI;
                        }
                        angDiff = ang2 - ang1;
                    }
                    else {
                        if (ang1 < ang2) {
                            ang1 += 2 * Math.PI;
                        }
                        angDiff = ang1 - ang2;
                    }
                    ang1 = ltmath_1.LTMath.getNormalizedAngle(ang1);
                    ang2 = ltmath_1.LTMath.getNormalizedAngle(ang2);
                    if (ltmath_1.LTMath.isAngleBetween(ang, ang1, ang2, reversed) && angDiff <= Math.PI) {
                        done = true;
                        break;
                    }
                }
            }
        }
        return { ang1: ang1, ang2: ang2, reversed: reversed, p1: p1, p2: p2 };
    }
    setExtensionLine1Start(p) {
        this.extensionLine1Start = p;
        //this.update();
    }
    getExtensionLine1Start() {
        return this.extensionLine1Start;
    }
    setExtensionLine1End(p) {
        this.extensionLine1End = p;
        //update();
    }
    getExtensionLine1End() {
        return this.extensionLine1End;
    }
    setExtensionLine2Start(p) {
        this.extensionLine2Start = p;
        //update();
    }
    getExtensionLine2Start() {
        return this.extensionLine2Start;
    }
    setExtensionLine2End(p) {
        this.setDefinitionPoint(p);
    }
    getExtensionLine2End() {
        return this._dim_point;
    }
    setDimArcPosition(p) {
        this.dimArcPosition = p;
        this.update();
    }
    getDimArcPosition() {
        return this.dimArcPosition;
    }
    getReferencePoints() {
        let ret = super.getReferencePoints();
        ret.push(this.extensionLine1Start);
        ret.push(this.extensionLine2Start);
        return ret;
    }
    getDimensionType() {
        return DimensionType.EntityDimAngular2L;
    }
    recomputeDefinitionPoint(oldExtPoint1, oldExtPoint2, newExtPoint1, newExtPoint2) {
    }
    render(canvas, entity, main, graphics) {
        if (this._dirty == true) {
            this.getDimPoints();
        }
        rdimstyle_1.RDimStyle.render(canvas, entity, main, this, graphics);
    }
    clone() {
        var _a;
        if (this.extensionLine1Start != undefined) {
            let dim = new RDimAngular2LData(this._extension_point1.clone(), this._extension_point2.clone(), "", this._dim_point);
            dim.extensionLine1Start = this.extensionLine1Start.clone();
            dim.extensionLine1End = this.extensionLine1End.clone();
            dim.extensionLine2Start = this.extensionLine2Start.clone();
            dim.dimArcPosition = this.dimArcPosition.clone();
            dim._rtext = this._rtext.clone();
            dim.autoTextPos = this.autoTextPos;
            super.cloneFrom(dim);
            if (this.rArc != null) {
                dim.rArc = this.rArc.clone();
            }
            dim.ext1info = this.ext1info;
            dim.ext2info = this.ext2info;
            dim.dimposInfo = this.dimposInfo;
            dim.blockscale = this.blockscale;
            dim._scale = this._scale;
            dim.setDimStyle((_a = this._style) === null || _a === void 0 ? void 0 : _a.name, false);
            return dim;
        }
        return {};
    }
    scale(scaleFactors, center) {
        super.scale(scaleFactors, center);
        this.extensionLine1Start.scale(scaleFactors.x, scaleFactors.y, center);
        this.extensionLine1End.scale(scaleFactors.x, scaleFactors.y, center);
        this.extensionLine2Start.scale(scaleFactors.x, scaleFactors.y, center);
        this.dimArcPosition.scale(scaleFactors.x, scaleFactors.y, center);
        this.update();
        return true;
    }
    getDimPoints() {
        this._calculateRender();
    }
    mirror(axis) {
        super.mirror(axis);
        this.extensionLine1Start.mirror(axis);
        this.extensionLine1End.mirror(axis);
        this.extensionLine2Start.mirror(axis);
        this.dimArcPosition.mirror(axis);
        this.update();
        return true;
    }
    move(offset) {
        super.move(offset);
        this.extensionLine1Start.move(offset.x, offset.y);
        this.extensionLine1End.move(offset.x, offset.y);
        this.extensionLine2Start.move(offset.x, offset.y);
        this.dimArcPosition.move(offset.x, offset.y);
        this.update();
        return true;
    }
    rotate(rotation, center) {
        super.rotate(rotation, center);
        this.extensionLine1Start.rotate(rotation, center);
        this.extensionLine1End.rotate(rotation, center);
        this.extensionLine2Start.rotate(rotation, center);
        this.dimArcPosition.rotate(rotation, center);
        this.update();
        return true;
    }
    getSaveValue() {
        var _a;
        let saveinfo = super._getSaveValue();
        let str;
        const json = { "arcPoint": this.getDimArcPosition().toJson(), "xLine1End": this.getExtensionLine1End().toJson(),
            "xLine1Start": this.getExtensionLine1Start().toJson(), "xLine2End": this.getExtensionLine2End().toJson(),
            "xLine2Start": this.getExtensionLine2Start().toJson(), "name": (_a = this._style) === null || _a === void 0 ? void 0 : _a.name, dimblk1: this.ext1info.blockname, dimblk2: this.ext1info.blockname,
            blockscale: this.blockscale, "textinfo": saveinfo.textinfo, dimscale: this._scale };
        str = JSON.stringify(json);
        let dict = [];
        dict["cadgeo"] = str;
        return dict;
    }
}
exports.RDimAngular2LData = RDimAngular2LData;
class RDimAngular3PData extends RDimAngularData {
    constructor(ext_point1, ext_point2, text, dim_point, text_position = {}) {
        super(ext_point1, ext_point2, text, dim_point, text_position);
        this.rArc = null;
        this.center = new ltpoint_1.LTPoint(0, 0, 0, false);
        this.arcPoint = new ltpoint_1.LTPoint(0, 0, 0, false);
        this.extensionLine1End = new ltpoint_1.LTPoint(0, 0, 0, false);
        this.extensionLine2End = new ltpoint_1.LTPoint(0, 0, 0, false);
    }
    setArc(arc) {
        this.rArc = arc;
    }
    setCenter(center) {
        this.center = center;
    }
    getEntityName() {
        return "acdb3pointangulardimension";
    }
    setExtensionLine1Start(p) {
    }
    getExtensionLine1Start() {
        return {};
    }
    setExtensionLine1End(p) {
        this.extensionLine1End = p;
    }
    getExtensionLine1End() {
        return this.extensionLine1End;
    }
    setExtensionLine2Start(p) {
    }
    getExtensionLine2Start() {
        return {};
    }
    setExtensionLine2End(p) {
        this.extensionLine2End = p;
    }
    getExtensionLine2End() {
        return this.extensionLine2End;
    }
    setDimArcPosition(p) {
        this.setDefinitionPoint(p);
    }
    getDimArcPosition() {
        return this._dim_point;
    }
    getCenter() {
        return this.center;
    }
    getAngles() {
        let ang = this.center.getAngleTo(this._dim_point);
        let ang1 = this.center.getAngleTo(this.extensionLine1End);
        let p1 = this.extensionLine1End;
        let ang2 = this.center.getAngleTo(this.extensionLine2End);
        let p2 = this.extensionLine2End;
        let reversed = true;
        if (ltmath_1.LTMath.isAngleBetween(ang, ang1, ang2, false)) {
            reversed = false;
        }
        return { ang1: ang1, ang2: ang2, reversed: reversed, p1: p1, p2: p2 };
    }
    getDimensionType() {
        return DimensionType.EntityDimAngular3P;
    }
    recomputeDefinitionPoint(oldExtPoint1, oldExtPoint2, newExtPoint1, newExtPoint2) {
    }
    getDimPoints() {
        this._calculateRender();
    }
    render(canvas, entity, main, graphics) {
        if (this._dirty == true) {
            this.getDimPoints();
        }
        rdimstyle_1.RDimStyle.render(canvas, entity, main, this, graphics);
    }
    getAutoLabel() {
        return super.getAutoLabel();
    }
    _getShape() {
        if (this._dirty == true) {
            this.getDimPoints();
        }
        if (this.render_point1 === null || this.render_point2 == null || this.render_point3 == null || this.render_point4 == null) {
            return [];
        }
        let ret = [];
        ret.push(new segment_1.RLine(this.render_point1, this.render_point2));
        ret.push(new segment_1.RLine(this.render_point3, this.render_point4));
        if (this.rArc != null) {
            ret.push(this.rArc);
        }
        ret.push(this._rtext);
        return ret;
    }
    clone() {
        var _a;
        if (this.center != undefined) {
            let dim = new RDimAngular3PData(this._extension_point1.clone(), this._extension_point2.clone(), "", this._dim_point);
            dim.center = this.center.clone();
            dim.arcPoint = this.arcPoint.clone();
            dim.extensionLine1End = this.extensionLine1End.clone();
            dim.extensionLine2End = this.extensionLine2End.clone();
            dim._rtext = this._rtext.clone();
            dim.autoTextPos = this.autoTextPos;
            super.cloneFrom(dim);
            if (this.rArc != null) {
                dim.rArc = this.rArc.clone();
            }
            dim.ext1info = this.ext1info;
            dim.ext2info = this.ext2info;
            dim.dimposInfo = this.dimposInfo;
            dim.blockscale = this.blockscale;
            dim._scale = this._scale;
            dim.setDimStyle((_a = this._style) === null || _a === void 0 ? void 0 : _a.name, false);
            return dim;
        }
        return {};
    }
    scale(scaleFactors, center) {
        super.scale(scaleFactors, center);
        this.center.scale(scaleFactors.x, scaleFactors.y, center);
        this.arcPoint.scale(scaleFactors.x, scaleFactors.y, center);
        this.extensionLine1End.scale(scaleFactors.x, scaleFactors.y, center);
        this.extensionLine2End.scale(scaleFactors.x, scaleFactors.y, center);
        this.update();
        return true;
    }
    mirror(axis) {
        super.mirror(axis);
        this.center.mirror(axis);
        this.arcPoint.mirror(axis);
        this.extensionLine1End.mirror(axis);
        this.extensionLine2End.mirror(axis);
        this.update();
        return true;
    }
    move(offset) {
        super.move(offset);
        this.center.move(offset.x, offset.y);
        this.arcPoint.move(offset.x, offset.y);
        this.extensionLine1End.move(offset.x, offset.y);
        this.extensionLine2End.move(offset.x, offset.y);
        this.update();
        return true;
    }
    rotate(rotation, center) {
        super.rotate(rotation, center);
        this.center.rotate(rotation, center);
        this.arcPoint.rotate(rotation, center);
        this.extensionLine1End.rotate(rotation, center);
        this.extensionLine2End.rotate(rotation, center);
        this.update();
        return true;
    }
    getSaveValue() {
        var _a;
        let saveinfo = super._getSaveValue();
        let str;
        const json = { "arcPoint": this.getDimArcPosition().toJson(), "centerPoint": this.getCenter().toJson(),
            "xLine1Point": this.extensionLine1End.toJson(), "xLine2Point": this.extensionLine2End.toJson(),
            "name": (_a = this._style) === null || _a === void 0 ? void 0 : _a.name, dimblk1: this.ext1info.blockname, dimblk2: this.ext1info.blockname,
            blockscale: this.blockscale, "textinfo": saveinfo.textinfo, dimscale: this._scale };
        str = JSON.stringify(json);
        let dict = [];
        dict["cadgeo"] = str;
        return dict;
    }
}
exports.RDimAngular3PData = RDimAngular3PData;
