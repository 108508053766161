"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShelveDrawUtil = void 0;
const ltentity_1 = require("../../cadplugins/cad/ltentity");
const ltpoint_1 = require("../../cadplugins/cad/ltpoint");
const ltfeature_1 = require("../../cadplugins/data/ltfeature");
const ltfields_1 = require("../../cadplugins/data/ltfields");
const sprinklerutils_1 = require("../sprinkler/sprinklerutils");
class ShelveDrawUtil {
    constructor(riserLayer, cadMap) {
        this._riserLayer = riserLayer;
        this._cadMap = cadMap;
    }
    addRiserBlockToLayer(pos, riserName, groupIndex, sprinklerCount, drawscale) {
        let valve_block = new ltentity_1.LTEntityPoint(pos);
        valve_block.blockName = sprinklerutils_1.STANDPIPE_NAME;
        valve_block.scale(new ltpoint_1.LTPoint(drawscale, drawscale), pos);
        let feature = new ltfeature_1.LTFeature({
            geometry: valve_block,
            layer: this._riserLayer,
            xdata: JSON.stringify({
                riser_name: riserName,
                groupIndex: groupIndex,
                sprinklerCount: sprinklerCount,
                source: "autoGenerate"
            }),
        });
        feature.setValue(ltfields_1.FieldNames.EntityLayer, feature.layer.name);
        feature.setValue(ltfields_1.FieldNames.Color, ltentity_1.dwg_bylayer);
        feature.setValue(ltfields_1.FieldNames.Linetype, ltentity_1.dwg_bylayer);
        feature.setValue(ltfields_1.FieldNames.Width, 0.0);
        this._cadMap.addFeature(feature);
        //绘制标注线和文字
        this.addRiserDimensionToLayer(pos, riserName, drawscale);
        return feature;
    }
    addRiserDimensionToLayer(pos, riserName, drawscale) {
        //立管名称标注的两根折线
        let p1 = new ltpoint_1.LTPoint(pos.x, pos.y - 1500);
        this.addDimensionLineToLayer(pos, p1);
        //文字
        let textLength = this.addDimensionTextToLayer(p1, riserName, drawscale);
        //改成和文字一样的长度
        //let p2 = new LTPoint(p1.x + 2000, p1.y);
        let p2 = new ltpoint_1.LTPoint(p1.x + textLength, p1.y);
        this.addDimensionLineToLayer(p1, p2);
    }
    addDimensionLineToLayer(pos1, pos2) {
        let line = new ltentity_1.LTLine(pos1, pos2);
        let lineFeature = new ltfeature_1.LTFeature({
            geometry: line,
            layer: this._riserLayer,
            xdata: JSON.stringify({
                source: "autoGenerate",
            }),
        });
        lineFeature.setValue(ltfields_1.FieldNames.EntityLayer, lineFeature.layer.name);
        lineFeature.setValue(ltfields_1.FieldNames.Color, ltentity_1.dwg_bylayer);
        line.setLineType(ltentity_1.linetype_continuous);
        lineFeature.setValue(ltfields_1.FieldNames.Linetype, ltentity_1.linetype_continuous);
        lineFeature.setValue(ltfields_1.FieldNames.Width, 0.0);
        lineFeature.setValue(ltfields_1.FieldNames.Rotate, 0.0);
        this._cadMap.addFeature(lineFeature);
    }
    addDimensionTextToLayer(textPosition, text, drawscale) {
        const textHeight = 300 * drawscale;
        const textOffset = textHeight / 3;
        let translation = new ltpoint_1.LTPoint(textOffset, textOffset);
        let ltText = new ltentity_1.LTText(textPosition.plus(translation));
        ltText.text = text;
        ltText.setHeight(textHeight);
        //构建管径文字要素
        let textFeature = new ltfeature_1.LTFeature({
            geometry: ltText,
            layer: this._riserLayer,
            xdata: JSON.stringify({
                source: "autoGenerate",
            }),
        });
        textFeature.setValue(ltfields_1.FieldNames.EntityLayer, textFeature.layer.name);
        textFeature.setValue(ltfields_1.FieldNames.EntityType, "AcDbText");
        textFeature.setValue(ltfields_1.FieldNames.Linetype, ltentity_1.dwg_bylayer);
        this._cadMap.addFeature(textFeature);
        //返回文字长度
        return ltText.getBoundingBox().getWidth();
    }
}
exports.ShelveDrawUtil = ShelveDrawUtil;
