"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LTLinetypePattern = void 0;
const ltpoint_1 = require("./ltpoint");
const ltutils_1 = require("./ltutils");
const ltentity_1 = require("./ltentity");
const ltmath_1 = require("./ltmath");
var PointTolerance = 1.0e-9;
/**
 * LTLinetypePattern 线型类
 */
class LTLinetypePattern {
    constructor(metric, name, description) {
        this.noOffset = false;
        this.symmetries = [];
        this._dwgPattern = "";
        this._metric = metric;
        this._name = name;
        this._description = description;
        this._linescale = 1.0;
        this._shapes = new Map();
        this._pattern_old = [];
        this._pattern = [];
        this._unitconvert = false;
    }
    get name() {
        return this._name;
    }
    set name(n) {
        this._name = n;
    }
    unitConvert(num) {
        if (this._unitconvert) {
            return num / 25.4;
        }
        return num;
    }
    setUnitConvert(convert) {
        this._unitconvert = convert;
    }
    getDescription() {
        return this._description;
    }
    getPatternString() {
        return this._patternString;
    }
    getNumDashes() {
        return this._pattern.length;
    }
    getPatternLength() {
        let ret = 0.0;
        this._pattern.forEach(e => {
            let l = Math.abs(e);
            ret = ret + l;
        });
        return ret;
    }
    isValid() {
        return this._pattern.length > 0;
    }
    setDwgPattern(pattern) {
        if (!this._unitconvert) {
            this._dwgPattern = pattern;
            return;
        }
        //单位换算
        this._dwgPattern = "";
        if (pattern == undefined) {
            return;
        }
        var reg = new RegExp(/\[[^\]]*\]|A|([+-]?\d+\.?\d*)|([+-]?\d*\.?\d+)/ig);
        let parts = pattern.match(reg);
        if (parts == null) {
            return;
        }
        if (parts.length < 1) {
            return false;
        }
        //去除开头的a
        if (parts[0].toLowerCase() == "a") {
            this._dwgPattern = "A";
        }
        var dashes = [];
        for (var i = 1; i < parts.length; i++) {
            var part = parts[i];
            if (part.includes("[")) {
                var shapeExp = new RegExp(/\[([^, ]*)[, ]*([^, ]*)(?:[, ]*([SRXYAU])[^=]*=(?:([+-]?\d+\.?\d*|[+-]?\d*\.\d+)))?(?:[, ]*([SRXYAU])[^=]*=(?:([+-]?\d+\.?\d*|[+-]?\d*\.\d+)))?(?:[, ]*([SRXYAU])[^=]*=(?:([+-]?\d+\.?\d*|[+-]?\d*\.\d+)))?(?:[, ]*([SRXYAU])[^=]*=(?:([+-]?\d+\.?\d*|[+-]?\d*\.\d+)))?\]/i);
                let shapeparts = part.match(shapeExp);
                if (shapeparts == null) {
                    return;
                }
                if (shapeparts.length < 1) {
                    return false;
                }
                this._dwgPattern = this._dwgPattern + ",[" + shapeparts[1] + "," + shapeparts[2];
                for (var k = 3; k < shapeparts.length; k += 2) {
                    var c = shapeparts[k];
                    if (c == "S" || c == "s") {
                        this._dwgPattern = this._dwgPattern + ",S=" + this.unitConvert(Number(shapeparts[k + 1])).toString();
                    }
                    else if (c == "X" || c == "x") {
                        this._dwgPattern = this._dwgPattern + ",X=" + this.unitConvert(Number(shapeparts[k + 1])).toString();
                    }
                    else if (c == "Y" || c == "y") {
                        this._dwgPattern = this._dwgPattern + ",Y=" + this.unitConvert(Number(shapeparts[k + 1])).toString();
                    }
                    else {
                        if (c !== undefined && shapeparts[k + 1] !== undefined) {
                            this._dwgPattern = this._dwgPattern + "," + c + "=" + shapeparts[k + 1];
                        }
                    }
                }
                this._dwgPattern = this._dwgPattern + "]";
            }
            else {
                this._dwgPattern = this._dwgPattern + "," + this.unitConvert(Number(part)).toString();
            }
        }
    }
    getDwgPattern() {
        return this._dwgPattern;
    }
    saveValue() {
        return {};
    }
    /**
     * \brief 设置线型比例
     */
    setSacle(scale) {
        if (this._linescale == scale) {
            return;
        }
        this._linescale = scale;
        this.updateShapes();
    }
    /**
     * \brief 获取图形
     */
    getShapes(i) {
        var it = this._shapes.get(i);
        return it;
    }
    get hasShapes() {
        return this._shapes.size > 0;
    }
    getDelta(pos) {
        let l = this.getPatternLength();
        if (pos < 0) {
            pos += (ltmath_1.LTMath.trunc(pos / l) + 1) * l;
        }
        else if (pos > l) {
            pos -= ltmath_1.LTMath.trunc(pos / l) * l;
        }
        let total = 0.0;
        for (let i = 0; i < this._pattern.length; ++i) {
            total += Math.abs(this._pattern[i]);
            if (total >= pos) {
                if (this._pattern[i] < 0) {
                    return -(total - pos);
                }
                return total - pos;
            }
        }
        return 0.0;
    }
    getPatternOffsetAt(length, symmetryPos, end) {
        let patternLength = this.getPatternLength();
        if (patternLength < PointTolerance) {
            return { offset: 0.0, gap: 0.0 };
        }
        let offset = length / 2 - symmetryPos;
        let m = ltmath_1.LTMath.trunc(offset / patternLength);
        offset -= (m + 1) * patternLength;
        let gap = this.getDelta(-offset);
        return { offset: offset, gap: gap };
        //return offset;
    }
    getPatternOffset(length) {
        if (this.noOffset == true) {
            return 0.0;
        }
        let optOffset = 0.0;
        let maxGap = -10000000;
        for (let i = 0; i < this.symmetries.length; ++i) {
            let res = this.getPatternOffsetAt(length, this.symmetries[i], false);
            if (res.gap > maxGap) {
                maxGap = res.gap;
                optOffset = res.offset;
            }
        }
        return optOffset;
    }
    /**
     * \brief 创建点
     * \param canvas 颜色
     * \param line 原来的线
     * \param map_scale 比例尺
     */
    exportLineSegment(canvas, line, pos, index, pointtolerance) {
        let p1OnShape = null;
        let p2OnShape = null;
        let lengths = pos.getLengthAt();
        //获取起始点
        for (index; index < pos.length(); ++index) {
            if (lengths[index] <= line.startX() &&
                line.startX() < lengths[index + 1]) {
                p1OnShape = pos.getPointsWithDistanceToEnd(index, line.startX() - lengths[index]);
                break;
            }
        }
        var index__first_temp = index;
        //获取结束点
        for (index; index < pos.length() - 1; ++index) {
            if (lengths[index] <= line.endX() &&
                line.endX() <= lengths[index + 1]) {
                p2OnShape = pos.getPointsWithDistanceToEnd(index, line.endX() - lengths[index]);
                break;
            }
        }
        var index_secent_temp = index;
        if (p1OnShape === null || p2OnShape === null) {
            //起始点或者结束点不符合要求则退出
            return;
        }
        //绘制
        if (line.getLength() < -pointtolerance) {
            //绘制一个点的情况 需要创建为一个线段
            let ang = this.getAngleAt(pos, index);
            let scaleHint = 1;
            let p = ltpoint_1.LTPoint.createPolar(1 * scaleHint, ang);
            let startPoint = new ltpoint_1.LTPoint(p1OnShape.x - p.x, p1OnShape.y - p.y);
            let endPoint = new ltpoint_1.LTPoint(p2OnShape.x + p.x, p1OnShape.y + p.y);
            if (canvas) {
                canvas.drawLine(startPoint, endPoint);
            }
        }
        else {
            for (var i = index__first_temp; i <= index_secent_temp; i++) {
                var p1 = pos.getPoint(i);
                var p2 = pos.getPoint(i + 1);
                if (i == index__first_temp) {
                    // trim start point:
                    p1 = p1OnShape;
                }
                if (i == index_secent_temp) {
                    // trim end point:
                    p2 = p2OnShape;
                }
                //绘制线段
                if (canvas) {
                    canvas.drawLine(p1, p2);
                }
            }
        }
    }
    /**
     * \brief 获取角度
     */
    getAngleAt(pos, index) {
        let lengths = pos.getLengthAt();
        if (index < 0 || index > pos.getLength() || index > lengths.length) {
            return 0.0;
        }
        return pos.getAngleAt(index);
    }
    getDashLengthAt(i) {
        if (i >= 0 && i < this._pattern.length) {
            return this._pattern[i];
        }
        return 0.0;
    }
    hasShapeAt(i) {
        return this._shapes.has(i);
    }
    getShapeAt(i) {
        return this._shapes.get(i);
    }
    getDashOffsetAt(dashes, i) {
        let ret = 0.0;
        if (i < 0 || i >= dashes.length) {
            return ret;
        }
        for (let k = 0; k < i; k++) {
            ret += Math.abs(dashes[k]);
        }
        return ret;
    }
    /**
     * \brief 设置dash
     */
    setdash(dashes) {
        this._pattern_old = dashes;
        this._pattern = dashes;
        // find symmetries:
        let normalizedPattern = [];
        for (let i = 0; i < this._pattern.length; i++) {
            if (i == 0) {
                normalizedPattern.push(this._pattern[i]);
                continue;
            }
            if ((this._pattern[i] >= 0.0 && this._pattern[i - 1] >= 0.0) ||
                (this._pattern[i] < 0.0 && this._pattern[i - 1] < 0.0)) {
                let p = normalizedPattern.pop();
                if (p !== undefined) {
                    normalizedPattern.push(p + this._pattern[i]);
                }
                continue;
            }
            normalizedPattern.push(this._pattern[i]);
        }
        let normalizedPatternOffset = 0.0;
        if (normalizedPattern.length > 2) {
            if (normalizedPattern[normalizedPattern.length - 1] >= 0.0 && normalizedPattern[0] >= 0) {
                normalizedPattern[0] += normalizedPattern[normalizedPattern.length - 1];
                normalizedPatternOffset = normalizedPattern[normalizedPattern.length - 1];
                normalizedPattern.pop();
            }
        }
        this.symmetries = [];
        let len = this.getPatternLength();
        let num = normalizedPattern.length;
        for (let i = 0; i < num; i++) {
            let sym = true;
            for (let a = 1; a < num; ++a) {
                if (Math.abs(normalizedPattern[ltmath_1.LTMath.absmod((i - a), num)] - normalizedPattern[ltmath_1.LTMath.absmod((i + a), num)]) > 0.1) {
                    sym = false;
                    break;
                }
            }
            if (sym) {
                // add middle of dash to symmetries:
                let s = this.getDashOffsetAt(normalizedPattern, i);
                s = s + Math.abs(normalizedPattern[i]) / 2.0;
                s = s - normalizedPatternOffset;
                if (s < 0.0) {
                    s = len + s;
                }
                if (s > len) {
                    s = len - s;
                }
                this.symmetries.push(s);
            }
        }
        //this._patternString = "";
    }
    /**
     * \brief 更新图形
     */
    updateShapes() {
        this._pattern = [];
        for (var i = 0; i < this._pattern_old.length; i++) {
            let scale = this._pattern_old[i] * this._linescale;
            this._pattern.push(scale);
        }
        for (let entry of this._shapes) {
            let shape = entry[1];
            if (shape instanceof ltentity_1.LTBlockReference || shape instanceof ltentity_1.LTPolyline) {
                let p = new ltpoint_1.LTPoint(this._linescale, this._linescale);
                shape.data.scale(p, null);
            }
            else if (shape instanceof ltentity_1.LTText) {
                shape.setScale(this._linescale);
            }
        }
    }
    /**
     * \brief 设置Pattern 初始化的时候使用
     */
    setPatternString(patternString) {
        this._patternString = "";
        var parts = [];
        let reg = new RegExp(";");
        parts = patternString.split(reg);
        if (parts.length < 1) {
            return false;
        }
        //去除开头的a
        if (parts[0].toLowerCase() == "a") {
            parts.shift();
            this._patternString = "A";
        }
        var dashes = [];
        for (var i = 0; i < parts.length; i++) {
            var part = parts[i];
            if (part.includes("[")) {
                //解析为shape
                let shape = part.slice(1, -1);
                var ent = null;
                let json_value;
                try {
                    json_value = JSON.parse(shape);
                }
                catch (error) { }
                let geometry = json_value["geos"];
                let text = json_value["text"];
                let scale = json_value["scale"];
                //文本
                if (text != undefined && text != null) {
                    let text_ent = new ltentity_1.LTText(new ltpoint_1.LTPoint(0, 0));
                    if (json_value.scale == undefined) {
                        text_ent.subData().height = this.unitConvert(3);
                    }
                    else {
                        scale = this.unitConvert(json_value.scale);
                        text_ent.subData().height = scale;
                    }
                    text_ent.subData().setRotate(text_ent.subData().getRotate() - json_value.rotate);
                    text_ent.subData().text = json_value.text;
                    ent = text_ent;
                    let textinfo = { "scale": scale, text: text };
                    let ts = "[" + JSON.stringify(textinfo) + "]";
                    this._patternString = this._patternString + ";" + ts;
                }
                else {
                    ent = ltutils_1.LTUtils.wkt2Entity(geometry);
                    let uscale = this.unitConvert(scale);
                    ent === null || ent === void 0 ? void 0 : ent.data.scale(new ltpoint_1.LTPoint(uscale, uscale), null);
                    let geoinfo = { "scale": uscale, geos: geometry };
                    let ts = "[" + JSON.stringify(geoinfo) + "]";
                    this._patternString = this._patternString + ";" + ts;
                }
                if (ent !== null) {
                    ent.setIsSub(true);
                    this._shapes.set(i - 1, ent);
                }
            }
            else {
                let d = this.unitConvert(Number(part));
                dashes.push(d);
                this._patternString = this._patternString + ";" + d.toString();
            }
        }
        if (dashes.length > 0) {
            this.setdash(dashes);
            this.updateShapes();
        }
        return true;
    }
}
exports.LTLinetypePattern = LTLinetypePattern;
