"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectionManager = exports.Direction = void 0;
class Direction {
    constructor(length, angle) {
        this._length = length;
        this._angle = angle;
    }
}
exports.Direction = Direction;
class DirectionManager {
    constructor() {
        this._directionList = new Array();
    }
    computeMainDirection(lineSegments) {
        for (let lineSegment of lineSegments) {
            this.add(lineSegment);
        }
        let mainDirection = this.getMainDirection();
        //为了在外面使用方便，角度转为弧度，并且范围转换
        let angle = mainDirection._angle;
        if (angle > 90) {
            angle -= 180;
        }
        return angle * Math.PI / 180;
    }
    add(lineSegment) {
        //由于要在一条直线上而角度相差180°的情况，所以所有角度处理成正数（0<α<=180）
        let angle = lineSegment.angle();
        if (angle <= 0) {
            angle += Math.PI;
        }
        angle = angle * 180 / Math.PI;
        let match = false;
        for (let direction of this._directionList) {
            if (Math.abs(direction._angle - angle) < 0.1) {
                match = true;
                direction._length += lineSegment.getLength();
                break;
            }
        }
        if (!match) {
            this._directionList.push(new Direction(lineSegment.getLength(), angle));
        }
    }
    getMainDirection() {
        let maxLength = this._directionList[0]._length;
        let maxLengthDirection = this._directionList[0];
        for (let i = 1; i < this._directionList.length; i++) {
            let direction = this._directionList[i];
            if (direction._length > maxLength) {
                maxLengthDirection = direction;
            }
        }
        return maxLengthDirection;
    }
}
exports.DirectionManager = DirectionManager;
