"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LTMath = void 0;
class LTMath {
    static isSane(v) {
        return !isNaN(v) && v > -1e12 && v < 1e12;
    }
    static isAngleReadable(angle, tolerance = 0.01) {
        let angleCorrected = this.getNormalizedAngle(angle);
        if (angleCorrected > Math.PI / 2.0 * 3.0 + tolerance || angleCorrected < Math.PI / 2.0 + tolerance) {
            return true;
        }
        return false;
    }
    static isSameDirection(dir1, dir2, tolerance) {
        let diff = Math.abs(dir1 - dir2);
        if (diff < tolerance || diff > 2 * Math.PI - tolerance) {
            return true;
        }
        else {
            return false;
        }
    }
    static trunc(v) {
        if (v == 0 || isNaN(v)) {
            return v;
        }
        if (v >= 0.0) {
            return Math.floor(v);
        }
        return Math.ceil(v);
    }
    static absmod(a, b) {
        if (b == 0) {
            return a;
        }
        let m = a % b;
        if ((b < 0 && m > 0) || (b > 0 && m < 0)) {
            return b + m;
        }
        return m;
    }
    static makeAngleReadable(angle, readable, corrected) {
        let ret;
        let a = this.isAngleReadable(angle);
        let cor = a !== readable;
        // quadrant 1 & 4
        if (!cor) {
            ret = angle;
        }
        // quadrant 2 & 3
        else {
            ret = angle + Math.PI;
        }
        if (corrected !== null) {
            corrected = cor;
        }
        return ret;
    }
    //两个角度相减
    static getAngleDifference(a1, a2) {
        var ret;
        if (a1 >= a2) {
            a2 += 2 * Math.PI;
        }
        ret = a2 - a1;
        if (ret >= 2 * Math.PI) {
            ret = 0.0;
        }
        return ret;
    }
    static getNormalizedAngle(a) {
        if (a >= 0.0) {
            var n = Math.floor(a / (2 * Math.PI));
            a -= 2 * Math.PI * n;
        }
        else {
            var n = Math.ceil(a / (-2 * Math.PI));
            a += 2 * Math.PI * n;
        }
        if (a > 2 * Math.PI - 1e-9) {
            a = 0.0;
        }
        return a;
    }
    static getRelativeAngle(a, baseAngle) {
        var ret = a - baseAngle;
        if (ret > Math.PI) {
            ret -= 2 * Math.PI;
        }
        if (ret < -Math.PI) {
            ret += 2 * Math.PI;
        }
        return ret;
    }
    static isAngleBetween(a, a1, a2, reversed) {
        a = LTMath.getNormalizedAngle(a);
        a1 = LTMath.getNormalizedAngle(a1);
        a2 = LTMath.getNormalizedAngle(a2);
        var ret = false;
        if (reversed) {
            var tmp = a1;
            a1 = a2;
            a2 = tmp;
        }
        if (a1 >= a2 - 1e-9) {
            if (a >= a1 - 1e-9 || a <= a2 + 1e-9) {
                ret = true;
            }
        }
        else {
            if (a >= a1 - 1e-9 && a <= a2 + 1e-9) {
                ret = true;
            }
        }
        return ret;
    }
    static getAngleDifference180(a1, a2) {
        var ret;
        ret = a2 - a1;
        if (ret > Math.PI) {
            ret = -(2 * Math.PI - ret);
        }
        if (ret < -Math.PI) {
            ret = 2 * Math.PI + ret;
        }
        return ret;
    }
    static fuzzyCompare(v1, v2, tolerance) {
        return Math.abs(v1 - v2) <= tolerance;
    }
    static getCubicRoots(p, r) {
        let s, t, b, c, d;
        let k = 0;
        if (p[0] != 1.0) {
            for (k = 1; k < 4; k++)
                p[k] = p[k] / p[0];
            p[0] = 1.0;
        }
        s = p[1] / 3.0;
        t = s * p[1];
        b = 0.5 * (s * (t / 1.5 - p[2]) + p[3]);
        t = (t - p[2]) / 3.0;
        c = t * t * t;
        d = b * b - c;
        if (d >= 0.0) {
            d = Math.pow((Math.sqrt(d) + Math.abs(b)), 1.0 / 3.0);
            if (d != 0.0) {
                if (b > 0.0)
                    b = -d;
                else
                    b = d;
                c = t / b;
            }
            d = r[2][2] = Math.sqrt(0.75) * (b - c);
            b = b + c;
            c = r[1][2] = -0.5 * b - s;
            if ((b > 0.0 && s <= 0.0) || (b < 0.0 && s > 0.0)) {
                r[1][1] = c;
                r[2][1] = -d;
                r[1][3] = b - s;
                r[2][3] = 0.0;
            }
            else {
                r[1][1] = b - s;
                r[2][1] = 0.0;
                r[1][3] = c;
                r[2][3] = -d;
            }
        } /* end 2 equal or complex roots */
        else {
            if (b == 0.0)
                d = Math.atan(1.0) / 1.5;
            else
                d = Math.atan(Math.sqrt(-d) / Math.abs(b)) / 3.0;
            if (b < 0.0)
                b = 2.0 * Math.sqrt(t);
            else
                b = -2.0 * Math.sqrt(t);
            c = Math.cos(d) * b;
            t = -Math.sqrt(0.75) * Math.sin(d) * b - 0.5 * c;
            d = -t - c - s;
            c = c - s;
            t = t - s;
            if (Math.abs(c) > Math.abs(t)) {
                r[1][3] = c;
            }
            else {
                r[1][3] = t;
                t = c;
            }
            if (Math.abs(d) > Math.abs(t)) {
                r[1][2] = d;
            }
            else {
                r[1][2] = t;
                t = d;
            }
            r[1][1] = t;
            for (k = 1; k < 4; k++)
                r[2][k] = 0.0;
        }
        return;
    }
    static getQuadRoots(p, r) {
        /*
        Array r[3][5] p[5]
        Roots of poly p[0]*x^2 + p[1]*x + p[2]=0
        x=r[1][k] + i r[2][k] k=1,2
        */
        let b, c, d;
        b = -p[1] / (2.0 * p[0]);
        c = p[2] / p[0];
        d = b * b - c;
        if (d >= 0.0) {
            if (b > 0.0)
                b = (r[1][2] = (Math.sqrt(d) + b));
            else
                b = (r[1][2] = (-Math.sqrt(d) + b));
            r[1][1] = c / b;
            r[2][1] = (r[2][2] = 0.0);
        }
        else {
            d = (r[2][1] = Math.sqrt(-d));
            r[2][2] = -d;
            r[1][1] = (r[1][2] = b);
        }
    }
    static getBiQuadRoots(p, r) {
        /*
        Array r[3][5] p[5]
        Roots of poly p[0]*x^4 + p[1]*x^3 + p[2]*x^2 + p[3]*x + p[4] = 0
        x=r[1][k] + i r[2][k] k=1,...,4
        */
        let a, b, c, d, e;
        let k, j;
        if (p[0] != 1.0) {
            for (k = 1; k < 5; k++)
                p[k] = p[k] / p[0];
            p[0] = 1.0;
        }
        e = 0.25 * p[1];
        b = 2.0 * e;
        c = b * b;
        d = 0.75 * c;
        b = p[3] + b * (c - p[2]);
        a = p[2] - d;
        c = p[4] + e * (e * a - p[3]);
        a = a - d;
        p[1] = 0.5 * a;
        p[2] = (p[1] * p[1] - c) * 0.25;
        p[3] = b * b / (-64.0);
        if (p[3] < 0.0) {
            LTMath.getCubicRoots(p, r);
            for (k = 1; k < 4; k++) {
                if (r[2][k] == 0.0 && r[1][k] > 0.0) {
                    d = r[1][k] * 4.0;
                    a = a + d;
                    if (a >= 0.0 && b >= 0.0)
                        p[1] = Math.sqrt(d);
                    else if (a <= 0.0 && b <= 0.0)
                        p[1] = Math.sqrt(d);
                    else
                        p[1] = -Math.sqrt(d);
                    b = 0.5 * (a + b / p[1]);
                    //goto 
                    p[2] = c / b;
                    LTMath.getQuadRoots(p, r);
                    for (k = 1; k < 3; k++)
                        for (j = 1; j < 3; j++)
                            r[j][k + 2] = r[j][k];
                    p[1] = -p[1];
                    p[2] = b;
                    LTMath.getQuadRoots(p, r);
                    for (k = 1; k < 5; k++)
                        r[1][k] = r[1][k] - e;
                }
            }
            return;
        }
        if (p[2] < 0.0) {
            b = Math.sqrt(c);
            d = b + b - a;
            p[1] = 0.0;
            if (d > 0.0)
                p[1] = Math.sqrt(d);
        }
        else {
            if (p[1] > 0.0)
                b = Math.sqrt(p[2]) * 2.0 + p[1];
            else
                b = -Math.sqrt(p[2]) * 2.0 + p[1];
            if (b != 0.0) {
                p[1] = 0.0;
            }
            else {
                for (k = 1; k < 5; k++) {
                    r[1][k] = -e;
                    r[2][k] = 0.0;
                }
            }
        }
    }
}
exports.LTMath = LTMath;
