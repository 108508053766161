"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LTField = exports.FieldNames = void 0;
//目前字段
exports.FieldNames = {
    EntityLayer: "entitylayer",
    Color: "color",
    Linetype: "linetype",
    EntityType: "entitytype",
    BlockName: "blockname",
    Width: "width",
    Text: "text",
    Rotate: "rotate",
    Info: "info",
    Status: "status",
    Cadgeo: "cadgeo",
    xData: "xData"
};
class LTField {
    constructor(name, aliasName, editable, showable, base = false, extend = false) {
        //英文字段
        this._name = name;
        //中文字段
        this._aliasName = aliasName;
        //可编辑
        this._editable = editable;
        //可显示
        this._showable = showable;
        this._base = base;
        this._xData = extend;
    }
    //能否编辑
    get editable() {
        return this._editable;
    }
    set editable(editable) {
        this._editable = editable;
    }
    //可显示
    get showable() {
        return this._showable;
    }
    set showable(showable) {
        this._showable = showable;
    }
    //可显示
    get base() {
        return this._base;
    }
    set base(base) {
        this._base = base;
    }
    get name() {
        return this._name;
    }
    set name(n) {
        this._name = n;
    }
    get xData() {
        return this._xData;
    }
    set xData(n) {
        this._xData = n;
    }
    //创建个空的
    static createFields() {
        let fields = [];
        fields.push(new LTField(exports.FieldNames.EntityLayer, "图层", true, true, true));
        fields.push(new LTField(exports.FieldNames.Color, "颜色", true, true, true));
        fields.push(new LTField(exports.FieldNames.Linetype, "线型", true, true, true));
        fields.push(new LTField(exports.FieldNames.Width, "宽度", true, true, true));
        fields.push(new LTField(exports.FieldNames.EntityType, "实体类型", false, true, true));
        fields.push(new LTField(exports.FieldNames.BlockName, "块名", true, true));
        fields.push(new LTField(exports.FieldNames.Text, "文本", true, true));
        fields.push(new LTField(exports.FieldNames.Rotate, "旋转", true, true));
        fields.push(new LTField(exports.FieldNames.Info, "符号信息", false, false));
        fields.push(new LTField(exports.FieldNames.Status, "状态信息", false, false));
        fields.push(new LTField(exports.FieldNames.Cadgeo, "符号参考几何", false, false));
        fields.push(new LTField(exports.FieldNames.xData, "扩展信息", false, false, false, true));
        return fields;
    }
}
exports.LTField = LTField;
