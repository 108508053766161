"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRelationshipList = exports.getSprayStrengthList = exports.getActionAreaList = exports.SprayConfigs = exports.SprinklerSettingMethod = exports.SprinklerType = exports.DangerLevel = void 0;
exports.DangerLevel = ["轻危险级", "中危险级I级", "中危险级II级", "严重危险级I级", "严重危险级II级"];
exports.SprinklerType = ["标准覆盖面积洒水喷头", "扩大覆盖面积洒水喷头"];
//喷头设置方式
exports.SprinklerSettingMethod = ["下垂型喷头", "直立型喷头", "吊顶型上下喷"];
exports.SprayConfigs = [
    {
        dangerLevel: "轻危险级", //危险等级
        actionArea: 160, //作用面积
        sprayStrength: 4, //喷水强度
        relationshipList: [
            { pipeDiameter: 25, maxNum: 1 },
            { pipeDiameter: 32, maxNum: 3 },
            { pipeDiameter: 40, maxNum: 5 },
            { pipeDiameter: 50, maxNum: 10 },
            { pipeDiameter: 65, maxNum: 18 },
            { pipeDiameter: 80, maxNum: 48 },
            { pipeDiameter: 100, maxNum: 49 }, //最后一个的maxNum写不写都可以
        ],
    },
    {
        dangerLevel: "中危险级I级",
        actionArea: 160,
        sprayStrength: 8,
        relationshipList: [
            { pipeDiameter: 25, maxNum: 1 },
            { pipeDiameter: 32, maxNum: 3 },
            { pipeDiameter: 40, maxNum: 4 },
            { pipeDiameter: 50, maxNum: 8 },
            { pipeDiameter: 65, maxNum: 12 },
            { pipeDiameter: 80, maxNum: 32 },
            { pipeDiameter: 100, maxNum: 64 },
        ],
    },
    {
        dangerLevel: "中危险级II级",
        actionArea: 160,
        sprayStrength: 8,
        relationshipList: [
            { pipeDiameter: 25, maxNum: 1 },
            { pipeDiameter: 32, maxNum: 3 },
            { pipeDiameter: 40, maxNum: 4 },
            { pipeDiameter: 50, maxNum: 8 },
            { pipeDiameter: 65, maxNum: 12 },
            { pipeDiameter: 80, maxNum: 32 },
            { pipeDiameter: 100, maxNum: 64 },
        ],
    },
    {
        dangerLevel: "严重危险级I级",
        actionArea: 12,
        sprayStrength: 260,
        relationshipList: [
            { pipeDiameter: 25, maxNum: 1 },
            { pipeDiameter: 32, maxNum: 3 },
            { pipeDiameter: 40, maxNum: 4 },
            { pipeDiameter: 50, maxNum: 8 },
            { pipeDiameter: 65, maxNum: 12 },
            { pipeDiameter: 80, maxNum: 32 },
            { pipeDiameter: 100, maxNum: 64 },
            { pipeDiameter: 150, maxNum: 65 },
        ],
    },
    {
        dangerLevel: "严重危险级II级",
        actionArea: 16,
        sprayStrength: 260,
        relationshipList: [
            { pipeDiameter: 25, maxNum: 0 },
            { pipeDiameter: 32, maxNum: 1 },
            { pipeDiameter: 40, maxNum: 2 },
            { pipeDiameter: 50, maxNum: 5 },
            { pipeDiameter: 65, maxNum: 6 },
            { pipeDiameter: 80, maxNum: 10 },
            { pipeDiameter: 100, maxNum: 16 },
            { pipeDiameter: 150, maxNum: 30 },
            { pipeDiameter: 200, maxNum: 31 },
        ],
    },
];
function getActionAreaList(dangerLevel) {
    let actionArea = [];
    for (let config of exports.SprayConfigs) {
        if (config.dangerLevel == dangerLevel) {
            if (actionArea.indexOf(config.actionArea) < 0) {
                actionArea.push(config.actionArea);
            }
        }
    }
    return actionArea;
}
exports.getActionAreaList = getActionAreaList;
function getSprayStrengthList(dangerLevel, actionArea) {
    let strengthList = [];
    for (let config of exports.SprayConfigs) {
        if (config.dangerLevel == dangerLevel && config.actionArea == actionArea) {
            strengthList.push(config.sprayStrength); //这里按理说是不会有重复的
        }
    }
    return strengthList;
}
exports.getSprayStrengthList = getSprayStrengthList;
function getRelationshipList(dangerLevel, actionArea, sprayStrength) {
    for (let config of exports.SprayConfigs) {
        if (config.dangerLevel == dangerLevel && config.actionArea == actionArea && config.sprayStrength == sprayStrength) {
            return config.relationshipList;
        }
    }
    return [];
}
exports.getRelationshipList = getRelationshipList;
