"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValveInfo = void 0;
class ValveInfo {
    constructor(position, angle) {
        this._position = position;
        this._angle = angle;
    }
}
exports.ValveInfo = ValveInfo;
