"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomCommandManager = void 0;
const commandfactory_1 = require("../../cadplugins/map/commandfactory");
class CustomCommandManager {
    constructor() {
        this._command_list = [];
    }
    static getInstance() {
        if (!this._instance) {
            this._instance = new CustomCommandManager();
            commandfactory_1.CommandFactory.getInstance().setCustomKeyFunction(CustomCommandManager.findCommandByKeywordName);
        }
        return this._instance;
    }
    get commandList() {
        if (this._command_list.length == 0) {
            this.initCommand();
        }
        return this._command_list;
    }
    set commandList(command_list) {
        //重新更新下;
        this._command_list = command_list;
        this.initCommand();
    }
    initCommand() {
        let command_list = commandfactory_1.CommandFactory.getInstance().commandList();
        let command_name_list = command_list.keys();
        let result_custom_command = [];
        for (let command_name of command_name_list) {
            let index = this._command_list.findIndex((value, index) => {
                if (value.command_name == command_name) {
                    return true;
                }
                return false;
            });
            if (command_name.startsWith("$")) {
                continue;
            }
            let temp_command = commandfactory_1.CommandFactory.getInstance().getCommand(command_name);
            if (temp_command && temp_command.hasFlag(commandfactory_1.CommandFlag.CommmandNoCommandLine)) {
                continue;
            }
            if (index == -1) {
                let custom_command = {
                    command_name: command_name,
                    simple_command_name: "",
                    keyword_command_name: ""
                };
                result_custom_command.push(custom_command);
            }
            else {
                result_custom_command.push(this._command_list[index]);
            }
        }
        this._command_list = result_custom_command;
        this._command_list.sort((a, b) => {
            return a.command_name.localeCompare(b.command_name);
        });
    }
    toJSON() {
        let custom_command_list = [];
        for (let custom_command of this._command_list) {
            if (custom_command.keyword_command_name.length == 0 || custom_command.simple_command_name.length == 0) {
                continue;
            }
            custom_command_list.push(custom_command);
        }
        return custom_command_list;
    }
    findCommandBySimpleName(command_name) {
        if (this._command_list.length == 0) {
            this.initCommand();
        }
        for (let command of this._command_list) {
            if (command.simple_command_name.toLowerCase() !== command_name.toLowerCase()) {
                continue;
            }
            return command.command_name;
        }
        return command_name;
    }
    static findCommandByKeywordName(key_event) {
        let command_list = CustomCommandManager.getInstance()._command_list;
        let shift_key = '';
        if (key_event.shiftKey) {
            shift_key = "SHIFT";
        }
        else if (key_event.altKey) {
            shift_key = "ALT";
        }
        else if (key_event.ctrlKey) {
            shift_key = "CTRL";
        }
        let key = key_event.key;
        if (key === 'Control' || key === 'Shift' || key === 'Alt') {
            return "";
        }
        if (shift_key.length !== 0) {
            shift_key += '+' + key_event.key;
        }
        else {
            shift_key = key_event.key;
        }
        for (let command of command_list) {
            if (command.keyword_command_name.toLowerCase() !== shift_key.toLowerCase()) {
                continue;
            }
            return command.command_name;
        }
        return "$" + shift_key.toLowerCase();
    }
}
exports.CustomCommandManager = CustomCommandManager;
