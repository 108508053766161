"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PointAttach = void 0;
const tolerance = 15;
class PointAttach {
    constructor() {
        this._attachInfoManager = new AttachInfoManager();
    }
    compute(segs) {
        for (let seg of segs) {
            this._attachInfoManager.addSeg(seg);
        }
        for (let attachInfo of this._attachInfoManager._attachInfos) {
            if (attachInfo._points.length > 1) {
                let templatePoint = attachInfo._points[0];
                for (let i = 1; i < attachInfo._points.length; i++) {
                    let point = attachInfo._points[i];
                    point.x = templatePoint.x;
                    point.y = templatePoint.y;
                }
            }
        }
    }
}
exports.PointAttach = PointAttach;
class AttachInfoManager {
    constructor() {
        this._attachInfos = new Array();
    }
    addSeg(seg) {
        for (let point of seg) {
            this.addPoint(point);
        }
    }
    addPoint(point) {
        let isExisted = false;
        for (let attachInfo of this._attachInfos) {
            if (point.equalsFuzzy(attachInfo._points[0], tolerance)) {
                isExisted = true;
                if (!point.equals(attachInfo._points[0])) {
                    attachInfo._points.push(point);
                }
                break;
            }
        }
        if (!isExisted) {
            this._attachInfos.push(new AttachInfo(point));
        }
    }
}
class AttachInfo {
    constructor(point) {
        this._points = [point];
    }
}
