"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShelveRow = void 0;
const boundingBox_1 = require("../boundingBox");
const Tolerance = 0.1;
class ShelveRow {
    constructor(firstShelve) {
        this._shelves = [firstShelve];
        this._boundingBox = new boundingBox_1.BoundingBox([firstShelve.getBoundingBox().min, firstShelve.getBoundingBox().max]);
        this._centerY = firstShelve.getBoundingBox().getCenter().y;
    }
    addShelve(shelve) {
        this._shelves.push(shelve);
        this._boundingBox.expand(shelve.getBoundingBox().min);
        this._boundingBox.expand(shelve.getBoundingBox().max);
    }
    isBelongToThisRow(y) {
        return Math.abs(y - this._centerY) < Tolerance;
    }
    getBoundingBox() {
        return this._boundingBox;
    }
    getMinX() {
        return this._boundingBox.min.x;
    }
    getMaxX() {
        return this._boundingBox.max.x;
    }
    getMinY() {
        return this._boundingBox.min.y;
    }
    getMaxY() {
        return this._boundingBox.max.y;
    }
    getCenterY() {
        return this._centerY;
    }
}
exports.ShelveRow = ShelveRow;
