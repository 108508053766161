"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawSpline = void 0;
const ltentity_1 = require("../cad/ltentity");
const commandfactory_1 = require("../map/commandfactory");
const drawutils_1 = require("./drawutils");
function* drawSpline(map, command_param) {
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定起点:");
    let start_mouse = yield;
    if (start_mouse.done == commandfactory_1.CommandStatus.eCancel || start_mouse.done == commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: start_mouse.done };
    }
    let controls_points = [];
    let spline = null;
    let track_line = new ltentity_1.LTLine(start_mouse.value, start_mouse.value);
    function drawCallback(mouse) {
        if (controls_points.length > 0) {
            controls_points[controls_points.length - 1] = mouse.mapPos;
        }
        track_line.setStart(controls_points[controls_points.length - 2]);
        track_line.setEnd(mouse.mapPos);
        drawutils_1.DrawUtilImpl.drawDashLine(map, track_line);
        if (controls_points.length <= 2) {
            return;
        }
        if (spline == null) {
            spline = new ltentity_1.LTSpline(controls_points);
            drawutils_1.DrawUtilImpl.trackEntity(map, spline);
        }
        else if (spline !== null) {
            spline.subData().controlPoints = controls_points;
            drawutils_1.DrawUtilImpl.trackEntity(map, spline);
        }
    }
    controls_points.push(start_mouse.value);
    let vertex_index = 1;
    while (start_mouse.done != commandfactory_1.CommandStatus.eDone) {
        let command_button_tip = [];
        if (vertex_index >= 2) {
            command_button_tip.push("闭合(C)");
        }
        else if (vertex_index == 1) {
            command_button_tip.push("放弃(U)");
        }
        let button_tip = command_button_tip.join(' ');
        vertex_index += 1;
        controls_points.push(start_mouse.value);
        commandfactory_1.CommandFactory.getInstance().getPoint(map, `请输入下一个点[${button_tip}]:`, drawCallback);
        start_mouse = yield;
        if (start_mouse.done == commandfactory_1.CommandStatus.eCancel) {
            if (spline != null) {
                drawutils_1.DrawUtilImpl.saveEntity(map, spline);
            }
            drawutils_1.DrawUtilImpl.destroyDashEntity(map, track_line);
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
        if (start_mouse.done == commandfactory_1.CommandStatus.eDone) {
            controls_points.pop();
            break;
        }
        else {
            controls_points[controls_points.length - 1] = start_mouse.value;
        }
    }
    drawutils_1.DrawUtilImpl.destroyDashEntity(map, track_line);
    if (spline && controls_points.length > 2) {
        let temp_spline = spline;
        temp_spline.subData().controlPoints = controls_points;
        drawutils_1.DrawUtilImpl.saveEntity(map, spline);
        drawutils_1.DrawUtilImpl.destroyEntity(map, spline);
    }
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.drawSpline = drawSpline;
