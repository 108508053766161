import { LTDwgServers } from "@/cadplugins/cad/ltdwgserver.js";
import { LTPoint } from "../../cadplugins/cad/ltpoint";
import { CommitFlag } from "@/cadplugins/data/ltfeature";
import { dwg_bylayer } from "../../cadplugins/cad/ltentity";
export const attributeMixin = {
  data() {
    return {
      showPx: false,
      showPy: false,
      lineLength: "",
      point: {
        x: "",
        y: "",
        index: 0
      },
      attributeData: [],
      // 属性数据
      showAttr: false,
      attributeName: "",
      colorArrs: [],
      layer_arrs: [],
      line_arrs: [],
      xk_arrs: [],
      xxbl_arrs: [],
      attributeList: this.returnAttriData(),
      // 几何类型
      geometryArrLength: 0,
      geometryArrIndex: 0,
      geometryArr: [],
      selectGeometrys: [],
      geometryVal: "all",
      showGeometry: false,
      dyxLineDatas: [],
      blockDatas: [],
      circleDatas: [],
      textDatas: [],
      LTHatchDatas: [],
      LTDimensionDatas: [],
      LTEllipseDatas: [],
      LTArcDatas: [],
      REllipseDatas: [],
      lineDatas: [],
      geometryIndex: -1,
      geometryList: [
        // 已验证
        {
          name: "多义线",
          sname: "LTPolyline",
          type: "moreInput",
          show: false,
          value: 0,
          namesL: "顶点",
          list: [],
          length: ""
        },
        // 已验证
        {
          name: "块",
          sname: "LTEntityPoint",
          type: "moreInput",
          show: false,
          value: "",
          list: [
            {
              name: "名称",
              value: "",
              sname: "blockName",
              type: "input",
              typeName: "text",
              disable: true
            },
            {
              name: "x比例",
              value: "",
              sname: "xscale",
              type: "input",
              typeName: "number"
            },
            {
              name: "y比例",
              value: "",
              sname: "yscale",
              type: "input",
              typeName: "number"
            },
            {
              name: "旋转",
              value: "",
              sname: "rotate",
              type: "input",
              typeName: "number"
            },
            {
              name: "x坐标",
              value: "",
              sname: "position.x",
              type: "input",
              typeName: "number"
            },
            {
              name: "y坐标",
              value: "",
              sname: "position.y",
              type: "input",
              typeName: "number"
            }
          ]
        },
        // 已验证
        {
          name: "圆",
          sname: "LTCircle",
          type: "moreInput",
          show: false,
          value: "",
          list: [
            {
              name: "半径",
              value: "",
              sname: "radius",
              type: "input",
              typeName: "number"
            },
            {
              name: "x坐标",
              value: "",
              sname: "center.x",
              type: "input",
              typeName: "number"
            },
            {
              name: "y坐标",
              value: "",
              sname: "center.y",
              type: "input",
              typeName: "number"
            },
            {
              name: "周长",
              value: "",
              disable: true,
              sname: "length",
              type: "input",
              typeName: "number"
            },
            {
              name: "面积",
              value: "",
              disable: true,
              sname: "area",
              type: "input",
              typeName: "number"
            }
          ]
        },
        // 已验证
        {
          name: "文本",
          sname: "LTText",
          type: "moreInput",
          show: false,
          value: "",
          list: [
            {
              name: "内容",
              value: "",
              sname: "text",
              type: "input",
              typeName: "text"
            },
            // 样式字段还没补
            {
              name: "样式",
              value: "",
              sname: "textfont",
              // type: "input",
              // typeName: "text"
              list: [],
              type: "select",
              typeName: ""
            },
            {
              name: "高度",
              value: "",
              sname: "theight",
              type: "input",
              typeName: "number"
            },
            {
              name: "旋转",
              value: "",
              sname: "rotate",
              type: "input",
              typeName: "number"
            },
            {
              name: "x坐标",
              value: "",
              sname: "position.x",
              type: "input",
              typeName: "number"
            },
            {
              name: "y坐标",
              value: "",
              sname: "position.y",
              type: "input",
              typeName: "number"
            }
          ]
        },
        // 已验证
        {
          name: "面",
          sname: "LTHatch",
          type: "moreInput",
          show: false,
          value: "",
          list: [
            {
              name: "图案",
              value: "",
              sname: "_hatchname",
              list: [],
              type: "select",
              typeName: ""
            },
            {
              name: "比例",
              value: "",
              sname: "hscale",
              type: "input",
              typeName: "number"
            }
          ]
        },
        {
          name: "标注",
          sname: "LTDimension",
          type: "moreInput",
          show: false,
          value: "",
          list: [
            {
              name: "箭头1",
              value: "",
              sname: "_arrow1",
              list: [],
              type: "select",
              typeName: ""
            },
            {
              name: "箭头2",
              value: "",
              sname: "_arrow2",
              list: [],
              type: "select",
              typeName: ""
            },
            {
              name: "箭头大小",
              value: "",
              sname: "_arrow_size",
              type: "input",
              typeName: "number"
            },
            {
              name: "标注样式",
              value: "",
              sname: "_style",
              list: [],
              type: "select",
              typeName: ""
            },

            {
              name: "文本大小",
              value: "",
              sname: "rtext._height",
              type: "input",
              typeName: "number"
            },
            {
              name: "标注内容",
              value: "",
              sname: "_rtext._text",
              type: "input",
              typeName: "text"
            },
            {
              name: "文本x坐标",
              value: "",
              sname: "_rtext.position.x",
              type: "input",
              typeName: "number"
            },
            {
              name: "文本y坐标",
              value: "",
              sname: "_rtext.position.y",
              type: "input",
              typeName: "number"
            }
          ]
        },
        // 已验证
        {
          name: "椭圆",
          sname: "LTEllipse",
          type: "moreInput",
          show: false,
          value: "",
          list: [
            {
              name: "圆心x坐标",
              value: "",
              sname: "center.x",
              type: "input",
              typeName: "number"
            },
            {
              name: "圆心y坐标",
              value: "",
              sname: "center.y",
              type: "input",
              typeName: "number"
            },
            {
              name: "主要点x坐标",
              value: "",
              sname: "_majorPoint.x",
              type: "input",
              typeName: "number"
            },
            {
              name: "主要点y坐标",
              value: "",
              sname: "_majorPoint.y",
              type: "input",
              typeName: "number"
            },
            {
              name: "比率",
              value: "",
              sname: "_ratio",
              type: "input",
              typeName: "number"
            },
            {
              name: "起始角度",
              value: "",
              sname: "_startParam",
              type: "input",
              typeName: "number"
            },
            {
              name: "结束角度",
              value: "",
              sname: "_endParam",
              type: "input",
              typeName: "number"
            }
          ]
        },
        // 已验证
        {
          name: "直线",
          sname: "LTLine",
          type: "moreInput",
          show: false,
          value: "",
          list: [
            {
              name: "起点X坐标",
              value: "",
              sname: "_start_point.x",
              type: "input",
              typeName: "number"
            },
            {
              name: "起点Y坐标",
              value: "",
              sname: "_start_point.y",
              type: "input",
              typeName: "number"
            },
            {
              name: "终点X坐标",
              value: "",
              sname: "_end_point.x",
              type: "input",
              typeName: "number"
            },
            {
              name: "终点Y坐标",
              value: "",
              sname: "_end_point.y",
              type: "input",
              typeName: "number"
            },

            {
              name: "长度",
              value: "",
              sname: "length",
              type: "input",
              typeName: "number"
            }
          ]
        },
        // 已验证
        {
          name: "弧",
          sname: "LTArc",
          type: "moreInput",
          show: false,
          value: "",
          list: [
            {
              name: "中心点x坐标",
              value: "",
              sname: "center.x",
              type: "input",
              typeName: "number"
            },
            {
              name: "中心点y坐标",
              value: "",
              sname: "center.y",
              type: "input",
              typeName: "number"
            },
            {
              name: "半径",
              value: "",
              sname: "radius",
              type: "input",
              typeName: "number"
            },
            {
              name: "起始角度",
              value: "",
              sname: "_start_angle",
              type: "input",
              typeName: "number"
            },
            {
              name: "结束角度",
              value: "",
              sname: "_end_angle",
              type: "input",
              typeName: "number"
            },
            {
              name: "弧长",
              value: "",
              sname: "_arcLength",
              type: "input",
              disable: true,
              typeName: "number"
            }
          ]
        },
        {
          name: "椭圆弧",
          sname: "REllipse",
          type: "moreInput",
          show: false,
          value: "",
          list: [
            {
              name: "中心x坐标",
              value: "",
              sname: "center.x",
              type: "input",
              typeName: "number"
            },
            {
              name: "中心y坐标",
              value: "",
              sname: "center.y",
              type: "input",
              typeName: "number"
            },
            {
              name: "主要点x坐标",
              value: "",
              sname: "_majorPoint.x",
              type: "input",
              typeName: "number"
            },
            {
              name: "主要点y坐标",
              value: "",
              sname: "_majorPoint.y",
              type: "input",
              typeName: "number"
            },
            {
              name: "短袖比例",
              value: "",
              sname: "_ratio",
              type: "input",
              typeName: "number"
            },
            {
              name: "起点参数",
              value: "",
              sname: "_startParam",
              type: "input",
              typeName: "number"
            },
            {
              name: "终点参数",
              value: "",
              sname: "_endParam",
              type: "input",
              typeName: "number"
            }
          ]
        }
      ],
      showColor: false,
      showJhColor: false,
      cgtcColor: null,
      sxColor: null,
      // 标注
      showArrow: false,
      arrrowLists: [],
      arrowList: [],
      arrowData: "",
      arrowIndex: -1,
      textStyleList: []
    };
  },
  computed: {},
  methods: {
    // 属性操作方法
    // 属性数据初始化
    attrManagement(data) {
      if (this.pixiMap.select_command._select_features.length == 0) {
        this.$message({
          message: "请先选择实体!!!",
          type: "warning"
        });
        return;
      }
      data.isSelected = !data.isSelected;
      let index = this.returnCardIndex(data.sname);
      if (data.isSelected) {
        this.setTypeVal("图层");
        this.isDrawName = data.sname;
        this.setAttriData();
        if (index == -1)
          this.cardList.push({
            name: data.sname
          });
      } else {
        this.cardList.splice(index, 1);
        if (this.cardList.length > 0) {
          let item = this.cardList[0];
          this.isDrawName = item.name;
          this.setTypeVal(item.name, true);
        }
      }
      this.changeMapSize();
    },
    returnMore() {
      return "*多种*";
    },
    setAttriData() {
      if (window.attriDataCallback == null) {
        window.attriDataCallback = this.setAttriData;
      }
      this.geometryArrLength = 0;
      this.geometryArrIndex = 0;
      this.geometryArr = [
        {
          name: "全部",
          sType: "全部",
          sname: "all",
          value: 0
        },
        {
          name: "多义线",
          sType: "多义线",
          sname: "LTPolyline",
          type: "dyxLineDatas",
          value: 0
        },
        {
          name: "块",
          sType: "块",
          sname: "LTEntityPoint",
          type: "blockDatas",
          value: 0
        },
        {
          name: "圆",
          sType: "圆",
          sname: "LTCircle",
          type: "circleDatas",
          value: 0
        },
        {
          name: "文本",
          sType: "文本",
          sname: "LTText",
          type: "textDatas",
          value: 0
        },
        {
          name: "面",
          sType: "面",
          sname: "LTHatch",
          type: "LTHatchDatas",
          value: 0
        },
        {
          name: "标注",
          sType: "标注",
          sname: "LTDimension",
          type: "LTDimensionDatas",
          value: 0
        },
        {
          name: "椭圆",
          sType: "椭圆",
          sname: "LTEllipse",
          type: "LTEllipseDatas",
          value: 0
        },
        {
          name: "直线",
          sType: "直线",
          sname: "LTLine",
          type: "lineDatas",
          value: 0
        },
        {
          name: "弧",
          sType: "弧",
          sname: "LTArc",
          type: "LTArcDatas",
          value: 0
        },
        {
          name: "椭圆弧",
          sType: "椭圆弧",
          sname: "REllipse",
          type: "REllipseDatas",
          value: 0
        }
      ];
      this.selectGeometrys = [];
      this.arrowLists = [];
      this.dyxLineDatas = [];
      this.blockDatas = [];
      this.circleDatas = [];
      this.textDatas = [];
      this.LTHatchDatas = [];
      this.LTDimensionDatas = [];
      this.LTEllipseDatas = [];
      this.LTArcDatas = [];
      this.REllipseDatas = [];
      this.lineDatas = [];
      this.attributeList = this.returnAttriData();
      this.colorArrs = [];
      this.layer_arrs = [];
      this.line_arrs = [];
      this.xk_arrs = [];
      this.xxbl_arrs = [];
      this.pixiMap.getLayers().map(item => {
        this.attributeList[1].list.push(item._name);
      });
      this.attributeList[2].list = this.pixiMap.dwgservice.getLinetypesName();
      let features = this.pixiMap.select_command._select_features;
      if (features && features.length > 0) {
        this.featureDatas = features;
        this.attri_feature = features[0];
        let selectData = this.attri_feature._layer;
        this.attributeName = selectData._name;
        this.attributeData = [];
        this.attri_feature._layer._fields.map(ele => {
          if (ele._showable) {
            let attri_colorInfo = this.getColorVal(selectData._colorInfo);
            this.attributeData.push({
              _name: ele._name,
              color: attri_colorInfo.value,
              colorIndex: attri_colorInfo.index,
              value: this.attri_feature.getValue(ele._name),
              isEdit: ele._editable
            });
          }
        });
        features.map(item => {
          let it_geometry = item._geometry;
          this.colorArrs.push(this.getFeatureColorVal(item._geometry));
          this.layer_arrs.push(it_geometry._layer || "");
          this.line_arrs.push(item.getValue("linetype") || "");
          this.xk_arrs.push(item.getValue("width") || "");
          this.xxbl_arrs.push(item._geometry.getLineTypeScale() || "");
          if (this.__geoType__(it_geometry).indexOf("LTEntityPoint") > -1) {
            this.blockDatas.push(it_geometry);
          } else if (this.__geoType__(it_geometry).indexOf("LTCircle") > -1) {
            this.circleDatas.push(it_geometry);
          } else if (this.__geoType__(it_geometry).indexOf("LTText") > -1) {
            this.textDatas.push(it_geometry);
          } else if (this.__geoType__(it_geometry).indexOf("LTHatch") > -1) {
            this.LTHatchDatas.push(it_geometry);
          } else if (
            this.__geoType__(it_geometry).indexOf("LTDimension") > -1
          ) {
            this.LTDimensionDatas.push(it_geometry);
          } else if (
            this.__geoType__(it_geometry).indexOf("LTEllipse") > -1 &&
            it_geometry.subData().isFullEllipse()
          ) {
            this.LTEllipseDatas.push(it_geometry);
          } else if (
            this.__geoType__(it_geometry).indexOf("LTEllipse") > -1 &&
            !it_geometry.subData().isFullEllipse()
          ) {
            this.REllipseDatas.push(it_geometry);
          } else if (this.__geoType__(it_geometry).indexOf("LTLine") > -1) {
            this.lineDatas.push(it_geometry);
          } else if (this.__geoType__(it_geometry).indexOf("LTPolyline") > -1) {
            this.dyxLineDatas.push(it_geometry);
          } else if (this.__geoType__(it_geometry).indexOf("LTArc") > -1) {
            this.LTArcDatas.push(it_geometry);
          }
        });
        let activeIndex = 0;
        this.geometryArr.map((ele, index) => {
          if (index > 0 && this[ele.type].length > 0) {
            let length = this[ele.type].length;
            ele.name = `${ele.name}(${length})`;
            ele.value = length;
            this.geometryArrLength++;
            activeIndex = index;
            this.selectGeometrys.push(ele);
          }
          return ele;
        });
        if (this.geometryArrLength > 1) {
          this.geometryArr[0].name = `${this.geometryArr[0].name}(${this.geometryArrLength})`;
          this.geometryArr[0].value = this.geometryArrLength;
          this.geometryVal = "all";
          this.selectGeometrys.unshift(this.geometryArr[0]);
        } else if (this.geometryArrLength == 1) {
          this.geometryArrIndex = activeIndex;
          this.geometryVal = this.geometryArr[activeIndex].sname;
        }
        this.attributeList.map(attrs => {
          if (attrs.stype == "colorArrs") {
            attrs.value = this.$getRepeatColorArr(this.colorArrs).value;
            attrs.val = this.$getRepeatColorArr(this.colorArrs).color;
            attrs.index = this.$getRepeatColorArr(this.colorArrs).index;
          } else attrs.value = this.$getRepeatArr(this[attrs.stype]);
          return attrs;
        });

        console.log(
          "数据",
          this.colorArrs,
          this.attributeList,
          activeIndex,
          this.geometryVal,
          this.geometryArr
        );
      }
    },
    getAttriButeLength() {},
    // 切换属性类型
    selectGeoArr() {
      if (this.geometryVal == "all") {
        this.geometryArrIndex = 0;
        return;
      } else {
        this.geometryArr.some((it, index) => {
          if (it.sname == this.geometryVal) {
            this.geometryArrIndex = index;
            return true;
          }
        });
      }
      console.log(
        "selectGeoArr",
        this.geometryArrIndex,
        this.geometryVal,
        this.geometryArr
      );
    },

    setArrowValue(data) {
      let features = this.pixiMap.select_command._select_features;
      let sname = "";
      if (this.geometryArrIndex < 1) {
        return;
      } else {
        sname = this.geometryArr[this.geometryArrIndex].sname;
      }
      features.map(item => {
        if (this.__geoType__(item._geometry).indexOf(sname) > -1) {
          if (data.sname == "_arrow1")
            item
              .getGeometry()
              .subData()
              .setBlock1(data.value);
          else if (data.sname == "_arrow2")
            item
              .getGeometry()
              .subData()
              .setBlock2(data.value);
          else if (data.sname == "_arrow_size") {
            item
              .getGeometry()
              .subData()
              .setDimBlockSacle(data.value);
          }
        }
        item.commit(3, true);
        this.pixiMap.updateFeatureGraph(item);
      });

      this.pixiMap.select_command.render();
    },
    // 双击显示表单
    onShowInput(item) {
      if (item && item.disable) return;
      if (!item.showInput) {
        this.$set(item, "showInput", true);
        this.$nextTick(() => {
          this.$refs.input[0].focus();
        });
      } else item.showInput = false;
    },
    // 表单entry
    entryInput(ele, show = true) {
      if (show) ele.showInput = false;
      var features = this.pixiMap.select_command._select_features;
      let isall = false;
      let sname = "";
      if (this.geometryArrIndex < 1) {
        isall = true;
      } else {
        sname = this.geometryArr[this.geometryArrIndex].sname;
      }
      if (sname == "REllipse") sname = "LTEllipse";

      features.forEach(f => {
        if (ele.sname == "_arrow_size") {
          f.getGeometry()
            .subData()
            .setDimBlockSacle(ele.value);
          console.log("_arrow_size", ele.value);
          this.pixiMap.updateFeatureGraph(f);

          return;
        }
        if (isall || this.__geoType__(f._geometry).indexOf(sname) > -1) {
          this.editgeometry(
            f._geometry,
            ele,
            this.geometryArr[this.geometryArrIndex].sType
          );
          //this.pixiMap.updateFeature(this.attri_feature, feature_clone);
          f.commit(3, true);
          this.pixiMap.updateFeatureGraph(f);
        }
      });
      this.pixiMap.select_command.render();
      //保存
      //this.pixiMap.render();
      //this.pixiMap.select_command.render();
      //this.pixiMap.easyedit_command.clearRender();
    },

    // 线-双击显示表单
    onShowPoint(type = "", inputName = "") {
      if (!this[type]) {
        this[type] = true;
        this.$nextTick(() => {
          this.$refs[inputName].focus();
        });
      } else this[type] = false;
    },
    editgeometry(geometry, val, name) {
      if (name === "多义线") {
        geometry._entity_data._vertices[val.index] = new LTPoint(
          val.x * 1,
          val.y * 1
        );
      } else {
        this.setGeometryAttr(val.sname, geometry, val.value);
      }
    },
    // 图层确定方法
    editAttr(val, type) {
      this[type] = false;
      if (this.geometryArrIndex > 0) {
        //判断id
        var features = this.pixiMap.select_command._select_features;
        let isall = false;
        let sname = "";
        if (this.geometryArrIndex < 1) {
          isall = true;
        } else {
          sname = this.geometryArr[this.geometryArrIndex].sname;
        }
        features.forEach(f => {
          if (isall || this.__geoType__(f._geometry).indexOf(sname) > -1) {
            //e .setValue(fieldname, value);
            this.editgeometry(
              f._geometry,
              val,
              this.geometryArr[this.geometryArrIndex].sType
            );
            //this.pixiMap.updateFeature(this.attri_feature, feature_clone);
            f.commit(3, true);
            this.pixiMap.updateFeatureGraph(f);
          }
        });
        this.pixiMap.select_command.render();
      }

      //   // this.attri_feature.commit(CommitFlag.Update);
      //   this.pixiMap.updateFeature(this.attri_feature, feature_clone);
      //   //保存
      //   this.pixiMap.select_command.render();
      //   this.pixiMap.easyedit_command.clearRender();
      // }
    },
    // 常规属性输入框修改
    setCommValue(ele, show = true) {
      if (show) ele.showInput = false;
      if (ele.sname == "linetypescale") {
        this.pixiMap.select_command._select_features.map(e => {
          e._geometry.setLineTypeScale(ele.value);
          e.commit(3, true);
          this.pixiMap.updateFeatureGraph(e);
        });
        this.pixiMap.select_command.render();
      } else this.setConventionData(ele);
    },
    // 常规属性修改
    setConventionData(ele) {
      this._setConventionData(ele.sname, ele.value, ele.index);
    },
    // 底层属性修改方法
    _setConventionData(fieldname, value, index = -1) {
      var features = this.pixiMap.select_command._select_features;
      let isall = false;
      let sname = "";
      if (this.geometryArrIndex < 1) {
        isall = true;
      } else {
        sname = this.geometryArr[this.geometryArrIndex].sname;
      }
      features.forEach(e => {
        if (isall || this.__geoType__(e._geometry).indexOf(sname) > -1) {
          if (fieldname == "color") {
            e._geometry._colorInfo.color = value;
            e._geometry._colorInfo.index = index;
            e._geometry._colorInfo.aci = true;
          } else {
            e.setValue(fieldname, value);
          }

          e.commit(3, true);
          this.pixiMap.updateFeatureGraph(e);
        }
      });
      this.pixiMap.select_command.render();
    },
    // 规定typeName为number表单只能输入数字、小数点和负号
    checkGreaterZero(value, type) {
      if (type == "number") value = value.replace(/[^\-?\d.]/g, "");
      // value
      //   .replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, "$1$2$3") // 只能输入数字、小数点限制3位
      //   .replace(/^0+(\d)/, "$1") // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      //   .replace(/^\./, "0.") // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
      //   .match(/^\d*(\.?\d{0,3})/g)[0] || ""; // 数字开头、小数点3位
      return value;
    },
    // 几何图形唤起颜色选择
    changeShow(type = "showGeometry") {
      this[type] = !this[type];
    },
    // 常规颜色确定
    callBackCgColor(val, data) {
      this.setConventionData(val);
    },
    // 快速选择颜色确定
    callBackKsxzColor(val, select) {
      this.selectionData.list[4].value = val.val;
      this.selectionData.list[4].index = val.index;
    },
    // 获取顶点数据
    getPoints(geometryData) {
      let points = [];
      geometryData.list.forEach(p => {
        let ps = new LTPoint(p.x * 1, p.y * 1);
        points.push(ps);
      });
      return points;
    },
    // 返回属性对应的list
    returnTypeList(type) {
      let data = [];
      this.geometryList.some(item => {
        if (item.sname == type) {
          data = item.list;
          return true;
        }
      });
      return data;
    },
    // 判断属性的类型
    __typeof__(objClass) {
      if (objClass && objClass.constructor) {
        var strFun = objClass.constructor.toString();
        var className = strFun.substr(0, strFun.indexOf("("));
        className = className.replace(" function ", "");
        return className.replace(/(^\/s*)| (\/s * $)/gi, "");
      }
      return typeof objClass;
    },
    __geoType__(geometry) {
      return Object.prototype.toString.call(geometry).slice(8, -1);
    },
    // 返回属性类型属性字段value值
    returnTypeName(name, geometry) {
      let value = "";
      if (!geometry || !geometry._entity_data) return "";
      switch (name) {
        case "text":
          value = geometry._entity_data._text || "";
          break;
        case "textfont":
          value = geometry._entity_data._fontname || "";
          break;
        case "theight":
          value = geometry._entity_data._textheight || "";
          break;
        case "rotate":
          value =
            Math.round((geometry._entity_data._rotate * 180) / Math.PI) || 0;
          break;
        case "position.x":
          value = geometry._entity_data.position.x || "";
          break;
        case "position.y":
          value = geometry._entity_data.position.y || "";
          break;
        case "radius":
          value = geometry._entity_data._radius || "";
          break;
        case "center.x":
          value = geometry._entity_data._center.x || "";
          break;
        case "center.y":
          value = geometry._entity_data._center.y || "";
          break;
        case "scale":
          value = geometry._entity_data._scale || "";
          break;
        case "xscale":
          value = geometry._entity_data.scaleFactors.x || 0;
          break;
        case "yscale":
          value = geometry._entity_data.scaleFactors.y || 0;
          break;
        case "color":
          value = geometry._entity_data._color || "";
          break;
        case "_color":
          value = geometry._color || "";
          break;
        case "rtext._height":
          value = geometry._entity_data._rtext._textheight || "";
          break;
        case "_rtext.position.x":
          value = geometry._entity_data._rtext.position.x || "";
          break;
        case "_rtext.position.y":
          value = geometry._entity_data._rtext.position.y || "";
          break;
        case "_rtext._text":
          value = geometry._entity_data._rtext._text || "";
          break;

        case "columnCount":
          value = geometry._entity_data.columnCount || 0;
          break;
        case "columnSpacing":
          value = geometry._entity_data.columnSpacing || 0;
          break;
        case "rowCount":
          value = geometry._entity_data.rowCount || 0;
          break;
        case "rowSpacing":
          value = geometry._entity_data.rowSpacing || 0;
          break;
        case "_majorPoint.x":
          value = geometry._entity_data._majorPoint.x || 0;
          break;
        case "_majorPoint.y":
          value = geometry._entity_data._majorPoint.y || 0;
          break;
        case "_ratio":
          value = geometry._entity_data._ratio || 0;
          break;
        case "_style":
          value = geometry._entity_data._style || "";
          break;
        case "_startParam":
          value =
            Math.round((geometry._entity_data._startParam * 180) / Math.PI) ||
            0;
          break;
        case "_endParam":
          value =
            Math.round((geometry._entity_data._endParam * 180) / Math.PI) || 0;
          break;
        case "_start_angle":
          value =
            Math.round((geometry._entity_data._start_angle * 180) / Math.PI) ||
            0;
          break;
        case "_end_angle":
          value =
            Math.round((geometry._entity_data._end_angle * 180) / Math.PI) || 0;
          break;
        case "center.x":
          value = geometry._entity_data._center.x || "";
          break;
        case "center.y":
          value = geometry._entity_data._center.y || "";
          break;
        case "_start_point.x":
          value = geometry._entity_data._start_point.x || "";
          break;
        case "_start_point.y":
          value = geometry._entity_data._start_point.y || "";
          break;
        case "_end_point.x":
          value = geometry._entity_data._end_point.x || "";
          break;
        case "_end_point.y":
          value = geometry._entity_data._end_point.y || "";
          break;
        case "_arcLength":
          value = geometry._entity_data.getAngleLength()
            ? Number(geometry._entity_data.getAngleLength()).toFixed(2)
            : "";
          break;
        case "blockName":
          value = geometry._entity_data.blockName || "";
      }
      return value;
    },
    // 设置属性类型字段value
    setGeometryAttr(name, geometry, value) {
      if (!geometry || !geometry._entity_data) return "";
      switch (name) {
        case "text":
          geometry._entity_data._text = value;
          break;
        case "textfont":
          geometry._entity_data._fontname = value;
          break;
        case "height":
          geometry._entity_data._height = value * 1;
          break;
        case "rotate":
          geometry.subData().setRotate((value * 1 * Math.PI) / 180);
          break;
        case "position.x":
          geometry._entity_data.position.x = value * 1;
          break;
        case "position.y":
          geometry._entity_data.position.y = value * 1;
          break;
        case "radius":
          geometry._entity_data._radius = value * 1;
          break;
        case "center.x":
          geometry._entity_data._center.x = value * 1;
          break;
        case "center.y":
          geometry._entity_data._center.y = value * 1;
          break;
        case "scale":
          geometry._entity_data._scale = value * 1;
          break;
        case "xscale":
          geometry._entity_data.scaleFactors.x = value * 1;
          break;
        case "yscale":
          geometry._entity_data.scaleFactors.y = value * 1;
          break;
        case "color":
          geometry._entity_data._color = value;
          break;
        case "_color":
          geometry._color = value;
          break;
        case "rtext._height":
          geometry._entity_data._rtext._textheight = value * 1;
          break;
        case "_rtext.position.x":
          geometry._entity_data._rtext.position.x = value * 1;
          break;
        case "_rtext.position.y":
          geometry._entity_data._rtext.position.y = value * 1;
          break;
        case "columnCount":
          geometry._entity_data.columnCount = value * 1;
          break;
        case "columnSpacing":
          geometry._entity_data.columnSpacing = value * 1;
          break;
        case "rowCount":
          geometry._entity_data.rowCount = value * 1;
          break;
        case "rowSpacing":
          geometry._entity_data.rowSpacing = value * 1;
          break;
        case "_start_point.x":
          geometry._entity_data._start_point.x = value * 1;
          break;
        case "_start_point.y":
          geometry._entity_data._start_point.y = value * 1;
          break;
        case "_end_point.x":
          geometry._entity_data._end_point.x = value * 1;
          break;
        case "_end_point.y":
          geometry._entity_data._end_point.y = value * 1;
          break;
        case "_majorPoint.x":
          geometry._entity_data._majorPoint.x = value * 1;
          geometry._entity_data.correctMajorMinor();
          break;
        case "_majorPoint.y":
          geometry._entity_data._majorPoint.y = value * 1;
          geometry._entity_data.correctMajorMinor();
          break;
        case "_ratio":
          geometry._entity_data._ratio = value * 1;
          break;
        case "_startParam":
          geometry._entity_data._startParam = (value * 1 * Math.PI) / 180;
          break;
        case "_endParam":
          geometry._entity_data._endParam = (value * 1 * Math.PI) / 180;
          break;
        case "_start_angle":
          geometry._entity_data._start_angle = (value * 1 * Math.PI) / 180;
          break;
        case "_end_angle":
          geometry._entity_data._end_angle = (value * 1 * Math.PI) / 180;
          break;
        case "text":
          geometry._entity_data._text = value * 1;
          geometry._entity_data._dirty = true;
          break;
        case "textfont":
          // geometry._entity_data._startParam = (value * 1 * Math.PI) / 180;
          break;
        case "theight":
          if (value * 1 > 0) {
            geometry._entity_data._textheight = value * 1;
            geometry._entity_data._dirty = true;
          }
          break;
        case "_hatchname":
          geometry._entity_data._hatchname = value;
          geometry._entity_data._pattern = null;
          break;
        case "hscale":
          geometry._entity_data._patternScale = value * 1;
          break;
        case "_style":
          geometry._entity_data.setDimStyle(value, true);
          break;
        case "_rtext._text":
          geometry._entity_data._rtext.text = value;
          break;

        default:
          value = "";
          break;
      }
    },
    // 其他js方法
    isFunction(obj) {
      return typeof obj === "function";
    },
    isString(obj) {
      return typeof obj === "string";
    },
    color16() {
      //十六进制颜色随机
      return (
        "#" +
        Math.random()
          .toString(16)
          .substr(2, 6)
      );
    },
    // 16进制转rgba
    getred(color) {
      const red = (color & 0xff0000) >> 16;
      return red;
    },
    getgreen(color) {
      const green = (color & 0x00ff00) >> 8;
      return green;
    },
    getblue(color) {
      const blue = color & 0x0000ff;
      return blue;
    },
    Rgb2Hex(color) {
      // const r = this.getred(color);
      // const g = this.getgreen(color);
      // const b = this.getblue(color);
      // const hex =
      //   "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
      let hex = color.replace(/0x/gi, "#");
      return hex;
    },
    returnAttriData() {
      return [
        {
          name: "颜色",
          sname: "color",
          type: "color",
          typeName: "color",
          value: "#fff",
          val: dwg_bylayer,
          index: -1,
          types: "常规",
          stype: "colorArrs"
        },
        {
          name: "图层",
          sname: "entitylayer",
          type: "select",
          typeName: "",
          list: [],
          value: "",
          types: "常规",
          stype: "layer_arrs"
        },
        {
          name: "线型",
          sname: "linetype",
          type: "select",
          typeName: "",
          list: [],
          value: "",
          types: "常规",
          stype: "line_arrs"
        },
        {
          name: "线宽",
          sname: "width",
          type: "input",
          typeName: "number",
          disable: false,
          value: "",
          types: "常规",
          stype: "xk_arrs"
        },
        {
          name: "线型比例",
          sname: "linetypescale",
          type: "input",
          typeName: "number",
          disable: false,
          value: "",
          types: "常规",
          stype: "xxbl_arrs"
        }
        // {
        //   name: "直线长度",
        //   sname: "length",
        //   type: "input",
        //   typeName: "number",
        //   disable: false,
        //   value: "",
        //   types: "常规"
        // }
      ];
    }
  }
};
