"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.qSelectCommand = void 0;
const ltentity_1 = require("../cad/ltentity");
const ltfields_1 = require("../data/ltfields");
const commandfactory_1 = require("../map/commandfactory");
class SelectCommandImpl {
    sure(cadmap, form) {
        //快速选择
        if (form == undefined || form == null) {
            return commandfactory_1.CommandStatus.eCancel;
        }
        let application = form.application;
        let additional = form.additional;
        let entityType, attributeType, oper_key, oper_value;
        entityType = form.dxType;
        attributeType = form.tx;
        oper_key = form.ysf;
        if (form.tx == "color") {
            oper_value = form._colorInfo.color;
        }
        else {
            oper_value = form.value;
        }
        let features = [];
        //附加到当前选择集
        if (additional) {
            features = cadmap.select_command.selectedFeatures();
        }
        //排除当前的选择集合
        if (!application) {
            let feas = [];
            features.forEach(feature => {
                if (!this.conforms(feature, entityType, attributeType, oper_key, oper_value)) {
                    feas.push(feature);
                }
            });
            features = feas;
        }
        else {
            cadmap.getLayers().forEach(layer => {
                layer.getFeatures(null).forEach(feature => {
                    //判断类型
                    if (this.conforms(feature, entityType, attributeType, oper_key, oper_value)) {
                        if (features.indexOf(feature) === -1) {
                            features.push(feature);
                        }
                    }
                });
            });
        }
        cadmap.select_command.addSelectFeatures(features);
        cadmap.select_command.show();
        if (window.attriDataCallback != undefined) {
            window.attriDataCallback();
        }
        return commandfactory_1.CommandStatus.eDone;
    }
    conforms(feature, entityType, attributeType, oper_key, oper_value) {
        var _a;
        if (entityType !== "all") {
            if (entityType !== feature.getGeometry().data.getShapeName()) {
                return;
            }
        }
        //全部选择
        if (oper_key === "all") {
            return true;
        }
        let value = null;
        if (attributeType === ltfields_1.FieldNames.Color) {
            value = feature.getGeometry().getColor().toLowerCase();
            oper_value = oper_value.toLowerCase();
        }
        else {
            //特殊
            let geo = feature.getGeometry();
            if (geo instanceof ltentity_1.LTText) {
                if (attributeType == "textfont") {
                    value = geo.subData().fontName;
                }
                else if (attributeType == "theight") {
                    value = Number(geo.subData().height);
                }
                else if (attributeType == "rotate") {
                    value = Number(geo.subData().getRotate());
                }
                else if (attributeType == "content") {
                    value = geo.subData().text;
                }
            }
            else if (geo instanceof ltentity_1.LTDimension) {
                if (attributeType == "_style") {
                    if (geo.subData()._style !== null) {
                        value = (_a = geo.subData()._style) === null || _a === void 0 ? void 0 : _a.name;
                    }
                }
            }
            if (value === null) {
                value = feature.getValue(attributeType);
            }
        }
        if (typeof value !== 'string') {
            oper_value = Number(oper_value);
        }
        else if (typeof value === 'string') {
            value = value.toLowerCase();
            oper_value = oper_value.toString().toLowerCase();
        }
        if (oper_key === "≠") {
            //判断特性
            if (oper_value !== value) {
                return true;
            }
        }
        else if (oper_key === "=") {
            if (oper_value === value) {
                return true;
            }
        }
        else if (oper_key === ">") {
            if (value > oper_value) {
                return true;
            }
        }
        else if (oper_key === "<") {
            if (value < oper_value) {
                return true;
            }
        }
        return false;
    }
    destroy() {
    }
}
function* qSelectCommand(map, command_param) {
    let impl = new SelectCommandImpl();
    impl.sure(map, command_param);
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.qSelectCommand = qSelectCommand;
