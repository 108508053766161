"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteCommand = void 0;
const commandfactory_1 = require("../map/commandfactory");
function* deleteCommand(map, param_map) {
    map.removeFeatures(map.selectedFeatures());
    map.select_command.clearSelection();
    return { value: null, done: commandfactory_1.CommandStatus.eOK };
}
exports.deleteCommand = deleteCommand;
