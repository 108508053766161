"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LTFeature = exports.CommitFlag = void 0;
const ltentity_1 = require("../cad/ltentity");
const ltutils_1 = require("../cad/ltutils");
const ltfields_1 = require("./ltfields");
var CommitFlag;
(function (CommitFlag) {
    CommitFlag[CommitFlag["Add"] = 1] = "Add";
    CommitFlag[CommitFlag["Delete"] = 2] = "Delete";
    CommitFlag[CommitFlag["Update"] = 3] = "Update";
    CommitFlag[CommitFlag["Nothing"] = 4] = "Nothing";
    CommitFlag[CommitFlag["DeleteOnline"] = 5] = "DeleteOnline";
})(CommitFlag || (exports.CommitFlag = CommitFlag = {}));
;
class LTFeature {
    constructor(options) {
        this._xdata = {};
        this._id = options.id;
        if (options.properties == undefined) {
            this._properties = [];
        }
        else {
            this._properties = options.properties;
        }
        if (options.xdata === undefined) {
            this._xdata = {};
        }
        else {
            try {
                this._xdata = JSON.parse(options.xdata);
            }
            catch (error) {
                let am = 0;
            }
        }
        //状态 增删改
        this.state = options.status;
        //状态 保持服务器的状态 既不进行更新
        this.keepstatus = false;
        if (typeof options.layer == "string") {
            let layer = window.cadmap.getLayerByName(options.layer);
            if (layer !== null) {
                this._layer = layer;
            }
            else {
                this._layer = options.layer;
            }
        }
        else {
            this._layer = options.layer;
        }
        this._item = null;
        if (options.geometry instanceof ltentity_1.LTEntity) {
            this._geometry = options.geometry;
        }
        else {
            let temp_geometry = ltutils_1.LTUtils.FeatureToEntity(options.geometry, this);
            if (temp_geometry == null) {
                this._geometry = {};
            }
            else {
                this._geometry = temp_geometry;
            }
        }
        if (this._layer !== null && this._layer != undefined && Object.keys(this._geometry).length !== 0) {
            this._geometry.setLayerName(this._layer.name);
            if (this._properties[ltfields_1.FieldNames.EntityLayer] == undefined) {
                this._properties[ltfields_1.FieldNames.EntityLayer] = this._layer.name;
            }
        }
    }
    valid() {
        if (Object.keys(this.getGeometry()).length === 0) {
            return false;
        }
        return true;
    }
    get item() {
        return this._item;
    }
    set item(m) {
        this._item = m;
    }
    get layer() {
        return this._layer;
    }
    set layer(layer) {
        this._layer = layer;
    }
    _updateState(state) {
        if (this.state == state) {
            return;
        }
        //增加保持增加状态
        if (this.state == CommitFlag.Add && state == CommitFlag.Update) {
            return;
        }
        this.state = state;
    }
    commit(status, isupdateRBush = true) {
        if (this._geometry === null) {
            return;
        }
        if (this._layer == null) {
            let mm = 0;
        }
        this._updateState(status);
        if (status == CommitFlag.Add) {
            if (isupdateRBush == true) {
                let rbox = this._geometry.getBoundingBox();
                let min_point = rbox.getMinimum();
                let max_point = rbox.getMaximum();
                this._item = { minX: min_point.x, minY: min_point.y, maxX: max_point.x, maxY: max_point.y, feature: this };
            }
            else {
                this._item = { minX: 0, minY: 0, maxX: 0, maxY: 0, feature: this };
            }
            this._layer.addFeature(this, this._item, isupdateRBush);
            return;
        }
        else if (status == CommitFlag.Delete) {
            this._layer.removeFeature(this);
        }
        else {
            //当前的直接更新才行，没有历史数据。
            let rbox1 = this._geometry.getBoundingBox();
            let min_point = rbox1.getMinimum();
            let max_point = rbox1.getMaximum();
            let item = { minX: min_point.x, minY: min_point.y, maxX: max_point.x, maxY: max_point.y, feature: this };
            this._layer.updateFeature(this, item);
        }
    }
    getValue(name) {
        if (name == ltfields_1.FieldNames.EntityType) {
            if (this._properties[name] === undefined || this._properties[name] === null) {
                return this._geometry.data.getEntityName();
            }
        }
        else if (name == ltfields_1.FieldNames.EntityLayer) {
            if (this._properties[name] === undefined || this._properties[name] === null) {
                return "0";
            }
        }
        else if (name == ltfields_1.FieldNames.Color) {
            return this._geometry.getColorInfo();
        }
        else if (name == ltfields_1.FieldNames.Linetype) {
            if (this._properties[name] === undefined || this._properties[name] === null) {
                return ltentity_1.dwg_bylayer;
            }
        }
        else if (name == ltfields_1.FieldNames.BlockName) {
            if (this._geometry instanceof ltentity_1.LTEntityPoint) {
                return this._geometry.blockName;
            }
            else {
                return "";
            }
        }
        else if (name == ltfields_1.FieldNames.Text) {
            if (this._geometry instanceof ltentity_1.LTText) {
                return this._geometry.subData().text;
            }
        }
        else if (name == "linetypescale") {
            return this._geometry.getLineTypeScale();
        }
        return this._properties[name];
    }
    //设置属性
    setValue(name, value) {
        if (this._geometry === null) {
            return;
        }
        if (name == ltfields_1.FieldNames.EntityLayer) {
            if (this._layer != null && this._layer.name !== value && window.cadmap != null) {
                //老图层删除 新图层增加
                window.cadmap.removeGraphics(this._geometry, this._layer.layerGraphics);
                let temp_layer = window.cadmap.getLayerByName(value);
                if (temp_layer !== null) {
                    this._layer = temp_layer;
                }
                if (this.state != undefined) {
                    this.commit(CommitFlag.Update);
                }
                this._geometry.setLayerName(value);
            }
        }
        else if (name == ltfields_1.FieldNames.Color) {
            this._geometry.setColor(value);
            return;
        }
        else if (name == ltfields_1.FieldNames.Linetype) {
            this._geometry.setLineType(value);
        }
        // else if (field == FieldFlag.EntityType) {
        //   this._geometry.setColor(value);
        // }
        else if (name == ltfields_1.FieldNames.BlockName) {
            if (this._geometry instanceof ltentity_1.LTEntityPoint) {
                this._geometry.blockName = value;
            }
            else {
                return;
            }
        }
        else if (name == ltfields_1.FieldNames.Width) {
            this._geometry.setStrokeWidth(value);
        }
        else if (name == ltfields_1.FieldNames.Text) {
            if (this._geometry instanceof ltentity_1.LTText) {
                this._geometry.text = value;
            }
            else {
                return;
            }
        }
        else if (name == ltfields_1.FieldNames.Rotate) {
            this._geometry.setRotate(value);
        }
        // else if (field == FieldFlag.Info) {
        //   this._geometry.setColor(value);
        // } else if (field == FieldFlag.Status) {
        //   this._geometry.setColor(value);
        // } else if (field == FieldFlag.Cadgeo) {
        //   this._geometry.setColor(value);
        // }
        this._properties[name] = value;
    }
    set id(id) {
        this._id = id;
    }
    get id() {
        return this._id;
    }
    setGeometry(geo) {
        // if (this._geometry != null && window.cadmap != null) {
        //   window.cadmap.removeGraphics(this._geometry);
        // }
        this._geometry = geo;
    }
    getGeometry() {
        return this._geometry;
    }
    clone() {
        var clone_feature = new LTFeature({
            layer: this._layer,
            geometry: this._geometry.clone(),
            properties: this._properties,
            status: CommitFlag.Add
        });
        clone_feature._xdata = this._xdata;
        return clone_feature;
    }
}
exports.LTFeature = LTFeature;
