"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SprinkerRegisterCommand = void 0;
const commandfactory_1 = require("../../cadplugins/map/commandfactory");
const drawsprinkler_1 = require("../tools/drawsprinkler");
const drawwarehouse_1 = require("../tools/drawwarehouse");
const exportpdf_1 = require("../tools/exportpdf");
const newsprink_1 = require("../tools/newsprink");
const schematicdiagramcommands_1 = require("../tools/schematicdiagramcommands");
class SprinkerRegisterCommand {
    static init() {
        if (this.has_init) {
            return;
        }
        this.has_init = true;
        commandfactory_1.CommandFactory.getInstance().registerCommand("clearSpire", exportpdf_1.clearSpireDraw, "清除喷淋绘制结果");
        commandfactory_1.CommandFactory.getInstance().registerCommand("sparydesign", schematicdiagramcommands_1.designGraphcisCommand, "原理原理图设计");
        commandfactory_1.CommandFactory.getInstance().registerCommand("sparycalculate", newsprink_1.newCalcSpire, '喷淋计算');
        commandfactory_1.CommandFactory.getInstance().registerCommand("drawsprinkler", drawsprinkler_1.drawSprinkler, "绘制喷淋");
        commandfactory_1.CommandFactory.getInstance().registerCommand("spirepreview", drawsprinkler_1.sprinkPreview, "喷淋图层预览", commandfactory_1.CommandFlag.CommandSnap | commandfactory_1.CommandFlag.CommmandNoCommandLine);
        commandfactory_1.CommandFactory.getInstance().registerCommand("linkageUpdate", schematicdiagramcommands_1.linkageUpdate, "更新喷淋设计图");
        commandfactory_1.CommandFactory.getInstance().registerCommand("exportpdf", exportpdf_1.exportPDF, "按图幅导出PDF", commandfactory_1.CommandFlag.CommandSnap | commandfactory_1.CommandFlag.CommmandNoCommandLine);
        commandfactory_1.CommandFactory.getInstance().registerCommand("drawWarehouse", drawwarehouse_1.drawWarehouse, "绘制喷淋仓库");
    }
}
exports.SprinkerRegisterCommand = SprinkerRegisterCommand;
SprinkerRegisterCommand.has_init = false;
