import { LTDwgServers } from "@/cadplugins/cad/ltdwgserver.js";
import { dwg_bylayer } from "../../cadplugins/cad/ltentity";
import { LTVectorlayer } from "../../cadplugins/data/ltvectorlayer";
export const layerMixin = {
  data() {
    return {
      isAddLayer: false,
      isDelLayer: false,
      selectLayerIndex: 0,
      delLaerData: null,
      layerY: 0,
      lineList: [],
      lineWidthList: [
        "默认",
        "0.00mm",
        "0.05mm",
        "0.09mm",
        "0.13mm",
        "0.15mm",
        "0.18mm",
        "0.20mm",
        "0.25mm",
        "0.30mm",
        "0.90mm",
        "1.00mm",
        "1.06mm",
        "1.20mm",
        "1.40mm",
        "1.58mm",
        "2.00mm",
        "2.11mm"
      ],
      selectLayer: {
        _name: "",
        showInput: false,
        _linetype: "",
        _linewidth: "默认",
        isChecked: false
      },
      layerIndexs: [],
      layerNames: []
    };
  },
  computed: {},
  methods: {
    // 监听键盘
    keyDown() {},
    // 图层操作方法-start
    // 赋值图层数据
    layerManagement(data) {
      data.isSelected = !data.isSelected;
      let index = this.returnCardIndex(data.sname);
      if (data.isSelected) {
        this.setTypeVal("属性");
        this.isDrawName = data.sname;
        this.setTcData();
        if (index == -1)
          this.cardList.push({
            name: data.sname
          });
      } else {
        this.cardList.splice(index, 1);
        if (this.cardList.length > 0) {
          let item = this.cardList[0];
          this.isDrawName = item.name;
          this.setTypeVal(item.name, true);
        }
      }
      this.changeMapSize();
      document.addEventListener("keydown", event => this.onKeyDown(event));
      document.addEventListener("keyup", event => this.onKeyDown(event));
    },
    onKeyDown(e) {
      this.isshift = e.shiftKey;
    },
    onkeyUp(e) {
      this.isshift = e.shiftKey;
    },
    setTcData() {
      this.layerData = [];
      this.layerIndexs = [0];
      this.selectLayerIndex = 0;
      this.pixiMap.getLayers().map(item => {
        item.isLock = false;
        if (item._name == this.pixiMap.activeLayer()._name) {
          item.isLock = true;
        }
        let color_val = this.getColorVal(item._colorInfo);
        item.selectColor = color_val.value;
        item.colorIndex = color_val.index;
        item.isChecked = !item._isOff;
        this.layerData.push(item);
      });
      this.isDelLayer = false;
      if (this.layerData.length > 0) {
        this.selectLayerIndex = 0;
        this.selectLayer._name = this.layerData[0]._name;
        this.lineList = [];
        this.pixiMap.dwgservice.getLinetypesName().map(item => {
          if (item.indexOf(dwg_bylayer) == -1) {
            this.lineList.push(item);
          }
        });

        this.selectLayer._linetype = this.layerData[0]._linetype;
        this.selectLayer._linewidth = this.layerData[0]._linewidth;
        this.selectLayer.isChecked = this.layerData[0].isChecked;
        this.selectLayer.isLock = this.layerData[0].isLock;
      }
      console.log("layerData", this.layerData, this.typeList, this.cardDatas);
    },
    // 双击显示表单
    onShowInputs(item) {
      console.log("onShowInputs", item, item.showInput);
      if (item && item.disable) return;

      if (!item.showInput) {
        this.$set(item, "showInput", true);

        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.input.focus();
          }, 500);
        });
      } else item.showInput = false;
    },
    // 图层中的显示隐藏切换
    changeAttr(val, index) {
      if (this.layerIndexs.length > 1) {
        this.layerIndexs.map(item => {
          let layer = this.pixiMap.getLayers()[item];
          // this.layerData[item].isChecked = val;
          if (index != item) this.$set(this.layerData[item], "isChecked", val);
          this.pixiMap.changLayerVisible(layer, val);
        });
        this.$forceUpdate();
      } else {
        let layer = this.pixiMap.getLayers()[index];
        this.pixiMap.changLayerVisible(layer, val);
        this.setLayerData(this.layerData[index], index);
        this.$forceUpdate();
      }
    },
    // 图层中的显示隐藏切换
    changeLayerAttr(val, index, type) {
      let layer = this.pixiMap.getLayers()[index];
      layer[type] = val;
      this.pixiMap.render();
      this.layerData[index][type] = val;
      this.setLayerData(this.layerData[index], index);
      this.$forceUpdate();
      console.log("changeLayerAttr", val, index, type, this.layerData);
    },
    // 图层颜色修改
    setPixColor(ele) {
      if (ele) {
        if (ele.types && ele.types == "常规") {
          // 常规颜色修改
          this._setConventionData(ele.sname, ele.value);
        } else {
          // 标注颜色修改
        }
      }
    },
    // 锁定图层
    changeLock(ele, index, type = "select") {
      this.isAddLayer = false;
      if (this.layerIndexs.length > 1) return;
      if (ele.isLock && type == "select") return;
      this.layerData.map((item, idx) => {
        if (index == idx) {
          {
            item.isLock = true;
            this.pixiMap.setActiveLayer(item);
          }
        } else {
          item.isLock = false;
        }
      });
      this.$forceUpdate();
    },
    // 图层颜色选择
    changeTcColor(index, color, colorIndex) {
      const _this = this;
      if (this.layerData.length == 0) return;
      if (this.layerIndexs.length > 1) {
        this.layerIndexs.map(item => {
          _this.cardColor = color;
          _this.layerData[item].selectColor = color;
          //获取图层，改变颜色
          let layer = _this.pixiMap.getLayerByName(_this.layerData[item]._name);
          layer._colorInfo.color = color.replace(/#/gi, "0x");
          layer._colorInfo.aci = true;
          layer._colorInfo.index = colorIndex;
          layer._color = color.replace(/#/gi, "0x");
          console.log("layer0", color, layer);
          _this.pixiMap.renderByLayer(layer);
        });
      } else if (_this.layerData[index] && index >= 0) {
        _this.cardColor = color;
        _this.layerData[index].selectColor = color;
        //获取图层，改变颜色
        let layer = _this.pixiMap.getLayerByName(_this.layerData[index]._name);
        layer._colorInfo.color = color.replace(/#/gi, "0x");
        layer._colorInfo.aci = true;
        layer._colorInfo.index = colorIndex;
        layer._color = color.replace(/#/gi, "0x");
        console.log("layer", color, layer);
        _this.pixiMap.renderByLayer(layer);
      }
      this.$forceUpdate();
    },
    // 新增图层
    addLayer() {
      this.isAddLayer = true;
      this.isDelLayer = false;
      this.layerIndexs = [];
      this.isshift = false;

      this.selectLayer = {
        _name: "",
        showInput: false,
        isLock: false,
        _linetype: this.lineList[0] || "",
        _linewidth: "默认",
        isChecked: false
      };
      console.log("selectLayer", this.selectLayer);
      this.selectLayerIndex = 0;
    },
    // 全选图层
    selectAllLayer() {
      this.isAddLayer = false;
      this.layerIndexs = this.cardDatas.map((it, index) => {
        it;
        return index;
      });
    },
    // 反选图层
    invertLayer() {
      this.isAddLayer = false;
      let layerIndexs = [];
      this.cardDatas.map((it, index) => {
        it;
        if (this.layerIndexs.indexOf(index) == -1) layerIndexs.push(index);
      });
      this.layerIndexs = layerIndexs;
    },
    // 图层右击事件
    showLayerRight(event, index) {
      this.isAddLayer = false;
      event.preventDefault();
      this.layerY = event.screenY - 80;
      setTimeout(() => {
        if (this.isDelLayer && this.delLaerData) return;
        else {
          this.isDelLayer = true;
          this.delLaerData = this.layerData[index];
        }
      }, 300);
      console.log("showLayerRight", event, index);
    },
    editLayer(index) {
      this.isAddLayer = false;
      this.isDelLayer = false;
      if (this.isshift) {
        this.retSetlayerIndexs(index);
      } else {
        this.setLayerData(this.layerData[index], index);
        this.layerIndexs = [index];
      }
      console.log("selectCardIndex", this.layerIndexs, this.layerData);
    },
    delLayer(index) {
      this.isAddLayer = false;
      this.layerData.splice(index, 1);
      this.setLayerData(this.layerData[0], 0);
      this.isDelLayer = false;
      this.delLaerData = null;
      this.layerIndexs = [];
      this.isshift = false;
    },
    // 修改图层参数
    setLayerAtt(type, layer) {
      console.log(
        "setLayerAtt",
        type,
        this.isAddLayer,
        layer._name,
        layer[type],
        this.layerIndexs
      );
      if (this.isAddLayer) {
        if (type == "_name") {
          this.selectLayer.showInput = false;
          if (!this.isAddLayer) {
            this.changeLayerAttr(layer[type], this.selectLayerIndex, type);
          } else {
            if (!layer._name) {
              this.$message.warning("图层名称不能为空!!");
              return;
            }
            let index = -1;
            this.layerData.some((item, idx) => {
              if (item._name == layer._name) {
                index = idx;
                return true;
              }
            });
            if (index == -1) {
              let selectLayerData = new LTVectorlayer("");
              selectLayerData._name = this.selectLayer._name;
              selectLayerData.isLock = this.selectLayer.isLock;
              selectLayerData._linetype = this.selectLayer._linetype;
              selectLayerData._linewidth = this.selectLayer._linewidth;
              selectLayerData.isChecked = this.selectLayer.isChecked;
              console.log("selectLayerData", selectLayerData);
              this.pixiMap.addLayer(selectLayerData);
              this.layerData.push(selectLayerData);
              this.layerIndexs.push(this.layerData.length - 1);
              this.isAddLayer = false;
            } else {
              this.$message.warning("图层名称已存在!!");
            }
          }
        }
      } else {
        if (type == "_name") {
          this.changeLayerAttr(layer[type], this.selectLayerIndex, type);
        }
        // 显示控制
        if (type == "isChecked") {
          this.changeLock(layer, this.selectLayerIndex, "click");
        } else {
          // 其他方法
          this.changeLayerAttr(layer[type], this.selectLayerIndex, type);
        }
      }
    },

    setLayerData(layers, index) {
      let layer = layers;
      this.selectLayer._name = layer._name;
      this.selectLayer.isChecked = layer.isChecked;
      this.selectLayer._linetype = layer._linetype;
      this.selectLayer._linewidth = layer._linewidth;
      this.selectLayer.isLock = layer.isLock;
      this.selectLayerIndex = index;
    },
    retSetlayerIndexs(index) {
      this.layerIndexs = this.layerIndexs.sort((a, b) => a - b);
      if (this.layerIndexs.length > 0) {
        let fristIndex = this.layerIndexs[0];
        let lastIndex = this.layerIndexs[this.layerIndexs.length - 1];
        let startIndex = 0,
          endIndex = 0;
        if (index < fristIndex) {
          startIndex = index;
          endIndex = fristIndex;
        } else if (index < lastIndex && index >= fristIndex) {
          startIndex = index;
          endIndex = lastIndex;
        } else if (index >= lastIndex) {
          startIndex = lastIndex;
          endIndex = index;
        }
        this.layerIndexs = [];
        for (let idx = startIndex; idx <= endIndex; idx++) {
          this.layerIndexs.push(idx);
        }
      }
    }

    // 图层操作方法-end
  }
};
