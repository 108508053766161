"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.xdataCommand = exports.regenCommand = void 0;
const commandfactory_1 = require("../map/commandfactory");
const selectcommands_1 = require("./selectcommands");
function* regenCommand(map, command_param) {
    map.regen();
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.regenCommand = regenCommand;
function* xdataCommand(map, command_param) {
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, { command_tip: "选择需要查看的扩展属性的对象", select_type: selectcommands_1.SelectType.eSelectPoint });
    let input_select = yield;
    if (input_select.done == commandfactory_1.CommandStatus.eCancel || input_select.done == commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: input_select.done };
    }
    let select_entity = input_select.value;
    if (select_entity) {
        map.printMessage(JSON.stringify(select_entity._xdata));
    }
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.xdataCommand = xdataCommand;
