import { LTDwgServers } from "@/cadplugins/cad/ltdwgserver.js";
import { CommandFactory } from "@/cadplugins/map/commandfactory";
export const blockMixin = {
  data() {
    return {
      isAddBlock: true,
      blockList: [],
      blockattriList: [],
      blockForm: {
        blockName: "",
        isAppoint: false,
        isPickUp: false,
        pointX: 0.0,
        pointY: 0.0,
        pointZ: 0.0
      },
      // 文本设置
      showText: false,
      textForm: {
        content: "",
        fontSize: "",
        angle: "",
        color: "",
        colorIndex: -1
      }
    };
  },
  methods: {
    // 块-start
    // 唤起新增块
    onShowAddBlock(
      form = {
        blockName: "",
        isAppoint: false,
        isPickUp: false,
        pointX: 0,
        pointY: 0,
        pointZ: 0
      }
    ) {
      this.isAddBlock = true;
      this.blockForm = form;
      this.showDialog = true;
    },
    // 唤起插入块
    onShowBlock() {
      this.onSetBlock();
    },
    // 块赋值方法
    onSetBlock(
      form = {
        blockName: "",
        isAppoint: true,
        isPickUp: false
      },
      blockList = []
    ) {
      //块对象的一个map;
      let blocks = this.pixiMap.dwgservice._blocks;
      this.blockList = Array.from(blocks.keys()).map(item => {
        return {
          name: item
        };
      });

      this.isAddBlock = false;
      this.blockForm = form;
      //属性块定义;
      this.blockattriList = [];
      this.showDialog = true;
    },
    // 选择块
    changeBlock(val) {
      this.currentblock = this.pixiMap.dwgservice._blocks.get(val);
      this.blockattriList = this.currentblock.attributeDefs();
      // this.blockattriList.map(item => {
      //   item.context = "";
      //   return item;
      // });
    },
    changePoint() {
      if (this.blockForm.isAppoint) {
        this.blockForm.isPickUp = true;
      } else this.blockForm.isPickUp = false;
    },
    // 拾取点方法
    pickupPoint() {
      console.log("pickupPoint");
    },
    // 块确定
    doSubmit() {
      if (this.blockForm.blockName == "") {
        this.$message("文字内容不能为空");
        return;
      }

      //取模板里面的数据;

      this.cancelDialog();

      let blockinfo_map = new Map();
      this.blockattriList.map(item => {
        blockinfo_map.set(item.text, item.context);
        item.context = "";
      });
      this.blockForm.blockinfo = blockinfo_map;
      if (this.isAddBlock == true) {
        CommandFactory.getInstance().execCommand(
          "block",
          this.pixiMap,
          this.blockForm
        );
      } else {
        CommandFactory.getInstance().execCommand(
          "insert",
          this.pixiMap,
          this.blockForm
        );
      }
    },
    // 块-end
    // 文字-start
    // 唤起文字弹框
    onShowText(
      form = {
        content: "",
        fontSize: "14",
        angle: "0",
        color: "#ffffff",
        colorIndex: 7
      }
    ) {
      this.showText = true;
      this.textForm = form;
    },
    callBackTxtColor(val) {
      this.textForm.color = val.value;
      this.textForm.colorIndex = val.index;
    },
    // 文字确定
    doSubmitText() {
      if (this.textForm.content == "") {
        this.$message("文字内容不能为空");
        return;
      }
      this.showText = false;
      CommandFactory.getInstance().execCommand(
        "drawText",
        this.pixiMap,
        this.textForm
      );
    },
    // 弹框取消
    cancelDialog(type = "showDialog") {
      if (this[type]) this[type] = false;
      this.typeIndex = -1;
      this.closeDialog();
    },
    closeDialog() {
      let refsDeom = this.$refs;
      for (const key in refsDeom) {
        if (refsDeom[key]) {
          if (refsDeom[key].length > 0) {
            refsDeom[key].map(ele => {
              if (ele.blur) ele.blur();
            });
          } else if (
            key == "ztys" &&
            refsDeom[key].$children &&
            refsDeom[key].$children.length > 0
          ) {
            refsDeom[key].$children.map(ele => {
              if (ele.blur) ele.blur();
            });
          } else refsDeom[key].blur && refsDeom[key].blur();
        }
      }
    }
    // 文字-end
  }
};
