"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EasyEditCommand = void 0;
const commandfactory_1 = require("../map/commandfactory");
const ltpoint_1 = require("../cad/ltpoint");
class EasyEditCommand extends commandfactory_1.AbstractCommand {
    constructor(map) {
        super();
        this._map = null;
        this._edit_feature = null;
        this._ref_point = null;
        this._clone_ent = null;
        this.active(map);
    }
    destroy() {
    }
    active(cadmap) {
        this._map = cadmap;
        return commandfactory_1.CommandStatus.eOK;
    }
    clear() {
        if (this._map === null) {
            return;
        }
        this.clearRender();
    }
    reset() {
        this._ref_point = null;
        this._edit_feature = null;
        this._clone_ent = null;
    }
    hasSelectNode(point) {
        if (this._ref_point == null) {
            return false;
        }
        return true;
    }
    clearRender() {
        if (this._clone_ent !== null && this._map !== null) {
            this._map.removeGraphics(this._clone_ent, this._map.trackGraphics);
        }
    }
    draw(entity) {
        if (this._map === null) {
            return commandfactory_1.CommandStatus.eOK;
        }
        entity.worldDraw(this._map.canvas, true, null, this._map.trackGraphics);
        return commandfactory_1.CommandStatus.eOK;
    }
    pickFeature(mapPos) {
        if (this._map == null) {
            return;
        }
        let pos = new ltpoint_1.LTPoint(mapPos.x, mapPos.y);
        var range_distance = 6 / this._map.mapScale();
        var minDis = -1;
        var lastsnap_point = null;
        let feature = null;
        if (this._map.selectedFeatures().length == 0) {
            return false;
        }
        let features = this._map.selectedFeatures();
        for (let i = 0; i < features.length; i++) {
            let point_list = features[i].getGeometry().data.getReferencePoints();
            point_list.forEach(point => {
                var dis = point.getDistanceTo(pos);
                if (minDis < 0 || dis < minDis) {
                    minDis = dis;
                    lastsnap_point = point;
                    feature = features[i];
                }
            });
        }
        if (minDis != -1 && minDis < range_distance) {
            this._edit_feature = feature;
            this._ref_point = lastsnap_point;
            return true;
        }
        return false;
    }
    onMouseDown(event) {
        if (this._map === null) {
            return commandfactory_1.CommandStatus.eOK;
        }
        if (this._ref_point == null) {
            if (!this.pickFeature(event.mapPos)) {
                this._map.select_command.onMouseDown(event);
            }
        }
        else {
            if (this._edit_feature === null) {
                return commandfactory_1.CommandStatus.eOK;
            }
            let clone_ent = this._edit_feature.getGeometry().clone();
            let ent = this._edit_feature.getGeometry();
            var new_pos = this._map.snap.snap(event.mapPos, this._map);
            event.mapPos = new_pos;
            ent.moveReferencePoint(this._ref_point, new ltpoint_1.LTPoint(event.mapPos.x, event.mapPos.y));
            this.clear();
            this._map.updateFeatureGeometry(this._edit_feature, clone_ent);
            this._map.select_command.show();
            this._edit_feature = null;
            this._ref_point = null;
        }
        return commandfactory_1.CommandStatus.eOK;
    }
    onMouseMove(event) {
        if (this._map === null) {
            return commandfactory_1.CommandStatus.eDone;
        }
        //高亮节点由捕捉做
        if (this._edit_feature === null || this._ref_point === null) {
            return commandfactory_1.CommandStatus.eDone;
        }
        //可以不用实时拷贝节点;
        if (this._clone_ent != null) {
            this._map.removeGraphics(this._clone_ent, this._map.trackGraphics);
        }
        var new_pos = this._map.snap.snap(event.mapPos, this._map);
        event.mapPos = new_pos;
        this._clone_ent = this._edit_feature.getGeometry().clone();
        let is_ok = this._clone_ent.moveReferencePoint(this._ref_point, new ltpoint_1.LTPoint(event.mapPos.x, event.mapPos.y));
        this._clone_ent.setColor("0x00ff00");
        this.draw(this._clone_ent);
        if (is_ok == true) {
            return commandfactory_1.CommandStatus.eOK;
        }
        return commandfactory_1.CommandStatus.eDone;
    }
    onMouseUp(event) {
        return commandfactory_1.CommandStatus.eOK;
    }
}
exports.EasyEditCommand = EasyEditCommand;
