"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShelveRowGroup = void 0;
class ShelveRowGroup {
    constructor(row) {
        this._shelveRows = [];
        this._shelveRows.push(row);
    }
    addRow(row) {
        this._shelveRows.push(row);
    }
}
exports.ShelveRowGroup = ShelveRowGroup;
