"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.entityListCommand = exports.saveCommand = exports.SaveCommandImpl = void 0;
const commandfactory_1 = require("../map/commandfactory");
const ltfeature_js_1 = require("../data/ltfeature.js");
const ltfields_1 = require("../data/ltfields");
class SaveCommandImpl {
    constructor(snapshot) {
        this.update_snapshot = 1;
        this.update_snapshot = snapshot;
    }
    saveMap(cadmap) {
        // cadmap.maxID=1;
        this._map = cadmap;
        let delete_id = [];
        let add_id = [];
        let edit_id = [];
        let layers = cadmap.getLayers();
        let layerinfos = [];
        for (let i = 0; i < layers.length; i++) {
            let feas = layers[i].getFeatures(null, null, true);
            for (let j = 0; j < feas.length; j++) {
                if (feas[j].state == ltfeature_js_1.CommitFlag.Delete) {
                    delete_id.push(feas[j]);
                }
                else if (feas[j].state == ltfeature_js_1.CommitFlag.Add) {
                    cadmap.maxID += 1;
                    feas[j].id = cadmap.maxID;
                    add_id.push(feas[j]);
                }
                else if (feas[j].state == ltfeature_js_1.CommitFlag.Update) {
                    edit_id.push(feas[j]);
                }
            }
            let layerinfo = layers[i].getSaveInfo();
            layerinfos.push(layerinfo);
        }
        let totalinfo = {};
        var str = JSON.stringify(layerinfos);
        totalinfo["layerinfo"] = str;
        if (this._map.dwgservice.getBlockDirty()) {
            let blockjson = this._map.dwgservice.getBlockSaveInfo();
            var strb = JSON.stringify(blockjson);
            totalinfo["textblockInfo"] = strb;
        }
        if (this._map.dwgservice.getLinetypeDirty()) {
            let linetypes = this._map.dwgservice.getLineTypesSaveInfo();
            var strsb = JSON.stringify(linetypes);
            totalinfo["linetypenames"] = strsb;
        }
        if (this._map.dwgservice.getHatchsDirty()) {
            let linetypes = this._map.dwgservice._hatchsjson;
            var strsb = JSON.stringify(linetypes);
            totalinfo["hatchs"] = strsb;
        }
        if (this._map.dwgservice.getTextStyleDirty()) {
            let text_style = this._map.dwgservice.getTextStyleSaveInfo();
            var strsb = JSON.stringify(text_style);
            totalinfo["textstyle"] = strsb;
        }
        let currentlinetype = this._map.dwgservice.getCurrentLineType();
        let currentLayername = this._map.activeLayer().name;
        let currentinfo = {};
        currentinfo["currentLinetype"] = currentlinetype;
        currentinfo["currentLayername"] = currentLayername;
        var strsbs = JSON.stringify(currentinfo);
        totalinfo["currentinfo"] = strsbs;
        this._save(delete_id, edit_id, add_id, totalinfo);
    }
    _getUpdateValues(feature, json) {
        let values = "";
        let field = feature.layer.fields;
        let saveValue = feature.getGeometry().getSaveValue();
        if (field != undefined) {
            for (var j = 0; j < field.length; j++) {
                let value;
                if (field[j].name == "status") {
                    continue;
                }
                if (field[j].xData == true) {
                    value = JSON.stringify(feature._xdata);
                }
                else {
                    value = saveValue[field[j].name];
                    if (value == undefined) {
                        value = feature.getValue(field[j].name);
                    }
                }
                if (value == undefined) {
                    continue;
                }
                values = values + field[j].name + "='" + value + "',";
            }
        }
        //改状态
        values = values + " status = '" + ltfeature_js_1.CommitFlag.Update + "'";
        json["values"] = values;
    }
    _getAddValues(feature, json) {
        let fields = "", values = "";
        //图层中获取属性
        let field = feature.layer.fields;
        let saveValue = feature.getGeometry().getSaveValue();
        let layer_fields = feature.layer.fields;
        for (var j = 0; j < layer_fields.length; j++) {
            let value;
            if (field[j].name == ltfields_1.FieldNames.Status || field[j].name == ltfields_1.FieldNames.Cadgeo) {
                continue;
            }
            if (field[j].xData == true) {
                value = JSON.stringify(feature._xdata);
            }
            else {
                value = saveValue[field[j].name];
                if (value == undefined) {
                    value = feature.getValue(layer_fields[j].name);
                }
            }
            if (value == undefined) {
                continue;
            }
            if (j == 0) {
                fields = fields + layer_fields[j].name + ",";
                values =
                    values + "'" + value + "',";
                continue;
            }
            fields = fields + layer_fields[j].name + ",";
            values = values + "'" + value + "',";
        }
        //几何
        fields = fields + "id,status,cadgeo";
        values =
            values + "'" + feature.id + "'," + "'" + ltfeature_js_1.CommitFlag.Add + "'," + "'" + saveValue[ltfields_1.FieldNames.Cadgeo] + "'";
        json["fields"] = fields;
        json["values"] = values;
        json["geoemtry"] = saveValue[ltfields_1.FieldNames.Cadgeo];
    }
    _save(delete_id, edit_id, add_id, totalinfo) {
        var _a;
        //用json 打成一个包 进行更新
        //删除
        let json_data = {};
        let delete_ids = [];
        for (var i = 0; i < delete_id.length; i++) {
            if (delete_id[i].id === undefined || delete_id[i].id === null) {
                continue;
            }
            delete_ids.push(delete_id[i].id);
        }
        json_data["delete"] = delete_ids.toString();
        //更新
        let updatejsons = [];
        for (var i = 0; i < edit_id.length; i++) {
            let feature = edit_id[i];
            let updatejson = {};
            this._getUpdateValues(feature, updatejson);
            updatejson["id"] = feature.id;
            updatejsons.push(updatejson);
        }
        //updatejsons["size"] = edit_id.length;
        json_data["updates"] = updatejsons;
        //增加
        let addjsons = [];
        for (var i = 0; i < add_id.length; i++) {
            let addfeature = add_id[i];
            let updatejson = {};
            this._getAddValues(addfeature, updatejson);
            //addjsons[i] = updatejson;
            addjsons.push(updatejson);
        }
        // if(updatejsons.length ==0 && addjsons.length==0 && delete_ids.length==0)
        // {
        //   return;
        // }
        json_data["adds"] = addjsons;
        json_data["totalinfo"] = totalinfo;
        json_data["updatesnapshot"] = this.update_snapshot;
        let ok = (_a = this._map) === null || _a === void 0 ? void 0 : _a.SaveJson(json_data, (result) => {
            var _a, _b, _c;
            if (result === true) {
                edit_id.forEach(fea => {
                    fea.state = ltfeature_js_1.CommitFlag.Nothing;
                });
                add_id.forEach(fea => {
                    fea.state = ltfeature_js_1.CommitFlag.Nothing;
                });
                delete_id.forEach(fea => {
                    fea.state = ltfeature_js_1.CommitFlag.DeleteOnline;
                });
                (_a = this._map) === null || _a === void 0 ? void 0 : _a.dwgservice.isSaveOK();
                (_b = this._map) === null || _b === void 0 ? void 0 : _b.printMessage(`保存成功!`);
            }
            else {
                (_c = this._map) === null || _c === void 0 ? void 0 : _c.printMessage(`保存失败!`);
            }
        });
    }
    commandFlags() {
        return commandfactory_1.CommandFlag.CommmandTransparent;
    }
    static save(cadmap, update_snapshot = true) {
        let temp_snapshot = 0;
        if (update_snapshot) {
            temp_snapshot = 1;
        }
        let save_command = new SaveCommandImpl(temp_snapshot);
        save_command.saveMap(cadmap);
    }
}
exports.SaveCommandImpl = SaveCommandImpl;
function* saveCommand(cadmap, param_map) {
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.saveCommand = saveCommand;
function* entityListCommand(cadmap, param_map) {
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", cadmap);
    let select_feature = yield;
    if (select_feature.done === commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    console.log(select_feature.value);
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.entityListCommand = entityListCommand;
