"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PipeSizeConfig = void 0;
class PipeSizeConfig {
    //BuildingCategory
    //DangerLevel
    //ActionArea
    //IsTallRoom
    //SprayStrength
    //CoefficientK
    //MinPressure
    //SprinklerCategory（可能有二级菜单）
    static getPipeSizeRelationship(sprinklerCategory, isTallRoom, actionArea, sprayStrength, dangerLevel, coefficientK, minPressure) {
        //1.早期抑制快速响应喷头
        //2.仓库型特殊应用喷头
        //3.其他
        if (sprinklerCategory == "早期抑制快速响应喷头") {
            return getEarly(coefficientK, minPressure);
        }
        else if (sprinklerCategory == "仓库型特殊应用喷头") {
            return getWarehouseSpecial(coefficientK, minPressure);
        }
        else {
            if (isTallRoom === true) { //高大空间
                return getTallRoom(actionArea, sprayStrength);
            }
            else {
                switch (dangerLevel) {
                    case "轻危险级": {
                        return getLow();
                    }
                    case "中危险I级":
                    case "中危险II级": {
                        return getMedium();
                    }
                    case "严重危险I级": {
                        return getSeriousFirst(actionArea, sprayStrength);
                    }
                    case "严重危险II级": {
                        return getSeriousSecond(actionArea, sprayStrength);
                    }
                    case "仓库危险级I级": {
                        return getWarehouseFirst(actionArea, sprayStrength);
                    }
                    case "仓库危险级II级": {
                        return getWarehouseSecond(actionArea, sprayStrength);
                    }
                    case "仓库危险级III级-货架储存时": {
                        return getWarehouseThird_shelf(actionArea, sprayStrength);
                    }
                    case "仓库危险级III级-堆垛储存时": {
                        return getWarehouseThird_stack(actionArea, sprayStrength);
                    }
                    default:
                        break;
                }
            }
        }
        throw Error("参数错误");
    }
}
exports.PipeSizeConfig = PipeSizeConfig;
//早期抑制快速响应喷头
function getEarly(coefficientK, minPressure) {
    if (coefficientK == 202) {
        switch (minPressure) {
            case 0.35:
                return [
                    { pipeDiameter: 50, maxNum: 1 },
                    { pipeDiameter: 65, maxNum: 2 },
                    { pipeDiameter: 80, maxNum: 3 },
                    { pipeDiameter: 100, maxNum: 6 },
                    { pipeDiameter: 150, maxNum: 12 },
                    { pipeDiameter: 200, maxNum: 13 },
                ];
            case 0.5:
                return [
                    { pipeDiameter: 50, maxNum: 1 },
                    { pipeDiameter: 65, maxNum: 2 },
                    { pipeDiameter: 80, maxNum: 3 },
                    { pipeDiameter: 100, maxNum: 5 },
                    { pipeDiameter: 150, maxNum: 11 },
                    { pipeDiameter: 200, maxNum: 12 },
                ];
            case 0.7:
                return [
                    { pipeDiameter: 50, maxNum: 1 },
                    { pipeDiameter: 80, maxNum: 2 },
                    { pipeDiameter: 100, maxNum: 4 },
                    { pipeDiameter: 150, maxNum: 9 },
                    { pipeDiameter: 200, maxNum: 10 },
                ];
            default: break;
        }
    }
    throw Error("早期抑制快速响应喷头参数错误");
}
//仓库型特殊应用喷头
function getWarehouseSpecial(coefficientK, minPressure) {
    if (coefficientK == 363) {
        switch (minPressure) {
            case 0.07:
                return [
                    { pipeDiameter: 40, maxNum: 1 },
                    { pipeDiameter: 65, maxNum: 3 },
                    { pipeDiameter: 80, maxNum: 4 },
                    { pipeDiameter: 100, maxNum: 7 },
                    { pipeDiameter: 150, maxNum: 8 },
                ];
            case 0.15:
                return [
                    { pipeDiameter: 50, maxNum: 1 },
                    { pipeDiameter: 65, maxNum: 2 },
                    { pipeDiameter: 80, maxNum: 3 },
                    { pipeDiameter: 100, maxNum: 5 },
                    { pipeDiameter: 150, maxNum: 11 },
                    { pipeDiameter: 200, maxNum: 12 },
                ];
            case 0.2:
                return [
                    { pipeDiameter: 50, maxNum: 1 },
                    { pipeDiameter: 80, maxNum: 2 },
                    { pipeDiameter: 100, maxNum: 4 },
                    { pipeDiameter: 150, maxNum: 10 },
                    { pipeDiameter: 200, maxNum: 11 },
                ];
            case 0.07:
                return [
                    { pipeDiameter: 40, maxNum: 1 },
                    { pipeDiameter: 65, maxNum: 3 },
                    { pipeDiameter: 80, maxNum: 4 },
                    { pipeDiameter: 100, maxNum: 7 },
                    { pipeDiameter: 150, maxNum: 17 },
                    { pipeDiameter: 200, maxNum: 18 },
                ];
            case 0.1:
                return [
                    { pipeDiameter: 40, maxNum: 1 },
                    { pipeDiameter: 65, maxNum: 3 },
                    { pipeDiameter: 80, maxNum: 4 },
                    { pipeDiameter: 100, maxNum: 6 },
                    { pipeDiameter: 150, maxNum: 14 },
                    { pipeDiameter: 200, maxNum: 15 },
                ];
            default: break;
        }
    }
    throw Error("仓库型特殊应用喷头参数错误");
}
//高大空间
function getTallRoom(actionArea, sprayStrength) {
    if (actionArea == 160) {
        switch (sprayStrength) {
            case 12:
                return [
                    { pipeDiameter: 25, maxNum: 1 },
                    { pipeDiameter: 32, maxNum: 2 },
                    { pipeDiameter: 40, maxNum: 3 },
                    { pipeDiameter: 50, maxNum: 5 },
                    { pipeDiameter: 65, maxNum: 9 },
                    { pipeDiameter: 80, maxNum: 14 },
                    { pipeDiameter: 100, maxNum: 22 },
                    { pipeDiameter: 150, maxNum: 23 },
                ];
            case 15:
                return [
                    { pipeDiameter: 25, maxNum: 1 },
                    { pipeDiameter: 40, maxNum: 2 },
                    { pipeDiameter: 50, maxNum: 4 },
                    { pipeDiameter: 65, maxNum: 7 },
                    { pipeDiameter: 80, maxNum: 11 },
                    { pipeDiameter: 100, maxNum: 17 },
                    { pipeDiameter: 150, maxNum: 18 },
                ];
            case 20:
                return [
                    { pipeDiameter: 25, maxNum: 1 },
                    { pipeDiameter: 40, maxNum: 2 },
                    { pipeDiameter: 50, maxNum: 3 },
                    { pipeDiameter: 65, maxNum: 5 },
                    { pipeDiameter: 80, maxNum: 8 },
                    { pipeDiameter: 100, maxNum: 13 },
                    { pipeDiameter: 150, maxNum: 20 },
                    { pipeDiameter: 200, maxNum: 21 },
                ];
            default: break;
        }
    }
    throw Error("高大空间参数错误");
}
//轻危险级
function getLow() {
    return [
        { pipeDiameter: 25, maxNum: 1 },
        { pipeDiameter: 32, maxNum: 3 },
        { pipeDiameter: 40, maxNum: 5 },
        { pipeDiameter: 50, maxNum: 10 },
        { pipeDiameter: 65, maxNum: 18 },
        { pipeDiameter: 80, maxNum: 48 },
        { pipeDiameter: 100, maxNum: 49 },
    ];
}
//中危险级
function getMedium() {
    return [
        { pipeDiameter: 25, maxNum: 1 },
        { pipeDiameter: 32, maxNum: 3 },
        { pipeDiameter: 40, maxNum: 4 },
        { pipeDiameter: 50, maxNum: 8 },
        { pipeDiameter: 65, maxNum: 12 },
        { pipeDiameter: 80, maxNum: 32 },
        { pipeDiameter: 100, maxNum: 64 },
        { pipeDiameter: 150, maxNum: 65 }
    ];
}
//严重危险Ⅰ级
function getSeriousFirst(actionArea, sprayStrength) {
    if (actionArea == 260 && sprayStrength == 12) {
        return [
            { pipeDiameter: 25, maxNum: 1 },
            { pipeDiameter: 32, maxNum: 2 },
            { pipeDiameter: 40, maxNum: 3 },
            { pipeDiameter: 50, maxNum: 5 },
            { pipeDiameter: 65, maxNum: 9 },
            { pipeDiameter: 80, maxNum: 13 },
            { pipeDiameter: 100, maxNum: 21 },
            { pipeDiameter: 150, maxNum: 22 },
        ];
    }
    throw Error("严重危险Ⅰ级参数错误");
}
//严重危险Ⅱ级
function getSeriousSecond(actionArea, sprayStrength) {
    if (actionArea == 260 && sprayStrength == 16) {
        return [
            { pipeDiameter: 25, maxNum: 1 },
            { pipeDiameter: 40, maxNum: 2 },
            { pipeDiameter: 50, maxNum: 5 },
            { pipeDiameter: 65, maxNum: 6 },
            { pipeDiameter: 80, maxNum: 10 },
            { pipeDiameter: 100, maxNum: 16 },
            { pipeDiameter: 150, maxNum: 30 },
            { pipeDiameter: 200, maxNum: 31 },
        ];
    }
    throw Error("严重危险Ⅱ级参数错误");
}
//仓库危险级I级
function getWarehouseFirst(actionArea, sprayStrength) {
    switch (actionArea) {
        case 160: {
            switch (sprayStrength) {
                case 6:
                case 8: {
                    return [
                        { pipeDiameter: 25, maxNum: 1 },
                        { pipeDiameter: 32, maxNum: 2 },
                        { pipeDiameter: 40, maxNum: 3 },
                        { pipeDiameter: 50, maxNum: 6 },
                        { pipeDiameter: 65, maxNum: 10 },
                        { pipeDiameter: 80, maxNum: 15 },
                        { pipeDiameter: 100, maxNum: 24 },
                        { pipeDiameter: 150, maxNum: 25 },
                    ];
                }
                default:
                    break;
            }
        }
        case 200: {
            switch (sprayStrength) {
                case 10: {
                    return [
                        { pipeDiameter: 25, maxNum: 1 },
                        { pipeDiameter: 32, maxNum: 2 },
                        { pipeDiameter: 40, maxNum: 3 },
                        { pipeDiameter: 50, maxNum: 6 },
                        { pipeDiameter: 65, maxNum: 10 },
                        { pipeDiameter: 80, maxNum: 15 },
                        { pipeDiameter: 100, maxNum: 24 },
                        { pipeDiameter: 150, maxNum: 25 },
                    ];
                }
                case 12: {
                    return [
                        { pipeDiameter: 25, maxNum: 1 },
                        { pipeDiameter: 32, maxNum: 2 },
                        { pipeDiameter: 40, maxNum: 3 },
                        { pipeDiameter: 50, maxNum: 5 },
                        { pipeDiameter: 65, maxNum: 9 },
                        { pipeDiameter: 80, maxNum: 14 },
                        { pipeDiameter: 100, maxNum: 22 },
                        { pipeDiameter: 150, maxNum: 23 },
                    ];
                }
                case 14: {
                    return [
                        { pipeDiameter: 25, maxNum: 1 },
                        { pipeDiameter: 40, maxNum: 2 },
                        { pipeDiameter: 50, maxNum: 4 },
                        { pipeDiameter: 65, maxNum: 7 },
                        { pipeDiameter: 80, maxNum: 11 },
                        { pipeDiameter: 100, maxNum: 18 },
                        { pipeDiameter: 150, maxNum: 19 },
                    ];
                }
                case 18: {
                    return [
                        { pipeDiameter: 32, maxNum: 1 },
                        { pipeDiameter: 40, maxNum: 2 },
                        { pipeDiameter: 50, maxNum: 3 },
                        { pipeDiameter: 65, maxNum: 6 },
                        { pipeDiameter: 80, maxNum: 9 },
                        { pipeDiameter: 100, maxNum: 14 },
                        { pipeDiameter: 150, maxNum: 32 },
                    ];
                }
                default:
                    break;
            }
        }
        default:
            break;
    }
    throw Error("仓库危险级I级参数错误");
}
//仓库危险级II级
function getWarehouseSecond(actionArea, sprayStrength) {
    switch (actionArea) {
        case 160: {
            switch (sprayStrength) {
                case 8: {
                    return [
                        { pipeDiameter: 25, maxNum: 1 },
                        { pipeDiameter: 32, maxNum: 2 },
                        { pipeDiameter: 40, maxNum: 3 },
                        { pipeDiameter: 50, maxNum: 6 },
                        { pipeDiameter: 65, maxNum: 10 },
                        { pipeDiameter: 80, maxNum: 15 },
                        { pipeDiameter: 100, maxNum: 24 },
                        { pipeDiameter: 150, maxNum: 25 },
                    ];
                }
                default:
                    break;
            }
        }
        case 200: {
            switch (sprayStrength) {
                case 8: {
                    return [
                        { pipeDiameter: 25, maxNum: 1 },
                        { pipeDiameter: 32, maxNum: 3 },
                        { pipeDiameter: 40, maxNum: 4 },
                        { pipeDiameter: 50, maxNum: 8 },
                        { pipeDiameter: 65, maxNum: 12 },
                        { pipeDiameter: 80, maxNum: 18 },
                        { pipeDiameter: 100, maxNum: 30 },
                        { pipeDiameter: 150, maxNum: 31 },
                    ];
                }
                case 12: {
                    return [
                        { pipeDiameter: 25, maxNum: 1 },
                        { pipeDiameter: 32, maxNum: 2 },
                        { pipeDiameter: 40, maxNum: 3 },
                        { pipeDiameter: 50, maxNum: 5 },
                        { pipeDiameter: 65, maxNum: 9 },
                        { pipeDiameter: 80, maxNum: 14 },
                        { pipeDiameter: 100, maxNum: 22 },
                        { pipeDiameter: 150, maxNum: 23 },
                    ];
                }
                case 16: {
                    return [
                        { pipeDiameter: 25, maxNum: 1 },
                        { pipeDiameter: 40, maxNum: 2 },
                        { pipeDiameter: 50, maxNum: 4 },
                        { pipeDiameter: 65, maxNum: 7 },
                        { pipeDiameter: 80, maxNum: 10 },
                        { pipeDiameter: 100, maxNum: 17 },
                        { pipeDiameter: 150, maxNum: 18 },
                    ];
                }
                case 18: {
                    return [
                        { pipeDiameter: 32, maxNum: 1 },
                        { pipeDiameter: 40, maxNum: 2 },
                        { pipeDiameter: 50, maxNum: 3 },
                        { pipeDiameter: 65, maxNum: 6 },
                        { pipeDiameter: 80, maxNum: 9 },
                        { pipeDiameter: 100, maxNum: 14 },
                        { pipeDiameter: 150, maxNum: 15 },
                    ];
                }
                case 22: {
                    return [
                        { pipeDiameter: 32, maxNum: 1 },
                        { pipeDiameter: 50, maxNum: 3 },
                        { pipeDiameter: 65, maxNum: 5 },
                        { pipeDiameter: 80, maxNum: 8 },
                        { pipeDiameter: 100, maxNum: 13 },
                        { pipeDiameter: 150, maxNum: 14 },
                    ];
                }
                default:
                    break;
            }
        }
        case 240: {
            switch (sprayStrength) {
                case 24: {
                    return [
                        { pipeDiameter: 32, maxNum: 1 },
                        { pipeDiameter: 50, maxNum: 2 },
                        { pipeDiameter: 65, maxNum: 4 },
                        { pipeDiameter: 80, maxNum: 7 },
                        { pipeDiameter: 100, maxNum: 12 },
                        { pipeDiameter: 150, maxNum: 13 },
                    ];
                }
                default:
                    break;
            }
        }
        default:
            break;
    }
    throw Error("仓库危险级II级参数错误");
}
//仓库危险级III级-货架储存时
function getWarehouseThird_shelf(actionArea, sprayStrength) {
    if (actionArea == 200) {
        switch (sprayStrength) {
            case 12: {
                return [
                    { pipeDiameter: 25, maxNum: 1 },
                    { pipeDiameter: 32, maxNum: 2 },
                    { pipeDiameter: 40, maxNum: 3 },
                    { pipeDiameter: 50, maxNum: 5 },
                    { pipeDiameter: 65, maxNum: 9 },
                    { pipeDiameter: 80, maxNum: 14 },
                    { pipeDiameter: 100, maxNum: 22 },
                    { pipeDiameter: 150, maxNum: 23 },
                ];
            }
            case 18: {
                return [
                    { pipeDiameter: 32, maxNum: 1 },
                    { pipeDiameter: 40, maxNum: 2 },
                    { pipeDiameter: 50, maxNum: 3 },
                    { pipeDiameter: 65, maxNum: 6 },
                    { pipeDiameter: 80, maxNum: 9 },
                    { pipeDiameter: 100, maxNum: 14 },
                    { pipeDiameter: 150, maxNum: 30 },
                    { pipeDiameter: 200, maxNum: 31 },
                ];
            }
            case 18.5: {
                return [
                    { pipeDiameter: 25, maxNum: 1 },
                    { pipeDiameter: 32, maxNum: 2 },
                    { pipeDiameter: 40, maxNum: 3 },
                    { pipeDiameter: 50, maxNum: 5 },
                    { pipeDiameter: 65, maxNum: 9 },
                    { pipeDiameter: 80, maxNum: 14 },
                    { pipeDiameter: 100, maxNum: 22 },
                    { pipeDiameter: 150, maxNum: 23 },
                ];
            }
            case 24.5: {
                return [
                    { pipeDiameter: 32, maxNum: 1 },
                    { pipeDiameter: 50, maxNum: 2 },
                    { pipeDiameter: 65, maxNum: 4 },
                    { pipeDiameter: 80, maxNum: 7 },
                    { pipeDiameter: 100, maxNum: 11 },
                    { pipeDiameter: 150, maxNum: 24 },
                    { pipeDiameter: 200, maxNum: 25 },
                ];
            }
            case 32.5: {
                return [
                    { pipeDiameter: 40, maxNum: 1 },
                    { pipeDiameter: 50, maxNum: 2 },
                    { pipeDiameter: 65, maxNum: 3 },
                    { pipeDiameter: 80, maxNum: 5 },
                    { pipeDiameter: 100, maxNum: 8 },
                    { pipeDiameter: 150, maxNum: 18 },
                    { pipeDiameter: 200, maxNum: 19 },
                ];
            }
            default:
                break;
        }
    }
    throw Error("仓库危险级III级-货架储存时参数错误");
}
//仓库危险级III级-堆垛储存时
function getWarehouseThird_stack(actionArea, sprayStrength) {
    if (actionArea == 240) {
        switch (sprayStrength) {
            case 12: {
                return [
                    { pipeDiameter: 25, maxNum: 1 },
                    { pipeDiameter: 32, maxNum: 2 },
                    { pipeDiameter: 40, maxNum: 3 },
                    { pipeDiameter: 50, maxNum: 5 },
                    { pipeDiameter: 65, maxNum: 9 },
                    { pipeDiameter: 80, maxNum: 14 },
                    { pipeDiameter: 100, maxNum: 22 },
                    { pipeDiameter: 150, maxNum: 27 },
                    { pipeDiameter: 200, maxNum: 28 },
                ];
            }
            case 16: {
                return [
                    { pipeDiameter: 25, maxNum: 1 },
                    { pipeDiameter: 40, maxNum: 2 },
                    { pipeDiameter: 50, maxNum: 4 },
                    { pipeDiameter: 65, maxNum: 6 },
                    { pipeDiameter: 80, maxNum: 10 },
                    { pipeDiameter: 100, maxNum: 16 },
                    { pipeDiameter: 150, maxNum: 27 },
                    { pipeDiameter: 200, maxNum: 28 },
                ];
            }
            case 16.5: {
                return [
                    { pipeDiameter: 32, maxNum: 1 },
                    { pipeDiameter: 40, maxNum: 2 },
                    { pipeDiameter: 50, maxNum: 3 },
                    { pipeDiameter: 65, maxNum: 6 },
                    { pipeDiameter: 80, maxNum: 10 },
                    { pipeDiameter: 100, maxNum: 15 },
                    { pipeDiameter: 150, maxNum: 27 },
                    { pipeDiameter: 200, maxNum: 28 },
                ];
            }
            case 18.5: {
                return [
                    { pipeDiameter: 32, maxNum: 1 },
                    { pipeDiameter: 40, maxNum: 2 },
                    { pipeDiameter: 50, maxNum: 3 },
                    { pipeDiameter: 65, maxNum: 5 },
                    { pipeDiameter: 80, maxNum: 9 },
                    { pipeDiameter: 100, maxNum: 14 },
                    { pipeDiameter: 150, maxNum: 27 },
                    { pipeDiameter: 200, maxNum: 28 },
                ];
            }
            case 20.5: {
                return [
                    { pipeDiameter: 32, maxNum: 1 },
                    { pipeDiameter: 40, maxNum: 2 },
                    { pipeDiameter: 50, maxNum: 3 },
                    { pipeDiameter: 65, maxNum: 5 },
                    { pipeDiameter: 80, maxNum: 8 },
                    { pipeDiameter: 100, maxNum: 12 },
                    { pipeDiameter: 150, maxNum: 27 },
                    { pipeDiameter: 200, maxNum: 28 },
                ];
            }
            case 22: {
                return [
                    { pipeDiameter: 32, maxNum: 1 },
                    { pipeDiameter: 50, maxNum: 3 },
                    { pipeDiameter: 65, maxNum: 5 },
                    { pipeDiameter: 80, maxNum: 7 },
                    { pipeDiameter: 100, maxNum: 12 },
                    { pipeDiameter: 150, maxNum: 27 },
                    { pipeDiameter: 200, maxNum: 28 },
                ];
            }
            case 24.5: {
                return [
                    { pipeDiameter: 32, maxNum: 1 },
                    { pipeDiameter: 50, maxNum: 2 },
                    { pipeDiameter: 65, maxNum: 4 },
                    { pipeDiameter: 80, maxNum: 6 },
                    { pipeDiameter: 100, maxNum: 10 },
                    { pipeDiameter: 150, maxNum: 24 },
                    { pipeDiameter: 200, maxNum: 25 },
                ];
            }
            case 28.5: {
                return [
                    { pipeDiameter: 40, maxNum: 1 },
                    { pipeDiameter: 50, maxNum: 2 },
                    { pipeDiameter: 65, maxNum: 3 },
                    { pipeDiameter: 80, maxNum: 5 },
                    { pipeDiameter: 100, maxNum: 9 },
                    { pipeDiameter: 150, maxNum: 20 },
                    { pipeDiameter: 200, maxNum: 21 },
                ];
            }
            case 32.5: {
                return [
                    { pipeDiameter: 40, maxNum: 1 },
                    { pipeDiameter: 50, maxNum: 2 },
                    { pipeDiameter: 65, maxNum: 3 },
                    { pipeDiameter: 80, maxNum: 5 },
                    { pipeDiameter: 100, maxNum: 8 },
                    { pipeDiameter: 150, maxNum: 18 },
                    { pipeDiameter: 200, maxNum: 19 },
                ];
            }
            case 34.5: {
                return [
                    { pipeDiameter: 40, maxNum: 1 },
                    { pipeDiameter: 65, maxNum: 3 },
                    { pipeDiameter: 80, maxNum: 5 },
                    { pipeDiameter: 100, maxNum: 7 },
                    { pipeDiameter: 150, maxNum: 18 },
                    { pipeDiameter: 200, maxNum: 35 },
                    { pipeDiameter: 250, maxNum: 36 },
                ];
            }
            case 36.5: {
                return [
                    { pipeDiameter: 40, maxNum: 1 },
                    { pipeDiameter: 65, maxNum: 3 },
                    { pipeDiameter: 80, maxNum: 4 },
                    { pipeDiameter: 100, maxNum: 7 },
                    { pipeDiameter: 150, maxNum: 16 },
                    { pipeDiameter: 200, maxNum: 30 },
                    { pipeDiameter: 250, maxNum: 31 },
                ];
            }
            default:
                break;
        }
    }
    throw Error("仓库危险级III级-堆垛储存时参数错误");
}
