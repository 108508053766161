"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainDirectionUtil = void 0;
const pipeCommonUtil_1 = require("./pipeCommonUtil");
const direction_1 = require("../direction");
const coordinateUtil_1 = require("../coordinateUtil");
const testUtil_1 = require("./testUtil");
class MainDirectionUtil {
    compute(points, doors_belongHere, mainPipes, polygonSegs) {
        //计算多边形中心
        let polygonCenter = pipeCommonUtil_1.PipeCommonUtil.computePolygonCenter(points);
        //选择距离多边形中心的门
        let nearestDoor = this.computeNearestDoorToPolygonCenter(doors_belongHere, polygonCenter);
        //计算多边形主方向
        let mainDirectionOfPolygon = new direction_1.DirectionManager().computeMainDirection(polygonSegs);
        //计算离门最近的主管道
        let nearestMainPipe = this.computeNearestMainPipe(nearestDoor, mainPipes);
        //如果主管线方向和门的方向不为平行或垂直，则选用门的方向
        let nearestMainPipeDirectionAngle = this.computeDirection(nearestMainPipe);
        let nearestDoorDirectionAngle = this.computeDirection(nearestDoor);
        //这里有三个角度，优先级最高是房间的角度，其次是门，最后是主管线
        let mainDirectionAngle = this.computeMainDirection(nearestDoorDirectionAngle, mainDirectionOfPolygon);
        mainDirectionAngle = this.computeMainDirection(nearestMainPipeDirectionAngle, mainDirectionAngle);
        //根据主方向的角度，建立局部坐标系
        //以多边形中心作为原点，并且以主方向角度的负数进行旋转，将主方向旋转至X轴正方形
        let coordinateUtil = new coordinateUtil_1.CoordinateUtil(polygonCenter, mainDirectionAngle);
        testUtil_1.TestUtil.coordinateUtil = coordinateUtil;
        return {
            coordinateUtil: coordinateUtil,
            nearestMainPipe: nearestMainPipe,
        };
    }
    computeMainDirection(angle1, angle2) {
        let diffAngle = Math.abs(angle1 - angle2) * 180 / Math.PI;
        let mainDirectionAngle;
        const angleTolerance = 0.01;
        if (diffAngle < angleTolerance || Math.abs(diffAngle - 90) < angleTolerance || Math.abs(diffAngle - 180) < angleTolerance) {
            mainDirectionAngle = angle1;
        }
        else {
            mainDirectionAngle = angle2;
        }
        return mainDirectionAngle;
    }
    computeNearestDoorToPolygonCenter(doors_belongHere, polygonCenter) {
        doors_belongHere.sort(function (a, b) { return a.distance(polygonCenter) - b.distance(polygonCenter); });
        let minDisDoor = doors_belongHere[0];
        //如果minDisDoor的角度不为直角角度，则看看有没有直角角度的门
        if (this.isRightAnlge(this.computeDirection(minDisDoor))) {
            return minDisDoor;
        }
        else {
            for (let i = 1; i < doors_belongHere.length; i++) {
                let door = doors_belongHere[i];
                if (this.isRightAnlge(this.computeDirection(door))) {
                    return door;
                }
            }
            return minDisDoor;
        }
    }
    computeNearestMainPipe(nearestDoor, mainPipes) {
        let minDis = Number.POSITIVE_INFINITY;
        let minDisPipe;
        for (let pipe of mainPipes) {
            let dis = pipe.distance(nearestDoor);
            if (dis < minDis) {
                minDis = dis;
                minDisPipe = pipe;
            }
        }
        return minDisPipe;
    }
    computeDirection(nearestDoor) {
        //选距离多边形中心最近的门的方向作为房间的主方向
        return new direction_1.DirectionManager().computeMainDirection([nearestDoor]);
    }
    isRightAnlge(angle) {
        let angle_abs = Math.abs(angle);
        return angle_abs < 1 * Math.PI / 180 || angle_abs > 89 * Math.PI / 180;
    }
}
exports.MainDirectionUtil = MainDirectionUtil;
