"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawEllipseArc = exports.drawEllipse = void 0;
const ltentity_1 = require("../cad/ltentity");
const ltpoint_1 = require("../cad/ltpoint");
const commandfactory_1 = require("../map/commandfactory");
const drawutils_1 = require("./drawutils");
//绘制椭圆;
function* drawEllipse(map, command_param) {
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定椭圆的轴端点:");
    let input_point = yield;
    if (input_point.done == commandfactory_1.CommandStatus.eCancel || input_point.done == commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: input_point.done };
    }
    let start_point = input_point.value;
    let track_line = new ltentity_1.LTLine(start_point, start_point);
    function lineCallback(mouse) {
        track_line.setEnd(mouse.mapPos);
        drawutils_1.DrawUtilImpl.drawDashLine(map, track_line);
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定轴的另一端点:", lineCallback);
    let other_point = yield;
    if (other_point.done == commandfactory_1.CommandStatus.eCancel || other_point.done == commandfactory_1.CommandStatus.eDone) {
        drawutils_1.DrawUtilImpl.destroyDashEntity(map, track_line);
        return { value: null, done: other_point.done };
    }
    let end_point = other_point.value;
    let center_point = new ltpoint_1.LTPoint((start_point.x + end_point.x) / 2, (start_point.y + end_point.y) / 2);
    let ellipse = new ltentity_1.LTEllipse(center_point, start_point.minus(center_point), 1, 0, 2 * Math.PI, false);
    let max_length = center_point.getDistanceTo(start_point);
    let ellipse_data = ellipse.subData();
    function centerCallback(mouse) {
        let length = mouse.mapPos.getDistanceTo(center_point);
        let temp_data = ellipse_data.clone();
        temp_data.setRatio(length / max_length);
        ellipse.data = temp_data;
        drawutils_1.DrawUtilImpl.trackEntity(map, ellipse);
    }
    drawutils_1.DrawUtilImpl.destroyDashEntity(map, track_line);
    commandfactory_1.CommandFactory.getInstance().getDistance(map, "指定另一条半轴长度:", centerCallback, center_point);
    let length_val = yield;
    if (length_val.done == commandfactory_1.CommandStatus.eCancel) {
        drawutils_1.DrawUtilImpl.destroyEntity(map, ellipse);
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    if (length_val.done == commandfactory_1.CommandStatus.eDone) {
        drawutils_1.DrawUtilImpl.destroyEntity(map, ellipse);
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let sub_ratio = length_val.value / max_length;
    let result_data = ellipse_data.clone();
    result_data.setRatio(sub_ratio);
    ellipse.data = result_data;
    drawutils_1.DrawUtilImpl.saveEntity(map, ellipse);
    drawutils_1.DrawUtilImpl.destroyEntity(map, ellipse);
    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
}
exports.drawEllipse = drawEllipse;
//绘制椭圆弧;
function* drawEllipseArc(map, command_param) {
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定椭圆的轴端点:");
    let input_point = yield;
    if (input_point.done == commandfactory_1.CommandStatus.eCancel || input_point.done == commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: input_point.done };
    }
    let start_point = input_point.value;
    let track_line = new ltentity_1.LTLine(start_point, start_point);
    function lineCallback(mouse) {
        track_line.setEnd(mouse.mapPos);
        drawutils_1.DrawUtilImpl.drawDashLine(map, track_line);
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定轴的另一端点:", lineCallback);
    let other_point = yield;
    if (other_point.done == commandfactory_1.CommandStatus.eCancel || other_point.done == commandfactory_1.CommandStatus.eDone) {
        drawutils_1.DrawUtilImpl.destroyDashEntity(map, track_line);
        return { value: null, done: other_point.done };
    }
    let end_point = other_point.value;
    let center_point = new ltpoint_1.LTPoint((start_point.x + end_point.x) / 2, (start_point.y + end_point.y) / 2);
    let start_angle = 0;
    let end_angle = 2 * Math.PI;
    let ellipse = new ltentity_1.LTEllipse(center_point, start_point.minus(center_point), 1, 0, 2 * Math.PI, false);
    let max_length = center_point.getDistanceTo(start_point);
    let ellipse_data = ellipse.subData();
    function centerCallback(mouse) {
        let length = mouse.mapPos.getDistanceTo(center_point);
        let temp_data = ellipse_data.clone();
        temp_data.setRatio(length / max_length);
        ellipse.data = temp_data;
        drawutils_1.DrawUtilImpl.trackEntity(map, ellipse);
    }
    drawutils_1.DrawUtilImpl.destroyDashEntity(map, track_line);
    commandfactory_1.CommandFactory.getInstance().getDistance(map, "指定另一条半轴长度:", centerCallback, center_point);
    let length_val = yield;
    if (length_val.done == commandfactory_1.CommandStatus.eCancel) {
        drawutils_1.DrawUtilImpl.destroyEntity(map, ellipse);
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    if (length_val.done == commandfactory_1.CommandStatus.eDone) {
        drawutils_1.DrawUtilImpl.destroyEntity(map, ellipse);
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let result_data = ellipse_data.clone();
    result_data.setRatio(length_val.value / max_length);
    ellipse.data = result_data;
    function startCallback(mouse) {
        let start_param = mouse.mapPos.getAngleTo(center_point);
        ellipse.subData().setStartParam(start_param);
        drawutils_1.DrawUtilImpl.trackEntity(map, ellipse);
    }
    commandfactory_1.CommandFactory.getInstance().getAngle(map, "指定起始角度:", startCallback, center_point);
    let start_val = yield;
    if (start_val.done == commandfactory_1.CommandStatus.eCancel || start_val.done == commandfactory_1.CommandStatus.eDone) {
        drawutils_1.DrawUtilImpl.destroyEntity(map, ellipse);
        return { value: null, done: start_val.done };
    }
    ellipse.subData().setStartParam(start_val.value - Math.PI);
    function endCallback(mouse) {
        let end_param = mouse.mapPos.getAngleTo(center_point);
        ellipse.subData().setEndParam(end_param);
        drawutils_1.DrawUtilImpl.trackEntity(map, ellipse);
    }
    commandfactory_1.CommandFactory.getInstance().getAngle(map, "指定终止角度:", endCallback, center_point);
    let end_val = yield;
    if (end_val.done == commandfactory_1.CommandStatus.eCancel || end_val.done == commandfactory_1.CommandStatus.eDone) {
        drawutils_1.DrawUtilImpl.destroyEntity(map, ellipse);
        return { value: null, done: end_val.done };
    }
    let end_param = end_val.value;
    ellipse.subData().setEndParam(end_param - Math.PI);
    drawutils_1.DrawUtilImpl.saveEntity(map, ellipse);
    drawutils_1.DrawUtilImpl.destroyEntity(map, ellipse);
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.drawEllipseArc = drawEllipseArc;
