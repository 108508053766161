"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PipeSizeMap = void 0;
class PipeSizeMap {
    constructor(relationshipArray) {
        this._relationshipList = relationshipArray.slice();
    }
    //管径前要加个DN
    getPipeSizeStr(count) {
        for (let relationship of this._relationshipList) {
            if (count <= relationship.maxNum) {
                return "DN" + relationship.pipeDiameter;
            }
        }
        return "DN" + this._relationshipList[this._relationshipList.length - 1].pipeDiameter;
    }
}
exports.PipeSizeMap = PipeSizeMap;
