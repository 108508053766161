"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SnapAuto = exports.Mode = void 0;
const ltpoint_1 = require("../cad/ltpoint");
const ltentity_1 = require("../cad/ltentity");
const commandfactory_1 = require("./commandfactory");
const segment_1 = require("../cad/segment");
const ltmath_1 = require("../cad/ltmath");
const RMAXDOUBLE = 10000000000;
const Status = {
    EndPoint: 1,
    OnEntity: 2,
    Middle: 3
};
class Snap {
    constructor() {
        this._entity = null;
    }
    snapEntities(pos, cadmap, entities, queryBox) {
        var minDis = -1;
        var lastsnap_point = null;
        let feature = null;
        entities.forEach(entitiy => {
            var point_list = this.snapEntity(entitiy, pos, queryBox);
            point_list.forEach(point => {
                var dis = point.getDistanceTo(pos);
                if (minDis < 0 || dis < minDis) {
                    minDis = dis;
                    lastsnap_point = point;
                    this._entity = feature;
                }
            });
        });
        return lastsnap_point;
    }
}
class SnapOnEntity extends Snap {
    constructor() {
        super();
    }
    snapEntity(entity, point, queryBox) {
        var ret = [];
        var last_point = entity.getClosestPointOnEntity(point, queryBox.getWidth() / 2, true);
        if (last_point == null) {
            return ret;
        }
        ret.push(last_point);
        return ret;
    }
}
class SnapMiddle extends Snap {
    constructor() {
        super();
    }
    snapEntity(entity, point, queryBox) {
        return entity.getMiddlePoints(queryBox);
    }
}
class SnapCenter extends Snap {
    constructor() {
        super();
    }
    snapEntity(entity, point, queryBox) {
        return entity.getCenterPoints(queryBox);
    }
}
class SnapPerpendicular extends Snap {
    constructor() {
        super();
    }
    snapEntity(entity, point, queryBox) {
        let last_post = commandfactory_1.CommandFactory.getInstance().lastPosition;
        if (last_post == null) {
            return [];
        }
        let shape = entity.data.getClosestShape(point, queryBox.getWidth() / 2, true);
        let ret = [];
        if (shape != null) {
            let result_point = shape.getClosestPointOnShape(last_post, false);
            //如果这两个点是同一个点，那么不做处理;
            if (result_point != null && result_point.getDistanceTo(last_post) > 0.001) {
                ret.push(result_point);
            }
        }
        return ret;
    }
}
class SnapEnd extends Snap {
    constructor() {
        super();
    }
    snapEntity(entity, point, queryBox) {
        return entity.getEndPoints(queryBox);
    }
}
class SnapIntersection extends Snap {
    constructor() {
        super();
    }
    snapEntity(entity, point, queryBox) {
        return [];
    }
    snapEntities(pos, cadmap, entities, queryBox) {
        let lastSnap = null;
        let minDist = RMAXDOUBLE;
        this._entity = null;
        for (let i = 0; i < entities.length; ++i) {
            let geo1 = entities[i];
            for (var j = i; j < entities.length; ++j) {
                var geo2 = entities[j];
                var ipsCandidates = geo1.getIntersectionPoints(geo2, true, queryBox);
                if (ipsCandidates.length == 0) {
                    continue;
                }
                // find closest intersection point:
                var idx = pos.getClosestIndex(ipsCandidates);
                if (idx < 0) {
                    continue;
                }
                var ipCandidate = ipsCandidates[idx];
                if (ipCandidate == null) {
                    continue;
                }
                let dist = ipCandidate.getDistanceTo(pos);
                if (minDist < 0 || dist < minDist) {
                    lastSnap = ipCandidate;
                    minDist = dist;
                    this._entity = entities[j];
                }
            }
        }
        return lastSnap;
    }
}
var Mode;
(function (Mode) {
    Mode[Mode["None"] = 0] = "None";
    Mode[Mode["Intersections"] = 1] = "Intersections";
    Mode[Mode["EndPoints"] = 2] = "EndPoints";
    Mode[Mode["MiddlePoints"] = 4] = "MiddlePoints";
    Mode[Mode["CenterPoints"] = 8] = "CenterPoints";
    Mode[Mode["PointsOnEntity"] = 256] = "PointsOnEntity";
    Mode[Mode["Perpendicular"] = 512] = "Perpendicular";
    Mode[Mode["PolarAxis"] = 1024] = "PolarAxis";
})(Mode || (exports.Mode = Mode = {}));
;
class SnapAuto extends Snap {
    //像素距离转地理距离,默认六个像素;
    constructor() {
        super();
        this._is_snap_enable = true;
        this._feature = null;
        this.refPoint = null;
        //对象捕捉追踪,存储角度列表;
        this._polarAngles = [];
        //是否使用相对极轴;
        this._is_relative_polar_axis = false;
        //是否为固定角度绘制;
        this._is_fixed_angle = false;
        //是否开启极轴追踪功能;
        this._is_polar_axis_enable = true;
        //是否正交
        this._orthogonalEnable = false;
        //角度增量;
        this._angle_increment = 90;
        this._mode = Mode.EndPoints | Mode.MiddlePoints | Mode.CenterPoints | Mode.Intersections | Mode.PointsOnEntity | Mode.Perpendicular;
    }
    toJSON() {
        return {
            mode: this._mode,
            polarAngles: this._polarAngles,
            is_relative_polar_axis: this._is_relative_polar_axis,
            is_fixed_angle: this._is_fixed_angle,
            is_polar_axis_enable: this._is_polar_axis_enable,
            orthogonalEnable: this._orthogonalEnable,
            angleIncrement: this._angle_increment
        };
    }
    fromJson(json_string) {
        let json = JSON.parse(json_string);
        this._mode = json.mode;
        for (let sub_angle of json.polarAngles) {
            this._polarAngles.push(sub_angle * 1.0);
        }
        this._is_relative_polar_axis = json.is_relative_polar_axis;
        this._is_fixed_angle = json.is_fixed_angle;
        this._is_polar_axis_enable = json.is_polar_axis_enable;
        this._orthogonalEnable = json.orthogonalEnable || false;
        this._angle_increment = json.angleIncrement;
        this._mode = Mode.EndPoints | Mode.MiddlePoints | Mode.CenterPoints | Mode.Intersections | Mode.PointsOnEntity;
    }
    //是否正交;
    get orthogonalEnable() {
        return this._orthogonalEnable;
    }
    set orthogonalEnable(enable) {
        this._orthogonalEnable = enable;
    }
    //角度增量;
    get angleIncrement() {
        return this._angle_increment;
    }
    set angleIncrement(angle) {
        this._angle_increment = angle;
    }
    get polarAxisEnable() {
        return this._is_polar_axis_enable;
    }
    set polarAxisEnable(enable) {
        this._is_polar_axis_enable = enable;
    }
    get relativePolarAxis() {
        return this._is_relative_polar_axis;
    }
    set relativePolarAxis(enable) {
        this._is_relative_polar_axis = enable;
    }
    getMode(mode, current_mode) {
        return (this._mode & current_mode) == current_mode;
    }
    snapEntity(entity, point, queryBox) {
        return [];
    }
    get snapEnable() {
        return this._is_snap_enable;
    }
    set snapEnable(enable) {
        this._is_snap_enable = enable;
    }
    hiddenDisplay() {
        if (this._ui != null) {
            this._ui.clear();
        }
        if (this._polar_graphics != null) {
            this._polar_graphics.visible = false;
        }
    }
    clear(cadmap) {
        if (this._ui == null) {
            return;
        }
        this._ui.clear();
        this._feature = null;
        if (this._polar_graphics != null) {
            this._polar_graphics.visible = false;
        }
    }
    setFeature(features) {
        this._feature = features;
    }
    initDisplay(cadmap, status, map_pos, range) {
        if (this._polar_graphics != null) {
            this._polar_graphics.visible = false;
        }
        this._ui.clear();
        const snap_color = 0x00ff00;
        if (status == Mode.PointsOnEntity) {
            this._ui.lineStyle({ width: 2, color: snap_color, native: true }); //边框色
            this._ui.moveTo(map_pos.x - range, map_pos.y - range);
            this._ui.lineTo(map_pos.x + range, map_pos.y + range);
            this._ui.lineTo(map_pos.x - range, map_pos.y + range);
            this._ui.lineTo(map_pos.x + range, map_pos.y - range);
            this._ui.lineTo(map_pos.x - range, map_pos.y - range);
        }
        else if (status == Mode.Intersections) {
            this._ui.lineStyle({ width: 2, color: snap_color, native: true }); //边框色
            this._ui.moveTo(map_pos.x - range, map_pos.y - range);
            this._ui.lineTo(map_pos.x + range, map_pos.y + range);
            this._ui.moveTo(map_pos.x - range, map_pos.y + range);
            this._ui.lineTo(map_pos.x + range, map_pos.y - range);
        }
        else if (status == Mode.EndPoints) {
            this._ui.lineStyle({ width: 2, color: snap_color, native: true }); //边框色
            //绘制一个正方形;
            this._ui.moveTo(map_pos.x - range, map_pos.y - range);
            this._ui.lineTo(map_pos.x + range, map_pos.y - range);
            this._ui.lineTo(map_pos.x + range, map_pos.y + range);
            this._ui.lineTo(map_pos.x - range, map_pos.y + range);
            this._ui.lineTo(map_pos.x - range, map_pos.y - range);
        }
        else if (status == Mode.MiddlePoints) {
            this._ui.lineStyle({ width: 2, color: snap_color, native: true }); //边框色
            //绘制三角形点;
            this._ui.moveTo(map_pos.x, map_pos.y + range);
            this._ui.lineTo(map_pos.x + range, map_pos.y - range);
            this._ui.lineTo(map_pos.x - range, map_pos.y - range);
            this._ui.lineTo(map_pos.x, map_pos.y + range);
        }
        else if (status == Mode.Perpendicular) {
            this._ui.lineStyle({ width: 2, color: snap_color, native: true }); //边框色
            this._ui.moveTo(map_pos.x - range, map_pos.y + range);
            this._ui.lineTo(map_pos.x - range, map_pos.y - range);
            this._ui.lineTo(map_pos.x + range, map_pos.y - range);
            this._ui.moveTo(map_pos.x - range, map_pos.y);
            this._ui.lineTo(map_pos.x, map_pos.y);
            this._ui.lineTo(map_pos.x, map_pos.y - range);
        }
        else if (status == Mode.PolarAxis) {
            let lastPosition = commandfactory_1.CommandFactory.getInstance().lastPosition;
            if (lastPosition !== null) {
                //线与屏幕的焦点
                let screen1 = cadmap.toScreenPoint(map_pos.x, map_pos.y);
                let screen2 = cadmap.toScreenPoint(lastPosition.x, lastPosition.y);
                let line = new segment_1.RLine(screen1, screen2);
                var temp_points = [];
                temp_points.push(new ltpoint_1.LTPoint(0, 0));
                temp_points.push(new ltpoint_1.LTPoint(0, cadmap.canvs_height));
                temp_points.push(new ltpoint_1.LTPoint(cadmap.canvs_width, cadmap.canvs_height));
                temp_points.push(new ltpoint_1.LTPoint(cadmap.canvs_width, 0));
                temp_points.push(new ltpoint_1.LTPoint(0, 0));
                let polyline = new segment_1.RPolyline(temp_points);
                let p11 = line.getIntersectionPoints(polyline, false);
                let mp = new ltpoint_1.LTPoint(0, 0, 0, false);
                for (let p1 of p11) {
                    let line2 = new segment_1.RLine(screen2, p1);
                    let bsd = line2.getDistanceTo(screen1, true);
                    if (line2.getDistanceTo(screen1, true) <= 1.0e-6) {
                        mp = p1;
                        break;
                    }
                }
                if (mp.valid) {
                    const shader = new window.PIXI.smooth.DashLineShader({ dash: 5, gap: 5 });
                    this._ui.lineStyle({ width: 2, color: 0x00FF00, native: true, shader: shader }); //边框色
                    this._ui.moveTo(map_pos.x, map_pos.y);
                    let p12 = cadmap.toMapPoint(mp.x, mp.y);
                    this._ui.lineTo(p12.x, p12.y);
                }
            }
            this._ui.lineStyle({ width: 2, color: 0xFFFFFF, native: true }); //边框色
            this._ui.moveTo(map_pos.x - range, map_pos.y - range);
            this._ui.lineTo(map_pos.x + range, map_pos.y + range);
            this._ui.moveTo(map_pos.x - range, map_pos.y + range);
            this._ui.lineTo(map_pos.x + range, map_pos.y - range);
            this._ui.endFill();
            if (this._polar_graphics != null) {
                this._polar_graphics.visible = true;
            }
        }
        else if (status == Mode.CenterPoints) {
            this._ui.lineStyle({ width: 2, color: snap_color, native: true }); //边框色
            this._ui.drawCircle(map_pos.x, map_pos.y, range);
        }
        this._ui.endFill();
    }
    set polarAngles(angles) {
        this._polarAngles = angles;
    }
    get polarAngles() {
        return this._polarAngles;
    }
    //是否为固定角度;
    get fixedAngle() {
        return this._is_fixed_angle;
    }
    set fixedAngle(enable) {
        this._is_fixed_angle = enable;
    }
    //相对极轴
    snapPolarAxis(snap_pos, map) {
        if (!this._is_polar_axis_enable) {
            return null;
        }
        if (this._feature == null) {
            return null;
        }
        let angles = [];
        let is_relative_polar_axis = this._is_relative_polar_axis;
        let fixed_angle = this._is_fixed_angle;
        if (this.orthogonalEnable) {
            angles = [0, 90, 180, 270, 360];
            is_relative_polar_axis = false;
            fixed_angle = true;
        }
        else {
            for (let angle = 0; angle <= 360; angle += this._angle_increment) {
                angles.push(angle);
            }
            angles = angles.concat(this._polarAngles);
            //去重;
            angles = Array.from(new Set(angles));
            angles.sort(function (a, b) { return a - b; });
        }
        const showText = (length, angle) => {
            let polar_text = "极轴:";
            if (is_relative_polar_axis) {
                polar_text = "相对极轴:";
            }
            polar_text += `${length.toFixed(3)}<${angle}`;
            let sceen_point = map.toScreenPoint(snap_pos.x, snap_pos.y);
            this._polar_graphics.position.x = sceen_point.x + 20;
            this._polar_graphics.position.y = sceen_point.y + 20;
            this._polar_graphics.text = polar_text;
        };
        const getAngle = (current_radian) => {
            let snap_angle = null;
            let angle = current_radian * 180 / Math.PI;
            if (fixed_angle == false) {
                for (let sub_snap_angle of angles) {
                    if (Math.abs(sub_snap_angle - angle) < 5) {
                        snap_angle = sub_snap_angle;
                        break;
                    }
                }
            }
            else {
                //只能按固定几个角度绘制的逻辑;
                let min_angle = Number.MAX_VALUE;
                for (let sub_snap_angle of angles) {
                    if (Math.abs(sub_snap_angle - angle) < min_angle) {
                        min_angle = Math.abs(sub_snap_angle - angle);
                        snap_angle = sub_snap_angle;
                    }
                }
            }
            if (snap_angle == null) {
                return null;
            }
            return snap_angle;
        };
        //求两条线的夹角
        let radian = 0;
        if (this._is_relative_polar_axis == true) {
            let polyline = this._feature.getGeometry();
            if (polyline == null) {
                return null;
            }
            let vertices = polyline.subData().vertices;
            if (vertices.length < 2) {
                return null;
            }
            let fixed_first_point;
            let fixed_second_point;
            fixed_first_point = vertices[vertices.length - 2];
            fixed_second_point = vertices[vertices.length - 1];
            let radian1 = fixed_first_point.getAngleTo(fixed_second_point);
            let radian2 = fixed_second_point.getAngleTo(snap_pos);
            radian = ltmath_1.LTMath.getAngleDifference(radian1, radian2);
            let axis_angle = getAngle(radian);
            if (axis_angle == null) {
                return null;
            }
            //求点到线的距离;
            let axis = new ltpoint_1.LTPoint(0, 0);
            axis.setPolar(fixed_first_point.getDistanceTo(fixed_second_point), radian1 + axis_angle * Math.PI / 180);
            let new_pos = fixed_second_point.plus(axis);
            let line = new segment_1.RLine(fixed_second_point, new_pos);
            showText(fixed_second_point.getDistanceTo(new_pos), axis_angle);
            return line.getClosestPointOnEntity(snap_pos, 1000, false);
        }
        else {
            let polyline = this._feature.getGeometry();
            if (polyline == null) {
                return null;
            }
            let last_pos = commandfactory_1.CommandFactory.getInstance().lastPosition;
            if (last_pos == null) {
                return null;
            }
            radian = last_pos.getAngleTo(snap_pos);
            let axis_angle = getAngle(radian);
            if (axis_angle == null) {
                return null;
            }
            let axis = new ltpoint_1.LTPoint(0, 0);
            axis.setPolar(1000, axis_angle * Math.PI / 180);
            let new_pos = last_pos.plus(axis);
            let line = new segment_1.RLine(last_pos, new_pos);
            let sceen_point = map.toScreenPoint(snap_pos.x, snap_pos.y);
            this._polar_graphics.position.x = sceen_point.x + 20;
            this._polar_graphics.position.y = sceen_point.y + 20;
            let default_length = 1000;
            if (fixed_angle) {
                default_length = 10000000;
            }
            let result_point = line.getClosestPointOnEntity(snap_pos, default_length, false);
            if (result_point != null) {
                showText(last_pos.getDistanceTo(result_point), axis_angle);
            }
            return result_point;
        }
    }
    _snap(snap_pos, cadmap, snap_mode = null) {
        let mode = this._mode;
        if (snap_mode != null) {
            mode = snap_mode;
        }
        if (this._ui == null) {
            this._ui = new window.PIXI.smooth.SmoothGraphics();
            cadmap.addGraphics(this._ui, cadmap.mapContainer);
        }
        if (this._polar_graphics == null) {
            this._polar_graphics = new window.PIXI.BitmapText("", {
                fontName: "正等线体", //字体名字就是我加载的fnt中的字体名,注意的是可能有文件名不相同
                fontSize: 20,
                align: "left",
                tint: "0xFFFFFF"
            });
            this._polar_graphics.visible = false;
            cadmap.addScreenGraphics(this._polar_graphics);
        }
        if (this._is_snap_enable == false) {
            return snap_pos;
        }
        var pos = new ltpoint_1.LTPoint(snap_pos.x, snap_pos.y);
        //像素距离转地理距离,默认六个像素;
        var range_distance = 6 / cadmap.mapScale();
        var ret = null;
        this.refPoint = null;
        this._entity = null;
        let current_state = 0;
        let box = new ltpoint_1.RBox(pos.x - range_distance, pos.y - range_distance, pos.x + range_distance, pos.y + range_distance);
        let features = cadmap.selectFeatures(box);
        //设置相对极轴;
        let polar_axis = this.snapPolarAxis(snap_pos, cadmap);
        if (polar_axis != null) {
            this.initDisplay(cadmap, Mode.PolarAxis, polar_axis, range_distance);
            this.refPoint = polar_axis;
            return polar_axis;
        }
        if (this._feature != null) {
            features.push(this._feature);
        }
        if (features.length == 0) {
            this.hiddenDisplay();
            return snap_pos;
        }
        let entities = [];
        for (let feature of features) {
            let geo = feature.getGeometry();
            if (geo instanceof ltentity_1.LTEntityPoint) {
                let block_reference = cadmap.dwgservice.getBlock(geo.blockName);
                if (block_reference == null) {
                    continue;
                }
                let sub_entities = block_reference.spatialIndex().getEntitys(geo, box);
                entities.push(...sub_entities);
            }
            else {
                entities.push(geo);
            }
        }
        if (entities.length > 10) {
            entities = entities.slice(0, 10);
        }
        if (ret == null && this.getMode(mode, Mode.Perpendicular)) {
            var snapPerpenr = new SnapPerpendicular();
            var lastSnap = snapPerpenr.snapEntities(pos, cadmap, entities, box);
            if (lastSnap != null && lastSnap.getDistanceTo(pos) < range_distance) {
                ret = lastSnap;
                this._entity = snapPerpenr._entity;
                current_state = Mode.Perpendicular;
            }
        }
        if (ret == null && this.getMode(mode, Mode.Intersections)) {
            var snapIntersection = new SnapIntersection();
            var lastSnap = snapIntersection.snapEntities(pos, cadmap, entities, box);
            if (lastSnap != null && lastSnap.getDistanceTo(pos) < range_distance) {
                ret = lastSnap;
                this._entity = snapIntersection._entity;
                current_state = Mode.Intersections;
            }
        }
        if (ret == null && this.getMode(mode, Mode.EndPoints)) {
            var snapEnd = new SnapEnd();
            var lastSnap = snapEnd.snapEntities(pos, cadmap, entities, box);
            if (lastSnap != null && lastSnap.getDistanceTo(pos) < range_distance) {
                ret = lastSnap;
                this._entity = snapEnd._entity;
                current_state = Mode.EndPoints;
            }
        }
        if (ret == null && this.getMode(mode, Mode.MiddlePoints)) {
            var snap_middle = new SnapMiddle();
            var lastSnap = snap_middle.snapEntities(pos, cadmap, entities, box);
            if (lastSnap !== null && lastSnap.getDistanceTo(pos) < range_distance) {
                ret = lastSnap;
                this._entity = snap_middle._entity;
                current_state = Mode.MiddlePoints;
            }
        }
        if (ret == null && this.getMode(mode, Mode.CenterPoints)) {
            var snap_center = new SnapCenter();
            var lastSnap = snap_center.snapEntities(pos, cadmap, entities, box);
            if (lastSnap != null && lastSnap.getDistanceTo(pos) < range_distance) {
                ret = lastSnap;
                current_state = Mode.CenterPoints;
            }
        }
        if (ret == null && this.getMode(mode, Mode.PointsOnEntity)) {
            var snap_entity = new SnapOnEntity();
            var lastSnap = snap_entity.snapEntities(pos, cadmap, entities, box);
            if (lastSnap != null && lastSnap.getDistanceTo(pos) < range_distance) {
                ret = lastSnap;
                this._entity = snap_entity._entity;
                current_state = Mode.PointsOnEntity;
            }
        }
        if (ret == null) {
            this.hiddenDisplay();
            return snap_pos;
        }
        this.initDisplay(cadmap, current_state, ret, range_distance);
        this.refPoint = ret;
        return ret;
    }
    snap(snap_pos, cadmap, snap_mode = null) {
        let result_point = this._snap(snap_pos, cadmap, snap_mode);
        return result_point;
    }
}
exports.SnapAuto = SnapAuto;
