"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DwgService = void 0;
const ltvectorlayer_1 = require("./data/ltvectorlayer");
const pako = __importStar(require("pako"));
let fields = [
    "id",
    "linetype",
    "color",
    "entitylayer",
    "blockname",
    "entitytype",
    "text",
    "rotate",
    "width",
    "info",
    "cadgeo",
    "xdata",
];
class DwgService {
    constructor(options) {
        this._token = "";
        this._productId = "";
        this.serviceUrl = options.serviceUrl;
        this.mapid = options.mapid;
        this._token = options.token;
        this._productId = options.productId;
    }
    get token() {
        return this._token;
    }
    set token(to) {
        this._token = to;
    }
    //加载dwg数据和initDataTables功能一致，initDataTables是按图层分次加载，initData是一次性加载
    initData(map, process_callback) {
        var xhr = new XMLHttpRequest();
        let url = this.serviceUrl + `geobuf/${this.mapid}?geom_column=shape&columns=`;
        for (var i = 0; i < fields.length; i++) {
            if (i == 0) {
                url = url + fields[i];
                continue;
            }
            url = url + "," + fields[i];
        }
        //  url = url + `&filter=entitylayer='${0}'`;
        xhr.open("get", url, true);
        xhr.responseType = "arraybuffer";
        if (this.token !== null) {
            xhr.setRequestHeader("Authorization", "Bearer " + this.token);
        }
        var service = map.dwgservice;
        xhr.onload = function (e) {
            if (service === null) {
                return;
            }
            if (this.status == 200) {
                //解析数据;
                // var geojson = geobuf.decode(new Pbf(xhr.response));
                if (xhr.response.byteLength !== 0) {
                    let start_time = Date.now();
                    let binData = Buffer.from(xhr.response);
                    let geojson_str = pako.inflate(binData, { to: 'string' });
                    let geo_json = JSON.parse(geojson_str);
                    if (geo_json !== undefined) {
                        service.setFeatures(geo_json, null);
                    }
                    console.log("load time:", Date.now() - start_time);
                    map.render(true, process_callback);
                    console.log("reader time:", Date.now() - start_time);
                }
                else {
                    //走空渲染，新的dwg；
                    map.render(true, process_callback);
                }
            }
            else if (this.status == 400) {
                //图层为空 不处理
                window.onClearError();
            }
            service.loadLayerFinal();
        };
        xhr.send(null);
    }
    initDataTables(map, process_callback) {
        const getLayer = (layer) => {
            return new Promise((resolve, reject) => {
                var xhr = new XMLHttpRequest();
                let url = this.serviceUrl + `geobuf/${this.mapid}?geom_column=shape&columns=`;
                for (var i = 0; i < fields.length; i++) {
                    if (i == 0) {
                        url = url + fields[i];
                        continue;
                    }
                    url = url + "," + fields[i];
                }
                url = url + "&filter=entitylayer='" + layer.name + "'";
                //  url = url + `&filter=entitylayer='${0}'`;
                xhr.open("get", url, true);
                xhr.responseType = "arraybuffer";
                if (this.token !== null) {
                    xhr.setRequestHeader("Authorization", "Bearer " + this.token);
                }
                var service = map.dwgservice;
                xhr.onload = function (e) {
                    if (service === null) {
                        return;
                    }
                    if (this.status == 200) {
                        if (xhr.response.byteLength !== 0) {
                            let binData = Buffer.from(xhr.response);
                            let geojson_str = pako.inflate(binData, { to: 'string' });
                            let geo_json = JSON.parse(geojson_str);
                            if (geo_json !== undefined) {
                                service.setFeatures(geo_json, layer);
                            }
                            let start_time = Date.now();
                            // map.render(true,process_callback);
                            console.log("load time:", Date.now() - start_time);
                        }
                        else {
                            //走空渲染，新的dwg；
                            //map.render(true,process_callback);
                            resolve('ok');
                        }
                    }
                    else if (this.status == 400) {
                        //图层为空 不处理
                        window.onClearError();
                        resolve('ok');
                    }
                    service.loadLayerFinal();
                    resolve('ok');
                };
                xhr.send(null);
            });
        };
        let promises = [];
        map.getLayers().forEach((layer) => {
            if (layer instanceof ltvectorlayer_1.LTVectorlayer) {
                promises.push(getLayer(layer).then(() => { }));
            }
        });
        Promise.all(promises).then(() => {
            map.render(true, process_callback);
        });
    }
    initStyle(cadmap, process_callback) {
        //送数据库里面获取线形样式;
        var xhr = new XMLHttpRequest();
        var service = cadmap.dwgservice;
        xhr.open("get", this.serviceUrl + `total/${this.mapid}`, false);
        if (this.token !== null) {
            xhr.setRequestHeader("Authorization", "Bearer " + this.token);
        }
        xhr.onload = function (e) {
            if (this.status == 200) {
                var json_data = JSON.parse(xhr.responseText);
                //加载基本线形;
                if (service === null) {
                    return;
                }
                service.setLinetypes(json_data.data.lineTypeNames);
                //加载图层数据;
                service.setLayerInfo(json_data.data.layerColors, process_callback);
                service.setDimStyle(json_data.data.dimstyle);
                service.setHatchs(json_data.data.hatchs);
                //let binData = Buffer.from(json_data.data.path);
                //let blocks=pako.inflate(binData,{ to: 'string' });
                service.setBlocks(json_data.data.textblocks);
                service.setLtScale(json_data.data.ltscale * 1);
                service.setCurrentInfo(json_data.data.currentinfo);
                //let t = '[{"bigFontFileName":"","fileName":"","isBackwards":false,"isShapeFile":false,"isUpsideDown":false,"isVertical":false,"name":"-宋体","obliquingAngle":0.0,"priorSize":900.0,"textSize":0.0,"xScale":0.8},{"bigFontFileName":"","fileName":"simhei.ttf","isBackwards":false,"isShapeFile":false,"isUpsideDown":false,"isVertical":false,"name":"-黑体","obliquingAngle":0.0,"priorSize":0.2,"textSize":0.0,"xScale":0.8},{"bigFontFileName":"","fileName":"","isBackwards":false,"isShapeFile":false,"isUpsideDown":false,"isVertical":false,"name":"AA","obliquingAngle":0.0,"priorSize":450.0,"textSize":0.0,"xScale":0.75},{"bigFontFileName":"","fileName":"arial.ttf","isBackwards":false,"isShapeFile":false,"isUpsideDown":false,"isVertical":false,"name":"Annotative","obliquingAngle":0.0,"priorSize":0.2,"textSize":0.0,"xScale":1.0},{"bigFontFileName":"","fileName":"arial.ttf","isBackwards":false,"isShapeFile":false,"isUpsideDown":false,"isVertical":false,"name":"Standard","obliquingAngle":0.0,"priorSize":0.2,"textSize":0.0,"xScale":1.0},{"bigFontFileName":"GBCBIG","fileName":"SIMPLEX","isBackwards":false,"isShapeFile":false,"isUpsideDown":false,"isVertical":false,"name":"_TCH_DIM_T3","obliquingAngle":0.0,"priorSize":350.0,"textSize":0.0,"xScale":0.7058823529411765},{"bigFontFileName":"","fileName":"方正细等线简体.TTF","isBackwards":false,"isShapeFile":false,"isUpsideDown":false,"isVertical":false,"name":"样式 1","obliquingAngle":0.0,"priorSize":55.0,"textSize":0.0,"xScale":1.0},{"bigFontFileName":"","fileName":"SIMPLEX.shx","isBackwards":false,"isShapeFile":false,"isUpsideDown":false,"isVertical":false,"name":"轴线号G4塔楼$0$_TCH_DIM_T3","obliquingAngle":0.0,"priorSize":0.2,"textSize":0.0,"xScale":0.7058823529411765}]';
                //service.setTextStyle(t);
                service.setTextStyle(json_data.data.textstyle);
            }
        };
        xhr.send(null);
    }
    SaveJson(savejson, callback) {
        //报错使用异步，否则容易卡死;
        var xhr = new XMLHttpRequest();
        let url = this.serviceUrl + `json/sql?projectId=${this._productId}`;
        xhr.open("post", url, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        if (this.token !== null) {
            xhr.setRequestHeader("Authorization", "Bearer " + this.token);
        }
        xhr.onload = function (e) {
            if (this.status == 200) {
                callback(true);
            }
            else {
                //失败
                callback(false);
            }
        };
        savejson["mapId"] = this.mapid;
        var str = JSON.stringify(savejson);
        xhr.send(str);
    }
    getMaxID(cadmap) {
        //送数据库里面获取线形样式;
        var xhr = new XMLHttpRequest();
        let url = this.GetSeviceUrl("feature");
        xhr.open("get", url, false);
        if (this.token !== null) {
            xhr.setRequestHeader("Authorization", "Bearer " + this.token);
        }
        const _this = this;
        xhr.onload = function (e) {
            if (this.status == 200) {
                var json_data = JSON.parse(xhr.responseText);
                cadmap.maxID = json_data.data;
            }
        };
        xhr.send(null);
    }
    //返回服务url
    GetSeviceUrl(params) {
        return this.serviceUrl + `${params}/${this.mapid}/`;
    }
}
exports.DwgService = DwgService;
exports.default = DwgService;
